/**
 * Created by Lkarmelo on 23.08.2017.
 * -----
 * Last Modified: 09.07.2018 11:21:12
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import {combineEpics, Epic} from 'redux-observable';

import history from 'app/history';
import {parseOptions} from 'app/utils/queryStringOptions';

import clientRoutes from 'common/clientRoutes';

import {
    logIn,
    getLogOutEpic,
    logoutOnUnauthorizedError,
    confirmAuthenticationEpic,
    confirmAuthenticationSuccessEpic,

    getRedirectToPreviousPageOnLoginSuccessEpic,
    authorizeOnFirstRequestSuccess,
    refreshAuthenticatorEpic,
} from './authorization';

import {
    loadUser,
    loadUserPermissions,
    loadUserSocial,
    refreshUserEpic
} from './loadUser';

import {loadFiltersMeta} from './loadFiltersMeta';
import {loadObjectCard, checkDocumentContentEpic} from './loadObjectCard';
import {loadShowcaseDocuments} from './loadShowcaseDocuments';
import {
    loadSearchResults,
    resetSearchQueryToLastExecutedQuery,
    updateBrowserLocation,
    executeSearchRequest,
} from './loadSearchResults';
import {loadIssueInfoCatalog} from './loadIssueInfoCatalog';
import {loadLibraryStats} from './loadLibraryStats';
import {requestLikeStateChangeEpic} from './requestLikeStateChange';
import {shareDocumentEpic} from './shareDocument';
import {loadSearchHints} from './loadSearchHints';
import {filtersToDefaultsEpic} from './filtersToDefaults';
import { ExtendedStore } from 'common/store';
import { Action } from 'redux';
import {requestFavoriteStateChangeEpic} from './requestFavoriteStateChange';
import {formEpics} from './forms';
import {
    loadReaderPageContent, loadReaderBookMeta, saveReaderBookScale,
    saveReaderLastPageEpic, addBookmarkEpic, loadBookmarks, removeBookmarkEpic
} from './bookReader';
import {
    subscriptionEpic,
    loadSubscriptionsEpic,
    subscribeToMailingListEpic,
    unsubscribeFromMailingListEpic,
    unsubscribeFromAllMailingListsEpic
} from './subscriptions';
import {
    loadNotificationTypesEpic,
    loadNotificationSettingsEpic,
    saveNotificationSettingEpic,
    loadNotificationsEpic,
    markNotificationsAsReadEpic,
    notificationsPollingEpic
} from './notifications';
import { uploadDocumentContentEpic, uploadDocumentCoverEpic } from './uploadDocument';
import { publishDocumentEpic, loadDocumentEpic, setDocumentEpic } from './document';

import secured from './secured';

// TODO: remove and rewrite nkc-frontend-tools
// hack for nkc-frontend-tools
const As = <T>(n: any): T => n as T;

export default combineEpics(
    As<Epic<Action, ExtendedStore.IState>>(secured),

    logIn,
    getLogOutEpic(history),
    getRedirectToPreviousPageOnLoginSuccessEpic(history, clientRoutes.main.getUrl(), parseOptions),
    authorizeOnFirstRequestSuccess,
    logoutOnUnauthorizedError,
    confirmAuthenticationEpic,
    confirmAuthenticationSuccessEpic,
    refreshAuthenticatorEpic,

    loadFiltersMeta,
    filtersToDefaultsEpic,

    loadObjectCard,
    checkDocumentContentEpic,

    loadSearchResults,
    resetSearchQueryToLastExecutedQuery,
    updateBrowserLocation,
    executeSearchRequest,

    As<Epic<Action, ExtendedStore.IState>>(loadShowcaseDocuments),
    As<Epic<Action, ExtendedStore.IState>>(loadLibraryStats),

    requestLikeStateChangeEpic,
    requestFavoriteStateChangeEpic,

    shareDocumentEpic,

    As<Epic<Action, ExtendedStore.IState>>(loadSearchHints),

    As<Epic<Action, ExtendedStore.IState>>(loadIssueInfoCatalog),

    As<Epic<Action, ExtendedStore.IState>>(loadUser),

    refreshUserEpic,

    loadUserPermissions,
    loadUserSocial,

    subscriptionEpic,
    loadSubscriptionsEpic,

    loadReaderPageContent,
    loadReaderBookMeta,
    saveReaderBookScale,
    saveReaderLastPageEpic,
    addBookmarkEpic,
    removeBookmarkEpic,
    loadBookmarks,

    subscribeToMailingListEpic,
    unsubscribeFromMailingListEpic,
    unsubscribeFromAllMailingListsEpic,

    notificationsPollingEpic,
    loadNotificationTypesEpic,
    loadNotificationSettingsEpic,
    loadNotificationsEpic,
    saveNotificationSettingEpic,
    markNotificationsAsReadEpic,

    uploadDocumentContentEpic,
    uploadDocumentCoverEpic,

    setDocumentEpic,
    loadDocumentEpic,
    publishDocumentEpic,

    ...formEpics,
);
