/**
 * Created by Lkarmelo on 08.11.2017.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import LoadingScreen from 'app/components/LoadingScreen';

import {ExtendedStore} from 'common/store';

import clientRoute from 'common/clientRoutes';

export const LoadingWrapper = (WrappedComponent): React.SFC<any> =>
    ({isLoading, ...props}) => {
        return isLoading ?
            <LoadingScreen/> :
            <WrappedComponent  {...props}/>;
    };

const RedirectOnAccessDeniedWrapper = (WrappedComponent): React.SFC<any> =>
    ({isAllowed, redirectTarget, ...props}) => {
        return isAllowed ?
            <WrappedComponent  {...props}/> :
            <Redirect to={redirectTarget} />;
    };

const redirectAuthorizedProps = (state: ExtendedStore.IState, ownProps) => ({
        redirectTarget: `${clientRoute.main.getUrl()}`,
        isLoading: state.authorization.isAuthorized === null,
        isAllowed: !state.authorization.isAuthorized,
        ...ownProps
    });

export const redirectAuthorized = (WrappedComponent) =>
    connect(redirectAuthorizedProps)(LoadingWrapper(RedirectOnAccessDeniedWrapper(WrappedComponent)));

const redirectUnauthorizedProps = (state: ExtendedStore.IState, ownProps) => {
    // current location
    const {location} = ownProps;
    let currentPagePath = location.pathname + location.search;
    let backURLParams = (currentPagePath ? '?backUrl=' + encodeURIComponent(currentPagePath) : '');

    return ({
        redirectTarget: `${clientRoute.login.getUrl()}${backURLParams}`,
        isLoading: state.authorization.isAuthorized === null,
        isAllowed: !!state.authorization.isAuthorized,
        ...ownProps
    });
};

export default (WrappedComponent) =>
    connect(redirectUnauthorizedProps)(LoadingWrapper(RedirectOnAccessDeniedWrapper(WrappedComponent)));
