/**
 * Created by pborisov
 */

import {createAction} from 'redux-actions';
import {ExtendedStore} from 'common/store';

export const clearUserData = createAction('CLEAR_USER_DATA');

export const fetchUserPermissions = createAction('FETCH_USER_PERMISSIONS');
export const setUserPermissions = createAction<string[]>('SET_USER_PERMISSIONS');

export const fetchUserSocial = createAction('FETCH_USER_SOCIAL');
export const setUserSocial = createAction<ExtendedStore.IUserSocial | null>('SET_USER_SOCIAL');
