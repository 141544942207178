/**
 * Created by lkarmelo on 16.08.2019.
 */

import {FACEBOOK_PROVIDER_ID, VK_PROVIDER_ID, TWITTER_PROVIDER_ID} from 'common/constants';

export interface IProviderSetting {
    className: string;
    name: string;
}

export const SocialProviderToSetting: Map<string, IProviderSetting> = new Map([
    [FACEBOOK_PROVIDER_ID, {className: 'fb', name: 'Facebook' }],
    [VK_PROVIDER_ID, { className: 'vk', name: 'Вконтакте' }],
    [TWITTER_PROVIDER_ID, { className: 'twitter', name: 'Twitter' }],
]);
