import React, { useCallback } from 'react';
import RegularModal from '../../common/modals/RegularModal';
import { Form, Field } from 'react-final-form';
import { emailValidation } from 'app/utils/forms/validation';
import { TextFormField, InputFieldMode } from '../../common/form-fields';
import classNames from 'classnames';
import { useFormSenderHelper } from 'app/hooks/forms';

import IProps from './interfaces/ISubscriptionPopupProps';

import * as styles from './SubscriptionPopup.scss';
import { useOnPopStateAndUnmountEffect } from 'app/hooks/useOnPopStateAndUnmountEffect';

const SubscriptionPopup: React.FC<IProps> = (props) => {
    const {
        isVisible,
        emailFormStatus,
        submitEmail,
        resetEmailForm,
        subscribeToAll,
        closePopup
    } = props;

    const onSubmit = useCallback(
        (values) => {
            submitEmail(values.email);
        },
        []
    );

    const onSetEmailSuccess = useCallback(
        () => {
            subscribeToAll();
            closePopup();
        },
        []
    );

    useOnPopStateAndUnmountEffect(closePopup);

    useFormSenderHelper(emailFormStatus, resetEmailForm, onSetEmailSuccess);

    return (
        <RegularModal
            isVisible={isVisible}
            close={closePopup}
        >
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, hasValidationErrors }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={styles.subscriptionPopupHeader}>
                                Введите email для рассылки
                            </div>
                            <Field name={'email'} validate={emailValidation}>
                                {({input, meta}) => (
                                    <TextFormField
                                        {...input}
                                        mode={InputFieldMode.REGULAR}
                                        label={'Email'}
                                        error={meta.touched ? meta.error : undefined}
                                    />
                                )}
                            </Field>
                            <button
                                type="submit"
                                className={classNames('btn-primary', styles.subscriptionPopupSubmitButton)}
                                disabled={hasValidationErrors || submitting}
                            >
                                Подписаться на рассылку
                            </button>
                        </form>
                    );
                }}
            />
        </RegularModal>
    );
};

export default SubscriptionPopup;
