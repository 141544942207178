import React from 'react';
import { Slider as AntSlider } from 'antd';
import classNames from 'classnames';
import { SliderProps } from 'antd/lib/slider';

import * as styles from './Slider.scss';

const Slider: React.FC<SliderProps> = ({ className, ...props }) => {
    return (
        <AntSlider
            {...props}
            className={classNames(
                className,
                styles.slider
            )}
        />
    );
};

export default Slider;
