import React, { useMemo, useCallback, useState, useRef } from 'react';
import classNames from 'classnames';

import uniqueId from 'lodash.uniqueid';
import { renderError } from '../utils/renderError';
import { KeyCode } from 'app/types/KeyCode';

import DropDownSelect from '../../select/DropDownSelect/SelectBase/SelectBase';
import { normalizeOption, findOption, filterOption } from './SelectFunction';
import OptionList from './OptionList';
import SelectLabel from './SelectLabel';

import IProps, { IOption, ViewMode } from './interfaces/ISelectProps';
import * as styles from './MaterialSelectFormField.scss';

import DropDownOptionGroup from './DropDownSelect/OptionGroup';
import DropDownOption from './DropDownSelect/Option';

import CheckboxOptionGroup from './CheckboxSelect/OptionGroup';
import CheckboxOption from './CheckboxSelect/Option';

import { IListProps } from '../../select/DropDownSelect/SelectBase/interfaces/ISelectProps';

const SelectFormField: React.FC<IProps> = ({ children, className, mode, error, required, ...rest }) => {
    const id = useMemo(() => uniqueId('select-field-'), []);

    const {
        active, defaultValues, disabled, withNull,
        onSelect: onSelectProp, onClear: onClearProp, title,
        isMultiSelect, options
    } = rest;

    const activeValues: string[] = useMemo(
        () => !active
            ? []
            : Array.isArray(active)
                ? active
                : [active],
        [active]
    );
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchFocus, setIsSearchFocus] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    /**
     * action's callback
     */
    const showClear = useMemo(() => !!defaultValues || withNull, [defaultValues, withNull]);
    const onClear = useCallback(
        () => {
            !!onClearProp && onClearProp();
            if (!withNull &&
                (!defaultValues || (Array.isArray(defaultValues) && defaultValues.length === 0))) {
                !!defaultValues
                    ? onSelectProp(defaultValues)
                    : onSelectProp(!isMultiSelect ? null : []);
            }
        },
        [isMultiSelect, onSelectProp, defaultValues, onClearProp]
    );

    const onSelect = useCallback(
        (value: IOption) =>
            !isMultiSelect
                ? onSelectProp(value.id)
                : onSelectProp([...activeValues, value.id]),
        [isMultiSelect, onSelectProp, activeValues]
    );

    const onDeselect = useCallback(
        (value: IOption) => {

            if (isMultiSelect) {
                const selectedValue = [...activeValues.filter((item: string) => value.id !== item)];
                onSelectProp((selectedValue.length === 0 && withNull) ? [] : selectedValue);
            } else if (withNull) {
                onSelectProp(null);
            }

        },
        [isMultiSelect, onSelectProp, activeValues, onClear]
    );

    /** */
    const normalizedOptions = useMemo(
        () => normalizeOption(options || []),
        [options]
    );

    const activeOptions = useMemo(
        () => findOption(activeValues, normalizedOptions, []),
        [normalizedOptions, activeValues]
    );

    const activeOptionsLabel = activeOptions
        .map((item: IOption) => item.label)
        .filter(Boolean)
        .join(', ');

    const searchInputRef = useRef<HTMLInputElement>(null);
    const onSearchInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(event.target.value);
        },
        [setSearchQuery]
    );

    const CustomOptionList = useMemo(
        () =>
            mode === ViewMode.CHECKBOX
                ? React.forwardRef<HTMLDivElement, IListProps>(
                    (props, ref) =>
                        (
                            <OptionList
                                ref={ref}
                                {...props}
                                optionRenderer={CheckboxOption}
                                optionGroupRenderer={CheckboxOptionGroup}
                            />
                        )
                )
                : React.forwardRef<HTMLDivElement, IListProps>(
                    (props, ref) =>
                        (
                            <OptionList
                                ref={ref}
                                {...props}
                                optionRenderer={DropDownOption}
                                optionGroupRenderer={DropDownOptionGroup}
                            />
                        )
                ),
        [mode]
    );

    const filteredOptions = useMemo(
        () => {
            if (!searchQuery) {
                return normalizedOptions;
            }

            const searchQueryLowerCase = searchQuery.toLowerCase();
            return filterOption(searchQueryLowerCase, normalizedOptions);
        },
        [normalizedOptions, searchQuery]
    );

    const isActive: boolean = activeValues.length > 0;
    const shrinkLabel: boolean = !!isActive || isOpened || isFocused;
    const isSearchActive: boolean = normalizedOptions.length > 6;

    const onFieldKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            switch (event.key) {
                case KeyCode.Enter:
                    setIsOpened(true);
                    break;
                case KeyCode.Escape:
                case KeyCode.Esc:
                    setIsOpened(false);
                    break;
                default:
                    break;
            }
        },
        [shrinkLabel]
    );

    return (
        <div
            key={id}
            className={classNames(
                className,
                'form-field',
                'material-select-form-field',
                {
                    [styles.materialSelectFormFieldError]: !!error,
                    [styles.materialSelectFormFieldDisabled]: disabled
                }
            )}
            tabIndex={0}
            onFocus={() => { setIsFocused(true); }}
            onBlur={() => { setIsFocused(false); }}
            onClick={() => !shrinkLabel && setIsOpened(true)}
            onKeyDown={onFieldKeyDown}
        >
            <div
                className={classNames(
                    styles.materialSelectFormFieldLabel,
                    {
                        [styles.materialSelectFormFieldLabelShrink]: shrinkLabel,
                        [styles.materialSelectFormFieldLabelRequired]: required
                    }
                )}
            >
                {title}
            </div>

            <DropDownSelect
                className={styles.materialSelectFormField}
                dropDownClassName={classNames(
                    styles.selectFormFieldDropDownList,
                    { [styles.materialSelectFormFieldFocus]: isSearchFocus }
                )}
                onSelect={onSelect}
                onDeselect={onDeselect}
                onOpen={() => setIsOpened(true)}
                onClose={() => setIsOpened(false)}
                onClear={showClear && onClear}
                isOpened={isOpened}
                active={activeValues}
                title={activeOptionsLabel || ''}
                labelRenderer={SelectLabel}
                optionRenderer={CustomOptionList}
                options={filteredOptions}
            >
                {isSearchActive &&
                    (
                        <>
                            <i className={styles.selectFormFieldSearchIcon} />
                            <input
                                className={styles.selectFormFieldSearchInput}
                                type="search"
                                value={searchQuery}
                                onChange={onSearchInputChange}
                                onFocus={() => setIsSearchFocus(true)}
                                onBlur={() => setIsSearchFocus(false)}
                                ref={searchInputRef}
                            />
                        </>
                    )
                }
            </DropDownSelect>
            <div className={'form-field-error'}>
                {renderError(error)}
            </div>
        </div>
    );
};

SelectFormField.defaultProps = {
    disabled: false,
    withNull: false,
    mode: ViewMode.CHECKBOX
};

export default SelectFormField;
