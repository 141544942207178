/**
 * Created by lkarmelo on 29.03.2019.
 */

import React from 'react';
import classNames from 'classnames';

import {Link} from 'react-router-dom';
import BookDetailsBase from '../Base/BookDetailsBase';

import IProps, {ClsNames} from '../interfaces/IBookDetailsProps';

import * as styles from './BookDetailsBulletin.scss';

const renderCategories = () => null;

const renderAnnotation = () => null;

const renderAuthorsRow = (props: IProps, clsNames: ClsNames) => (
    <div className={clsNames.bookDetailsAuthors}>
        {props.renderAuthorNames(props, clsNames)}
        <Link
            className={styles.bookDetailsBulletinBulletinsLink}
            to={''}
        >
            Все вестники рффи
        </Link>
    </div>
);

const BookDetailsBulletin: React.FunctionComponent<IProps> = (props) => (
    <BookDetailsBase
        renderCategories={renderCategories}
        renderAuthorsRow={renderAuthorsRow}
        renderAnnotation={renderAnnotation}
        {...props}
        className={classNames(props.className, styles.bookDetailsBulletin)}
    />
);

export default BookDetailsBulletin;
