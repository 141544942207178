/**
 * Created by Lkarmelo on 16.10.2017.
 */

import queryString from 'qs';

export type ParamMap =  {[paramName: string]: string | number | boolean};

type TParamMap<T extends string> = {[paramName in T]?: string | number | boolean | Array<string | number | boolean>};

export interface IRouteOptions {
    paramsWithBackslash?: boolean;
}

export default class Route<TParams extends string = any, TQuery extends string = any> {
    static stringifyOptions = {};
    options: IRouteOptions = {};
    template: {expand: Function} = null;
    getUrl(params?: TParamMap<TParams>, query?: TParamMap<TQuery>): string {
        if (!this.template) {
            return;
        }
        let url: string = this.template.expand(params);
        if ( this.options.paramsWithBackslash ) {
            url = url.replace(/%2F|%2f/g, '/');
        }

        if (query === undefined) {
            return url;
        }

        let paramStr = queryString.stringify(query, Route.stringifyOptions);

        return `${url}${paramStr}`;
    }
}
