import React, { useMemo, useCallback } from 'react';

import LinkIcon from 'app/components/common/LinkIcon';
import clientRoutes from 'common/clientRoutes';
import Table from 'app/components/common/Table';
import { Column, useTable } from 'react-table';
import { INotificationSetting } from 'common/api/ExtendedApi';
import Checkbox from 'app/components/common/Checkbox';

import IProps from './interfaces/INotificationSettingsProps';
import * as styles from './NotificationSettings.scss';

const NotificationSettings: React.FC<IProps> = (props) => {
    const { notificationSettings, notificationTypes, saveNotificationSetting } = props;

    const columns: Column<INotificationSetting>[] = useMemo(
        () => [
            {
                id: 'type',
                Header: 'Тип уведомления',
                accessor: notification => notification.notificationType,
                Cell: ({ cell: notificationType }) => notificationTypes[notificationType.value] || notificationType.value,
            },
            {
                id: 'email',
                Header: 'Email',
                accessor: notification => notification.sendToEmail,
                Cell: ({ cell: isEmailNotification, row: notificationSetting }) => (
                    <Checkbox
                        checked={isEmailNotification.value}
                        onChange={(event) => {
                            const checked = event.target.checked;
                            saveNotificationSetting({ ...notificationSetting.original, sendToEmail: checked });
                        }}
                    />
                ),
            },
            {
                id: 'board',
                Header: 'Личный кабинет',
                accessor: notification => notification.sendToUserPage,
                Cell: ({ cell: isBoardNotification, row: notificationSetting }) => (
                    <Checkbox
                        checked={isBoardNotification.value}
                        onChange={(event) => {
                            const checked = event.target.checked;
                            saveNotificationSetting({ ...notificationSetting.original, sendToUserPage: checked });
                        }}
                    />
                )
            },
            {
                id: 'empty'
            }
        ],
        [notificationTypes]
    );

    const getRowID = useCallback(
        row => row.notificationType,
        []
    );

    const allNotificationSettings = useMemo(
        () => notificationTypes && Object.keys(notificationTypes).map<INotificationSetting>(notificationType => ({
                notificationType,
                sendToEmail: false,
                sendToUserPage: false,
                ...notificationSettings[notificationType]
            })
        ),
        [notificationTypes, notificationSettings]
    );

    const tableInstance = useTable<INotificationSetting>({
        columns,
        data: allNotificationSettings,
        getRowID
    });

    return (
        <>
            <Table
                className={styles.notificationSettings}
                columnClassNames={{
                    type: styles.notificationSettingsTypeColumn,
                    email: styles.notificationSettingsEmailColumn,
                    board: styles.notificationSettingsBoardColumn
                }}
                tableInstance={tableInstance}
            />
            <LinkIcon
                className={styles.notificationSettingsBackBtn}
                link={clientRoutes.notifications.getUrl()}
            >
                Назад
            </LinkIcon>
        </>
    );
};

export default NotificationSettings;
