/**
 * Created by lkarmelo on 29.03.2019.
 */

import React from 'react';

import BookSnippetBulletin from './Bulletin/BookSnippetBulletin';
import BookSnippetBase from './Base/BookSnippetBase';
import BookSnippetDigest from './Digest/BookSnippetDigest';

import {ObjectType} from 'common/store/ExtendedStore';

import IProps from './interfaces/IBookSnippetProps';

const BookSnippet: React.FunctionComponent<IProps> = (props) => {
    const type = props.book &&
        props.book.document &&
        props.book.document.meta &&
        props.book.document.meta.type;

    switch (type) {
        case ObjectType.SocialBulletin:
        case ObjectType.TechnicalBulletin: return <BookSnippetBulletin {...props} />;
        case ObjectType.Digest: return <BookSnippetDigest {...props} />;
        default: return <BookSnippetBase {...props} />;
    }
};

export default BookSnippet;
