/**
 * Created by lkarmelo on 01.03.2019.
 */

import React from 'react';
import {useMemo, useState, useRef, useCallback} from 'react';
import {Portal} from 'react-portal';
import classNames from 'classnames';

import {CSSTransition} from 'react-transition-group';

import {useLayoutEffectOnUpdate} from 'app/hooks/useLayoutEffectOnUpdate';
import {useOnSelectToggle} from 'app/hooks/useOnSelectToggle';

import {getElementOffset} from 'nkc-frontend-tools/utils/getElementOffset';
import {getEnhancedReactChildren} from 'app/utils/getEnhancedReactChildren';

import IProps from './interfaces/IOptionGroupProps';

import * as styles from './OptionGroup.scss';

const OptionGroup: React.FunctionComponent<IProps> = (props) => {

    const {title, children, childrenValues, onDeselect, onSelect, activeValues, isActive, isDisabled} = props;

    const [isOpened, setIsOpened] = useState(false);

    const dropDownRef = useRef<HTMLDivElement>(null);
    const selfRef = useRef<HTMLLIElement>(null);

    const hasActiveChildren = Array.isArray(childrenValues) && childrenValues.some(childVal =>
        Array.isArray(activeValues) && activeValues.includes(childVal)
    );

    const onClick = useOnSelectToggle(
        () => onSelect && onSelect(props),
        () => onDeselect && onDeselect(props),
        isActive
    );

    const enhancedChildren = useMemo(
        () => getEnhancedReactChildren(
            children,
            (childProps) => ({
                onSelect,
                onDeselect,
                isActive: Array.isArray(activeValues) && activeValues.includes(childProps.value)
            })
        ),
        [children]
    );

    useLayoutEffectOnUpdate(
        () => {
            const dropDown: HTMLDivElement = dropDownRef.current;
            const selfElement: HTMLLIElement = selfRef.current;

            if (!isOpened || !dropDown || !selfElement) {
                return;
            }
            const selfRect = selfElement.getBoundingClientRect();

            dropDown.style.display = 'block';

            //вычисление позиции по горизонтали
            dropDown.style.left = '0';

            const defaultLeftPosition = selfRect.left + selfRect.width;
            const isOutOfViewportHorizontally = defaultLeftPosition + dropDown.clientWidth > document.documentElement.clientWidth;

            if (isOutOfViewportHorizontally) {
                dropDown.style.left = `${selfRect.left - dropDown.clientWidth}px`;
            } else {
                dropDown.style.left = `${defaultLeftPosition}px`;
            }

            //вычисление позиции по вертикали
            dropDown.style.top = '0';

            //top если элемент расположен обычно нормально
            const defaultTopPosition = getElementOffset(selfElement).top;
            //top если элемент выходит за нижнюю гранизу вьюпорта
            const topPositionHigher = defaultTopPosition + selfRect.height - dropDown.clientHeight;
            const isOutOfViewportVertically = defaultTopPosition + dropDown.clientHeight > window.innerHeight;

            if (isOutOfViewportVertically) {
                //если элемент слишком большой и уходит за верхние границы страницы (не вьюпорта)
                if (topPositionHigher >= 0) {
                    dropDown.style.top = `${topPositionHigher}px`;
                } else {
                    dropDown.style.top = '0';
                }
            } else {
                dropDown.style.top = `${defaultTopPosition}px`;
            }

            dropDown.style.display = '';
        },
        [isOpened]
    );

    const onMouseEnter = useCallback(
        () => {
            setIsOpened(true);
        },
        []
    );

    const onMouseLeave = useCallback(
        () => {
            setIsOpened(false);
        },
        []
    );

    return (
        <li
            ref={selfRef}
            className={classNames(
                styles.optionGroup,
                {
                    [styles.optionGroupActive]: isActive,
                    [styles.optionGroupDisabled]: isDisabled,
                    [styles.optionGroupActiveChildren]: hasActiveChildren
                }
            )}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <button
                type="button"
                className={`btn ${styles.optionGroupToggle}`}
                onClick={onClick}
                tabIndex={-1}
            >
                {title}
            </button>
            <Portal>
                <CSSTransition
                    in={isOpened}
                    timeout={{
                        enter: 200,
                        exit: 0
                    }}
                    classNames={'slide-transition-right'}
                >
                    {status => (
                        <div
                            ref={dropDownRef}
                            className={classNames(
                                styles.optionGroupDropDown,
                                {[styles.optionGroupDropDownHidden]: status === 'exited'}
                            )}
                        >
                            <ul
                                className={styles.optionGroupList}
                            >
                                {enhancedChildren}
                            </ul>
                        </div>
                    )}
                </CSSTransition>
            </Portal>
        </li>
    );
};

export default React.memo(OptionGroup) as React.FunctionComponent<IProps>;
