/**
 * Created by Lkarmelo on 08.11.2017.
 */

import React from 'react';
import {PureComponent} from 'react';
import LinkIcon from 'app/components/common/LinkIcon';

import clientRoutes from 'common/clientRoutes';

import * as styles from './LoginLayout.scss';

export default class LoginLayout extends PureComponent {
    render(): JSX.Element {
        return (
            <div className={'layout-wrapper-no-footer'}>
                {/* Link on main page */}
                <LinkIcon
                    className={styles.mainPageLink}
                    link={clientRoutes.main.getUrl()}
                >
                    На главную
                </LinkIcon>
                {this.props.children}
            </div>
        );
    }
}
