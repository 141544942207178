/**
 * Created by Lkarmelo on 21.11.2017.
 */

import {handleActions, Action, handleAction} from 'redux-actions';

import {ExtendedStore} from 'common/store';

import { SET_USER_DATA } from 'nkc-frontend-tools/redux/actions/user';
import {clearUserData, setUserSocial, setUserPermissions} from '../actions/user';

import {setReducer} from './utils/setReducer';
import { updateProfile } from '../actions/profile';
import { ExtendedApi } from 'common/api';

export default handleActions(
    {
        [SET_USER_DATA]: setReducer,
        [updateProfile.toString()]: (
            state: ExtendedStore.ISystemUser,
            {payload}: Action<ExtendedApi.IProfileUpdateBody>
        ): ExtendedStore.IProfile => ({
            ...state,
            middleName: null, // empty middleName clear original one
            ...payload
        }),

        [clearUserData.toString()](state: ExtendedStore.ISystemUser | {}) {
            return {};
        },
        [setUserPermissions.toString()](state: ExtendedStore.ISystemUser, {payload}: Action<string[]>) {
            return {
                ...state,
                permissions: payload
            };
        },
        [setUserSocial.toString()](
            state: ExtendedStore.ISystemUser,
            {payload}: Action<ExtendedStore.IUserSocial[]>
        ): ExtendedStore.ISystemUser {
            return {
                ...state,
                userSocial: payload
            };
        },
    },
    {} //- default value for user
);
