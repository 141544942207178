import {
    subscribeToAllMailingLists,
    fetchSubscriptions,
    setSubscriptions,
    setSubscriptionStatus,
    subscribeToMailingList,
    ISubscribeToMailingListPayload,
    unsubscribeFromMailingList,
    unsubscribeFromAllMailingLists,
    clearSubscriptions
} from '../actions/subscriptions';
import { MiddlewareAPI } from 'redux';
import { ExtendedStore } from 'common/store';
import { Observable } from 'rxjs';
import { setProfile } from '../actions/profile';
import { showPopup } from '../actions/popups';
import { PopupName } from 'app/types/PopupName';
import { SubscriptionType, ISubscriptionIdentifier } from 'common/api/ExtendedApi';
import {
    subscriptionsRequest,
    subscriptionsResolve,
    subscriptionsReject,
    subscribeToMailingListResolve,
    unsubscribeFromMailingListResolve,
    subscribeToMailingListReject,
    unsubscribeFromMailingListReject,
    subscribeToMailingListRequest,
    unsubscribeFromMailingListRequest,
    unsubscribeFromAllMailingListsResolve,
    unsubscribeFromAllMailingListsReject
} from '../actions/loading';
import { createSimpleLoadingEpic } from 'nkc-frontend-tools/redux/epics/utils';
import { Action } from 'redux-actions';
import { apiCalls } from 'common/api';
import { raiseSystemNotification } from '../actions/systemNotifications';
import { SystemNotificationType } from 'common/store/ExtendedStore';

export const subscriptionEpic = (action$, store: MiddlewareAPI<ExtendedStore.IState>) => action$
    .ofType(subscribeToAllMailingLists.toString())
    .exhaustMap(action => {
        const profile = store.getState().profile;
        if (profile && Object.keys(profile).length > 0) {
            return Observable.of(action);
        }
        return action$
            .ofType(setProfile.toString())
            .mapTo(action)
            .take(1);
    })
    .map(() => {
        const email = store.getState().profile.email;
        if (email) {
            return subscribeToMailingList({
                subscription: {
                    type: SubscriptionType.NEWS
                },
                raiseNotification: true
            });
        }
        return showPopup(PopupName.SubscribeToMailingList);
    });

export const loadSubscriptionsEpic = createSimpleLoadingEpic({
    triggers: [fetchSubscriptions.toString()],
    apiCallName: 'subscriptions' as any,
    actions: {
        requestAction: subscriptionsRequest,
        resolveAction: subscriptionsResolve,
        rejectAction: subscriptionsReject,
        setAction: setSubscriptions
    }
});

export const unsubscribeFromAllMailingListsEpic = createSimpleLoadingEpic({
    triggers: [unsubscribeFromAllMailingLists.toString()],
    apiCallName: 'unsubscribeFromAllMailingLists' as any,
    actions: {
        requestAction: unsubscribeFromMailingListRequest,
        resolveAction: unsubscribeFromAllMailingListsResolve,
        rejectAction: unsubscribeFromAllMailingListsReject,
        setAction: clearSubscriptions
    }
});

export const subscribeToMailingListEpic = action$ => action$
    .ofType(subscribeToMailingList.toString())
    // принцип работы эпика такой же, как в requestFavoriteStateChange
    .groupBy(
        ({payload}: Action<ISubscribeToMailingListPayload>) => payload.subscription.type + payload.subscription.value,
        undefined,
        () => Observable.timer(15000)
    )
    .mergeMap(group => group
        .exhaustMap(({payload}: Action<ISubscribeToMailingListPayload>) =>
            apiCalls.subscribeToMailingList(payload.subscription)
                .mergeMap(() => {
                    const commonActions = [
                        subscribeToMailingListResolve(),
                        setSubscriptionStatus({ subscription: payload.subscription, status: true })
                    ];

                    if (payload.raiseNotification) {
                        return Observable.of(...commonActions, raiseSystemNotification(SystemNotificationType.SubscribeToAllNews));
                    }

                    return Observable.of(...commonActions);
                })
                .catch(e => {
                    console.error(e);
                    return Observable.of(subscribeToMailingListReject(e));
                })
                .startWith(subscribeToMailingListRequest())
        )
    );

export const unsubscribeFromMailingListEpic = action$ => action$
    .ofType(unsubscribeFromMailingList.toString())
        .groupBy(
        ({payload}: Action<ISubscriptionIdentifier>) => payload.type + payload.value,
        undefined,
        () => Observable.timer(15000)
    )
    .mergeMap(group => group
        .exhaustMap(({payload}: Action<ISubscriptionIdentifier>) =>
            apiCalls.unsubscribeFromMailingList(payload)
                .mergeMap(() => Observable.of(
                        unsubscribeFromMailingListResolve(),
                        setSubscriptionStatus({ subscription: payload, status: false })
                ))
                .catch(e => {
                    console.error(e);
                    return Observable.of(unsubscribeFromMailingListReject(e));
                })
                .startWith(unsubscribeFromMailingListRequest())
        )
    );
