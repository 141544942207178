/**
 * Created by lkarmelo on 07.05.2019.
 */
import {ExtendedStore} from 'common/store';

export const getDocumentSnippetWithModifiedStat = (
    snippet: ExtendedStore.IDocumentSnippet,
    statName: Exclude<keyof ExtendedStore.IDocumentStats, 'shares'>,
    delta: number
): ExtendedStore.IDocumentSnippet => {
    if (!snippet || !snippet.stats) {
        return snippet;
    }

    const currentStatVal = snippet.stats[statName];
    const nextStatVal = currentStatVal + delta >= 0 ? currentStatVal + delta : 0;

    return {
    ...snippet,
        stats: {
            ...snippet.stats,
            [statName]: nextStatVal
        }
    };
};
