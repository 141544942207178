import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';

import { IOption } from 'app/components/common/select/DropDownSelect/SelectBase/interfaces/ISelectProps';
import { FormName } from 'app/types/FormName';
import { ExtendedStore } from 'common/store';
import { IForm, IDocument } from 'common/store/ExtendedStore';
import { FilterName } from 'common/types/FilterName';
import { IPerson } from 'common/api/ExtendedApi';

import { AttrForms } from '../forms';
import { IFilterValueDescription, IFilterMeta, IDocumentPerson } from 'nkc-frontend-tools/types/Store';

import DocumentForm from './DocumentForm';

import { createDocument, updateDocument } from 'app/redux/actions/document';
import { removeForm, setForm } from 'app/redux/actions/forms/forms';

import {
    IDocumentFormActionProps,
    IDocumentFormOwnProps,
    IDocumentFormStateProps
} from '../interfaces/IDocumentFormProps';
import { IWizzardFormProps } from '../AddEditDocument/WizzardWrapper';

const toFilterValueToOptions = (values: IFilterValueDescription[], parent?: string) => {
    const filteredValues =
        values ?.filter(filterValue =>
            !!parent ? filterValue.parent === parent : !filterValue.parent) || [];

    return filteredValues.map((filterValue: IFilterValueDescription) => {

        const children = toFilterValueToOptions(values, filterValue.value);
        return {
            id: filterValue.value,
            label: filterValue.label,
            children: children.length > 0 ? children : undefined
        };
    });
};

const metaToOptions = () =>
    memoizeOne((filterMeta: IFilterMeta) =>
        toFilterValueToOptions((filterMeta ?.values) as IFilterValueDescription[]));

const getPublicationTypes = memoizeOne((publicationTypeMeta: IFilterMeta) => {
    const publicationTypeAttrForms = Object.keys(AttrForms);
    const publicationTypeFilterValue = (publicationTypeMeta?.values);
    return toFilterValueToOptions(publicationTypeFilterValue as IFilterValueDescription[])
        .filter((item: IOption) => publicationTypeAttrForms.includes(item.id));
});

const documentSourceProvider = () => {
    const getMaterialTypes = metaToOptions();
    const getKnowledgeCategories = metaToOptions();

    return memoizeOne((
        materialTypeFilterMeta: IFilterMeta,
        publicationTypeFilterMeta: IFilterMeta,
        knowledgeCategoriesFilterMeta: IFilterMeta
    ) =>
    ({
        materialTypes: getMaterialTypes(materialTypeFilterMeta),
        publicationTypes: getPublicationTypes(publicationTypeFilterMeta),
        knowledgeCategories: getKnowledgeCategories(knowledgeCategoriesFilterMeta)

    }));
};

const mapStateToProps = (initialState: ExtendedStore.IState) => {
    const getDocumentSources = documentSourceProvider();

    return (state: ExtendedStore.IState): IDocumentFormStateProps => {
        const form = state.forms[FormName.AddEditDocumentForm];

        const documentSources = getDocumentSources(
            state.filtersMeta[FilterName.MaterialType],
            state.filtersMeta[FilterName.PublicationType],
            state.filtersMeta[FilterName.KnowledgeArea]
        );

        return {
            formResponse: form?.response,
            formStatus: form?.status,
            submitting: false,
            documentSources
        };
    };
};

const getFullName = (person: IPerson): string =>
    [person.lastName, person.firstName, person.middleName]
        .filter(Boolean)
        .join(' ');

export const mapDispatchToProps = (dispatch, ownProps: IDocumentFormOwnProps)
: IDocumentFormActionProps & Pick<IWizzardFormProps, 'onComplete'>  =>
({
    onComplete: () => {
        dispatch(setForm(FormName.AddEditDocumentForm, null, ExtendedStore.FormStatus.Completed));

        ownProps.onComplete();
    },

    onSubmit: (doc: IDocument, authors: IPerson[], editor: IPerson) => {
        const editorFullName = getFullName(editor);
        const currentDocument = {
            ...doc,
            authors: authors.map(author => ({
                ...author,
                fullName: getFullName(author)
            })),
            meta: {
                ...doc.meta,
                number: doc.meta?.issueInfo?.issueIdentifier,
                issueInfo: {
                    ...doc.meta?.issueInfo,
                    editor: editorFullName.trim() === '' ? null : editorFullName
                }
            }
        };

        !!currentDocument.id
            ? dispatch(createDocument(currentDocument))
            : dispatch(updateDocument(currentDocument));
    }
});

const DocumentFormConnected = connect(mapStateToProps, mapDispatchToProps)(DocumentForm);

export default DocumentFormConnected;
