import React, { useCallback } from 'react';
import classNames from 'classnames';

import { IOptionProps } from '../interfaces/ISelectProps';

import * as styles from '../MaterialSelectFormField.scss';
import Checkbox from 'app/components/common/Checkbox';

const Option: React.FC<IOptionProps> = (props) => {
    const {
        value,
        children,
        isActive,
        onSelect,
        onDeselect,
        isDisabled,
        level
    } = props;

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.target.checked ? onSelect(value) : onDeselect(value);
        },
        [onSelect, onDeselect]
    );

    return (
        <div
            className={classNames(styles.selectFormFieldCheckboxOption)}
        >
            <Checkbox
                checked={isActive}
                onChange={onChange}
            />
            <div
                className={classNames(
                    `select__form-field-col${level}`,
                    styles.selectFormFieldCheckboxOptionText
                )}
            >
                {children}
            </div>
        </div>
    );
};

Option.defaultProps = {
    level: 0
};

export default Option;
