import {DependencyList, useEffect} from 'react';

export const useOnPopStateAndUnmountEffect = (
    callback: () => void,
    deps: DependencyList = [],
): void => {
    useEffect(
        () => {
            typeof window !== 'undefined' && window.addEventListener('popstate', callback);

            return () => {
                window.removeEventListener('popstate', callback);
                callback();
            };
        },
        deps
    );
};
