import React, { useMemo, useCallback, useState, useRef } from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import { splitFileName } from 'app/utils/splitFileName';
import LoadingBar from 'app/components/common/LoadingBar';
import { useFileUpload } from 'app/hooks/useFileUpload';

import IProps from './interfaces/IUploadDocumentSectionProps';
import * as styles from './UploadDocumentSection.scss';

const UploadDocumentSection: React.FC<IProps> = (props) => {
    const {
        className,
        documentId,
        uploadDocumentContent,
        fileUploadProgress,
        cleanFileUploadProgress,
        setIsDocumentUploaded
    } = props;

    const uploadFile = useCallback(
        (fileToUpload: File, uploadId: string) => {
            documentId && uploadDocumentContent(documentId, uploadId, fileToUpload);
        },
        [documentId]
    );

    const onFileUploadSuccess = useCallback(
        () => {
            setIsDocumentUploaded(true);
        },
        []
    );

    const {
        file,
        setFile,
        progressRatio,
        uploadSuccess,
        uploadError
    } = useFileUpload(
        fileUploadProgress,
        uploadFile,
        cleanFileUploadProgress,
        onFileUploadSuccess
    );

    const fileNameSplit = useMemo(
        () => file && splitFileName(file.name),
        [file]
    );

    const kiloBytesTotal = file && Math.round(file.size / 1000);

    const kiloBytesUploaded = progressRatio && file && Math.round(progressRatio * kiloBytesTotal);

    const progressPercentage = progressRatio && Math.round(100 * progressRatio);

    const [isDropZoneHighlighted, setIsDropZoneHighlighted] = useState(false);

    const originalDropZoneRef = useRef<EventTarget>(null);

    const handleFiles = useCallback(
        (files: FileList) => {
            setFile(files.item(0));
        },
        []
    );

    const onInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { files } = event.target;
            files && handleFiles(files);

            event.target.value = null;
        },
        []
    );

    const onDragEnter = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();

            originalDropZoneRef.current = event.target;
            !isDropZoneHighlighted && setIsDropZoneHighlighted(true);
        },
        [isDropZoneHighlighted]
    );

    const onDragLeave = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();

            //проверка нужна, чтобы dragleave не срабатывал на дочерних элементах
            const currentDropZone = event.target;
            currentDropZone === originalDropZoneRef.current && setIsDropZoneHighlighted(false);
        },
        []
    );

    const onDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();

            setIsDropZoneHighlighted(false);

            const { files } = event.dataTransfer;
            handleFiles(files);
        },
        []
    );

    const inputId = useMemo(
        () => uniqueId('file-upload-field-'),
        []
    );

    return (
        <div
            className={classNames(
                className,
                styles.uploadDocumentSection,
                { [styles.uploadDocumentSectionHighlighted]: isDropZoneHighlighted }
            )}
            onDragEnter={onDragEnter}
            onDragOver={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            <input
                className={styles.uploadDocumentSectionInput}
                type="file"
                id={inputId}
                onChange={onInputChange}
            />
            <label
                htmlFor={inputId}
                className={classNames('btn', styles.uploadDocumentSectionUploadButton)}
            >
                <i className={classNames('icon-upload', styles.uploadDocumentSectionUploadIcon)}/> Загрузить материал
            </label>
            <span className={styles.uploadDocumentSectionCaption}>
                Загрузите оригинал материала в любом из текстовых форматов.
                Загружаемый файл будет использоваться в режиме чтения, а также для обработки и индексации документа.
            </span>
            {!!progressRatio && (
                <>
                    <div className={styles.uploadDocumentSectionFileNameBlock}>
                        <span className={styles.uploadDocumentSectionFileName}>
                            {fileNameSplit && fileNameSplit[0]}
                        </span>
                        {fileNameSplit && fileNameSplit[1]}
                    </div>
                    {!uploadSuccess || uploadError ? (
                        <>
                            <LoadingBar
                                className={styles.uploadDocumentSectionLoadingBar}
                                valueClassName={classNames(
                                    { [styles.uploadDocumentSectionLoadingBarValueError]: uploadError }
                                )}
                                progressRatio={progressRatio}
                            />
                            <div className={styles.uploadDocumentSectionUploadInfo}>
                                {uploadError ? (
                                    <span>
                                        <em className={styles.uploadDocumentSectionErrorText}>Ошибка загрузки.</em>&#32;
                                        Попробуйте еще раз.
                                    </span>
                                ) : (
                                    <span>Загрузка&hellip; {progressPercentage}%</span>
                                )}
                                <span>{kiloBytesUploaded} Кб из {kiloBytesTotal} Кб</span>
                            </div>
                        </>
                    ) : (
                        <div className={styles.uploadDocumentSectionUploadedSuccessful}>
                            Документ загружен
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UploadDocumentSection;
