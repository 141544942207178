/**
 * Created by lkarmelo on 04.03.2019.
 */

import React from 'react';
import {Portal} from 'react-portal';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import escapeStringRegexp from 'escape-string-regexp';

import {useOnOutsideClick} from 'app/hooks/useOnOutsideClick';
import { useStateWithCallback } from 'app/hooks/useStateWithCallback';
import { usePrevious } from 'app/hooks/usePrevious';

import {ArrowsFocusContextProvider, ArrowsFocusContextItem} from 'app/components/common/ArrowsFocusContext';

import IProps from './interfaces/ISearchPanelProps';

import {KeyCode} from 'app/types/KeyCode';

import * as styles from './SearchPanel.scss';

const SearchPanel: React.FunctionComponent<IProps> = (props): JSX.Element => {
    const {query: searchQuery, setQuery, fetchSearchResults, fetchHints, hints, clearHints} = props;

    const [isHintsListShowed, setIsHintsListShowed] = useStateWithCallback<boolean>(true);

    const [currentQueryFromHints, setCurrentQueryFromHints] = useState<string>('');

    const searchInput = useRef<HTMLInputElement>(null);
    const firstHintBtnRef = useRef<HTMLButtonElement>(null);
    const lastHintBtnRef = useRef<HTMLButtonElement>(null);

    const isQueryHintsArray = !!hints && Array.isArray(hints.queries);

    const previousHints = usePrevious(hints);

    const firstQueryHint = useMemo(
        () => isQueryHintsArray && hints.queries.length > 0 ? hints.queries[0].query : undefined,
        [hints, isQueryHintsArray]
    );

    const setFirstHintAsCurrentQueryFromHints = useCallback(
        () => {
            setCurrentQueryFromHints(firstQueryHint);
        },
        [firstQueryHint]
    );

    if (hints !== previousHints && currentQueryFromHints !== firstQueryHint) {
        //если изменим стейт во время выполнения функции, рендер прервётся и начнётся заново
        setFirstHintAsCurrentQueryFromHints();
    }

    useEffect(
        () => {
            document.activeElement === searchInput.current &&
            !isHintsListShowed &&
            isQueryHintsArray &&
            hints.queries.length > 0 &&
            hints !== previousHints &&
            setIsHintsListShowed(true);
        },
        [hints, isHintsListShowed, isQueryHintsArray]
    );

    useEffect(() => () => clearHints(), []);

    const aheadSearchQuery = useMemo(
        () => {
            if (!searchQuery || !currentQueryFromHints) {
                return '';
            }

            const queryLowerCase = searchQuery.toLowerCase();

            if (
                currentQueryFromHints.toLowerCase().indexOf(queryLowerCase) !== 0 ||
                queryLowerCase === currentQueryFromHints.toLowerCase()
            ) {
                return '';
            } else {
                return searchQuery + currentQueryFromHints.slice(searchQuery.length);
            }
        },
        [searchQuery, hints, currentQueryFromHints, isHintsListShowed, isQueryHintsArray]
    );

    const fetchResults = useCallback(
        () => {
            setIsHintsListShowed(false);
            setCurrentQueryFromHints(undefined);
            fetchSearchResults(searchQuery);
        },
        [searchQuery]
    );

    const onInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const {key, currentTarget} = e;
            if (key === KeyCode.Enter) {
                fetchResults();
            }
            if (key === KeyCode.ArrowRight) {
                if (aheadSearchQuery && currentTarget.selectionStart === currentTarget.value.length) {
                    setQuery(aheadSearchQuery);
                    fetchHints(aheadSearchQuery);
                }
            }
            if (key === KeyCode.Escape || key === KeyCode.Esc) {
                e.preventDefault();
                if (isHintsListShowed) {
                    setIsHintsListShowed(false);
                }
            }
            if (!hints || !Array.isArray(hints.queries) || hints.queries.length === 0) {
                return;
            }
            if (key === KeyCode.ArrowDown) {
                e.preventDefault();
                if (!isHintsListShowed) {
                    setIsHintsListShowed(true, () => {
                        const currentFirstButtonRef = firstHintBtnRef.current;
                        currentFirstButtonRef.focus();
                    });
                }
                firstHintBtnRef.current && firstHintBtnRef.current.focus();
            }
            if (key === KeyCode.ArrowUp) {
                e.preventDefault();
                if (hints.queries.length === 1) {
                    firstHintBtnRef.current && firstHintBtnRef.current.focus();
                } else {
                    lastHintBtnRef.current && lastHintBtnRef.current.focus();
                }
            }
        },
        [fetchResults, aheadSearchQuery, hints, firstHintBtnRef, lastHintBtnRef, isHintsListShowed]
    );

    const onInputChange = useCallback(
        ({target}: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(target.value);
            fetchHints(target.value);
        },
        [isHintsListShowed]
    );

    const onMouseDown = useOnOutsideClick(
        () => {
            setIsHintsListShowed(false);
        },
        []
    );

    const onHintsFocusOutOfBoundaries = useCallback(
        () => {
            searchInput.current && searchInput.current.focus();
            setFirstHintAsCurrentQueryFromHints();
        },
        [setFirstHintAsCurrentQueryFromHints]
    );

    const onClearButtonClick = () => {
        setQuery('');
        setIsHintsListShowed(false);
        clearHints();
        searchInput.current.focus();
    };

    const onHintKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const { key } = e;
            if (key === KeyCode.Escape || key === KeyCode.Esc) {
                setIsHintsListShowed(false);
                searchInput.current.focus();
            }
            if (key === KeyCode.ArrowRight) {
                if (currentQueryFromHints) {
                    searchInput.current.focus();
                    setQuery(currentQueryFromHints);
                    fetchHints(currentQueryFromHints);
                }
            }
        },
        [currentQueryFromHints]
    );

    //специально в deps useMemo не добавляем searchQuery, чтобы список не ререндерился и подсветка не обновлялась при вводе каждой буквы.
    //это должно происходить только при загрузке новых hints
    const hintsPanelChildren = useMemo(
        () => isQueryHintsArray && hints.queries.map(({query: hintQuery, highlighted}, index) => {
            const escapedQuery = escapeStringRegexp(searchQuery || '');
            const renderedHintLabel = highlighted ||
                hintQuery.replace(
                    new RegExp(escapedQuery, 'ig'),
                    '<span class="highlight-suggestions">$&</span>'
                );
            return (
                <li key={`${hintQuery}-${index}`}>
                    <ArrowsFocusContextItem
                        passRefToChild={
                            index === 0 ? firstHintBtnRef :
                            index === hints.queries.length - 1 ? lastHintBtnRef :
                            undefined
                        }
                    >
                        <button
                            className={'btn'}
                            onFocus={() => setCurrentQueryFromHints(hintQuery)}
                            onClick={() => {
                                setIsHintsListShowed(false);
                                setQuery(hintQuery);
                                fetchSearchResults(hintQuery);
                            }}
                            onKeyDown={onHintKeyDown}
                            dangerouslySetInnerHTML={{__html: renderedHintLabel}}
                        />
                    </ArrowsFocusContextItem>
                </li>
            );
        }),
        [hints, isQueryHintsArray, currentQueryFromHints]
    );

    return (
        <div
            className={styles.searchPanel}
            onMouseDown={onMouseDown}
        >
            <div className={styles.searchPanelInputBox}>
                <input
                    ref={searchInput}
                    className={styles.searchPanelInput}
                    type="search"
                    autoComplete="off"
                    placeholder="Поиск по библиотеке"
                    value={searchQuery || ''}
                    onChange={onInputChange}
                    onKeyDown={onInputKeyDown}
                />
                <input
                    disabled
                    tabIndex={-1}
                    value={aheadSearchQuery || ''}
                    autoComplete="off"
                    className={styles.searchPanelAheadInput}
                />
                <div className={`${styles.searchPanelIcon} icon-search`}/>
                {searchQuery &&
                    <button
                        className={`${styles.searchPanelClearButton} btn icon-cross`}
                        onClick={onClearButtonClick}
                    />
                }
                <ArrowsFocusContextProvider
                    onOutOfBoundary={onHintsFocusOutOfBoundaries}
                    linkFirstToLast={false}
                    linkLastToFirst={false}
                >
                    <Portal>
                        {isHintsListShowed && isQueryHintsArray && hints.queries.length > 0 &&
                            <ul className={styles.searchPanelHints}>
                                {hintsPanelChildren}
                            </ul>
                        }
                    </Portal>
                </ArrowsFocusContextProvider>
            </div>
            <button
                type="button"
                className={`${styles.searchPanelSearchButton} btn`}
                onClick={fetchResults}
            >
                Расширенный поиск
            </button>
        </div>
    );
};

export default SearchPanel;
