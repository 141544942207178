/**
 * Created by lkarmelo on 25.01.2019.
 */

import React from 'react';

import LibraryStat from 'app/components/common/LibraryStat';

import * as styles from './Stats.scss';

const Stats: React.FunctionComponent = () => (
    <div className={styles.stats}>
        <div className={styles.statsTitle}>Сегодня в библиотеке</div>
        <LibraryStat/>
    </div>
);

export default Stats;
