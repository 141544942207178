import { connect } from 'react-redux';

import { ExtendedStore } from 'common/store';
import {
    ISubscriptionsStateProps,
    ISubscriptionsDispatchProps
} from './interfaces/ISubscriptionsProps';
import Subscriptions from './Subscriptions';
import {
    fetchSubscriptions,
    subscribeToMailingList,
    unsubscribeFromMailingList,
    unsubscribeFromAllMailingLists
} from 'app/redux/actions/subscriptions';
import { SubscriptionType } from 'common/api/ExtendedApi';
import { subscriptionsSelector } from 'app/redux/selectors/subscriptionsSelector';

const mapStateToProps = (state: ExtendedStore.IState): ISubscriptionsStateProps => ({
    subscriptionOptions: subscriptionsSelector(state),
    subscriptions: state.subscriptions
});

const mapDispatchToProps = (dispatch): ISubscriptionsDispatchProps => ({
    fetchSubscriptions() {
        dispatch(fetchSubscriptions());
    },
    subscribe(type: SubscriptionType, value?: string) {
        dispatch(subscribeToMailingList({
            subscription: { type, value },
            raiseNotification: false
        }));
    },
    unsubscribe(type: SubscriptionType, value?: string) {
        dispatch(unsubscribeFromMailingList({ type, value }));
    },
    unsubscribeFromAll() {
        dispatch(unsubscribeFromAllMailingLists());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
