
export enum PublicationType {
    Catalog = 'Catalog',
    Book = 'Book',
    AnnotationReport = 'AnnotationReport',
    Digest = 'Digest',
    InformationalBulletin = 'InformationalBulletin',
    SocialBulletin = 'SocialBulletin',
    TechnicalBulletin = 'TechnicalBulletin',
    PublicationReferenceBook = 'PublicationReferenceBook',
    ScienceArticle = 'ScienceArticle'
}
