/**
 * Created by lkarmelo on 21.05.2019.
 */

import React from 'react';
import {useRef} from 'react';
import {useMemo} from 'react';

import ArrowsFocusContext from './ArrowsFocusContext';

import {IArrowFocusContextValue, IListItem} from './interfaces/IArrowFocusContext';
import IProps from './interfaces/IArrowsFocusContextProviderProps';

/**
 * Компонент для управления фокуса стрелками вниз/вверх
 * Элементы, к которым должен применяться фокус, должны быть обёрнуты в ArrowsFocusContextItem
 */
const ArrowsFocusContextProvider: React.FunctionComponent<IProps> = (props) => {
    const {children, linkFirstToLast, linkLastToFirst, onOutOfBoundary} = props;

    const list = useRef<IListItem[]>([]);

    const focusList = useMemo<IArrowFocusContextValue>(
        () => ({
            //когда происходит unmount одного ArrowsFocusContextItem, нужно обновить индексы у всех остальных
            //возможно есть решение получше, чем постоянно следить за индексами всех элементов
            onFocusItemUnMount(index: number) {
                list.current = [...list.current.slice(0, index), ...list.current.slice(index + 1)];
                for (let i = index; i < list.current.length; i++) {
                    list.current[i].indexRef.current -= 1;
                }
            },
            getNextFocusElement(index: number): IListItem | undefined {
                return index >= list.current.length - 1 ?
                    linkLastToFirst ? list.current[0] : undefined :
                    list.current[index + 1];
            },
            getPrevFocusElement(index: number): IListItem | undefined {
                return index <= 0 ?
                    linkFirstToLast ? list.current[list.current.length - 1] : undefined :
                    list.current[index - 1];
            },
            onOutOfBoundary,
            list,
        }),
        [linkFirstToLast, linkLastToFirst, onOutOfBoundary]
    );

    const {Provider} = ArrowsFocusContext;

    return <Provider value={focusList}>{children}</Provider>;
};

ArrowsFocusContextProvider.defaultProps = {
    linkFirstToLast: true,
    linkLastToFirst: true,
};

export default ArrowsFocusContextProvider;
