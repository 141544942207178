/**
 * Created by lkarmelo on 31.07.2019.
 */

import React from 'react';
import {useState, Dispatch, SetStateAction, useCallback} from 'react';
import { SubmitPanelFormControls } from 'app/components/common/form-controls';

import * as styles from './EditablePanel.scss';

export type ControlsRenderer = (
    onSave: Function,
    submitButtonDisabled: boolean,
    cancelButtonDisabled: boolean,
    submitLabel?: string,
    cancelLabel?: string
) => JSX.Element;

export const useEditablePanelControls =  (): [ControlsRenderer, [boolean, Dispatch<SetStateAction<boolean>>]] => {
    const editingState = useState(false);

    const setIsEditing = editingState[1];

    const controlsRenderer = useCallback(
        (
            onSave,
            submitButtonDisabled: boolean,
            cancelButtonDisabled: boolean,
            submitLabel: string = 'Сохранить',
            cancelLabel: string = 'отменить'
        ) => (
            <SubmitPanelFormControls
                onSaveClick={onSave}
                onCancelClick={() => { setIsEditing(false); }}
                saveButtonDisabled={submitButtonDisabled}
                cancelButtonDisabled={cancelButtonDisabled}
                saveLabel={submitLabel}
                cancelLabel={cancelLabel}
            />
        ),
        []
    );

    return [controlsRenderer, editingState];
};

export const useEditablePanel = (
    idleContent: React.ReactElement,
    editingContent: React.ReactElement,
    title: string,
    editingState,
): JSX.Element => {

    const [isEditing, setIsEditing] = editingState;

    const content = isEditing ? editingContent : idleContent;

    return (
        <div className={styles.editablePanel}>
            <div className={styles.editablePanelHeader}>
                <div className={styles.editablePanelTitle}>
                    {title}
                </div>
                {!isEditing &&
                    <button
                        className={'btn-edit'}
                        onClick={() => {setIsEditing(true); }}
                    >
                        Изменить
                    </button>
                }
            </div>
            <div>
                {content}
            </div>
        </div>
    );
};
