/**
 * Created by Lkarmelo on 02.10.2017.
 */

import {Action, handleActions} from 'redux-actions';
import {unHighlightHints} from '../../../utils/unHighlightHints';

import {setQuery} from '../../actions/search/searchQuery';

export default handleActions(
    {
        [setQuery.toString()](state: string, {payload}: Action<string | null>) {
            const isString = typeof payload === 'string';
            const payloadReplaced = isString ? payload.replace(/\r?\n|\r|\u21b5/g, ' ') : payload;
            return isString ? unHighlightHints(payloadReplaced) : payload;
        },
    },
    null //- default value for query
);
