/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {createActions, createAction} from 'redux-actions';

import {Store} from '../../../types';

export interface IChangeFilterValuePayload {
    filterName: string;
    value: Store.IFilterValue;
    updateFilterMetaIfValueUnknown?: boolean;
}

const changeFilterValuePayloadCreator = (filterName, value, updateFilterMetaIfValueUnknown): IChangeFilterValuePayload => ({
    filterName,
    value,
    updateFilterMetaIfValueUnknown,
});

export const {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    toggleMultiSelectFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilterValue
} = createActions<IChangeFilterValuePayload> ({
    ['ADD_MULTI_SELECT_FILTER_VALUE']: changeFilterValuePayloadCreator,
    ['REMOVE_MULTI_SELECT_FILTER_VALUE']: changeFilterValuePayloadCreator,
    ['TOGGLE_MULTI_SELECT_FILTER_VALUE']: changeFilterValuePayloadCreator,
    ['SET_DATE_RANGE_FROM']: changeFilterValuePayloadCreator,
    ['SET_DATE_RANGE_TO']: changeFilterValuePayloadCreator,
    ['SET_FILTER_VALUE']: changeFilterValuePayloadCreator,
});

export const setFilters = createAction<Store.IFilters>('SET_FILTERS');

export interface ISetFiltersByNamePayloadItem {
    filterName: string;
    value: Store.IFilterValue;
    isDisabled?: boolean;
}
export const setFiltersByNames = createAction<ISetFiltersByNamePayloadItem[]>('SET_FILTERS_BY_NAMES');

export const updateFilters = createAction<boolean>('UPDATE_FILTERS');
