/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {createAction, ActionFunctionAny} from 'redux-actions';
import {AjaxError} from 'rxjs/observable/dom/AjaxObservable';

import {LoadingActionCreator, LoadingErrorInfo} from './utils/createLoadingActions';

//import {FormName, RequestName, Api} from '../../api';

export interface IShowLoadingErrorPayload {
    requestName: string;
    message: string;
    showInMainErrorComponent: boolean;
}
export const SHOW_LOADING_ERROR = 'SHOW_LOADING_ERROR';
export const showLoadingError = createAction<IShowLoadingErrorPayload, string, string, boolean>(
    SHOW_LOADING_ERROR,
    (requestName: string, message: string, showInMainErrorComponent: boolean) => ({requestName, message, showInMainErrorComponent})
);

export interface IRemoveLoadingErrorPayload {
    requestNames: string[];
}
export const REMOVE_LOADING_ERROR = 'REMOVE_LOADING_ERROR';
export const removeLoadingError = createAction<IRemoveLoadingErrorPayload, string[]>(
    REMOVE_LOADING_ERROR,
    (requestNames: string[]) => ({requestNames})
);

export interface ILoadingActionMeta {
    requestName: string;
    errorMessage?: string;
    showErrorInMainErrorComponent?: boolean;
}

interface IDefaultLoadingActions {
    objectCardRequest: ActionFunctionAny<any>;
    objectCardResolve: ActionFunctionAny<any>;
    objectCardReject: ActionFunctionAny<any>;

    logInRequest: ActionFunctionAny<any>;
    logInResolve: ActionFunctionAny<any>;
    logInReject: ActionFunctionAny<any>;

    filtersMetaReject: ActionFunctionAny<any>;
    filtersMetaRequest: ActionFunctionAny<any>;
    filtersMetaResolve: ActionFunctionAny<any>;

    userRequest: ActionFunctionAny<any>;
    userResolve: ActionFunctionAny<any>;
    userReject: ActionFunctionAny<any>;
}

interface IActionLists {
    loadingShowActionCreators: ActionFunctionAny<any>[];
    loadingHideActionCreators: ActionFunctionAny<any>[];
    setErrorActionCreators: ActionFunctionAny<any>[];
    logOutOnUnAuthActionCreators: ActionFunctionAny<any>[];
    logInOnAuthActionCreators: ActionFunctionAny<any>[];
}

class LoadingActionsProvider {
    static actionLists: IActionLists;
    static loadingActions: IDefaultLoadingActions;
    static recreateActions() {
        LoadingActionsProvider.actionLists = LoadingActionsProvider.getEmptyActionLists();

        LoadingActionsProvider.loadingActions = <IDefaultLoadingActions><any>LoadingActionCreator.createLoadingActions(
            [
                'objectCard',
                'filtersMeta',
                'logIn',
                'user',
            ],
            LoadingActionsProvider.actionLists.loadingShowActionCreators,
            LoadingActionsProvider.actionLists.loadingHideActionCreators,
            LoadingActionsProvider.actionLists.setErrorActionCreators,
            LoadingActionsProvider.actionLists.logOutOnUnAuthActionCreators,
            LoadingActionsProvider.actionLists.logInOnAuthActionCreators,
        );
    }
    static setActionCreatorDefaultOption(options: Partial<typeof LoadingActionCreator.defaultOption>): void {
        LoadingActionCreator.defaultOption = {...LoadingActionCreator.defaultOption, ...options};
    }
    private static getEmptyActionLists(): IActionLists {
        return {
            loadingShowActionCreators: [],
            loadingHideActionCreators: [],
            setErrorActionCreators: [],
            logOutOnUnAuthActionCreators: [],
            logInOnAuthActionCreators: [],
        };
    }
}
LoadingActionsProvider.recreateActions();

export {LoadingActionsProvider};
