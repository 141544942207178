/**
 * Created by Lkarmelo on 23.08.2017.
 * -----
 * Last Modified: 09.07.2018 11:21:12
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import createSecuredEpics from 'nkc-frontend-tools/redux/epics/utils/createSecuredEpics';
import { requestLikeStateChange } from '../actions/like';
import {
    likeResolve,
    likeReject,

    logoutResolve,
    logoutReject,

    searchResolve,
    searchReject,

    documentsShowcaseResolve,
    documentsShowcaseReject,

    shareReject,
    shareResolve,

    issueInfoCatalogResolve,
    issueInfoCatalogReject,

    checkDocumentContentResolve,
    checkDocumentContentReject,

    libraryStatsResolve,
    libraryStatsReject,
    changePasswordReject,
    changePasswordResolve,
    profileUpdateReject,
    profileUpdateResolve,
    deleteAccountReject,
    deleteAccountResolve,
    favoriteResolve,
    favoriteReject,
    emailSubmitResolve,
    emailSubmitReject,
    subscribeToMailingListResolve,
    subscribeToMailingListReject,
    unsubscribeFromMailingListResolve,
    unsubscribeFromMailingListReject,
    unsubscribeFromAllMailingListsResolve,
    unsubscribeFromAllMailingListsReject,
    subscriptionsResolve,
    subscriptionsReject,
    saveBookReaderScaleResolve,
    saveBookReaderScaleReject,
    saveBookReaderLastPageReject,
    saveBookReaderLastPageResolve,
    saveNotificationSettingResolve,
    saveNotificationSettingReject,
    notificationSettingsResolve,
    notificationSettingsReject,
    notificationTypesResolve,
    notificationTypesReject,
    notificationsResolve,
    notificationsReject,

    addBookmarkReject,
    addBookmarkResolve,
    removeBookmarkReject,
    removeBookmarkResolve,
    bookmarksReject,
    bookmarksResolve,

    uploadDocumentContentResolve,
    uploadDocumentContentReject,
} from '../actions/loading';

import { LOG_OUT } from 'nkc-frontend-tools/redux/actions/authorization';
import { BaseActionFunctions } from 'redux-actions';
import { fetchShowcaseDocuments } from '../actions/showcaseDocuments';
import { fetchSearchResults, fetchMoreSearchResults } from '../actions/search/results';
import { fetchLibraryStats } from '../actions/libraryStats';
import { shareDocument } from '../actions/share';
import { fetchIssueInfoCatalog } from '../actions/issueInfoCatalog';
import { checkDocumentContent } from '../actions/objectCard';
import {
    changePassword,
    deleteAccount,
    submitProfile,
    submitEmail
} from '../actions/forms/formsSendActions';
import {requestFavoriteStateChange} from '../actions/favorite';
import {
    subscribeToMailingList,
    unsubscribeFromMailingList,
    unsubscribeFromAllMailingLists,
    fetchSubscriptions
} from '../actions/subscriptions';
import {setReaderBookScale, saveReaderLastPage, requestAddBookmark, requestRemoveBookmark, fetchBookmarks} from '../actions/bookReader';
import {
    saveNotificationSetting,
    fetchNotificationSettings,
    fetchNotificationTypes,
    fetchNotifications
} from '../actions/notifications';
import { uploadDocumentContent } from '../actions/uploadDocument';

type Triggers = BaseActionFunctions<any>[] | BaseActionFunctions<any> | string | string[];

const Secured = (action: Triggers, resolve: Triggers, reject: Triggers) => ({action, resolve, reject});

export default createSecuredEpics([
    Secured(requestLikeStateChange, likeResolve, likeReject),
    Secured(shareDocument, shareResolve, shareReject),
    Secured(LOG_OUT, logoutResolve, logoutReject),
    Secured([fetchSearchResults, fetchMoreSearchResults], searchResolve, searchReject),
    Secured(fetchIssueInfoCatalog, issueInfoCatalogResolve, issueInfoCatalogReject),
    Secured(checkDocumentContent, checkDocumentContentResolve, checkDocumentContentReject),
    Secured(fetchLibraryStats, libraryStatsResolve, libraryStatsReject),
    Secured(fetchShowcaseDocuments, documentsShowcaseResolve, documentsShowcaseReject),
    Secured(submitProfile, profileUpdateResolve, profileUpdateReject),
    Secured(changePassword, changePasswordResolve, changePasswordReject),
    Secured(deleteAccount, deleteAccountResolve, deleteAccountReject),
    Secured(requestFavoriteStateChange, favoriteResolve, favoriteReject),
    Secured(subscribeToMailingList, subscribeToMailingListResolve, subscribeToMailingListReject),
    Secured(unsubscribeFromMailingList, unsubscribeFromMailingListResolve, unsubscribeFromMailingListReject),
    Secured(unsubscribeFromAllMailingLists, unsubscribeFromAllMailingListsResolve, unsubscribeFromAllMailingListsReject),
    Secured(submitEmail, emailSubmitResolve, emailSubmitReject),
    Secured(setReaderBookScale, saveBookReaderScaleResolve, saveBookReaderScaleReject),
    Secured(saveReaderLastPage, saveBookReaderLastPageReject, saveBookReaderLastPageResolve),
    Secured(requestAddBookmark, addBookmarkResolve, addBookmarkReject),
    Secured(requestRemoveBookmark, removeBookmarkResolve, removeBookmarkReject),
    Secured(fetchBookmarks, bookmarksResolve, bookmarksReject),

    Secured(fetchSubscriptions, subscriptionsResolve, subscriptionsReject),

    Secured(fetchNotificationSettings, notificationSettingsResolve, notificationSettingsReject),
    Secured(fetchNotificationTypes, notificationTypesResolve, notificationTypesReject),
    Secured(saveNotificationSetting, saveNotificationSettingResolve, saveNotificationSettingReject),
    Secured(fetchNotifications, notificationsResolve, notificationsReject),

    Secured(uploadDocumentContent, uploadDocumentContentResolve, uploadDocumentContentReject)
]);
