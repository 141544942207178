/**
 * Created by lkarmelo on 19.03.2019.
 */

import {connect} from 'react-redux';

import Catalog from './Catalog';
import {ICatalogStateProps} from './interfaces/ICatalogProps';

import {ExtendedStore} from 'common/store';

import {filtersValueToLabelSelector} from 'app/redux/selectors/filtersValueToLabelSelector';

const mapStateToProps = (state: ExtendedStore.IState): ICatalogStateProps => ({
    sectionTitles: filtersValueToLabelSelector(state),
});

export default connect(mapStateToProps)(Catalog);
