/**
 * Created by lkarmelo on 13.03.2019.
 */

import {connect} from 'react-redux';

import Preview from './Preview';
import {IPreviewOwnProps as IOwnProps, IPreviewStateProps as IStateProps} from './interfaces/IPreviewProps';

import {mapDispatchToProps as bookMapDispatchToProps} from 'app/components/pages/book/Book/BookConnected';

import {ExtendedStore} from 'common/store';

const mapStateToProps = (state: ExtendedStore.IState, ownProps: IOwnProps): IOwnProps & IStateProps => ({
    book: state.objectCard,
    ...ownProps
});

export default connect(mapStateToProps, bookMapDispatchToProps)(Preview);
