/**
 * Created by lkarmelo on 31.07.2019.
 */

import React from 'react';

import NameInfo from '../NameInfo';
import EmailInfo from '../EmailInfo';

import {styles as editablePanelStyles} from 'app/hooks/useEditablePanel';
import * as styles from './PersonalInfo.scss';

const PersonalInfo: React.FC = () => (
    <div className={styles.personalInfo}>
        <div className={editablePanelStyles.editablePanelHeader}>
            <div className={editablePanelStyles.editablePanelTitle}>
                Личные данные
            </div>
        </div>
        <EmailInfo />
        <NameInfo />
    </div>
);

export default PersonalInfo;
