/**
 * Created by lkarmelo on 16.08.2019.
 */

import React from 'react';
import {connect} from 'react-redux';

import * as styles from './Social.scss';
import {styles as editablePanelStyles}  from 'app/hooks/useEditablePanel';
import {ExtendedStore} from 'common/store';

import {SocialProviderToSetting} from 'app/types/SocialProviderToSetting';

import IProps from './interfaces/ISocialProps';

const Social: React.FC<IProps> = (props) => {
    const {userSocial} = props;

    const providerSetting = userSocial && userSocial.length > 0 && SocialProviderToSetting.get(userSocial[0]);
    const providerName = providerSetting ? providerSetting.name : '';

    return (
        <div className={styles.socialProfile}>
            <div className={editablePanelStyles.editablePanelHeader}>
                <div className={editablePanelStyles.editablePanelTitle}>Социальная сеть</div>
            </div>
            <div className={styles.socialProfileInfo}>
                {providerSetting &&
                    <span className={`${styles.socialProfileImg} icon-${providerSetting.className}`} />
                }
                <div>
                    <div className={styles.socialProfileProvider}>
                        {providerName}
                    </div>
                    <div className={styles.socialProfileDescription}>
                        Выполнен вход через {providerName}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Social;
