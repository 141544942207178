/**
 * Created by Lkarmelo on 27.10.2017.
 * -----
 * Last Modified: 07.02.2018 16:29:13
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React from 'react';

import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import NavPanel from 'app/components/NavPanel';
import LoginPanelPopup from 'app/components/LoginPanel/LoginPanelPopupConnected';
import Registration from 'app/components/Registration';
import PasswordResetEmailPopup from 'app/components/Popups/PasswordResetEmailPopup';
import SubscriptionPopup from 'app/components/Popups/SubscriptionPopup';
import ConfirmDeleteBook from 'app/components/Popups/ConfirmDeleteBook';
// import setBodyClassOnMount from 'app/components/common/high-order/setBodyClassOnMount';

import * as styles from './DefaultLayout.scss';

const DefaultLayout: React.FC = ({children}) => {
    return (
        <div className={'layout-wrapper'}>
            <Header />
            <NavPanel />
            <main className={styles.mainContent}>
                {children}
            </main>
            <Footer />
            <Registration />
            <LoginPanelPopup />
            <PasswordResetEmailPopup />
            <SubscriptionPopup />
            <ConfirmDeleteBook />
        </div>
    );
};

export default DefaultLayout;
