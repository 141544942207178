/**
 * Created by Lkarmelo on 21.09.2017.
 */

import React from 'react';

import { Link } from 'react-router-dom';
import RFBRLogo from 'app/components/common/RFBRLogo';
import SearchPanel from 'app/components/SearchPanel';
import UserPanel from 'app/components/UserPanel';

import * as styles from './Header.scss';

const Header: React.FunctionComponent = () => (
    <header className={styles.header}>
        {/*контейнер для тултипов UserPanel. для них нужен отдельный контейнер, чтобы их не заслоняли элементы,
        которые должны заслонять сам хедер*/}
        <div className={styles.headerTooltipContainer} />
        <div className={styles.headerFiller} />
        <div className={styles.headerFixedContainer}>
            <div className={styles.headerSearch}>
                <SearchPanel />
            </div>
            <div className={styles.headerAccount}>
                <UserPanel />
            </div>
        </div>
    </header>
);

export default Header;
