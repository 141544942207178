import { connect } from 'react-redux';

import UploadImageSection from './UploadImageSection';
import { IUploadImageSectionStateProps, IUploadImageSectionActionProps } from './interfaces/IUploadImageSectionProps';
import { uploadDocumentCover } from 'app/redux/actions/uploadDocument';
import { fileUploadProgressClean } from 'app/redux/actions/fileUploadProgress';
import { IState } from 'common/store/ExtendedStore';

const mapStateToProps = (state: IState): IUploadImageSectionStateProps => ({
    fileUploadProgress: state.fileUploadProgress
});

const mapDispatchToProps = (dispatch): IUploadImageSectionActionProps => ({
    uploadDocumentCover(documentId: string, uploadId: string, file: File) {
        dispatch(uploadDocumentCover({ documentId, uploadId, file }));
    },
    cleanFileUploadProgress(fileName: string) {
        dispatch(fileUploadProgressClean(fileName));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadImageSection);
