/**
 * Created by lkarmelo on 17.01.2019.
 */

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import NavPanel from './NavPanel';

import {INavPanelStateProps as IStateProps} from './interfaces/INavPanelProps';
import {ExtendedStore} from 'common/store';

import {FilterName} from 'common/types/FilterName';
import {FilterToTitleNavigationPanel} from 'app/types/FilterToTitle';

import {createOrderedFiltersSelector} from 'app/redux/selectors/createNormalizedFiltersSelector';

const filtersSelector = createOrderedFiltersSelector(
    [FilterName.PublicationType, FilterName.KnowledgeArea, FilterName.MaterialType],
    FilterToTitleNavigationPanel
);

const mapStateToProps = (state: ExtendedStore.IState): IStateProps => ({
    filters: <ExtendedStore.IFilterMetaWithNormalizedValues[]>filtersSelector(state)
});

export default withRouter(connect(mapStateToProps)(NavPanel));
