/**
 * Created by lkarmelo on 06.05.2019.
 */
import {handleActions, Action} from 'redux-actions';

import {setLibraryStats} from 'app/redux/actions/libraryStats';

import {ExtendedStore} from 'common/store';

export default handleActions(
    {
        [setLibraryStats.toString()]: (_, {payload}: Action<ExtendedStore.ILibraryStats>): ExtendedStore.ILibraryStats => payload
    },
    {}
);
