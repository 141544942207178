import AddEditDocumentConnected from './AddEditDocumentConnected';
import { connect } from 'react-redux';
import { ExtendedStore } from 'common/store';
import { FormName } from 'app/types/FormName';
import { IWizzardWrapperOwnProps } from './WizzardWrapper';
import { IAddEditDocumentOwnProps } from './interfaces/IAddEditDocumentProps';

const mapStateToProps = (state: ExtendedStore.IState): IAddEditDocumentOwnProps & IWizzardWrapperOwnProps => {
    const form = state.forms[FormName.AddEditDocumentForm];

    return {
        pageTitle: 'Добавить материал',
        startStep: form?.data?.id ? 2 : 1
    };
};

const CreateDocument = connect(mapStateToProps)(AddEditDocumentConnected);

export default CreateDocument;
