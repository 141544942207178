/**
 * Created by lkarmelo on 20.08.2019.
 */

import {ExtendedStore} from 'common/store';
import {connect} from 'react-redux';

import RegistrationInfo from './RegistrationInfo';

import IProps from './interfaces/IRegistrationInfoProps';

const mapStateToProps = (state: ExtendedStore.IState): IProps => ({
    email: state.user && state.user.login,
});

export default connect(mapStateToProps)(RegistrationInfo);
