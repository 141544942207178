/**
 * Created by lkarmelo on 03.07.2019.
 */

import React from 'react';

import {CollapsibleMenu} from 'nkc-frontend-tools/components';
import CategoryLink from '../CategoryLink';

import clientRoutes from 'common/clientRoutes';

import IProps from './interfaces/IMenuItemsProps';

const MenuItems: React.FunctionComponent<IProps> = (props) => {
    const {
        filterName, filterIndex, valueDesc, depth, filters, activeFilterValue,
        className, linkActiveClassName, collapsibleMenuClassName, linkClassName,
        locationPathname
    } = props;
    if (valueDesc.children && valueDesc.children.length > 0 && depth < 2) {
        const isOpened = locationPathname === clientRoutes.catalog.getUrl() &&
            (valueDesc.value === activeFilterValue || valueDesc.children.includes(activeFilterValue));

        return (
            <li className={className} key={valueDesc.value}>
                <CollapsibleMenu
                    className={collapsibleMenuClassName}
                    isOpened={isOpened}
                    renderToggle={(_, toggleOpenedState, cssClassNames) => (
                        <div className={cssClassNames.toggle}>
                            <div>
                                <CategoryLink
                                    title={valueDesc.label}
                                    filterName={filterName}
                                    filterValue={valueDesc.value}
                                    isActive={isOpened}
                                    className={linkClassName}
                                    activeClassName={linkActiveClassName}
                                />
                            </div>
                        </div>
                    )}
                >
                    {valueDesc.children.map( subValue =>
                        <MenuItems
                            key={subValue}
                            {...props}
                            valueDesc={filters[filterIndex].values[subValue]}
                            depth={depth + 1}
                        />
                    )}
                </CollapsibleMenu>
            </li>
        );
    } else {
        const isActive = valueDesc.value === activeFilterValue && locationPathname === clientRoutes.catalog.getUrl();

        return (
            <li
                className={className}
                key={valueDesc.value}
            >
                <span>
                    <CategoryLink
                        title={valueDesc.label}
                        filterName={filterName}
                        filterValue={valueDesc.value}
                        isActive={isActive}
                        className={linkClassName}
                        activeClassName={linkActiveClassName}
                    />
                </span>
            </li>
        );
    }
};

export default React.memo(MenuItems);
