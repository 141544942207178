/**
 * Created by lkarmelo on 30.08.2019.
 */

import {ExtendedStore} from 'common/store';

export type SnippetOrContainer = ExtendedStore.IDocumentSnippet
    | ExtendedStore.IDocumentSnippet[]
    | ExtendedStore.ISimilarity[]
    | ExtendedStore.IDocumentMap;

export type Updater = (snippet: ExtendedStore.IDocumentSnippet) => ExtendedStore.IDocumentSnippet;

const updateSnippet = (snippet: ExtendedStore.IDocumentSnippet, updater: Updater): ExtendedStore.IDocumentSnippet => updater(snippet);

const updateSnippetList = (snippetList: ExtendedStore.IDocumentSnippet[], updater: Updater): ExtendedStore.IDocumentSnippet[] => {
    //приходится добавлять флаг, потому что если updater не обновит ни одного документа, нужно
    //возвращать старый snippetList, чтобы не вызывать лишних ререндеров в реакте
    let wasUpdated = false;

    const nextList = snippetList.map(snippet => {
        const nextSnippet = updateSnippet(snippet, updater);
        if (nextSnippet !== snippet && !wasUpdated) {
            wasUpdated = true;
        }
        return nextSnippet;
    });

    return wasUpdated ? nextList : snippetList;
};

const updateSimilarList = (similarList: ExtendedStore.ISimilarity[], updater: Updater) => {
    let wasUpdated = false;

    const nextSimilarList = similarList.map(sim => {
        const prevSnippet = sim.documentSnippet;
        const nextSnippet = updater(prevSnippet);
        //если сниппет обновился, то нужно обновить и similarity
        if (prevSnippet !== nextSnippet && !wasUpdated) {
            wasUpdated = true;
            return {
                ...sim,
                documentSnippet: nextSnippet
            };
        }
        return sim;
    });

    return wasUpdated ? nextSimilarList : similarList;
};

const updateSnippetMap = (snippetMap: ExtendedStore.IDocumentMap, updater: Updater): ExtendedStore.IDocumentMap => {
    let wasUpdated = false;

    const nextMap: ExtendedStore.IDocumentMap = {};
    Object.entries(snippetMap).map(([key, snippet]) => {
        const nextSnippet = updater(snippet);
        if (nextSnippet !== snippet && !wasUpdated) {
            wasUpdated = true;
        }
        nextMap[key] = nextSnippet;
    });

    return wasUpdated ? nextMap : snippetMap;
};

/**
 * т.к. может быть много моделей, где находятся DocumentSnippet, например массив сниппетов, массив ISimilarity, мапы или просто сниппет,
 * эта функция обходит все снипеты в таких контейнерах и возвращает новый контейнер
 */
export const updateAnySnippetContainer = (snippetOrContainer: SnippetOrContainer, updater: Updater): SnippetOrContainer => {
    if (!snippetOrContainer) {
        return snippetOrContainer;
    }
    if (Array.isArray(snippetOrContainer)) {
        if (snippetOrContainer.length === 0) {
            return snippetOrContainer;
        }

        return snippetOrContainer[0].hasOwnProperty('similarity') ?
            updateSimilarList(snippetOrContainer as ExtendedStore.ISimilarity[], updater) :
            updateSnippetList(snippetOrContainer as ExtendedStore.IDocumentSnippet[], updater);
    } else if (typeof snippetOrContainer === 'object') {
        return snippetOrContainer.hasOwnProperty('document') ?
            updateSnippet(snippetOrContainer as ExtendedStore.IDocumentSnippet, updater) :
            updateSnippetMap(snippetOrContainer as ExtendedStore.IDocumentMap, updater);
    }

    return snippetOrContainer;
};
