/**
 * Created by Lkarmelo on 05.09.2017.
 */

import {createAction} from 'redux-actions';

import {Store} from '../../types';

export const FETCH_OBJECT_CARD = 'FETCH_OBJECT_CARD';
export const SET_OBJECT_CARD = 'SET_OBJECT_CARD';
export const UPDATE_OBJECT_CARD_SIMILARS = 'UPDATE_OBJECT_CARD_SIMILARS';

export const fetchObjectCard = createAction<string>(FETCH_OBJECT_CARD);

export const setObjectCard = createAction<Store.IObjectCard>(SET_OBJECT_CARD);
export const updateObjectCardSimilars = createAction<Store.IUpdateDocumentSnippet>(UPDATE_OBJECT_CARD_SIMILARS);
