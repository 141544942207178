/**
 * Created by PBorisov on 05.07.2019
 */
import React from 'react';

import IInitAuthorizeOnClickWrapperProps from './interfaces/IInitAuthorizeOnClickWrapperProps';

const initAuthorizeOnClickWrapper = (WrappedComponent): React.FC<any & IInitAuthorizeOnClickWrapperProps> =>
    ({onInitAuthorize, disabled, ...props}) => (
        <WrappedComponent onClick={onInitAuthorize} {...props}/>
    );

export default initAuthorizeOnClickWrapper;
