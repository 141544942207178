/**
 * Created by lkarmelo on 31.07.2019.
 */

import React from 'react';

import PageHeader from 'app/components/common/PageHeader';
import PersonalInfo from '../PersonalInfo';
import PasswordChange from '../PasswordChange';
import DeleteAccount from '../DeleteAccount';
import Social from '../Social';
import RegistrationInfo from '../RegistrationInfo';

import IProps from './interfaces/IProfileProps';

import clientRoutes from 'common/clientRoutes';

import {ROOT_BREADCRUMB_NAME} from 'app/utils/constants';

import * as styles from './Profile.scss';

const pageTitle = 'Профиль';

const breadcrumbs = [
    {
        link: clientRoutes.main.getUrl(),
        title: ROOT_BREADCRUMB_NAME
    },
    {
        link: '#',
        title: 'Личный кабинет'
    },
    {
        link: clientRoutes.profile.getUrl(),
        title: pageTitle
    }
];

const Profile: React.FC<IProps> = ({rfbrAuth}) => (
    <div className={styles.profile}>
        <PageHeader
            breadcrumbsPath={breadcrumbs}
            header={pageTitle}
        />
        <div className={styles.profileContent}>
            {rfbrAuth &&
                <RegistrationInfo />
            }
            <PersonalInfo />
            {!rfbrAuth &&
                <Social />
            }
            <DeleteAccount />
        </div>
    </div>
);

export default Profile;
