/**
 * Created by lkarmelo on 06.08.2019.
 */
import { Action, createAction } from 'redux-actions';
import { Omit } from 'nkc-frontend-tools/types';

import { ExtendedStore } from 'common/store';
import { FormName } from '../../../types/FormName';
import { Route } from 'nkc-frontend-tools/routing';

export interface ISetFormPayload {
    formName: string;
    response: any;
    data: any;
    backUrl?: string;
    status: ExtendedStore.FormStatus;
}

interface ISetForm {
    (
        formName: string,
        response: any,
        status: ExtendedStore.FormStatus,
        data?: any,
        backUrl?: string
    ): Action<ISetFormPayload>;
}
export const setForm: ISetForm = createAction(
    'SET_FORM',
    (
        formName: string,
        response: any,
        status: ExtendedStore.FormStatus,
        data?: any,
        backUrl?: string
    ): ISetFormPayload => ({
        formName,
        response,
        status,
        data,
        backUrl
    })
);

export interface ISetFormStatusPayload extends Pick<ISetFormPayload, 'formName' | 'status'> { }
export const setFormStatus = createAction(
    'SET_FORM_STATUS',
    (formName: string, status: ExtendedStore.FormStatus | null): ISetFormStatusPayload => ({
        formName,
        status,
    })
);

export interface ISetFormDataPayload extends Pick<ISetFormPayload, 'formName' | 'data'> { }
export const setFormData = createAction(
    'SET_FORM_DATA',
    (formName: string, data: any | null): ISetFormDataPayload => ({
        formName,
        data,
    })
);

export interface ISetFormBackURLPayload extends Pick<ISetFormPayload, 'formName' | 'backUrl'> { }
export const setFormBackURL = createAction(
    'SET_FORM_BACK_URL',
    (formName: string, backUrl: string): ISetFormBackURLPayload => ({
        formName,
        backUrl
    })
);

export const removeForm = createAction<FormName>('REMOVE_FORM');
