/**
 * Created by lkarmelo on 19.03.2019.
 */

import {createFilteredReducer} from '../utils/createFilteredReducer';

export const createContextReducer = (reducerFunction: Function, context: string) =>
    createFilteredReducer(
        reducerFunction,
        (action) =>  action && action.context && action.context === context
    );

export const createNoContextReducer = (reducerFunction: Function) =>
    createFilteredReducer(
        reducerFunction,
        action => action && !action.hasOwnProperty('context')
    );
