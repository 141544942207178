/**
 * Created by lkarmelo on 10.01.2019.
 */

import React from 'react';
import {useCallback, useEffect} from 'react';

import {Link} from 'react-router-dom';
import BookSnippet from 'app/components/common/BookSnippet';
import Subscription from 'app/components/pages/main/Subscription';
import Stats from 'app/components/pages/main/Stats';

import {createDataMockProvider} from 'app/components/common/CreateDataMockProvider';

import clientRoutes from 'common/clientRoutes';

import {ObjectType} from 'common/store/ExtendedStore';
import IProps from './interfaces/IMainProps';

import {FilterName} from 'common/types/FilterName';

import * as styles from './Main.scss';

const Main: React.FunctionComponent<IProps> = (props) => {
    const {showcaseDocuments, fetchShowcaseDocuments} = props;

    useEffect(
        () => {
            fetchShowcaseDocuments();
        },
        []
    );

    const renderSnippet = useCallback(
        (bookId: string) => (
            <BookSnippet
                key={bookId}
                book={showcaseDocuments.items[bookId]}
            />
        ),
        [showcaseDocuments]
    );

    return (
        <div className={styles.main}>
            <div className={styles.mainDocumentsShowcase}>
                <div className={styles.mainDocumentRow}>
                    {(showcaseDocuments && showcaseDocuments.latest) &&
                        <React.Fragment>
                            <h1 className={styles.mainShowcaseSectionTitle}>Последние поступления</h1>
                            <div>
                                {showcaseDocuments.latest.map(renderSnippet)}
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className={styles.mainDocumentRow}>
                    {(showcaseDocuments && showcaseDocuments.journalsTechnical) &&
                        <React.Fragment>
                            <div className={styles.mainShowcaseSectionHeader}>
                                <h1 className={styles.mainShowcaseSectionTitle}>Вестник РФФИ</h1>
                                <Link
                                    className={styles.mainMoreLink}
                                    to={clientRoutes.catalog.getUrl(
                                            {},
                                            {
                                                masterFilterValue: ObjectType.TechnicalBulletin,
                                                masterFilterName: 'objectType',
                                                objectType: ObjectType.TechnicalBulletin
                                            }
                                        )}
                                >
                                    Все
                                </Link>
                            </div>
                            <div>
                                {renderSnippet(showcaseDocuments.journalsTechnical)}
                            </div>
                            <div className={styles.mainFiller} />
                        </React.Fragment>
                    }
                    {(showcaseDocuments && showcaseDocuments.journalsSocial) &&
                        <React.Fragment>
                            <div className={styles.mainTitleFix}>
                                <div className={styles.mainDelimeter} />
                                <div className={styles.mainShowcaseSectionHeader}>
                                    <h1
                                        className={styles.mainShowcaseSectionTitle}
                                    >
                                        <span className={styles.mainBulletinSectionTitle}>ВЕСТНИК РФФИ. </span>
                                        ГУМАНИТАРНЫЕ И&nbsp;ОБЩЕСТВЕННЫЕ НАУКИ
                                    </h1>
                                    <Link
                                        className={styles.mainMoreLink}
                                        to={clientRoutes.catalog.getUrl(
                                            {},
                                            {
                                                masterFilterValue: ObjectType.SocialBulletin,
                                                masterFilterName: 'objectType',
                                                objectType: ObjectType.SocialBulletin
                                            }
                                        )}
                                    >
                                        Все
                                    </Link>
                                </div>
                            </div>
                            <div>
                                {renderSnippet(showcaseDocuments.journalsSocial)}
                            </div>
                            <div className={styles.mainFiller} />
                        </React.Fragment>
                    }
                    {(showcaseDocuments && showcaseDocuments.popularScience) &&
                        <React.Fragment>
                            <div className={styles.mainTitleFix}>
                                <div className={styles.mainDelimeter} />
                                <div className={styles.mainShowcaseSectionHeader}>
                                    <h1
                                        className={styles.mainShowcaseSectionTitle}
                                    >
                                        НАУЧНО-ПОПУЛЯРНЫЕ МАТЕРИАЛЫ
                                    </h1>
                                    <Link
                                        className={styles.mainMoreLink}
                                        to={clientRoutes.catalog.getUrl(
                                            {},
                                            {
                                                masterFilterValue: 'POPULAR_SCIENCE',
                                                masterFilterName: FilterName.MaterialType,
                                                [FilterName.MaterialType]: 'POPULAR_SCIENCE'
                                            }
                                        )}
                                    >
                                        Все
                                    </Link>
                                </div>
                            </div>
                            <div>
                                {showcaseDocuments.popularScience.map(renderSnippet)}
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
            <div className={styles.mainSubscriptionStats}>
                <div className={styles.mainSubscriptionStatsDelimiter} />
                <div className={styles.mainSubscription}>
                    <Subscription />
                </div>
                <div className={styles.mainStats}>
                    <Stats />
                </div>
            </div>
        </div>
    );
};

export default createDataMockProvider({
    propsMock: {
        showcaseDocuments: {
            latest: Array.from(Array(7), (_, i) => `${Date.now()}-${i}`),
            journalsTechnical: `${Date.now()}-technical-mock`,
            journalsSocial: `${Date.now()}-social-mock`,
            popularScience: Array.from(Array(3), (_, i) => `${Date.now()}-${i}`),
            items: {}
        }
    },
    shouldMock: (props: IProps): boolean => Object.keys(props.showcaseDocuments).length === 0
})(Main);
