import React, { useMemo, useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import { PasswordFormField, InputFieldMode } from 'app/components/common/form-fields';
import { getPasswordsMatchValidator, requiredStringValidation, oneOfValidation } from 'app/utils/forms/validation';
import { Link } from 'react-router-dom';
import clientRoutes, { AccountActionQueryParams } from 'common/clientRoutes';
import RFBRVerticalLogo from 'app/components/common/RFBRVertivalLogo';
import { useFormSenderHelper } from 'app/hooks/forms';
import { UserActionStatus } from 'common/api/ExtendedApi';
import classNames from 'classnames';

import IProps from './interfaces/IPasswordResetProps';

import * as styles from './PasswordReset.scss';

const passwordsMatchValidation = getPasswordsMatchValidator('newPassword');

const PasswordReset: React.FC<IProps> = (props) => {
    const {
        formStatus,
        formResponse,
        resetPasswordRequest,
        resetForm,
        showLoginPopup,
        showResetPasswordPopup,
        history
    } = props;

    const { token } = props.match.params;

    const onSuccess = useCallback(
        () => {
            history.push(clientRoutes.main.getUrl());
            showLoginPopup();
        },
        []
    );

    const onResetButtonClick = useCallback(
        () => {
            history.push(clientRoutes.main.getUrl());
            showResetPasswordPopup();
        },
        []
    );

    const resetButton = useMemo(
        () => (
            <button
                onClick={onResetButtonClick}
                className={classNames('btn', styles.resetPasswordFormResetButton)}
            >
                восстановление пароля.
            </button>
        ),
        [onResetButtonClick]
    );

    const onFail = useCallback(
        () => {
            if (!formResponse || !formResponse.response || !formResponse.response.status) {
                return;
            }
            switch (formResponse.response.status) {
                case UserActionStatus.TOKEN_NOT_FOUND:
                case UserActionStatus.TOKEN_INVALID:
                    return (
                        <>
                            Произошла ошибка.
                            Вам необходимо выполнить повторное {resetButton}
                        </>
                    );
                case UserActionStatus.TOKEN_EXPIRED:
                    return (
                        <>
                            Истек срок действия ссылки для восстановления пароля.
                            Вам необходимо выполнить повторное {resetButton}
                        </>
                    );
                case UserActionStatus.USER_NOT_FOUND:
                    return 'Произошла ошибка. Данный аккаунт не активен, обратитесь в службу поддержки.';
                default:
                    return;
            }
        },
        [formResponse]
    );

    const formSubmitCbRef = useFormSenderHelper(
        formStatus,
        resetForm,
        onSuccess,
        onFail
    );

    const onSubmit = useCallback(
        (values, _, cb) => {
            resetPasswordRequest(token, values.newPassword);
            formSubmitCbRef.current = cb;
        },
        [token]
    );

    return !token ? (
        <div className={styles.resetPasswordFormNoTokenError}>Ошибка</div>
    ) : (
        <Form
            onSubmit={onSubmit}
            render={({handleSubmit, submitting, errors, submitErrors}) => {
                const formHasErrors = errors && Object.keys(errors).length > 0;
                return (
                    <form
                        className={styles.resetPasswordForm}
                        onSubmit={handleSubmit}
                    >
                        <Link
                            to={clientRoutes.main.getUrl()}
                        >
                            <RFBRVerticalLogo/>
                        </Link>
                        <h1 className={styles.resetPasswordFormTitle}>
                            Восстановление пароля
                        </h1>
                        <Field
                            name="newPassword"
                            validate={requiredStringValidation}
                            render={({input, meta}) => (
                                <PasswordFormField
                                    {...input}
                                    className={styles.resetPasswordFormInput}
                                    mode={InputFieldMode.REGULAR}
                                    label={'Новый пароль'}
                                    disabled={submitting}
                                    autoComplete={'new-password'}
                                    error={meta.touched ? meta.error : undefined}
                                />
                            )}
                        />
                        <Field
                            name="confirmation"
                            validate={oneOfValidation(requiredStringValidation, passwordsMatchValidation)}
                            render={({input, meta}) => (
                                <PasswordFormField
                                    {...input}
                                    className={styles.resetPasswordFormInput}
                                    mode={InputFieldMode.REGULAR}
                                    label={'Подтвердите новый пароль'}
                                    disabled={submitting}
                                    autoComplete={'new-password'}
                                    error={meta.touched ? meta.error : undefined}
                                />
                            )}
                        />
                        <button
                            type="submit"
                            className={`btn-primary ${styles.resetPasswordFormSubmitButton}`}
                            disabled={formHasErrors || submitting}
                        >
                            Сохранить
                        </button>
                        {submitErrors &&
                            <div className={'form-field-error'}>
                                {submitErrors}
                            </div>
                        }
                    </form>
                );
            }}
        />
    );
};

export default PasswordReset;
