/**
 * Created by lkarmelo on 20.03.2019.
 */

import React from 'react';
import {useEffect, useMemo} from 'react';
import queryString from 'qs';

import {IFiltersProps, FiltersComponent as DefaultFilters} from 'app/components/common/ContextBookList';

import {parseOptions} from 'app/utils/queryStringOptions';
import {queryValueToFilterValue} from 'app/utils/filters/queryValueToFilterValue';
import {StaticFilterName} from 'common/types/FilterName';

const hiddenFilters = [StaticFilterName.Sort];

const CatalogFilters: React.FunctionComponent<IFiltersProps> = (props) => {
    const {location, filters, setFilters} = props;

    const {masterFilterName: filterName, masterFilterValue: filterValue} =
        useMemo(() => queryString.parse(location.search, parseOptions), [location.search]);

    const filtersWithoutMasterFilter = useMemo(
        () => Array.isArray(filters) ? filters.filter(f => f.name !== filterName) : filters,
        [filters, filterName]
    );

    useEffect(
        () => {
            if (!filters || !filterName || !filterValue) {
                return;
            }

            const filter = filters.find(f => filterName === f.name);
            if (!filter) {
                return;
            }
            const value = queryValueToFilterValue(filterValue, filter.type);
            setFilters([{filterName: filter.name, value}]);
        },
        [filterName, filterValue, filters]
    );

    return (
        <DefaultFilters {...props} filters={filtersWithoutMasterFilter} hiddenFilters={hiddenFilters} />
    );
};

export default CatalogFilters;
