/**
 * Created by lkarmelo on 15.08.2019.
 */

import {connect} from 'react-redux';

import DeleteAccount from './DeleteAccount';
import {IDeleteAccountStatProps, IDeleteAccountActionProps} from './interfaces/IDeleteAccountProps';

import {deleteAccount} from 'app/redux/actions/forms/formsSendActions';
import {setFormStatus} from 'app/redux/actions/forms/forms';

import {ExtendedStore} from 'common/store';
import {FormName} from 'app/types/FormName';
import {withRouter} from 'react-router';
import {logOut} from 'nkc-frontend-tools/redux/actions/authorization';

const mapStateToProps = (state: ExtendedStore.IState): IDeleteAccountStatProps => ({
    formStatus: state.forms[FormName.DeleteAccount] && state.forms[FormName.DeleteAccount].status
});

const mapDispatchToProps = (dispatch): IDeleteAccountActionProps => ({
    deleteAccount() {
        dispatch(deleteAccount());
    },
    resetFormStatus() {
        dispatch(setFormStatus(FormName.DeleteAccount, null));
    },
    logout(): void {
        dispatch(logOut());
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteAccount));
