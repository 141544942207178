/**
 * Created by lkarmelo on 19.08.2019.
 */

import React from 'react';

import PasswordChange from '../PasswordChange';

import IProps from './interfaces/IRegistrationInfoProps';

import {styles as editablePanelStyles} from 'app/hooks/useEditablePanel';
import * as styles from './RegistrationInfo.scss';

const RegistrationInfo: React.FC<IProps> = ({email}) => (
    <div className={styles.registrationInfo}>
        <div className={editablePanelStyles.editablePanelHeader}>
            <div className={editablePanelStyles.editablePanelTitle}>Данные регистрации</div>
        </div>
        <div className={styles.registrationInfoEmailHintContainer}>
            <div className={styles.registrationInfoPanel}>
                <div className={'field-label'}>
                    Email (основной, является логином)
                </div>
                <div className={styles.registrationInfoEmail}>
                    {email}
                </div>
            </div>
            <div className={styles.registrationInfoHint}>
                Ваш еmail для входа в систему. Вы не можете его изменить. Все системные уведомления
                <br />
                (восстановление и смена пароля) будут приходить на эту почту.
            </div>
        </div>
        <PasswordChange />
    </div>
);

export default RegistrationInfo;
