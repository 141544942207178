import {ObjectType} from 'common/store/ExtendedStore';
import {ExtendedStore} from 'common/store';

export const getAuthorsOrEditor = <T extends Partial<ExtendedStore.IDocumentSnippet>>(book: T): string[] | undefined => {
    if (!book || !book.document) {
        return;
    }

    const editor = book.document.meta &&
        book.document.meta.issueInfo &&
        book.document.meta.issueInfo.editor || '';
    const objectType = book.document.meta &&
        book.document.meta.type;

    return objectType ===  ObjectType.TechnicalBulletin ||  ObjectType.SocialBulletin === objectType ?
        [editor] :
        Array.isArray(book.document.authors) ?
            book.document.authors.map(p => {
                if (!p.lastName) {
                    return p.fullName;
                }
                const partialFirstName = p.firstName ? `${p.firstName.slice(0, 1)}.` : '';
                const partialMiddleName = p.middleName ? `${p.middleName.slice(0, 1)}.` : '';
                return `${p.lastName} ${partialFirstName} ${partialMiddleName}`;
            }) :
            undefined;
};
