/**
 * Created by lkarmelo on 20.03.2019.
 */

import {Store} from 'nkc-frontend-tools/types';

const queryValueToMultiSelect = (queryValue: any): string[] => {
    if (Array.isArray(queryValue)) {
        return queryValue;
    } else if (typeof queryValue === 'string') {
        return [queryValue];
    } else {
        return [JSON.stringify(queryValue)];
    }
};

const queryValueToSelect = (queryValue: any): string => {
    if (typeof queryValue === 'string') {
        return queryValue;
    } else if (Array.isArray(queryValue)) {
        return queryValue.length > 0 ? queryValue[0] : '';
    } else {
        return JSON.stringify(queryValue);
    }
};

export const queryValueToFilterValue = (queryValue: any, filterType: Store.FilterType): Store.IFilterValue => {
    switch (filterType) {
        case Store.FilterType.MultiSelect: {
            return queryValueToMultiSelect(queryValue);
        }
        case Store.FilterType.Select: {
            return queryValueToSelect(queryValue);
        }
        default: return queryValue;
    }
};
