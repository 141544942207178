/**
 * Created by lkarmelo on 25.01.2019.
 */

import React, { useCallback } from 'react';
import { RestrictedPermission } from 'app/components/high-order/RestrictedPermission';
import PermissionKey from 'common/permissions/PermissionKey';
import { useFormSenderHelper } from 'app/hooks/forms';
import classNames from 'classnames';

import IProps from './interfaces/ISubscriptionProps';

import * as styles from './Subscription.scss';

const Subscription: React.FC<IProps> = (props) => {
    const { subscribe } = props;

    const onSubscribeButtonClick = useCallback(
        () => {
            subscribe();
        },
        []
    );

    return (
        <div className={styles.subscription}>
            <div className={styles.subscriptionTitle}>УЗНАВАЙТЕ О ПОСТУПЛЕНИЯХ В БИБЛИОТЕКУ РФФИ</div>
            <RestrictedPermission pk={PermissionKey.subscribeToMailingList}>
                <button
                    type="button"
                    className={classNames(`btn-primary`, styles.subscriptionButton)}
                    onClick={onSubscribeButtonClick}
                >
                    Подписаться на рассылку
                </button>
            </RestrictedPermission>
        </div>
    );
};

export default Subscription;
