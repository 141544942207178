/**
 * Created by lkarmelo on 04.10.2019.
 */

import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import ReaderSettings from '../ReaderControls/ReaderSettings/ReaderSettings';
import ReaderBookmarks from '../ReaderControls/ReaderBookmarks/ReaderBookmarks';

import clientRoutes from 'common/clientRoutes';

import IProps from './interfaces/IReaderHeaderProps';

import * as styles from './ReaderHeader.scss';

const ReaderHeader: React.FC<IProps> = (props) => {
    const {className, pageScale, setPageScale, title, bookId, addBookmark, bookmarks, removeBookmark, setPage} = props;
    return (
        <div className={classNames(className, styles.readerHeader)}>
            <Link to={clientRoutes.main.getUrl()} className={styles.readerHeaderMainLink}>
                <span className={'icon-arrow-left'} />
                В библиотеку
            </Link>
            <div className={styles.readerHeaderTitleContainer}>
                <Link className={styles.readerHeaderTitle} to={clientRoutes.book.getUrl({id: bookId})}>
                    {title}
                </Link>
            </div>
            <div className={styles.readerHeaderControls}>
                <button
                    className={'btn icon-contents'}
                    title={'Содержание'}
                />
                <ReaderSettings
                    pageScale={pageScale}
                    setPageScale={setPageScale}
                />
                <button
                    className={'btn icon-bookmark-add'}
                    onClick={addBookmark}
                    title={'Добавить страницу в закладки'}
                />
                <ReaderBookmarks
                    bookmarks={bookmarks}
                    removeBookmark={removeBookmark}
                    setPage={setPage}
                />
                <button
                    className={'btn icon-fullscreen'}
                    title={'Полноэкранный режим'}
                />
            </div>
        </div>
    );
};

ReaderHeader.defaultProps = {
    title: '',
    bookId: '',
};

export default React.memo(ReaderHeader);
