import React from 'react';
import { useCallback, useRef, useState } from 'react';

import { apiRoutes } from 'common/api';
import { disabled } from 'stylessheets/antd.scss';

import classNames from 'classnames';
import {SocialProviderToSetting, IProviderSetting} from 'app/types/SocialProviderToSetting';

interface IProps {
    provider: string;
    onLogin(): void;
}

const Authenticate: React.FunctionComponent<IProps> = (props): JSX.Element => {
    const { provider, onLogin, children } = props;

    const popup = useRef<Window | null>(null);
    const openPopup = useCallback(() => {

            const width = 600, height = 600;
            const left = (window.innerWidth / 2) - (width / 2);
            const top = (window.innerHeight / 2) - (height / 2);
            const url = `${apiRoutes.authenticate.getUrl({ provider })}`;

            popup.current = window.open(
                url,
                '',
                `toolbar=no, location=no, directories=no, status=no, menubar=no,
                scrollbars=no, resizable=no, copyhistory=no, width=${width},
                height=${height}, top=${top}, left=${left}`
            );
        },
                                  []
    );

    const checkInterval = useRef<number | null>(null);
    const [process, setProcess] = useState(false);

    const checkPopup = useCallback(() => {
            checkInterval.current = window.setInterval(() => {
                    const popupWindow = popup.current;
                    if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                        clearInterval(checkInterval.current);
                        setProcess(false);

                        // try login
                        onLogin();
                    }
                },
                                                       1000
            );
        },
                                   []
    );

    const startAuth = useCallback(() => {
            if (!process) {
                openPopup();
                checkPopup();

                setProcess(true);
            }
        },
                                  []
    );

    const providerSetting: IProviderSetting = SocialProviderToSetting.get(provider);

    return (
        <button
            className={classNames(providerSetting.className, 'btn')}
            onClick={startAuth}
            disabled={disabled && process}
        >
            {children}
        </button>
    );

};

export default Authenticate;
