import React, { useState, useMemo, useEffect } from 'react';

import uniqueId from 'lodash.uniqueid';
import { IPerson } from 'common/api/ExtendedApi';
import IDocumentFormProps from '../interfaces/IDocumentFormProps';
import { IDocumentAttrs } from '../interfaces/IAttrDocumentFormProps';
import { findOption } from 'app/components/common/form-fields/Select/SelectFunction';
import DocumentFormView from './DocumentFormView';
import { PublicationType } from 'common/types/PublicationType';
import { ICategory, IDocumentPerson } from 'nkc-frontend-tools/types/Store';
import { ICatalogItem } from 'common/store/ExtendedStore';

const emptyDocument = {
    title: '',
    materialType: '',
    tags: [],
    knowledgeCategories: [],
    grnti: []
};

const emptyIssueField = {
    [PublicationType.Catalog]: {
        issueInfo: {
            projectNumber: []
        }
    },
    [PublicationType.Book]: {
        issueInfo: {
            projectNumber: []
        }
    },
    [PublicationType.AnnotationReport]: {
        issueInfo: {
            projectNumber: []
        }
    },
    [PublicationType.Digest]: {
        issueInfo: {
            projectNumber: []
        },
        childrenArticles: []
    },
    [PublicationType.InformationalBulletin]: {
        issueInfo: {
            projectNumber: []
        }
    },
    [PublicationType.SocialBulletin]: {
        issueInfo: {
            projectNumber: []
        },
        childrenArticles: []
    },
    [PublicationType.TechnicalBulletin]: {
        issueInfo: {
            projectNumber: []
        },
        childrenArticles: []
    },
    [PublicationType.PublicationReferenceBook]: {
        issueInfo: {
            projectNumber: []
        }
    },
    [PublicationType.ScienceArticle]: {
        issueInfo: {
            projectNumber: []
        }
    }
};

const DocumentForm: React.FC<IDocumentFormProps> = (props) => {

    const {
        document: documentProp,
        documentSources,
        onSubmit: onSubmitProps
    } = props;

    const [authors, setAuthors] = useState<IPerson[]>([]);
    const [editor, setEditor] = useState<IPerson>(null);

    const document = useMemo(() => documentProp ?? {}, [documentProp]);
    const knowledgeCategories = useMemo(
        () => document.knowledgeCategories ?.map((item: ICategory | ICatalogItem) => (item as ICategory).id ?? (item as ICatalogItem).code),
        [document.knowledgeCategories]
    );

    // update authors and editor
    const documentAuthors = document?.authors;
    useEffect(
        () => {
            setAuthors((document?.authors || []).map((item: IDocumentPerson) =>
                ({
                    id: item.id,
                    title: item.fullName,
                    firstName: item.firstName,
                    middleName: item.middleName,
                    lastName: item.lastName
                })));
        },
        [documentAuthors]
    );

    const documentEditor = document?.meta?.issueInfo?.editor;
    useEffect(
        () => {
            const editorFullName = document?.meta?.issueInfo?.editor as string || '';
            const [edLastName, edFirstName, edMiddleName] = editorFullName.split(' ');

            setEditor({
                id: uniqueId('editor'),
                title: editorFullName,
                lastName: edLastName,
                firstName: edFirstName,
                middleName: edMiddleName
            });
        },
        [documentEditor]
    );

    const onEditAuthor = (author: IPerson) => {
        const editIndex = authors.findIndex((item) => item.id === author.id);
        setAuthors( editIndex === -1
            ? authors
            : [
                ...authors.slice(0, editIndex),
                author,
                ...authors.slice(editIndex + 1)
            ]);
    };

    const onAddAuthor = (newAuthor: Omit<IPerson, 'id' | 'title'>) => {
        setAuthors([
            ...authors,
            {
                ...newAuthor,
                id: uniqueId('author'),
                title: [newAuthor.lastName, newAuthor.firstName, newAuthor.middleName].filter(Boolean).join(' ')
            }
        ]);
    };

    const onDeleteAuthor = (author: IPerson) => {
        setAuthors(authors.filter(item => item.id !== author.id));
    };

    const onEditEditor = (person: IPerson) => setEditor(person);
    const onClearEditor = (person: IPerson) => setEditor({
        id: person.id,
        title: '',
        lastName: '',
        firstName: '',
        middleName: ''
    });

    const onSubmit = (documentAttrs: IDocumentAttrs) => {

        const emptyMeta = (emptyIssueField[documentAttrs.meta.type]);
        const newDocument = {
            ...emptyDocument,
            ...document,
            ...documentAttrs,
            knowledgeCategories:
                documentAttrs.knowledgeCategories &&
                findOption(documentAttrs.knowledgeCategories, documentSources.knowledgeCategories, [])
                    .map((item) => ({
                        code: item.id,
                        title: item.label
                    })),
            year: parseInt(documentAttrs.year, 10),
            meta: {
                ...emptyMeta,
                ...document.meta,
                ...documentAttrs.meta,
                issueInfo: {
                    ...emptyMeta.issueInfo,
                    ...document.meta?.issueInfo,
                    ...documentAttrs.meta.issueInfo,
                    projectNumber: !!documentAttrs.meta.issueInfo.projectNumber
                        ? [documentAttrs.meta.issueInfo.projectNumber]
                        : []
                }
            }
        };

        onSubmitProps(newDocument, authors, editor);
    };

    return (
        <DocumentFormView
            {... props}
            document={document}
            authors={authors}
            editor={editor}
            knowledgeCategories={knowledgeCategories}
            onAddAuthor={onAddAuthor}
            onEditAuthor={onEditAuthor}
            onDeleteAuthor={onDeleteAuthor}
            onEditEditor={onEditEditor}
            onClearEditor={onClearEditor}
            onSubmit={onSubmit}
        />
    );
};

export default DocumentForm;
