/**
 * Created by lkarmelo on 23.01.2019.
 */

import {handleActions, Action} from 'redux-actions';

import {setShowcaseDocuments} from 'app/redux/actions/showcaseDocuments';
import {setLikeState, ISetLikeStatusPayload} from 'app/redux/actions/like';
import {IFavoriteActionPayload, setFavoriteState} from '../actions/favorite';

import {updateSnippetContainerFavorite, updateSnippetContainerLike} from './utils/updateDocummentSnippet';

import {ExtendedStore} from 'common/store';

export default handleActions<ExtendedStore.IShowcaseDocuments, any>(
    {
        [setShowcaseDocuments.toString()]: (_, {payload}: Action<ExtendedStore.IShowcaseDocuments>): ExtendedStore.IShowcaseDocuments =>
            payload,

        [setLikeState.toString()](
            state: ExtendedStore.IShowcaseDocuments,
            {payload: {nextLikeState, id: bookId}}: Action<ISetLikeStatusPayload>
        ): ExtendedStore.IShowcaseDocuments {
            const {items} = state;
            const nextItems = updateSnippetContainerLike(items, bookId, nextLikeState);

            if (items === nextItems) {
                return state;
            }
            return {...state, items: nextItems};
        },
        [setFavoriteState.toString()](
            state: ExtendedStore.IShowcaseDocuments,
            {payload: {favoriteState, bookId}}: Action<IFavoriteActionPayload>
        ): ExtendedStore.IShowcaseDocuments {
            const {items} = state;
            const nextItems = updateSnippetContainerFavorite(items, bookId, favoriteState);

            if (items === nextItems) {
                return state;
            }
            return {...state, items: nextItems};
        }
    },
    {}
);
