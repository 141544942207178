import React, { useMemo, useEffect, useState, useCallback } from 'react';
import IProps, { ConfirmationStatus } from './interfaces/IConfirmAccountCreationProps';

import queryString from 'qs';
import { parseOptions } from 'app/utils/queryStringOptions';
import { useFormSenderHelper } from 'app/hooks/forms';
import { Link, Router } from 'react-router-dom';

import clientRoutes, { AccountActionQueryParams } from 'common/clientRoutes';
import RFBRVerticalLogo from 'app/components/common/RFBRVertivalLogo';

import * as styles from './ConfirmAccountCreation.scss';

const ConfirmAccountCreation: React.FC<IProps> = (props) => {
    const {
        formStatus,
        confirmRequest,
        resetForm,
        showLoginPopup,
        showResetPasswordPopup,
        location,
        history
    } = props;

    const { token } = props.match.params;

    const [confirmationStatus, setConfirmationStatus] = useState<ConfirmationStatus>(ConfirmationStatus.PENDING);

    useEffect(
        () => {
            token ? confirmRequest(token) : setConfirmationStatus(ConfirmationStatus.ERROR);
        },
        [token]
    );

    const onSuccess = useCallback(() => setConfirmationStatus(ConfirmationStatus.SUCCESS), []);
    const onFail = useCallback(() => setConfirmationStatus(ConfirmationStatus.ERROR), []);

    useFormSenderHelper(
        formStatus,
        resetForm,
        onSuccess,
        onFail
    );

    const onEnterButtonClick = useCallback(
        () => {
            history.push(clientRoutes.main.getUrl());
            showLoginPopup();
        },
        []
    );

    const onResetPasswordButtonClick = useCallback(
        () => {
            history.push(clientRoutes.main.getUrl());
            showResetPasswordPopup();
        },
        []
    );

    if (confirmationStatus === ConfirmationStatus.PENDING) {
        return <div className={styles.confirmAccountPageLoading}>Загрузка...</div>;
    }

    return (
        <div className={styles.confirmAccountPage}>
            <Link
                to={clientRoutes.main.getUrl()}
                className={styles.confirmAccountPageLogo}
            >
                <RFBRVerticalLogo/>
            </Link>
            <h1 className={styles.confirmAccountPageTitle}>
                {
                    confirmationStatus === ConfirmationStatus.SUCCESS ?
                        'Ваш аккаунт успешно подтвержден!' : 'Ваш аккаунт не подтвержден'
                }
            </h1>
            {
                confirmationStatus === ConfirmationStatus.SUCCESS ? (
                    <button
                        className={`btn-primary ${styles.confirmAccountPageBtn}`}
                        onClick={onEnterButtonClick}
                    >
                        Войти
                    </button>
                ) : (
                    <button
                        className={`btn-primary ${styles.confirmAccountPageBtn}`}
                        onClick={onResetPasswordButtonClick}
                    >
                        Восстановить пароль
                    </button>
                )
            }
        </div>
    );
};

export default ConfirmAccountCreation;
