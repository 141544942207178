import { handleActions, Action } from 'redux-actions';
import initialState from 'common/store/storeInitialState';
import {
    setNotificationTypes,
    setNotificationSettings,
    setNotificationSetting,
    setNotifications,
    setNotificationsRequestParams
} from '../actions/notifications';
import { INotificationSetting, INotificationsResponseBody, INotificationsRequestParams } from 'common/api/ExtendedApi';
import { INotifications } from 'common/store/ExtendedStore';
import { schema, normalize } from 'normalizr';

const notificationsSchema = new schema.Entity(
    'notifications',
    undefined,
    {
        idAttribute: notification => notification.notificationType
    }
);

type PayloadTypes =
    Record<string, string> &
    INotificationSetting[] &
    INotificationSetting &
    INotificationsResponseBody &
    INotificationsRequestParams;
export default handleActions<INotifications, PayloadTypes>(
    {
        [setNotificationTypes.toString()]: (state, { payload }: Action<Record<string, string>>) => ({
            ...state,
            notificationTypes: payload || {}
        }),
        [setNotificationSettings.toString()]: (state, { payload }: Action<INotificationSetting[]>) => {
            const normalizedSettings = normalize(payload, [notificationsSchema]);

            return {
                ...state,
                notificationSettings: normalizedSettings.entities.notifications || {}
            };
        },
        [setNotificationsRequestParams.toString()]: (state, { payload }: Action<INotificationsRequestParams>) => ({
            ...state,
            requestParams: payload
        }),
        [setNotifications.toString()]: (state, { payload }: Action<INotificationsResponseBody>) => ({
            ...state,
            ...payload
        }),
        [setNotificationSetting.toString()]: (state, { payload }: Action<INotificationSetting>) => ({
            ...state,
            notificationSettings: {
                ...state.notificationSettings,
                [payload.notificationType]: payload
            }
        })
    },
    initialState.notifications
);
