/**
 * Created by lkarmelo on 01.08.2019.
 */

import React from 'react';
import {Form, Field} from 'react-final-form';

import IProps from './interfaces/INameInfoFormProps';
import {TextFormField, InputFieldMode} from 'app/components/common/form-fields';

import {requiredStringValidation} from 'app/utils/forms/validation';

import * as styles from './NameInfoForm.scss';

const NameInfoForm = (props: IProps) => {
    const {initialValues, renderControls, onSubmit} = props;

    return (
        <Form onSubmit={onSubmit}>
            {({handleSubmit, submitting, pristine, errors, submitErrors}) => {
                const formHasErrors = errors && Object.keys(errors).length > 0;

                return (
                    <form onSubmit={handleSubmit} className={styles.nameInfoForm}>
                        <div className={styles.nameInfoFormTitle}>
                            Изменение ФИО
                        </div>
                        <Field name={'lastName'} initialValue={initialValues.lastName} validate={requiredStringValidation}>
                            {({input, meta}) =>
                                <div className={styles.nameInfoFormFieldWrapper}>
                                    <TextFormField
                                        {...input}
                                        mode={InputFieldMode.MATERIAL}
                                        label={'Фамилия'}
                                        disabled={submitting}
                                        error={meta.error}
                                    />
                                </div>
                            }
                        </Field>
                        <Field name={'firstName'} initialValue={initialValues.firstName} validate={requiredStringValidation}>
                            {({input, meta}) => (
                                <div className={styles.nameInfoFormFieldWrapper}>
                                    <TextFormField
                                        {...input}
                                        mode={InputFieldMode.MATERIAL}
                                        label={'Имя'}
                                        disabled={submitting}
                                        error={meta.error}
                                    />
                                </div>
                            )}
                        </Field>
                        <Field name={'middleName'} initialValue={initialValues.middleName}>
                            {({input}) =>
                                <div className={styles.nameInfoFormFieldWrapper}>
                                    <TextFormField
                                        {...input}
                                        mode={InputFieldMode.MATERIAL}
                                        label={'Отчество'}
                                        disabled={submitting}
                                    />
                                </div>
                            }
                        </Field>
                        {submitErrors &&
                            <div className={'form-field-error'}>
                                {submitErrors}
                            </div>
                        }
                        {renderControls(handleSubmit, formHasErrors || submitting || pristine, submitting)}
                    </form>
                );
            }}
        </Form>
    );
};

export default NameInfoForm;
