/**
 * Created by lkarmelo on 05.09.2019.
 */

import React from 'react';

export const renderError = (error: string | {[er: string]: string}): React.ReactChild => {
    if (!error || typeof error === 'string') {
        return error as string;
    } else if (typeof error === 'object') {
        let resultError = '';
        Object.entries(error).map(([_, er]) => {
            if (er) {
                resultError += `${er}\n`;
            }
        });

        return resultError;
    }
};
