/**
 * Created by lkarmelo on 19.06.2019.
 */
import {useRef, useEffect} from 'react';

export const usePrevious = <T>(value: T): T => {
    const ref = useRef(undefined);

    useEffect(
        () => {
            ref.current = value;
        },
        [value]
    );

    return ref.current;
};
