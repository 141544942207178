/**
 * Created by lkarmelo on 12.03.2019.
 */

import {DependencyList, useLayoutEffect, useRef} from 'react';

/*
* Колбек срабатывает только на update, в отличии от useLayoutEffect, который срабатывает ещё и после mount'а
* */

export const useLayoutEffectOnUpdate = (callback: Function, deps: DependencyList) => {
    const isOnMountCompleted = useRef(false);

    useLayoutEffect(
        () => {
            if (isOnMountCompleted.current) {
                callback();
            } else {
                isOnMountCompleted.current = true;
            }
        },
        deps
    );
};
