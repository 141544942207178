/**
 * Created by lkarmelo on 07.05.2019.
 */

import {ContextAction} from 'app/types/ContextAction';
import {ExtendedStore} from 'common/store';
import {actionWithContext} from './actionWithContext';

export const getActionWithAllContexts = (action, keepOriginalAction = true): ContextAction[] => {
    const list = keepOriginalAction ? [action] : [];

    Object.entries(ExtendedStore.BookSearchContext).forEach(([_, context]) => {
        list.push(actionWithContext(action, context));
    });

    return list;
};
