/**
 * Created by lkarmelo on 09.04.2019.
 */

import React from 'react';
import {useCallback, useMemo, useState} from 'react';

import ProgressiveImage from 'react-progressive-image';

import IProps from './interfaces/IBookCoverProps';

import {apiRoutes} from 'common/api';
import {getImagePlaceHolderUrl} from 'app/utils/getImagePlaceHolderUrl';
import {getIsTypeBulletin} from 'app/utils/getIsTypeBulletin';

import * as styles from './BookCover.scss';
import {ObjectType, objectTypeColors, objectTypeCoverShortTitles} from 'common/store/ExtendedStore';

const BookCover: React.FunctionComponent<IProps> = (props) => {
    const {
        loadingPlaceholderClassName,
        id,
        objectType,
        title,
        author,
        imgClassName,
        size,
        showBookType,
        covers
    } = props;
    const [isFailed, setIsFailed] = useState(false);

    const coverLink = useMemo(
        () => {
            const foundCover = Array.isArray(covers) && covers.find(cover => cover.coverSize === size);
            return foundCover && foundCover.link;
        },
        [size, covers]
    );

    const isBulletin = getIsTypeBulletin(objectType);
    const imgSrc = useMemo(
        () => isFailed || !coverLink ? getImagePlaceHolderUrl(objectType, size) : coverLink,
        [isFailed, objectType, id, coverLink]
    );
    const onError = useCallback(() => setIsFailed(true), []);

    return (
        <ProgressiveImage
            src={imgSrc}
            placeholder={''}
            onError={onError}
        >
            {(src, isLoading) =>
                isLoading ?
                    <div
                        className={loadingPlaceholderClassName}
                    />
                    :
                    <div className={styles.bookCoverContainer}>
                        <img
                            className={imgClassName}
                            src={src}
                            alt={title}
                        />
                        {(isFailed || !coverLink) && !isBulletin &&
                            <React.Fragment>
                                <div style={{textAlign: 'center'}}>
                                    <div className={styles.bookCoverTitle}>
                                        {title}
                                    </div>
                                </div>
                                <div className={styles.bookCoverAuthor}>
                                    {author}
                                </div>
                            </React.Fragment>
                        }
                        {showBookType && !isBulletin && (
                            <span
                                className={styles.bookCoverType}
                                style={{backgroundColor: objectTypeColors[objectType] || objectTypeColors[ObjectType.Article]}}
                            >
                                {objectTypeCoverShortTitles[objectType] || objectTypeCoverShortTitles[ObjectType.Article]}
                            </span>
                        )}
                    </div>
            }
        </ProgressiveImage>
    );
};

BookCover.defaultProps = {
    size: 'MEDIUM',
};

export default BookCover;
