import { PublicationType } from 'common/types/PublicationType';
import AnnotationReportForm from './AnnotationReportForm/AnnotationReportForm';
import BookForm from './BookForm/BookForm';
import CatalogForm from './CatalogForm/CatalogForm';
import DigestForm from './DigestForm/DigestForm';
import InformationBulletinForm from './InformationBulletinForm/InformationBulletinForm';
import SocialBulletinForm from './SocialBulletinForm/SocialBulletinForm';
import TechincalBulletinForm from './TechincalBulletinForm/TechincalBulletinForm';
import PublicationReferenceBookForm from './PublicationReferenceBookForm/PublicationReferenceBookForm';
import ScienceArticleForm from './ScienceArticleForm/ScienceArticleForm';

export {
    BookForm,
    AnnotationReportForm,
    CatalogForm,
    DigestForm,
    InformationBulletinForm,
    SocialBulletinForm,
    TechincalBulletinForm,
    PublicationReferenceBookForm
};

export const AttrForms = {
    [PublicationType.Catalog]: CatalogForm,
    [PublicationType.Book]: BookForm,
    [PublicationType.AnnotationReport]: AnnotationReportForm,
    [PublicationType.Digest]: DigestForm,
    [PublicationType.InformationalBulletin]: InformationBulletinForm,
    [PublicationType.SocialBulletin]: SocialBulletinForm,
    [PublicationType.TechnicalBulletin]: TechincalBulletinForm,
    [PublicationType.PublicationReferenceBook]: PublicationReferenceBookForm,
    [PublicationType.ScienceArticle]: ScienceArticleForm
};
