import { connect } from 'react-redux';

import UploadDocumentSection from './UploadDocumentSection';
import { IUploadDocumentSectionActionProps, IUploadDocumentSectionStateProps } from './interfaces/IUploadDocumentSectionProps';
import { uploadDocumentContent } from 'app/redux/actions/uploadDocument';
import { fileUploadProgressClean } from 'app/redux/actions/fileUploadProgress';
import { ExtendedStore } from 'common/store';

const mapStateToProps = (state: ExtendedStore.IState): IUploadDocumentSectionStateProps => ({
    fileUploadProgress: state.fileUploadProgress
});

const mapDispatchToProps = (dispatch): IUploadDocumentSectionActionProps => ({
    uploadDocumentContent(documentId: string, uploadId: string, file: File) {
        dispatch(uploadDocumentContent({ documentId, uploadId, file }));
    },
    cleanFileUploadProgress(fileName: string) {
        dispatch(fileUploadProgressClean(fileName));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentSection);
