/**
 * Created by lkarmelo on 20.02.2019.
 */

import {ObjectType, CoverSize} from 'common/store/ExtendedStore';

const annotationReport = require('images/cover-placeholders/annotation-report.svg');
const article = require('images/cover-placeholders/article.svg');
const book = require('images/cover-placeholders/book.svg');
const catalog = require('images/cover-placeholders/catalog.svg');
const digest = require('images/cover-placeholders/digest.svg');
const other = require('images/cover-placeholders/other.svg');
const publPreferenceBook = require('images/cover-placeholders/publication-reference-book.svg');
const informationalBulletin = require('images/cover-placeholders/informational-bulletin.svg');

const socialBulletin = require('images/cover-placeholders/social-bulletin.png');
const techBulletinBig = require('images/cover-placeholders/technical-bulletin-big.jpg');
const techBulletinMedium = require('images/cover-placeholders/technical-bulletin-medium.jpg');
const techBulletinSmall = require('images/cover-placeholders/technical-bulletin-small.jpg');

export const getImagePlaceHolderUrl = (type: string, size?: CoverSize): string => {
    switch (type) {
        case ObjectType.AnnotationReport: return annotationReport;
        case ObjectType.PublicationReferenceBook: return publPreferenceBook;
        case ObjectType.Catalog: return catalog;
        case ObjectType.Book: return book;
        case ObjectType.Digest: return digest;
        case ObjectType.ScienceArticle: return article;
        case ObjectType.InformationalBulletin: return informationalBulletin;
        case ObjectType.TechnicalBulletin: {
            switch (size) {
                case 'BIG': return techBulletinBig;
                case 'SMALL': return techBulletinSmall;
                case 'MEDIUM':
                default: return techBulletinMedium;
            }
        }
        case ObjectType.SocialBulletin: return socialBulletin;
        case ObjectType.Article:
        default: return other;
    }
};
