import React from 'react';
import { Action } from 'redux';
import { DispatchProp, connect } from 'react-redux';

import { useEffect } from 'react';

import queryString from 'qs';
import { parseOptions } from 'app/utils/queryStringOptions';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { confirmAuthenticationInPopup } from 'app/redux/actions/authorization';
import { ExtendedStore } from 'common/store';

interface IConfirmProps {
    confirmError?: boolean;
    errorMessage?: string;
}

const ConfirmAuthentication: React.FunctionComponent<IConfirmProps & DispatchProp<Action> & RouteComponentProps<any>> = (props) => {
    const { dispatch, location, confirmError, errorMessage } = props;

    useEffect(
        () => {
            const qsParams = queryString.parse(location.search, parseOptions);

            dispatch(confirmAuthenticationInPopup(qsParams['Access-Token']));
        },
        []
    );

    return (
        <div>
            <h2>Авторизация</h2>
            {confirmError == null && <span>Пожалуйста подождите ... выполняется проверка авторизации</span>}
            {confirmError === false && <span>Авторизация подтверждена</span>}
            {confirmError === true &&
                (
                    <React.Fragment>
                        <span className="error">Ошибка авторизации</span>
                        <span>{errorMessage}</span>
                    </React.Fragment>
                )
            }
        </div>
    );
};

const mapStateToProps = (state: ExtendedStore.IState): IConfirmProps => ({
    confirmError: state.authorization.authorizationFailed,
    errorMessage: state.authorization.error
});

export default withRouter(connect(mapStateToProps)(ConfirmAuthentication));
