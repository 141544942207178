import React from 'react';

import { requiredStringValidation } from 'app/utils/forms/validation';

import {
    TextField,
    MultiselectField,
    SelectField,
    TextAreaField
} from 'app/components/common/form-fields/material-fields';

import IProps from '../../interfaces/IAttrDocumentFormProps';

const InformationBulletinForm: React.FC<IProps> = (
    {
        document: doc,
        editor,
        documentSources: {
            knowledgeCategories: knowledgeCategoriesSource,
            materialTypes: materialTypeSource
        },
        submitting
    }) => {

    return (
        <>
            <TextField
                name={'title'}
                initialValue={doc.title}
                label="Наименование"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <SelectField
                name={'materialType'}
                label="Тип материала"
                disabled={submitting}
                validate={requiredStringValidation}
                initialValue={doc.materialType}
                options={materialTypeSource || []}
                required
            />

            <TextField
                className={'half'}
                name={'year'}
                initialValue={doc.year ?.toString()}
                label="Год"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <TextField
                className={'half'}
                name={'meta.issueInfo.issueIdentifier'}
                initialValue={doc.meta ?.issueInfo ?.issueIdentifier as string}
                label="Номер выпуска"
                disabled={submitting}
            />

            <TextAreaField
                name={'abstract'}
                initialValue={doc.abstract}
                disabled={submitting}
                placeholder={'Аннотация к информационному бюллетеню'}
            />
        </>
    );
};

export default InformationBulletinForm;
