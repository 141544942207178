import { createSelector } from 'reselect';
import { FilterName } from 'common/types/FilterName';
import { createOrderedFiltersSelector } from './createNormalizedFiltersSelector';
import { SubscriptionType } from 'common/api/ExtendedApi';
import { IFilterMetaWithNormalizedValues } from 'common/store/ExtendedStore';
import { ISelectOption } from 'app/components/common/select/CheckboxSelect/Select/interfaces/ICheckboxSelectProps';
import { capitalizeFirstLetter } from 'app/utils/capitalizeFirstLetter';

export interface ISubscriptionSelectMeta {
    type: SubscriptionType;
    categoryName: string;
    title: string;
    options: ISelectOption[];
}

const filterOrder = [FilterName.KnowledgeArea, FilterName.PublicationType, FilterName.MaterialType];

const filterNames = new Map([
    [FilterName.KnowledgeArea, 'Области знаний'],
    [FilterName.PublicationType, 'Типы издания'],
    [FilterName.MaterialType, 'Типы материала']
]);

const filterSubscriptionMap = new Map<string, SubscriptionType>([
    [FilterName.KnowledgeArea, SubscriptionType.CATEGORY],
    [FilterName.PublicationType, SubscriptionType.STORAGE_SOURCE],
    [FilterName.MaterialType, SubscriptionType.MATERIAL_TYPE]
]);

const selectFilters = createOrderedFiltersSelector(filterOrder, filterNames);

export const subscriptionsSelector = createSelector(
    selectFilters,
    (state) => state.subscriptions,
    (filters: IFilterMetaWithNormalizedValues[], subscriptions): ISubscriptionSelectMeta[] => {
        const isUserSubscribedToAll = !!subscriptions.news;

        return filters.map(filter => {
            const { values, topValues, name, title } = filter;
            const subscriptionType = filterSubscriptionMap.get(name);
            const subscriptionGroup = subscriptions[subscriptionType];

            const options = topValues.map(topFilterValue => {
                const topFilter = values[topFilterValue];

                const subOptions = topFilter.children && topFilter.children.map<ISelectOption>(childFilterValue => {
                    const childFilter = values[childFilterValue];

                    return {
                        name: capitalizeFirstLetter(childFilter.label),
                        value: childFilter.value,
                        checked: Boolean(
                            isUserSubscribedToAll ||
                            subscriptionGroup &&
                            subscriptionGroup[childFilter.value]
                        )
                    };
                });

                return {
                    name: capitalizeFirstLetter(topFilter.label),
                    value: topFilter.value,
                    checked: Boolean(
                        isUserSubscribedToAll ||
                        subscriptionGroup &&
                        subscriptionGroup[topFilter.value]
                    ),
                    subOptions
                };
            });

            return {
                title,
                options,
                type: subscriptionType,
                categoryName: name
            };
        });
    }
);
