/**
 * Created by lkarmelo on 05.08.2019.
 */
import {connect} from 'react-redux';

import {ExtendedStore} from 'common/store';

import {IPasswordChangeActionProps, IPasswordChangeStateProps} from './interfaces/IPasswordChangeProps';
import {IPasswordChangeFormValues} from '../Form';
import {changePassword} from 'app/redux/actions/forms/formsSendActions';
import {setFormStatus} from 'app/redux/actions/forms/forms';
import {FormName} from '../../../../../types/FormName';
import PasswordChange from './PasswordChange';

const mapStateToProps = (state: ExtendedStore.IState): IPasswordChangeStateProps => {
    const form = state.forms[FormName.ChangePassword];

    return {
        formResponse: form && form.response,
        formStatus: form && form.status,
    };
};

const mapDispatchToProps = (dispatch): IPasswordChangeActionProps => ({
    save(values: IPasswordChangeFormValues): void {
        dispatch(changePassword(values));
    },
    resetFormStatus(): void {
        dispatch(setFormStatus(FormName.ChangePassword, null));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
