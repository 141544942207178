/**
 * Created by Lkarmelo on 08.11.2017.
 */

import { handleActions } from 'redux-actions';

import { Store } from '../../types';

import {
    SET_AUTHORIZED, LOG_IN_SUCCESS, LOG_IN_FAIL,
    RESET_PASSWORD_FAIL, RECOVERY_PASSWORD_FAIL,
} from '../actions/authorization';

export default handleActions<Partial<Store.IAuthorization>, any>(
    {
        [SET_AUTHORIZED](state: Store.IAuthorization, { payload }: { payload: boolean; type: string; }) {
            const nextState: Store.IAuthorization = { ...state };
            nextState.isAuthorized = payload;
            return nextState;
        },
        [LOG_IN_SUCCESS](state: Store.IAuthorization) {
            const nextState: Store.IAuthorization = { ...state };
            nextState.authorizationFailed = false;
            return nextState;
        },
        [LOG_IN_FAIL](state: Store.IAuthorization) {
            const nextState: Store.IAuthorization = { ...state };
            nextState.authorizationFailed = true;
            return nextState;
        },
        [RESET_PASSWORD_FAIL](state: Store.IAuthorization, { payload }: { payload: string; type: string; }) {
            const nextState: Store.IAuthorization = { ...state };

            const statusToMsg = (status) => {
                switch (status) {
                    case 'user.not.found':
                        return 'Пользователь не зарегистрирован.';

                    case 'token.not.found':
                    case 'token.invalid':
                        return 'Ссылка на смену пароля не действительна. Необходимо проити процедуру восстановления пароля повторно.';
                    case 'token.expired':
                        return 'Ссылка на смену пароля устарела. Необходимо проити процедуру восстановления пароля повторно.';

                    default:
                        return 'Пароль должен быть не менее 6 символов.';
                }
            };

            nextState.error = statusToMsg(payload);
            return nextState;
        }
    },
    {} //- default value for authorization
);

export const passwordRecovery = handleActions(
    {
        [RECOVERY_PASSWORD_FAIL](state: { error?: string }, { payload }: { payload: string; type: string; }) {
            const nextState: { error?: string } = { ...state };
            nextState.error = payload || 'Произошла неизвестная ошибка';
            return nextState;
        }
    },
    {} //- default value for authorization
);
