/**
 * Created by lkarmelo on 02.10.2019.
 */

import React, {useCallback, useEffect} from 'react';

import LocationObserver from '../ReaderLocationObserver/ReaderLocationObserver';
import ReaderHeader from '../ReaderHeader/ReaderHeader/ReaderHeader';
import ReaderPage from '../ReaderPage/ReaderPage';
import ReaderFooter from '../ReaderFooter/ReaderFooter';

import {useEventListener} from 'app/hooks/useEventListener';
import {useUpdateableRef} from 'app/hooks/useUpdetableRef';

import {clampNumber} from 'app/utils/clampNumber';
import {KeyCode} from 'app/types/KeyCode';

import IProps from './interfaces/IReaderProps';

import * as styles from './Reader.scss';

const Reader: React.FC<IProps> = (props) => {
    const {
        nextPage, prevPage, pageContent, currentPageNumber, resetReader, bookMeta, setPageScale,
        addBookmark, removeBookmark, fetchBookmarks, bookmarks, setPage
    } = props;

    //выносим в ref, чтобы не создавать новый eventListener каждый раз, когда меняется страница
    const currentPageNumberRef = useUpdateableRef(currentPageNumber);
    const totalPages = bookMeta?.totalPages;

    useEffect(() => {bookMeta && fetchBookmarks(bookMeta.bookId); }, [bookMeta?.bookId]);

    useEffect(() => () => resetReader(), []);

    const toPrevPage = useCallback(
        () => {
            currentPageNumberRef.current > 1 && prevPage();
        },
        []
    );

    const toNextPage = useCallback(
        () => {
            currentPageNumberRef.current < totalPages && nextPage();
        },
        [totalPages]
    );

    const _setPageScale = useCallback(
        (nextScale: number) => {
            const safeScale = clampNumber(nextScale, .25, 4);

            setPageScale(safeScale);

        },
        [setPageScale]
    );

    const _addBookmark = useCallback(
        () => bookMeta && currentPageNumberRef.current && addBookmark(bookMeta.bookId, currentPageNumberRef.current, bookMeta.title),
        [bookMeta]
    );

    const _removeBookmark = useCallback((bookId: string, pageNumber: number) => removeBookmark(bookId, pageNumber), [bookMeta]);

    typeof window !== 'undefined' && useEventListener(
        window,
        'keydown',
        (e: KeyboardEvent) => {
            if (e.key === KeyCode.ArrowRight) {
                toNextPage();
            } else if (e.key === KeyCode.ArrowLeft) {
                toPrevPage();
            }
        },
        undefined,
        [toNextPage]
    );

    return (
        <div className={styles.reader}>
            <LocationObserver />
            <ReaderHeader
                className={styles.readerHeader}
                title={bookMeta && bookMeta.title}
                bookId={bookMeta && bookMeta.bookId}
                pageScale={bookMeta && bookMeta.currentScale || 1}
                setPageScale={_setPageScale}
                bookmarks={bookmarks}
                addBookmark={_addBookmark}
                removeBookmark={_removeBookmark}
                setPage={setPage}
            />
            <ReaderPage
                onNext={toNextPage}
                onPrev={toPrevPage}
                pageContent={pageContent}
                className={styles.readerPage}
                //пока не загрузилась информация о сохранённом масштабе, передаём undefined, а не 1, чтобы при применении масштаба не
                //скакало увеличение страницы
                scale={bookMeta ? bookMeta.currentScale || 1 : undefined}
                setScale={_setPageScale}
            />
            <ReaderFooter
                className={styles.readerFooter}
                currentPage={currentPageNumber}
                totalPages={bookMeta && bookMeta.totalPages}
            />
        </div>
    );
};

export default Reader;
