/**
 * Created by PBorisov on 05.07.2019
 */
import React from 'react';

import withRestrictedPermission from './withRestrictedPermission';
import { Permissions } from 'common/permissions';

export interface IPermission {
    pk: Permissions;
    viewOnDisallowed?: boolean;
    disabled?: boolean | string;
}

const TransparentWrapper = (props) => {
    const {children, disabled, onClick} = props;

    // console.log("- transparent wrapper - rerender", ... props);

    const otherChildren = React.Children.map(children, (child) =>
        React.cloneElement(
            child,
            Object.assign(
                {},
                disabled ? { disabled: child.disabled || disabled } : {},
                onClick ? { onClick: onClick || child.onClick } : {}
            )
        )
    );

    return (<React.Fragment>{otherChildren}</React.Fragment>);
};

export const RestrictedPermission = withRestrictedPermission(TransparentWrapper);
