import { useState, useEffect, useRef, useCallback } from 'react';

export type ReactDispatchWithCallback<State> = (newState: React.SetStateAction<State>, callback?: () => void) => void;

export const useStateWithCallback = <State>(initialState: State): [State, ReactDispatchWithCallback<State>] => {
    const [state, setState] = useState<State>(initialState);

    const callbackAfterStateChange = useRef(null);

    useEffect(
        () => {
            callbackAfterStateChange.current && callbackAfterStateChange.current();
        },
        [callbackAfterStateChange.current]
    );

    const setStateWithCallback: ReactDispatchWithCallback<State> = useCallback(
        (newState, callback?) => {
            callbackAfterStateChange.current = callback;
            setState(newState);
        },
        []
    );

    return [state, setStateWithCallback];
};
