import {connect} from 'react-redux';

import ConfirmDeleteBook from './ConfirmDeleteBook';

import {closePopup} from 'app/redux/actions/popups';
import {deleteBook} from 'app/redux/actions/forms/formsSendActions';
import {removeForm, setFormStatus} from 'app/redux/actions/forms/forms';

import {FormName} from 'app/types/FormName';
import {PopupName} from 'app/types/PopupName';
import {ExtendedStore} from 'common/store';

import {IConfirmDeleteBookActionProps, IConfirmDeleteBookStateProps} from './interfaces/IConfirmDeleteBookProps';

const mapStateToProps = (state: ExtendedStore.IState): IConfirmDeleteBookStateProps => {
    const form = state.forms[FormName.ConfirmDeleteBook];

    return {
        isVisible: state.popups[PopupName.ConfirmDeleteBook],
        formStatus: form && form.status,
        formResponse: form && form.response,
        bookId: form && form.data.bookId,
    };
};

const mapDispatchToProps = (dispatch): IConfirmDeleteBookActionProps => ({
    closePopup() {
        dispatch(closePopup(PopupName.ConfirmDeleteBook));
    },
    requestDelete(bookId: string): void {
        dispatch(deleteBook(bookId));
    },
    resetForm(): void {
        dispatch(removeForm(FormName.ConfirmDeleteBook));
    },
    resetFormStatus(): void {
        dispatch(setFormStatus(FormName.ConfirmDeleteBook, null));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteBook);
