import React from 'react';
import { ExtendedStore } from 'common/store';
import { connect } from 'react-redux';
import { ILoginOwnProps, ILoginActionProps } from './interfaces/ILoginProps';
import { logIn } from 'nkc-frontend-tools/redux/actions/authorization';
import LoginPanelPopup from './LoginPanelPopup';
import {closePopup, showPopup} from 'app/redux/actions/popups';
import {PopupName} from 'app/types/PopupName';

interface ILoginPopupOwnProps {
    showLogin?: boolean;
    onPanelClose?(): void;
}

interface ILoginPopupStateProps extends ILoginOwnProps {
    showLogin: boolean;
}

const mapStateToProps = (state: ExtendedStore.IState, ownProps: ILoginPopupOwnProps): ILoginPopupStateProps => ({
    showLogin: !ownProps.onPanelClose ? state.popups[PopupName.Login] || false : ownProps.showLogin,
    authorizationFailed: state.authorization.authorizationFailed,
    alreadyAuthorized: state.authorization.isAuthorized
});

export const mapDispatchToProps = (dispatch, ownProps: ILoginPopupOwnProps): ILoginActionProps => ({
    onLogIn(login: string, password: string): void {
        dispatch(logIn(login, password));
    },
    onPanelClose: !!ownProps.onPanelClose ? ownProps.onPanelClose : (): void => {
        dispatch(closePopup(PopupName.Login));
    },
    openRegistrationPopup(): void {
        dispatch(showPopup(PopupName.Registration));
    },
    openResetPasswordPopup(): void {
        dispatch(showPopup(PopupName.ResetPassword));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanelPopup);
