/**
 * Created by lkarmelo on 19.03.2019.
 */

import React from 'react';
import {useCallback} from 'react';
import classNames from 'classnames';

import BreadCrumbs from 'nkc-frontend-tools/components/BreadCrumbs';
import {IBookListProps, ClsNames as BookListClsNames, BookList} from '../List';

import IProps from './interfaces/IContextBookListProps';

import * as styles from './ContextBookList.scss';

const defaultRenderHeaderContent = ({title}: IProps, {totalFoundResults}: IBookListProps, clsNames: BookListClsNames): JSX.Element => (
    <>
        {title}
        {typeof totalFoundResults === 'number' &&
            <span className={clsNames.bookListResultCount}>{totalFoundResults}</span>
        }
    </>
);

const ContextBookList: React.FunctionComponent<IProps> = (props) => {
    const {breadcrumbsPath, title, filtersComponent, className, renderHeaderContent, ...rest} = props;

    const renderListHeaderContent = useCallback(
        (bookListProps: IBookListProps, clsNames: BookListClsNames) => renderHeaderContent ?
            renderHeaderContent(props, bookListProps, clsNames) :
            defaultRenderHeaderContent(props, bookListProps, clsNames),
        [renderHeaderContent, title]
    );

    return (
        <div className={classNames(styles.contextBookList, className)}>
            <div className={styles.contextBookListBreadcrumbs}>
                <BreadCrumbs
                    paths={breadcrumbsPath}
                    delimiter={<i className={'icon-arrow-right'}/> as any}
                />
            </div>
            <BookList
                {...rest}
                filtersPanel={filtersComponent}
                renderHeaderContent={renderListHeaderContent}
                query={undefined}
            />
        </div>
    );
};

export default ContextBookList;
