import React, { useCallback } from 'react';
import classNames from 'classnames';
import Checkbox from 'app/components/common/Checkbox';

import IProps from './interfaces/ICheckboxOptionProps';
import * as styles from './Option.scss';

const Option: React.FC<IProps> = (props) => {
    const {
        className,
        labelClassName,
        name,
        value,
        meta,
        checked,
        onSelect,
        onDeselect
    } = props;

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.target.checked ? onSelect(value, meta) : onDeselect(value, meta);
        },
        []
    );

    return (
        <label
            className={classNames(
                className,
                styles.checkboxOption
            )}
        >
            <Checkbox
                checked={checked}
                onChange={onChange}
            />
            <div
                className={classNames(
                    labelClassName,
                    styles.checkboxOptionText
                )}
            >
                {name}
            </div>
        </label>
    );
};

export default Option;
