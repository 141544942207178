/**
 * Created by lkarmelo on 19.03.2019.
 */

//взято из документации redux https://redux.js.org/recipes/structuring-reducers/reusing-reducer-logic
export const createFilteredReducer = (reducerFunction, reducerPredicate) => {
    return (state, action) => {
        const isInitializationCall = state === undefined;
        const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
        return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
    };
};
