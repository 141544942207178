/**
 * Created by lkarmelo on 02.10.2019.
 */

import {createAction} from 'redux-actions';
import {ExtendedStore} from 'common/store';

export const setReaderPageContent = createAction<HTMLElement | null>('BOOK_READER_SET_PAGE_CONTENT');

export const setReaderCurrentPageNumber = createAction<number>('BOOK_READER_SET_CURRENT_PAGE_NUMBER');
export const setReaderCurrentBookId = createAction<string>('BOOK_READER_SET_CURRENT_BOOK_ID');

export interface ISetReaderPageNumberAndBookIdPayload {
    bookId: string;
    page: number;
}
export const setReaderCurrentPageNumberAndBookId =
    createAction<ISetReaderPageNumberAndBookIdPayload>('BOOK_READER_SET_CURRENT_BOOK_ID_AND_PAGE_NUMBER');

export const readerNextPage = createAction('BOOK_READER_NEXT_PAGE');
export const readerPrevPage = createAction('BOOK_READER_PREV_PAGE');

export interface IFetchReadingBookMetaPayload {
    bookId: string;
    loadSavedPage?: boolean;
}
export const fetchReadingBookMeta = createAction<IFetchReadingBookMetaPayload>('BOOK_READER_FETCH_META');
export const setReadingBookMeta = createAction<ExtendedStore.IReadingBookMeta>('BOOK_READER_SET_META');

export const resetReader = createAction('BOOK_READER_RESET');

export const setReaderBookScale = createAction<number>('BOOK_READER_SET_BOOK_SCALE');

export const saveReaderLastPage = createAction<ISetReaderPageNumberAndBookIdPayload>('BOOK_READER_SAVE_LAST_PAGE');

export interface IAddBookmarkPayload extends ISetReaderPageNumberAndBookIdPayload {
    title: string;
}
export const requestAddBookmark = createAction<IAddBookmarkPayload>('BOOK_READER_REQUEST_ADD_BOOKMARK');
export const requestRemoveBookmark = createAction<ISetReaderPageNumberAndBookIdPayload>('BOOK_READER_REQUEST_REMOVE_BOOKMARK');

export const addBookmark = createAction<IAddBookmarkPayload>('BOOK_READER_ADD_BOOKMARK');
export const removeBookmark = createAction<ISetReaderPageNumberAndBookIdPayload>('BOOK_READER_REMOVE_BOOKMARK');

export const fetchBookmarks = createAction<string>('BOOK_READER_FETCH_BOOKMARKS');
export const setBookmarks = createAction<ExtendedStore.IBookmark[]>('BOOK_READER_SET_BOOKMARKS');
