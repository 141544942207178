import { INotificationsActionProps } from './interfaces/INotificationsProps';
import {
    fetchNotificationTypes,
    fetchNotificationSettings
} from 'app/redux/actions/notifications';
import { connect } from 'react-redux';
import Notifications from './Notifications';

const mapDispatchToProps = (dispatch): INotificationsActionProps => ({
    fetchNotificationTypes() {
        dispatch(fetchNotificationTypes());
    },
    fetchNotificationSettings() {
        dispatch(fetchNotificationSettings());
    }
});

export default connect(null, mapDispatchToProps)(Notifications);
