import {documentSubmitReject, documentSubmitRequest, documentSubmitResolve} from 'app/redux/actions/loading';
import {raiseSystemNotification} from 'app/redux/actions/systemNotifications';

import {FormName} from 'app/types/FormName';
import {ExtendedStore} from 'common/store';

import {createFormSendEpic} from './createFormSendEpic';
import { createDocument, updateDocument } from 'app/redux/actions/document';
import { IDocument } from 'common/store/ExtendedStore';
import { Action } from 'redux-actions';
import { setFormData } from 'app/redux/actions/forms/forms';

import history from 'app/history';
import clientRoutes from 'common/clientRoutes';
import { ExtendedApi } from 'common/api';
import { redirectOnDocument } from 'app/redux/actions/forms/createDocument';
import { MiddlewareAPI } from 'redux';

export const redirectOnDocumentEpic = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
    action$.ofType( redirectOnDocument.toString() )
        .filter((action: Action<string>) => !!action.payload)
        .do(({payload}: Action<string>) => {
            history.push(clientRoutes.continueNewUpload.getUrl({id: payload}));
        })
        .ignoreElements();

export const addUpdateDocumentEpic = createFormSendEpic({
    triggers: [createDocument.toString(), updateDocument.toString()],
    formName: FormName.AddEditDocumentForm,
    actions: {
        resolveAction: documentSubmitResolve,
        requestAction: documentSubmitRequest,
        rejectAction: documentSubmitReject,
        spawnOnSuccess: [
            (_, {payload}: Action<IDocument>) =>
                !!payload.id
                    ? raiseSystemNotification(ExtendedStore.SystemNotificationType.DocumentUpdate)
                    : raiseSystemNotification(ExtendedStore.SystemNotificationType.DocumentAdd),
            (response, {payload}: Action<IDocument>) => {
                // update form data
                return setFormData(FormName.AddEditDocumentForm,
                                   {
                        ...response,
                        ...payload
                    });
            },
            (response, {payload}: Action<IDocument>) =>
                // update form data
                redirectOnDocument(!!response.id ? response.id : payload.id)
        ]
    }
});
