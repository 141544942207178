import { connect } from 'react-redux';
import { ExtendedStore } from 'common/store';
import { FormName } from 'app/types/FormName';
import WizzardWrapper from './WizzardWrapper';
import AddEditDocument from './AddEditDocument';
import { IAddEditDocumentOwnProps, IAddEditDocumentActionProps, IAddEditDocumentStateProps } from './interfaces/IAddEditDocumentProps';
import { fetchDocument, setDocument } from 'app/redux/actions/document';
import { documentReject } from 'app/redux/actions/loading';
import { raiseSystemNotification } from 'app/redux/actions/systemNotifications';

const mapStateToProps = (
    state: ExtendedStore.IState,
    ownProps: IAddEditDocumentOwnProps
): IAddEditDocumentStateProps & IAddEditDocumentOwnProps => {
    const form = state.forms[FormName.AddEditDocumentForm];

    return {
        pageTitle: ownProps.pageTitle ?? 'Редактирование издания',
        document: form?.data,
        backUrl: form?.backUrl
    };
};

export const mapDispatchToProps = (dispatch): IAddEditDocumentActionProps => ({
    loadDocument(id: string): void {
        dispatch(fetchDocument(id));
    },
    resetDocument(): void {
        dispatch(setDocument(null));
        dispatch(documentReject());
    },
    raiseDocumentNotUploadedNotification(): void {
        dispatch(raiseSystemNotification(ExtendedStore.SystemNotificationType.DocumentContentNotUploaded));
    }
});

const AddEditDocumentConnected = connect(mapStateToProps, mapDispatchToProps)(WizzardWrapper(AddEditDocument));

export default AddEditDocumentConnected;
