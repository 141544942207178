/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 14.03.2018
 * -----
 * Last Modified: 10.05.2018 13:20:17
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { combineReducers } from 'redux';

import lastExecutedSearchQuery from 'nkc-frontend-tools/redux/reducers/search/lastExecutedSearchQuery';
import searchQuery from 'nkc-frontend-tools/redux/reducers/search/searchQuery';
import filters from 'nkc-frontend-tools/redux/reducers/search/filters';

import hints from './hints';
import results from './results';
import {getPagingReducer} from './paging';

import {ExtendedStore} from 'common/store';

export const getSearchReducer = (initialState: ExtendedStore.IDocumentSearch) =>
    combineReducers({
        searchQuery,
        lastExecutedSearchQuery,
        results,
        paging: getPagingReducer(initialState.paging),
        filters,
        hints,
        contextName: (state = initialState.contextName) => state,
    });
