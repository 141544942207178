/**
 * Created by lkarmelo on 19.08.2019.
 */

import {ExtendedStore} from 'common/store';
import {connect} from 'react-redux';

import Social from './Social';
import {ISocialStateProps} from './interfaces/ISocialProps';

const mapStateToProps = (state: ExtendedStore.IState): ISocialStateProps => ({
    userSocial: state.user && state.user.userSocial || [],
});

export default connect(mapStateToProps)(Social);
