/**
 * Created by Lkarmelo on 27.10.2017.
 */

import React from 'react';
import {Route} from 'react-router-dom';

const RouteWithLayout: React.SFC<any> = ({layout: Layout, component: Component, subRoutes, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            <Layout>
                {Component &&
                    <Component {...props}>
                        {subRoutes}
                    </Component>
                }
            </Layout>
        }
    />
);

export default RouteWithLayout;
