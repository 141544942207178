/**
 * Created by lkarmelo on 23.09.2019.
 */

export const openLinkNewTab = (href: string, download?: string) => {
    const linkEl = document.createElement('a');
    linkEl.target = '_blank';
    linkEl.download = download;
    linkEl.href = href;
    if (typeof MouseEvent === 'function') {
        linkEl.dispatchEvent(new MouseEvent('click'));
    } else {
        //для IE
        linkEl.click();
    }
};
