import {handleActions} from 'redux-actions';

import {setLastExecutedSearchQuery} from '../../actions/search/lastExecutedSearchQuery';

export default handleActions(
    {
        [setLastExecutedSearchQuery.toString()](state: string, {payload}: {payload: string; type: string}) {
            return payload;
        },
    },
    null //null используется вместо undefined, потому что redux не позволяет иметь undefined как начальное значение
);
