import React from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import IProps from './interfaces/ILinkIconProps';
import * as styles from './LinkIcon.scss';

const LinkIcon: React.FC<IProps> = ({ className, iconClassName, iconPosition, children, link }) => {
    return (
        <Link
            to={link}
            className={classNames(
                styles.linkIcon,
                className
            )}
        >
            {iconPosition === 'left' ?
                <><i className={classNames(iconClassName, styles.linkIconIcon, styles.linkIconIconLeft)}/>{children}</>
                :
                <>{children}<i className={classNames(iconClassName, styles.linkIconIcon, styles.linkIconIconRight)}/></>
            }
        </Link>
    );
};

LinkIcon.defaultProps = {
    iconClassName: 'icon-arrow-left',
    iconPosition: 'left'
};

export default LinkIcon;
