import React, { useMemo, PropsWithoutRef, RefAttributes, ForwardRefExoticComponent } from 'react';
import classNames from 'classnames';

import { IListProps } from './../../select/DropDownSelect/SelectBase/interfaces/ISelectProps';
import { IOption } from './interfaces/ISelectProps';

import * as styles from './MaterialSelectFormField.scss';

import { IOptionProps, IOptionGroupProps } from './interfaces/ISelectProps';

export interface IOptionRenderProps {
    optionRenderer: React.FC<IOptionProps>;
    optionGroupRenderer: React.FC<IOptionGroupProps>;
}

const OptionList =
    React.forwardRef<HTMLDivElement, IListProps & IOptionRenderProps>(
        (
            {
                isOpen, className,
                options: listOptions, active: listActive,
                onSelect, onDeselect, children,
                optionRenderer: Option, optionGroupRenderer: OptionGroup
            },
            ref
        ) => {

            const renderFunc = (itemOption: IOption, level: number = 0) => {
                const { id, label } = itemOption;
                const hasChildren = Array.isArray(itemOption.children);

                if (hasChildren) {
                    const subChildren = itemOption.children.map(childItemOption => renderFunc(childItemOption, level + 1));

                    return (
                        <OptionGroup
                            key={id}
                            value={itemOption}
                            title={label}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                            isActive={listActive.includes(id)}
                            level={level}
                        >
                            {subChildren}
                        </OptionGroup>
                    );
                }

                return (
                    <Option
                        key={id}
                        value={itemOption}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        isActive={listActive.includes(id)}
                        level={level}
                    >
                        {label}
                    </Option>
                );
            };

            const listChildren = useMemo(
                () => (listOptions || []).map((itemOption: IOption) => renderFunc(itemOption)),
                [listOptions, listActive]
            );

            return (
                <div
                    ref={ref}
                    className={classNames(
                        className,
                        { [styles.selectFormFieldDropDownListHidden]: !isOpen }
                    )}
                >
                    {children}
                    <ul>
                        {listChildren}
                    </ul>
                </div>
            );
        });

export default OptionList;
