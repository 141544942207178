/*
 *  Роли
 */
declare const enum PermissionRole {
    Guest = 'Guest',
    Active = 'Active',
    Editor = 'Editor',
    Admin = 'Admin'
}

export const defaultRoles: string[] = [PermissionRole.Guest].map(item => item.toString());

export default PermissionRole;
