import React from 'react';

import BreadCrumbs from 'nkc-frontend-tools/components/BreadCrumbs/BreadCrumbs';
import clientRoutes from 'common/clientRoutes';
import { ROOT_BREADCRUMB_NAME } from 'app/utils/constants';
import { useResetWindowScroll } from 'app/hooks/useResetWindowScroll';

import * as styles from './PrivacyPolicy.scss';

const breadCrumbsPaths = [
    {
        title: ROOT_BREADCRUMB_NAME,
        link: clientRoutes.main.getUrl()
    },
    {
        title: 'Политика конфиденциальности',
        link: clientRoutes.privacy.getUrl()
    }
];

const filePathPrefix = '/api/inline/public/policy/';
const files = {
    personalData: {
        path: `${filePathPrefix}personal_data_rffi.pdf`
    },
    personalDataProcessing: {
        path: `${filePathPrefix}personal_data_processing.pdf`
    },
    termsOfUse: {
        path: `${filePathPrefix}terms of_use.pdf`
    },
    cookiesPolicy: {
        path: `${filePathPrefix}cookies_policy.pdf`
    },
    privacyPolicy: {
        path: `${filePathPrefix}privacy_policy.pdf`
    }
};

const Privacy = () => {
    useResetWindowScroll([]);

    return (
        <div className={styles.privacyPolicyPage}>
            <div className={styles.privacyPolicyPageBreadcrumbs}>
                <BreadCrumbs
                    paths={breadCrumbsPaths}
                    delimiter={<i className={'icon-arrow-right'}/> as any}
                />
            </div>
            <header className={styles.privacyPolicyPageHeader}>
                <h1 className={styles.privacyPolicyPageTitle}>Политика конфиденциальности РФФИ</h1>
            </header>
            <ul className={`${styles.privacyPolicyPageFilesList}`}>
                <li>
                    <a
                        className={styles.privacyPolicyPageFile}
                        href={files.personalData.path}
                        target="_blank"
                        rel="nofollow"
                    >
                        <div className={styles.privacyPolicyPageIcon}>
                            <div className={styles.privacyPolicyPageIconImage}/>
                        </div>
                        <div className={styles.privacyPolicyPageFileName}>
                            Политика обработки персональных данных в РФФИ
                        </div>
                    </a>
                </li>
                <li>
                    <a
                        className={styles.privacyPolicyPageFile}
                        href={files.personalDataProcessing.path}
                        target="_blank"
                        rel="nofollow"
                    >
                        <div className={styles.privacyPolicyPageIcon}>
                            <div className={styles.privacyPolicyPageIconImage}/>
                        </div>
                        <div className={styles.privacyPolicyPageFileName}>
                            Положение по обработке персональных данных в РФФИ
                        </div>
                    </a>
                </li>
                <li>
                    <a
                        className={styles.privacyPolicyPageFile}
                        href={files.termsOfUse.path}
                        target="_blank"
                        rel="nofollow"
                    >
                        <div className={styles.privacyPolicyPageIcon}>
                            <div className={styles.privacyPolicyPageIconImage}/>
                        </div>
                        <div className={styles.privacyPolicyPageFileName}>
                            Условия использования ресурсов сайта РФФИ
                        </div>
                    </a>
                </li>
                <li>
                    <a
                        className={styles.privacyPolicyPageFile}
                        href={files.cookiesPolicy.path}
                        target="_blank"
                        rel="nofollow"
                    >
                        <div className={styles.privacyPolicyPageIcon}>
                            <div className={styles.privacyPolicyPageIconImage}/>
                        </div>
                        <div className={styles.privacyPolicyPageFileName}>
                            Политика использования cookie-файлов в РФФИ
                        </div>
                    </a>
                </li>
                <li>
                    <a
                        className={styles.privacyPolicyPageFile}
                        href={files.privacyPolicy.path}
                        target="_blank"
                        rel="nofollow"
                    >
                        <div className={styles.privacyPolicyPageIcon}>
                            <div className={styles.privacyPolicyPageIconImage}/>
                        </div>
                        <div className={styles.privacyPolicyPageFileName}>
                            Политика конфиденциальности обработки персональных данных граждан ЕС в РФФИ
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Privacy;
