/**
 * Created by lkarmelo on 03.12.2018.
 */
/**
 * Возвращает координаты элементы относительно document
 * @param el
 */
export const getElementOffset = (el: HTMLElement): {top: number; left: number; } => {
    const rect = el.getBoundingClientRect();

    return {
        top: rect.top + el.ownerDocument.defaultView.pageYOffset,
        left: rect.left + el.ownerDocument.defaultView.pageXOffset
    };
};
