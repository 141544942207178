/**
 * Created by lkarmelo on 14.06.2019.
 */

import {MiddlewareAPI} from 'redux';
import {ExtendedStore} from 'common/store';
import {BookSearchContext} from 'common/store/ExtendedStore';

export const getStateByContext = (store: MiddlewareAPI<ExtendedStore.IState>, context: string): ExtendedStore.IDocumentSearch =>
    store.getState()[context];
