import React from 'react';

import { Link } from 'react-router-dom';
import {Field, Form} from 'react-final-form';
import {InputFieldMode, TextFormField, PasswordFormField, CheckboxFormField} from 'app/components/common/form-fields';

import clientRoutes from 'common/clientRoutes';

import {
    getPasswordsMatchValidator, requiredStringValidation, requiredBooleanValidation, emailValidation, oneOfValidation
} from 'app/utils/forms/validation';

import IProps from './interfaces/IRegistrationFormProps';

import * as styles from './RegistrationForm.scss';

const passwordsMatchValidation = getPasswordsMatchValidator('password');

const RegistrationForm: React.FC<IProps> = ({onSubmit}) => {
    return (
        <Form
            onSubmit={onSubmit}
        >
            {({handleSubmit, submitErrors}) => {
                return (
                    <form
                        onSubmit={handleSubmit}
                        className={styles.registrationForm}
                    >
                        <Field name={'email'} validate={emailValidation}>
                            {({input, meta}) => (
                                <TextFormField
                                    {...input}
                                    label={'Email'}
                                    autoComplete={'email'}
                                    mode={InputFieldMode.REGULAR}
                                    error={meta.touched ? meta.error : undefined}
                                />
                            )}
                        </Field>
                        <Field name={'password'} validate={requiredStringValidation}>
                            {({input, meta}) => (
                                <PasswordFormField
                                    {...input}
                                    label={'Пароль'}
                                    mode={InputFieldMode.REGULAR}
                                    autoComplete={'new-password'}
                                    error={meta.touched ? meta.error : undefined}
                                />
                            )}
                        </Field>
                        <Field name={'confirmation'} validate={oneOfValidation(requiredStringValidation, passwordsMatchValidation)}>
                            {({input, meta}) => (
                                <PasswordFormField
                                    {...input}
                                    label={'Подтверждение пароля'}
                                    mode={InputFieldMode.REGULAR}
                                    autoComplete={'new-password'}
                                    error={meta.touched ? meta.error : undefined}
                                />
                            )}
                        </Field>
                        <Field name={'accept'} validate={requiredBooleanValidation} type={'checkbox'}>
                            {({input, meta}) => (
                                <CheckboxFormField
                                    {...input}
                                    error={meta.touched ? meta.error : undefined}
                                >
                                    Я принимаю условия&#32;
                                    <Link to={clientRoutes.terms.getUrl()} target="_blank">
                                        Пользовательского соглашения
                                    </Link>
                                </CheckboxFormField>
                            )}
                        </Field>
                        <button
                            className={'btn-primary'}
                            type={'submit'}
                        >
                            СОЗДАТЬ АККАУНТ
                        </button>
                        {submitErrors &&
                            <div className={'form-field-error'}>
                                {submitErrors}
                            </div>
                        }
                    </form>
                );
            }}
        </Form>
    );
};

export default RegistrationForm;
