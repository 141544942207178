/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {createAction} from 'redux-actions';

import {Store} from '../../../types';
import {Api} from '../../../api';

export const EXECUTE_SEARCH = 'EXECUTE_SEARCH';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const UPDATE_SEARCH_RESULT = 'UPDATE_SEARCH_RESULT';

export const executeSearch = createAction<boolean, boolean | void>(EXECUTE_SEARCH, (newSearch: boolean | void) => newSearch !== false );
export const fetchSearchResults = createAction('FETCH_SEARCH_RESULTS');

export type SetSearchResultsPayload = Api.IPersonSearchResponseBody |
    Api.IDocumentSearchResponseBody |
    {list?: Array<Store.ISearchSnippetDocument | Store.IPerson>};
export const setSearchResults = createAction<SetSearchResultsPayload>(SET_SEARCH_RESULTS);

export const updateSearchResult = createAction<Store.IUpdateDocumentSnippet | Store.IPersonResults | {}>(UPDATE_SEARCH_RESULT);
