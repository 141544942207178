/**
 * Created by Lkarmelo on 08.11.2017.
 */

import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import LoginPanel from '../LoginPanel/LoginPanel';

import { logIn } from 'nkc-frontend-tools/redux/actions/authorization';
import { ExtendedStore } from 'common/store';
import ILoginProps, { ILoginOwnProps, ILoginActionProps } from '../LoginPanel/interfaces/ILoginProps';

import * as styles from './Login.scss';

const Login: React.FC<ILoginProps> = (props: ILoginProps) => {
    const {authorizationFailed} = props;

    return (
            <div className={styles.loginContainerWrapper}>
                <div className={styles.loginContainer}>
                    <div className={classNames(styles.login, {[styles.loginFailed]: authorizationFailed === true})}>
                        <LoginPanel {...props}/>
                    </div>
                </div>
            </div>
        );
};

const loginPanelMapStateToProps = (state: ExtendedStore.IState): ILoginOwnProps => ({
    authorizationFailed: state.authorization.authorizationFailed,
    alreadyAuthorized: state.authorization.isAuthorized
});

export const loginPanelMapDispatchToProps = (dispatch): ILoginActionProps => ({
    onLogIn(login: string, password: string): void {
        dispatch(logIn(login, password));
    },
});

export default connect(loginPanelMapStateToProps, loginPanelMapDispatchToProps)(Login);
