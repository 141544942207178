import React from 'react';
import {useCallback} from 'react';
import Popover from 'antd/es/popover';

import * as styles from './UserPanel.scss';

import { TooltipTrigger } from 'antd/es/tooltip';

import {clsNames as headerClsNames} from '../Header';
import IPopoverPanelProps, { PanelShowTrigger } from './interfaces/IPopoverPanelProps';

const trigger = (showTrigger: PanelShowTrigger): TooltipTrigger => {
    switch (showTrigger) {
        case (PanelShowTrigger.OnClick):
            return 'click';
        case (PanelShowTrigger.OnHover):
            return 'hover';
        default:
            return 'contextMenu';
    }
};

const PopoverPanel: React.FC<IPopoverPanelProps & React.Props<any>> = (props: IPopoverPanelProps & React.Props<any>) => {
    const [isAccountPopoverVisible, changePopoverVisibility] = React.useState(false);
    const {showTrigger, content, onShowPopup, onClosePopup, children} = props;

    const onClose = useCallback(
        () => {
            changePopoverVisibility(false);
            !!onClosePopup && onClosePopup();
        },
        [onClosePopup]
    );

    const onVisibleChange = useCallback(
        (visible: boolean) => {
            changePopoverVisibility(visible);

            ( visible )
                ? !!onShowPopup && onShowPopup()
                : !!onClosePopup && onClosePopup();
        },
        [onShowPopup, onClosePopup]
    );

    return (
        <Popover
            trigger={trigger(showTrigger)}
            placement="bottomRight"
            arrowPointAtCenter
            overlayClassName={styles.headerOverlay}
            content={content(onClose)}
            visible={isAccountPopoverVisible}
            onVisibleChange={onVisibleChange}
            getPopupContainer={() => document.querySelector(`.${headerClsNames.headerTooltipContainer}`)}
        >
            <div>
                {children}
            </div>
        </Popover>
    );
};

PopoverPanel.defaultProps = {
    showTrigger: PanelShowTrigger.OnHover
};

export default PopoverPanel;
