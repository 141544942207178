import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { IOptionProps } from '../interfaces/ISelectProps';

import * as styles from '../MaterialSelectFormField.scss';

const Option: React.FC<IOptionProps> = (props) => {
    const {
        value,
        children,
        isActive,
        onSelect,
        onDeselect,
        isDisabled,
        level
    } = props;

    const onClick = useCallback(
        () => {
            !isActive ? onSelect(value) : onDeselect(value);
        },
        [isActive, onSelect, onDeselect]
    );

    return (
        <div
            className={classNames(
                `select__form-field-col${level}`,
                styles.selectFormFieldDropDownOption,
                {[styles.selectFormFieldDropDownOptionSelected]: isActive}
            )}
        >
            <i className={styles.selectFormFieldDropDownOptionIcon}/>
            <div
                className={styles.selectFormFieldDropDownOptionText}
                onClick={onClick}
            >
                {children}
            </div>
        </div>
    );
};

Option.defaultProps = {
    level: 0
};

export default Option;
