/**
 * Created by lkarmelo on 20.06.2019.
 */
import React from 'react';
import {useRef} from 'react';

/**
 * Возвращает ref с обновлённым ref.current. Полезно, если нужно в других хуках читать новое значение, но нельзя его включать в deps
 */
export const useUpdateableRef = <T>(value: T): React.MutableRefObject<T> => {
    const ref = useRef(value);
    ref.current = value;

    return ref;
};
