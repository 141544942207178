import React from 'react';
import { FieldProps, Field, FieldRenderProps } from 'react-final-form';

import { InputFieldMode } from './BaseInput/interfaces/IBaseInputFormFieldProps';
import { TextFormField } from '.';
import SelectFormField from './Select/SelectFormField';
import { IOption, ViewMode } from './Select/interfaces/ISelectProps';
import TextAreaFormField from './TextArea';

interface IFieldConfig<FieldValue, T extends HTMLElement> extends FieldProps<FieldValue, FieldRenderProps<FieldValue, T>> {
    label?: string;
    required?: boolean;
}

type FieldDescription<FieldValue, T extends HTMLElement, E = {}> = React.FC<IFieldConfig<FieldValue, T> & E>;

export const TextField: FieldDescription<string, HTMLDivElement> = ({
    className,
    label,
    ...restProps
}) =>
    (
        <Field {...restProps}>
            {({ input, meta }) => (
                <div className={className}>
                    <TextFormField
                        {...input}
                        mode={InputFieldMode.MATERIAL}
                        label={label}
                        error={meta.touched ? meta.error : undefined}
                        required={restProps.required ?? false}
                    />
                </div>
            )}
        </Field>
    );

export const TextAreaField: FieldDescription<string, HTMLTextAreaElement> = ({
    className,
    placeholder,
    ...restProps
}) =>
    (
        <Field {...restProps}>
            {({ input, meta }) => (
                <div className={className}>
                    <TextAreaFormField
                        {...input}
                        placeholder={placeholder}
                        error={meta.touched ? meta.error : undefined}
                        required={restProps.required ?? false}
                    />
                </div>
            )}
        </Field>
    );

export const MultiselectField: FieldDescription<string[], HTMLDivElement, { options: IOption[] }> = ({
    className,
    label,
    disabled,
    options,
    onSelect,
    ...restProps
}) =>
    (
        <Field {...restProps}>
            {({ input, meta }) =>
                (
                    <div className={className}>
                        <SelectFormField
                            {...input}
                            className=""
                            error={meta.touched ? meta.error : undefined}
                            title={label}
                            disabled={disabled}
                            active={input.value}
                            options={options}
                            onSelect={(selected: string[]) => input.onChange(selected)}
                            isMultiSelect={true}
                            required={restProps.required ?? false}
                        />
                    </div>
                )
            }
        </Field>
    );

export const SelectField: FieldDescription<string, HTMLDivElement, { options: IOption[]; }> = ({
    className,
    label,
    disabled,
    options,
    onSelect,
    ...restProps
}) =>
    (
        <Field {...restProps}>
            {({ input, meta }) => (
                <div className={className}>
                    <SelectFormField
                        {...input}
                        className=""
                        error={meta.touched ? meta.error : undefined}
                        title={label}
                        disabled={disabled}
                        active={input.value}
                        options={options}
                        mode={ViewMode.LABEL}
                        onSelect={(selected: string) => input.onChange(selected)}
                        isMultiSelect={false}
                        required={restProps.required ?? false}
                    />
                </div>
            )}
        </Field>
    );
