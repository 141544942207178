/**
 * Created by lkarmelo on 06.09.2019.
 */

import React from 'react';
import {IFiltersProps, FiltersComponent} from 'app/components/common/ContextBookList';
import {StaticFilterName} from 'common/types/FilterName';

const hiddenFilters = [StaticFilterName.Sort];

const FavoriteFilters: React.FC<IFiltersProps> = (props) => <FiltersComponent {...props} hiddenFilters={hiddenFilters} />;

export default FavoriteFilters;
