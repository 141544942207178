/**
 * Created by lkarmelo on 31.01.2019.
 */

const unHighlightQuery = (query: string, highlightPreTag: string, highlightPostTag: string): String => {
    // escape regexp special characters in search string
    const negSearchRegExp = new RegExp(`(?:${highlightPreTag}.*?)(.*?)(?:${highlightPostTag})`, 'gi');

    return query.replace( negSearchRegExp, '$1');
};

export const unHighlightHints = (query: string): String =>
    unHighlightQuery(query, '<span class="highlight-suggestions">', '</span>');
