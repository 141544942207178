/**
 * Created by lkarmelo on 19.03.2019.
 */

export const actionWithContext = (action, context) => {
    if (!action || context === undefined || context === null) {
        return action;
    }
    const nextAction = {...action};
    nextAction.context = context;
    return nextAction;
};
