/**
 * Created by Lkarmelo on 23.08.2017
 * -----
 * Last Modified: 07.02.2018 18:42:03
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoadingBar from 'react-redux-loading-bar';
import SystemNotifications from 'app/components/SystemNotifications';

import Routes from 'app/routing/Routes';

import {fetchFiltersMeta} from 'nkc-frontend-tools/redux/actions/search/filtersMeta';
import {fetchUserData} from 'nkc-frontend-tools/redux/actions/user';

import {fetchLibraryStats} from 'app/redux/actions/libraryStats';
import {fetchIssueInfoCatalog} from 'app/redux/actions/issueInfoCatalog';

import {pollNotifications, stopPollingNotifications} from 'app/redux/actions/notifications';

import * as ExtendedStore from 'common/store/ExtendedStore';
import IProps, {IRootStateProps} from './interfaces/IRootProps';

import './Root.scss';

class Root extends Component<IProps> {
    render(): JSX.Element {
        return (
            <div className={'root'}>
                <SystemNotifications />
                <LoadingBar
                    className={'loading-bar'}
                    progressIncrease={5}
                />
                <div className={'root__router'}>
                    <div className={'root__app-content'}>
                        <div className={'root__route'}>
                            {Routes}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(): void {
        const {dispatch, filtersMeta} = this.props;

        // load user data
        dispatch(fetchUserData());

        if (!filtersMeta || Object.keys(filtersMeta).length === 0) {
            dispatch(fetchFiltersMeta());
        }
        dispatch(fetchIssueInfoCatalog());
        dispatch(fetchLibraryStats());

        dispatch(pollNotifications());
    }

    componentWillUnmount(): void {
        const {dispatch} = this.props;

        dispatch(stopPollingNotifications());
    }
}

const mapStateToProps = (state: ExtendedStore.IState): IRootStateProps => ({
    filtersMeta: state.filtersMeta
});

export default withRouter(connect(mapStateToProps)(Root));
