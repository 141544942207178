import React from 'react';
import classNames from 'classnames';

import IProps from './interfaces/ITableProps';
import styles from './Table.scss';

const Table: React.FC<IProps<object>> = <Data extends object>(props: IProps<Data>) => {
    const {
        className,
        headClassName,
        bodyClassName,
        columnClassNames,
        columnCellsClassNames,
        tableInstance
    } = props;

    const { headerGroups, prepareRow } = tableInstance;
    const rows = tableInstance.page || tableInstance.rows;

    return (
        <table className={classNames(className, styles.table)}>
            <thead className={classNames(headClassName, styles.tableHead)}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className={classNames(
                                    styles.tableHeadCell,
                                    columnClassNames[column.id]
                                )}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className={bodyClassName}>
                {rows.map(row => {
                    prepareRow(row);
                    const rowProps = row.getRowProps() as any;

                    return (
                        <tr key={rowProps.key} {...rowProps}>
                            {row.cells.map(cell => (
                                <td
                                    {...cell.getCellProps()}
                                    className={classNames(
                                        styles.tableBodyCell,
                                        columnCellsClassNames[cell.column.id]
                                    )}
                                >
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

Table.defaultProps = {
    columnClassNames: {},
    columnCellsClassNames: {}
};

export default React.memo(Table);
