/**
 * Created by lkarmelo on 31.07.2019.
 */

import {createAction} from 'redux-actions';

import {ExtendedStore} from 'common/store';
import { ExtendedApi } from 'common/api';

export const setProfile = createAction<ExtendedStore.IProfile>('SET_PROFILE');

export const updateProfile = createAction<ExtendedApi.IProfileUpdateBody>('UPDATE_PROFILE');

export const setEmail = createAction<string>('SET_EMAIL');
