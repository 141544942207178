//важно импортить раньше всего, чтобы изменения в LoadingActionProvider отразились в эпиках из nkc-frontend-tools
import 'app/redux/actions/loading';

import 'nkc-frontend-tools/utils/rxjsAdd';

import 'stylessheets/antd.scss';
import 'stylessheets/common.scss';

import moment from 'moment';

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app/components/App/App';
//import registerServiceWorker from './registerServiceWorker';

moment.locale('ru');

ReactDOM.hydrate(<App />, document.getElementById('root'));
//registerServiceWorker();
