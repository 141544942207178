/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {MiddlewareAPI} from 'redux';
import queryString from 'qs';
import { Action } from 'redux-actions';
import {ActionsObservable} from 'redux-observable';

import {Observable} from 'rxjs/Observable';
import {concat} from 'rxjs/observable/concat';
import {merge} from 'rxjs/observable/merge';
import {forkJoin} from 'rxjs/observable/forkJoin';

import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';
import {Api} from 'nkc-frontend-tools/api';

import {setLastExecutedSearchQuery} from 'nkc-frontend-tools/redux/actions/search/lastExecutedSearchQuery';
import {
    setSearchResults,
    executeSearch, EXECUTE_SEARCH
} from 'nkc-frontend-tools/redux/actions/search/results';

import {setSkip, setLimit, blockPaging, unblockPaging, resetPaging} from 'nkc-frontend-tools/redux/actions/search/searchPaging';
import {searchRequest, searchReject, searchResolve} from 'app/redux/actions/loading';
import {
    addToSearchResults,
    executeAdditionalSearchRequest,
    fetchMoreSearchResults,
    IFetchSearchResultsPayload,
    fetchSearchResults,
    updateLocationFromFilters,
    IUpdateLocationFromFiltersPayload
} from 'app/redux/actions/search/results';
import {SET_DEFAULT_FILTERS} from 'nkc-frontend-tools/redux/actions/search/defaultFilters';
import {setQuery, selectTag, removeTag} from 'nkc-frontend-tools/redux/actions/search/searchQuery';

import {
    addMultiSelectFilterValue,
    toggleMultiSelectFilterValue,
    setFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilters,
    removeMultiSelectFilterValue,
    setFiltersByNames
} from 'nkc-frontend-tools/redux/actions/search/filters';

import history from 'app/history';
import {parseOptions, stringifyOptions} from 'app/utils/queryStringOptions';
import {DOCUMENTS_COUNT_PARAM_NAME, MAX_ITEMS_SINGLE_SEARCH, SEARCH_QUERY_PARAM_NAME} from 'app/utils/constants';
import {compareFilterValues} from 'app/utils/filters/compareFilterValues';
import {getDefaultFilterValue} from 'app/utils/filters/getDefaultFilterValue';
import {getStateByContext} from 'app/utils/getStateByContext';

import {replaceDashesWithDots} from 'nkc-frontend-tools/utils/filterNamesEscape';
import {actionWithContext} from 'app/redux/context/actionWithContext';

import {ContextAction} from 'app/types/ContextAction';
import {StaticFilterName} from 'common/types/FilterName';

import clientRoutes from 'common/clientRoutes';

const fetchSearchAndChangePage = [
    fetchSearchResults.toString(),
    fetchMoreSearchResults.toString(),
];

const filtersChangeActions = [
    setFiltersByNames.toString(),
    addMultiSelectFilterValue.toString(),
    toggleMultiSelectFilterValue.toString(),
    removeMultiSelectFilterValue.toString(),
    setFilterValue.toString(),
    setDateRangeFrom.toString(),
    setDateRangeTo.toString(),
    setFilters.toString(),
    selectTag.toString(),
    removeTag.toString(),
];

export const loadSearchResults = (action$, store: MiddlewareAPI<ExtendedStore.IState>) => {
    let isNewSearch = true;

    const ep = action$.ofType(...fetchSearchAndChangePage, ...filtersChangeActions)
        .do(({type}) => {
            //если после fetchSearchResults() был экшен, меняющий фильтры, может считаться, что это не новый запрос, если не присваивать
            //в этом месте true, потому что debounceTime ниже проглатывает все экшены, кроме последнего
            if (type === fetchSearchResults.toString()) {
                isNewSearch = true;
            }
        })
        .ignoreElements();

    //объединяем все экшены, которые начинают поиск, в один эпик и даём им общий debounceTime,
    //чтобы одновременная смена фильтра и вызов fetchSearchResults() не начинали 2 поисковых запроса
    const epic: ActionsObservable<Action<any>> = action$
        .ofType(...fetchSearchAndChangePage, ...filtersChangeActions)
        .ignoreSearchActionsByMeta()
        .debounceTime(300);
    /*
        разделяем на 2 Observable'а:
        1. ручной запуск поиска и смена страниц
        2. смена значений фильтров
    */
    const [manualLoadSearchResults, filtersChangeLoadSearchResults] = epic.partition(a => [...fetchSearchAndChangePage].includes(a.type));

    const manualLoadSearchResultsResObs = manualLoadSearchResults
        .switchMap(action => {
            if (Object.keys(store.getState().defaultFilters).length > 0) {
                return Observable.of(action);
            }
            return action$
                .ofType(SET_DEFAULT_FILTERS)
                .mapTo(action)
                .take(1);
        })
        .mergeMap((action: ContextAction<IFetchSearchResultsPayload | number>) => {
            const {context, payload, type} = action;

            return concat(
                isNewSearch ? Observable.of(actionWithContext(resetPaging(), context)) : Observable.empty(),
                Observable.of(actionWithContext(
                    updateLocationFromFilters(
                        isNewSearch,
                        typeof payload === 'object' ? payload.clearUrlBeforeRedirect : false,
                        typeof payload === 'object' ? payload.redirectPathname : undefined,
                        type === fetchMoreSearchResults.toString(),
                    ),
                    context
                )),
                Observable.of(isNewSearch ?
                    actionWithContext(executeSearch(isNewSearch), context) :
                    actionWithContext(executeAdditionalSearchRequest(), context)
                )
            );
        });

    const filtersChangeLoadSearchResultsResObs = filtersChangeLoadSearchResults
        .debounceTime(300)
        .mergeMap(({context}: ContextAction) =>
            Observable.of(
                actionWithContext(resetPaging(), context),
                actionWithContext(updateLocationFromFilters(isNewSearch), context),
                actionWithContext(executeSearch(isNewSearch), context)
            )
        );

    return merge(
        manualLoadSearchResultsResObs,
        filtersChangeLoadSearchResultsResObs,
        ep
    )
    .do(() => {
        isNewSearch = false;
    });
};

export const resetSearchQueryToLastExecutedQuery = (action$, store: MiddlewareAPI<ExtendedStore.IState>) =>
    action$.ofType(...filtersChangeActions, setSkip.toString(), setLimit.toString(), fetchMoreSearchResults.toString())
        .ignoreSearchActionsByMeta()
        .filter((action: ContextAction) => getStateByContext(store, action.context).lastExecutedSearchQuery !== null)
        .switchMap((action: ContextAction) =>
            //ждём все debounce'ы и прочее, меняем только когда начнётся загрузка запроса
            action$.ofType(searchRequest.toString())
                .filter(reqAction => reqAction.context === action.context)
                .mapTo(action)
                .take(1)
        )
        .map((action: ContextAction) => setQuery(getStateByContext(store, action.context).lastExecutedSearchQuery));

export const updateBrowserLocation = (action$, store: MiddlewareAPI<ExtendedStore.IState>) =>
    action$.ofType(updateLocationFromFilters.toString())
        .switchMap(action => {
            if (Object.keys(store.getState().defaultFilters).length > 0) {
                return Observable.of(action);
            }
            return action$
                .ofType(SET_DEFAULT_FILTERS)
                .mapTo(action)
                .take(1);
        })
        .do(({payload, context}: ContextAction<IUpdateLocationFromFiltersPayload>) => {
            const {isNewSearch, redirectPathname, clearUrlBeforeRedirect, replace} = payload;

            const state: ExtendedStore.IDocumentSearch = getStateByContext(store, context);
            const filtersMeta = store.getState().filtersMeta;

            const currentUrl = `${history.location.pathname}${history.location.search}`;
            const parsedLocationSearch = queryString.parse(history.location.search, parseOptions);
            const nextLocationSearchObject = clearUrlBeforeRedirect ? {} : parsedLocationSearch;

            Object.entries(state.filters).forEach(([filterName, filter]) => {
                const meta = filtersMeta[filterName];
                const defaultFilterValue = getDefaultFilterValue(state, {name: filterName, ...meta});
                const isValueEqualToDefault = compareFilterValues(filter.value, defaultFilterValue, meta.type);
                //если у фильтра выбрано дефолтное значение, нет смысла писать его в адресную строку, потому что из-за этого
                //могут быть бессмысленные редиректы /search => /search?filter=value
                const nextFilterValue = isValueEqualToDefault ? undefined : filter.value;
                /*
                    если модуль qs в метод stringify передать объект с пустой строкой в поле, то он вернёт строку вроде
                    ?test= хотя с пустыми массивами и объектами он не вернёт ничего, поэтому нужно убрать пустые строки
                 */
                nextLocationSearchObject[filterName] = nextFilterValue === '' ? undefined : nextFilterValue;
            });

            nextLocationSearchObject[SEARCH_QUERY_PARAM_NAME] = isNewSearch ? state.searchQuery : state.lastExecutedSearchQuery;

            const nextSearchQueryNoCount = queryString.stringify(nextLocationSearchObject, {...stringifyOptions, skipNulls: true});

            nextLocationSearchObject[DOCUMENTS_COUNT_PARAM_NAME] = state.paging.limit + state.paging.skip;

            const nextSearchQuery = queryString.stringify(nextLocationSearchObject, {...stringifyOptions, skipNulls: true});
            const nextPathName = redirectPathname === undefined ? history.location.pathname : redirectPathname;
            const nextUrl = `${nextPathName}${nextSearchQuery}`;

            if (nextUrl !== currentUrl) {
                //делаем replace, если происходит редирект с /search, чтобы избежать редиректа каждый раз
                //при нажатии кнопки "назад" в браузере
                const isRedirectFromSearchUrl = currentUrl === clientRoutes.search.getUrl();
                //если просто добавляется count, то тоже делаем replace
                const isRedirectForCountOnly = history.location.search === nextSearchQueryNoCount;

                if (replace || isRedirectFromSearchUrl || isRedirectForCountOnly) {
                    history.replace(nextUrl);
                } else {
                    history.push(nextUrl);
                }
            }
        })
        .ignoreElements();

const getFilterValue = (value) => {
    return Array.isArray(value) ?
        {
            type: 'SelectFilterValue',
            values: value.map(getFilterValue)
        } :
        {
            type: 'StringFilterValue',
            value
        };
};

const getSearchRequestBody = (state: ExtendedStore.IDocumentSearch, isNewSearch: boolean): any => {
    const sortFilterName = StaticFilterName.Sort;
    return {
        limit: state.paging.limit,
        skip: state.paging.skip,
        userFilters: Object.entries(state.filters)
            .filter(([ filterName, { value } ]) => {
                //удаляем фильтр сортировки
                if (filterName === sortFilterName) {
                    return false;
                }
                if (Array.isArray(value)) {
                    return value.length > 0;
                } else if (typeof value === 'string') {
                    return value.length > 0;
                }
                return value !== undefined && value !== null;
            })
            .map(([filterName, {value}]) => ({
                type: 'BaseFilter',
                code: replaceDashesWithDots(filterName),
                value: getFilterValue(value)
            })),
        //сортировка по релевантности = нет сортировки, поэтому undefined вместо объекта с сортировкой
        sortBy: state.filters[sortFilterName] &&
            state.filters[sortFilterName].value &&
            state.filters[sortFilterName].value !== ExtendedStore.SortFilterValues.Relevancy ?
                {[<string>state.filters.sort.value]: 'DESC' } : undefined,
        query: (isNewSearch ? state.searchQuery : state.lastExecutedSearchQuery) || '',
    };
};

const getApiCallByContext = (context: string, apiCalls: ExtendedApi.ApiCalls) => {
    switch (context) {
        case ExtendedStore.BookSearchContext.Favorite: return apiCalls.loadFavorite;
        case ExtendedStore.BookSearchContext.UploadedList: return apiCalls.searchUploads;

        default: return apiCalls.search;
    }
};

export const executeSearchRequest = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
        action$.ofType(EXECUTE_SEARCH, executeAdditionalSearchRequest.toString())
            .mergeMap(({payload: isNewSearch, type, context}: ContextAction<boolean>) => {
                const isAdditionalSearchAction = type === executeAdditionalSearchRequest.toString();

                const state = getStateByContext(store, context);

                const searchPayload = isAdditionalSearchAction ?
                    getSearchRequestBody(state, false) :
                    getSearchRequestBody(state, isNewSearch);

                //Разбиваем реквест на несколько, если загрузить нужно сразу больше MAX_ITEMS_SINGLE_SEARCH документов, потому что сервер
                //больше не отдаст за 1 раз
                let requestCount = Math.floor(searchPayload.limit / (MAX_ITEMS_SINGLE_SEARCH + 1)) + 1;
                const payloads = Array.from(Array(requestCount)).map((_, i) => ({
                    ...searchPayload,
                    skip: searchPayload.skip + MAX_ITEMS_SINGLE_SEARCH * i,
                    limit: i === requestCount - 1 ?
                        searchPayload.limit - (MAX_ITEMS_SINGLE_SEARCH * (requestCount - 1)) :
                        MAX_ITEMS_SINGLE_SEARCH,
                }));

                return concat(
                    isNewSearch ?
                        Observable.of(actionWithContext(setLastExecutedSearchQuery(state.searchQuery), context)) :
                        Observable.empty(),
                    Observable.of(actionWithContext(searchRequest(), context)),
                    Observable.of(actionWithContext(unblockPaging(), context)),
                    forkJoin(
                        ...payloads.map(r => getApiCallByContext(context, apiCall)(r))
                    )
                        .mergeMap((responses: {response: Api.IDocumentSearchResponseBody}[]) => {
                            const er = responses.find(r => r instanceof Error);
                            if (er) {
                                return Observable.throw(er);
                            }
                            const mergedResponses = responses[0].response;
                            for (let i = 1; i < responses.length; i++) {
                                const currentResponse = responses[i].response;
                                mergedResponses.list.push(...currentResponse.list);
                            }

                            return Observable.of(isAdditionalSearchAction ?
                                actionWithContext(addToSearchResults(mergedResponses), context) :
                                actionWithContext(setSearchResults(mergedResponses), context)
                            );
                        })
                        .catch(e => {
                            console.error(e);
                            return Observable.of(actionWithContext(searchReject(e), context));
                        })
                        .takeUntil(
                            action$.ofType(...fetchSearchAndChangePage, ...filtersChangeActions, EXECUTE_SEARCH)
                                .ignoreSearchActionsByMeta()
                                .filter(action => action.context === context)
                        ),
                    Observable.of(searchResolve())
                )
                    .takeUntil(action$.ofType(searchReject.toString()).filter(action => action.context === context));
            });
