export type PropTypeValidator = (props: object, propName: string, componentName: string) => Error | undefined;

export const combineValidators = (...validators: PropTypeValidator[]) => {
    return (props: object, propName: string, componentName: string) => {
        for (const validator of validators) {
            const err = validator(props, propName, componentName);
            if (err instanceof Error) {
                return err;
            }
        }
    };
};

export const positiveNumberValidator = (props: object, propName: string, componentName: string) => {
    if (props[propName] < 0) {
        return new Error(`Prop "${propName}" of component "${componentName}" must be not negative`);
    }
};

export const integerValidator = (props: object, propName: string, componentName: string) => {
    const num = props[propName];
    if (Math.floor(num) !== num) {
        return new Error(`Prop "${propName}" of component "${componentName}" must be integer`);
    }
};
