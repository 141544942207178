/**
 * Created by lkarmelo on 11.04.2019.
 */

import React from 'react';

import BookSnippetBase from '../Base/BookSnippetBase';

import IProps from '../interfaces/IBookSnippetProps';

const renderCategories = () => null;

const BookSnippetDigest: React.FunctionComponent<IProps> = (props) => (
    <BookSnippetBase renderCategories={renderCategories} {...props} />
);

export default BookSnippetDigest;
