/**
 * Created by lkarmelo on 27.02.2019.
 */

import React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import queryString from 'qs';
import classNames from 'classnames';

import Sticky from 'react-stickynode';
import BookSnippet from 'app/components/common/BookSnippet';
import Preview from 'app/components/pages/search/Preview';
import PageHeader from 'app/components/common/PageHeader';

import {useCookies} from 'react-cookie';
import {useEffectOnUpdate} from 'app/hooks/useEffectOnUpdate';
import {useEventListener} from 'app/hooks/useEventListener';
import {useResetWindowScroll} from 'app/hooks/useResetWindowScroll';
import {usePrevious} from 'app/hooks/usePrevious';
import {useUpdateableRef} from 'app/hooks/useUpdetableRef';

import IProps from './interfaces/IBookListProps';
import * as Cookies from 'common/cookies/Cookies';

import {parseOptions, stringifyOptions} from 'app/utils/queryStringOptions';
import {
    DOCUMENTS_COUNT_PARAM_NAME,
    HEADER_HEIGHT_XL,
    PREVIEW_DOCUMENT_PARAM_NAME,
    SEARCH_QUERY_PARAM_NAME,
    VIEWPORT_L
} from 'app/utils/constants';
import {SEARCH_VIEW_MODE_COOKIE_NAME} from 'common/constants';

import * as styles from './BookList.scss';

const BookLoadLimitIncrease = new Map<Cookies.ViewModeCookie, number>([
    ['tiles', 36],
    ['preview', 12]
]);

const BookList: React.FunctionComponent<IProps> = (props) => {
    const {
        resultDocuments, onUnMount, location, setQuery, query, fetchSearchResults,
        totalFoundResults, loadMoreBooks, onChangeViewMode,
        filtersPanel: Filters, renderHeaderContent, setLimit,
        history
    } = props;
    const viewedCount = resultDocuments ? resultDocuments.length : 0;
    const prevResultDocuments = usePrevious(resultDocuments);
    const [cookies, setCookie] = useCookies([SEARCH_VIEW_MODE_COOKIE_NAME]);
    const [isViewChangerShown, setIsViewChangerShown] = useState<boolean>(true);
    const stickyRef = useRef(null);

    const viewMode: Cookies.ViewModeCookie = cookies && cookies.viewMode || 'tiles';
    const setViewMode = useCallback(
        (mode: Cookies.ViewModeCookie) => {
            setCookie(SEARCH_VIEW_MODE_COOKIE_NAME, mode);
        },
        []
    );

    const parsedQuery = useMemo(
        () => {
            return queryString.parse(location.search, parseOptions);
        },
        [location.search]
    );
    const queryFromLocation = parsedQuery[SEARCH_QUERY_PARAM_NAME];
    const docCountFromLocation = parseInt(parsedQuery[DOCUMENTS_COUNT_PARAM_NAME], 10);

    const previewedBookId = useUpdateableRef(parsedQuery[PREVIEW_DOCUMENT_PARAM_NAME]);

    const setPreviewedBookId = useUpdateableRef((id: string, replace: boolean = false) => {
        const nextQueryObject = {...parsedQuery, [PREVIEW_DOCUMENT_PARAM_NAME]: id};
        const nextQuery = queryString.stringify(nextQueryObject, stringifyOptions);
        if (replace) {
            history.replace(nextQuery);
        } else {
            history.push(nextQuery);
        }
    });

    useEffect(() => () => onUnMount(), []);

    useEffect(
        () => {
            onChangeViewMode(viewMode);
        },
        []
    );

    useResetWindowScroll([]);

    useEffectOnUpdate(
        () => {
            onChangeViewMode(viewMode);

            if (viewMode !== 'preview') {
                setPreviewedBookId.current(undefined);
            }
        },
        [viewMode]
    );

    useEffect(
        () => {
            const isResultArray = Array.isArray(resultDocuments);

            if (!isResultArray || resultDocuments.length === 0) {
                return;
            }

            //делаем предпросмотр для первой книги в результатах поиска, если ещё нет в предпросмотре книги
            if (viewMode === 'preview' && resultDocuments.length > 0 &&
                (previewedBookId.current === undefined || !resultDocuments.some(res => res.document.id === previewedBookId.current))
            ) {
                setPreviewedBookId.current(resultDocuments[0].document.id, true);
            }
        },
        [resultDocuments, viewMode]
    );

    useEffect(
        () => {
            const hasPreviousResult = Array.isArray(prevResultDocuments) && prevResultDocuments.length !== 0;
            const hasCurrentResult = Array.isArray(resultDocuments) && resultDocuments.length !== 0;

            if (viewMode !== 'preview' || hasPreviousResult || !hasCurrentResult) {
                return;
            }

            const previewedElement = document.querySelector(`.${styles.bookListSnippetPreviewed}`);
            if (!previewedElement) {
                return;
            }
            const {height: pvElementHeight, top: pvElementTop} = previewedElement.getBoundingClientRect();
            //если уже проскроллили просматриваемое издание, не меняем скролл
            if (pvElementTop < document.body.clientHeight) {
                return;
            }

            window.scrollTo(0, pvElementTop + window.pageYOffset - document.body.clientHeight / 2 + pvElementHeight);
        },
        [resultDocuments]
    );

    useEffect(
        () => {
            docCountFromLocation && setLimit(docCountFromLocation);
        },
        [docCountFromLocation]
    );

    useEffect(
        () => {
            if (queryFromLocation !== query) {
                setQuery(queryFromLocation || '');
                fetchSearchResults();
            }
        },
        [queryFromLocation]
    );

    const setViewModeByScreenWidth = () => {
        //считаем вместе со скроллбаром, как и в media query в css
        if (window.innerWidth < VIEWPORT_L) {
            viewMode === 'preview' && setViewMode('tiles');
            setIsViewChangerShown && setIsViewChangerShown(false);
        } else {
            !isViewChangerShown && setIsViewChangerShown(true);
        }
    };

    useEffect(setViewModeByScreenWidth, []);

    typeof window !== 'undefined' && useEventListener(
        window,
        'resize',
        setViewModeByScreenWidth,
        undefined,
        [viewMode, isViewChangerShown]
    );

    const adjustStickyPosition = useCallback(
        () => {
            if (viewMode !== 'preview') {
                return;
            }
            stickyRef.current && stickyRef.current.updateInitialDimension();
            //В react-stickynode обновляются некоторые параметры через setState, поэтому приходится ждать, пока они асинхронно обновятся
            setTimeout(
                () => stickyRef.current && stickyRef.current.update(),
                50
            );
        },
        [viewMode]
    );

    useEffectOnUpdate(
        () => {
            adjustStickyPosition();
        },
        [resultDocuments]
    );

    const renderSnippetHeader = useCallback(
        ({book}, clsNames) => {
            const title = book && book.document && book.document.title;
            return (
                <header>
                    <h2
                        itemProp="headline"
                        className={clsNames.bookSnippetTitle}
                        title={title}
                    >
                        {title}
                    </h2>
                </header>
            );
        },
        []
    );

    const viewedDocumentsAndLoadBtn = viewedCount > 0 ?
        (
            <React.Fragment>
                <div className={styles.bookListViewedCount}>
                    <span>
                        {viewedCount} из {totalFoundResults}
                    </span>
                </div>
                {(viewedCount < totalFoundResults) &&
                    <div className={styles.bookListLoadMoreWrapper}>
                        <button
                            onClick={() => {
                                loadMoreBooks(viewedCount, BookLoadLimitIncrease.get(viewMode));
                            }}
                            className={`btn-misc ${styles.bookListLoadMore}`}
                        >
                            Загрузить ещё
                        </button>
                    </div>
                }
            </React.Fragment>
        ) :
        null;

    return (
        <div
            className={classNames(
                styles.bookList,
                {
                    [styles.bookListPreview]: viewMode === 'preview',
                    [styles.bookListTiles]: viewMode === 'tiles'
                }
            )}
        >
            <PageHeader
                header={renderHeaderContent(props, styles)}
            />
            <div className={styles.bookListControls}>
                <div className={styles.bookListFilters}>
                    <Filters />
                </div>
                {isViewChangerShown &&
                    <div>
                        <button
                            type="button"
                            className={classNames(
                                'btn',
                                'icon-preview',
                                styles.bookListViewMode,
                                {[styles.bookListViewModeActive]: viewMode === 'preview'}
                            )}
                            title="Предпросмотр"
                            onClick={() => setViewMode('preview')}
                        />
                        <button
                            type="button"
                            className={classNames(
                                'btn',
                                'icon-tiles',
                                styles.bookListViewMode,
                                {[styles.bookListViewModeActive]: viewMode === 'tiles'}
                            )}
                            title="Плитка"
                            onClick={() => setViewMode('tiles')}
                        />
                    </div>
                }
            </div>
            <div className={styles.bookListResultsPreview}>
                {(Array.isArray(resultDocuments) && resultDocuments.length === 0) &&
                    <div className={styles.bookListNoResultsMsg}>По данному запросу ничего не найдено.</div>
                }
                <div className={styles.bookListResults}>
                    {Array.isArray(resultDocuments) && resultDocuments.map(result =>
                        <div
                            key={result.document.id}
                            className={styles.bookListResult}
                            onClick={viewMode === 'preview' ? () => setPreviewedBookId.current(result.document.id) : undefined}
                        >
                            <BookSnippet
                                className={viewMode === 'preview' && previewedBookId.current === result.document.id ?
                                    styles.bookListSnippetPreviewed :
                                    undefined
                                }
                                popoverClassName={classNames(
                                    styles.bookListSnippetPopover,
                                    {[styles.bookListSnippetPopoverTiles]: viewMode === 'tiles'}
                                )}
                                book={result}
                                isAdaptive={viewMode === 'preview'}
                                coverSize={viewMode === 'preview' ? 'SMALL' : 'MEDIUM'}
                                renderHeader={viewMode === 'preview' ? renderSnippetHeader : undefined}
                            />
                        </div>
                    )}
                    {viewMode === 'preview' &&
                        <React.Fragment>
                            {viewedDocumentsAndLoadBtn}
                        </React.Fragment>
                    }
                </div>
                {(viewMode === 'preview' && Array.isArray(resultDocuments) && resultDocuments.length > 0) &&
                    <React.Fragment>
                        <div className={styles.bookListResultsPreviewDelimiter} />
                        <div className={styles.bookListPreviewBlock}>
                            {previewedBookId.current &&
                                <Sticky
                                    ref={stickyRef}
                                    bottomBoundary={`.${styles.bookListResultsPreviewDelimiter}`}
                                    top={HEADER_HEIGHT_XL}
                                >
                                    <div className={styles.bookListPreviewContainer}>
                                        <Preview
                                            bookId={previewedBookId.current}
                                            onLoadingBookStart={adjustStickyPosition}
                                            onLoadingBookEnd={adjustStickyPosition}
                                        />
                                    </div>
                                </Sticky>
                            }
                        </div>
                    </React.Fragment>
                }
            </div>
            {viewMode === 'tiles' &&
                <React.Fragment>
                    {viewedDocumentsAndLoadBtn}
                </React.Fragment>
            }
        </div>
    );
};

export default BookList;
