/**
 * Created by Lkarmelo on 18.01.2018.
 */

import React from 'react';

import './LoadingScreen.scss';

const LoadingScreen = () => (
    <div className={'loading-screen'}>
        <div>
            <div className={'loading-screen__content'}>
                <p>Загрузка</p>
            </div>
        </div>
    </div>
);

export default LoadingScreen;
