/**
 * Created by Lkarmelo on 21.09.2017.
 */

import React, { useState } from 'react';

import PopoverPanel from './PopoverPanel';
import UserMenuPlaceholder from './UserMenuPlaceholder';
import { IUserPanelProps } from './interfaces/IUserPanelProps';
import { PanelShowTrigger } from './interfaces/IPopoverPanelProps';
import { UserMenuPopoverPanel } from './UserMenuPopoverPanel';
import { UserNotLoggedPopoverPanel } from './UserNotLoggedPopoverPanel';
import LoginIcon from './LoginIcon';
import Badge from '../common/Badge';
import { NOTIFICATION_BADGE_OVERFLOW_COUNT } from 'common/constants';

import * as styles from './UserPanel.scss';

const UserPanel: React.FC<IUserPanelProps> = (props: IUserPanelProps) => {
    const {isAuthorized, firstName, lastName, onLogOut, onShowLogin, onShowRegisterPopup, notificationCount} = props;

    const [isOpen, setIsOpen] = useState(false);

    if ( isAuthorized ) {

        const userAttrs = { firstName, lastName, onLogOut };

        return (
            <PopoverPanel
                showTrigger={PanelShowTrigger.OnClick}
                content={(onClose: () => void) => (
                    <UserMenuPopoverPanel
                        notificationCount={notificationCount}
                        onClose={onClose}
                        {...userAttrs}
                    />
                )}
                onShowPopup={() => setIsOpen(true)}
                onClosePopup={() => setIsOpen(false)}
            >
                {!isOpen && (
                    <Badge
                        className={styles.headerNotificationCountBadge}
                        count={notificationCount}
                        overflowCount={NOTIFICATION_BADGE_OVERFLOW_COUNT}
                    />
                )}
                <UserMenuPlaceholder {...userAttrs}/>
            </PopoverPanel>
        );

    }

    return (
        <PopoverPanel
            showTrigger={PanelShowTrigger.OnHover}
            content={(onClose: () => void) => (
                <UserNotLoggedPopoverPanel
                    onClose={onClose}
                    onShowPopupLogin={onShowLogin}
                    onShowRegisterPopup={onShowRegisterPopup}
                />
            )}
        >
            <LoginIcon />
        </PopoverPanel>
    );
};

export default UserPanel;
