import React from 'react';

import { requiredStringValidation, requiredArrayStringValidation } from 'app/utils/forms/validation';

import {
    TextField,
    MultiselectField,
    SelectField,
    TextAreaField
} from 'app/components/common/form-fields/material-fields';

import IProps from '../../interfaces/IAttrDocumentFormProps';
import { Field } from 'react-final-form';
import { requiredPersonBlockValidation } from '../../DocumentPersons/validation';
import PersonField from '../../DocumentPersons/PersonField';
import { ICategory } from 'nkc-frontend-tools/types/Store';
import PersonBlock from '../../DocumentPersons';

const ScienceArticleForm: React.FC<IProps> = (
    {
        document: doc,
        knowledgeCategories,
        authors,
        editor,
        documentSources: {
            knowledgeCategories: knowledgeCategoriesSource,
            materialTypes: materialTypeSource
        },
        submitting,
        onAddAuthor, onEditAuthor, onDeleteAuthor,
        onEditEditor, onClearEditor
    }) => {

    return (
        <>
            <TextField
                name={'title'}
                initialValue={doc.title}
                label="Наименование"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <MultiselectField
                name={'knowledgeCategories'}
                label="Область знаний"
                disabled={submitting}
                initialValue={knowledgeCategories}
                options={knowledgeCategoriesSource || []}
                validate={requiredArrayStringValidation}
                required
            />

            <Field
                name="meta.authors"
                initialValue={authors}
                validate={requiredPersonBlockValidation}
            >
                {({ meta }) => (
                    <PersonBlock
                        authors={authors}
                        error={meta.touched ? meta.error : undefined}
                        required
                        onEdit={onEditAuthor}
                        onCreate={onAddAuthor}
                        onDelete={onDeleteAuthor}
                    />
                )}
            </Field>

            <SelectField
                name={'materialType'}
                label="Тип материала"
                disabled={submitting}
                validate={requiredStringValidation}
                initialValue={doc.materialType}
                options={materialTypeSource || []}
                required
            />

            <TextField
                className={'half'}
                name={'year'}
                initialValue={doc.year ?.toString()}
                label="Год"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <TextAreaField
                name={'abstract'}
                initialValue={doc.abstract}
                disabled={submitting}
                placeholder={'Аннотация к cтатье'}
            />
        </>
    );
};

export default ScienceArticleForm;
