import React, { useMemo, useCallback, useState } from 'react';
import uniqueId from 'lodash.uniqueid';
import classNames from 'classnames';

import { getAuthorsOrEditor } from 'app/utils/getAuthorsOrEditor';
import BookCover from 'app/components/common/BookCover/BookCover';
import CropImagePopup from 'app/components/Popups/CropImagePopup';
import { useFileUpload } from 'app/hooks/useFileUpload';
import { ICover } from 'common/store/ExtendedStore';
import LoadingBar from 'app/components/common/LoadingBar';
import {
    UPLOAD_COVER_MIN_WIDTH,
    UPLOAD_COVER_MIN_HEIGHT,
    UPLOAD_COVER_MAX_WIDTH,
    UPLOAD_COVER_MAX_HEIGHT
} from 'app/utils/constants';
import { usePrevious } from 'app/modules/hooks/usePrevious';

import IProps from './interfaces/IUploadImageSectionProps';
import * as styles from './UploadImageSection.scss';

const enum ImageValidationStatus {
    OK,
    TOO_LARGE,
    TOO_SMALL
}

const UploadImageSection: React.FC<IProps> = (props) => {
    const {
        className,
        document,
        fileUploadProgress,
        uploadDocumentCover,
        cleanFileUploadProgress
    } = props;

    const { id, title } = document;

    const objectType = document.meta?.type;

    const author = useMemo(
        () => {
            const authorsOrEditor = getAuthorsOrEditor({document});

            return Array.isArray(authorsOrEditor) ? authorsOrEditor[0] : '';
        },
        [document]
    );

    const [isCropPopupVisible, setIsCropImagePopupVisible] = useState(false);
    const previousCropPopupVisible = usePrevious(isCropPopupVisible);

    const [originalImage, setOriginalImage] = useState<HTMLImageElement>(null);
    const previousOriginalImage = usePrevious(originalImage);

    const [croppedCanvas, setCroppedCanvas] = useState<HTMLCanvasElement>(null);

    const [imageValidationErrorMessage, setImageValidationErrorMessage] = useState<string>(null);

    const uploadCover = useCallback(
        (fileToUpload: File, uploadId: string) => {
            id && uploadDocumentCover(id, uploadId, fileToUpload);
        },
        [id]
    );

    const {
        setFile,
        progressRatio,
        uploadSuccess,
        uploadError
    } = useFileUpload(
        fileUploadProgress,
        uploadCover,
        cleanFileUploadProgress
    );

    isCropPopupVisible !== previousCropPopupVisible &&
    !isCropPopupVisible &&
    originalImage !== null &&
    setOriginalImage(null);

    originalImage !== previousOriginalImage &&
    originalImage !== null &&
    !isCropPopupVisible &&
    setIsCropImagePopupVisible(true);

    const validateImageDimensions = useCallback(
        (image: HTMLImageElement) => {
            const { width, height } = image;

            if (width < UPLOAD_COVER_MIN_WIDTH || height < UPLOAD_COVER_MIN_HEIGHT) {
                return ImageValidationStatus.TOO_SMALL;
            }

            if (width > UPLOAD_COVER_MAX_WIDTH || height > UPLOAD_COVER_MAX_HEIGHT) {
                return ImageValidationStatus.TOO_LARGE;
            }

            return ImageValidationStatus.OK;
        },
        []
    );

    const handleFiles = useCallback(
        (files: FileList) => {
            const file = files.item(0);
            const dataUrl = URL.createObjectURL(file);
            const image = new Image();

            image.addEventListener('load', () => {
                switch (validateImageDimensions(image)) {
                    case ImageValidationStatus.OK:
                        setOriginalImage(image);
                        setImageValidationErrorMessage(null);
                        break;
                    case ImageValidationStatus.TOO_SMALL:
                        setImageValidationErrorMessage(
                            `Размер обложки должен быть не менее ${UPLOAD_COVER_MIN_WIDTH}*${UPLOAD_COVER_MIN_HEIGHT} px`
                        );
                        break;
                    case ImageValidationStatus.TOO_LARGE:
                        setImageValidationErrorMessage(
                            `Размер обложки должен быть не более ${UPLOAD_COVER_MAX_WIDTH}*${UPLOAD_COVER_MAX_HEIGHT} px`
                        );
                        break;
                    default:
                        break;
                }
            });

            image.src = dataUrl;
            image.title = file.name;
        },
        []
    );

    const onInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { files } = event.target;
            files && handleFiles(files);

            event.target.value = null;
        },
        []
    );

    const inputId = useMemo(
        () => uniqueId('file-upload-field-'),
        []
    );

    const onCropImageSave = useCallback(
        (croppedImageCanvas: HTMLCanvasElement) => {
            setIsCropImagePopupVisible(false);
            setCroppedCanvas(croppedImageCanvas);

            originalImage && croppedImageCanvas.toBlob(
                (blob) => {
                    const file = new File([blob], originalImage.title);
                    setFile(file);
                },
                'image/png'
            );
        },
        [originalImage]
    );

    const onCropImageClose = useCallback(
        () => {
            setIsCropImagePopupVisible(false);
        },
        []
    );

    const cover = useMemo<ICover>(
        () => croppedCanvas && ({
            coverSize: 'DEFAULT',
            link: croppedCanvas.toDataURL('image/png')
        }),
        [croppedCanvas]
    );

    const errorMessage = imageValidationErrorMessage ?? (uploadError && 'Попробуйте ещё раз');

    const progressRatioWithError = errorMessage ? 1 : progressRatio;

    const shouldProgressBarRender = !!progressRatioWithError || errorMessage;

    return (
        <div className={classNames(className, styles.uploadImageSection)}>
            <div className={styles.uploadImageSectionCover}>
                <BookCover
                    id={id}
                    title={title}
                    author={author}
                    objectType={objectType}
                    showBookType
                    size={'DEFAULT'}
                    covers={cover && uploadSuccess ? [cover] : undefined}
                    imgClassName={styles.uploadImageSectionCoverImage}
                    loadingPlaceholderClassName={styles.uploadImageSectionCoverPlaceholder}
                />
            </div>
            <div className={styles.uploadImageSectionCaption}>
                <input
                    className={styles.uploadImageSectionInput}
                    type="file"
                    accept="image/jpeg, image/png"
                    id={inputId}
                    onChange={onInputChange}
                />
                <label
                    htmlFor={inputId}
                    className={classNames('btn', styles.uploadImageSectionUploadButton)}
                >
                    Загрузите изображение
                </label>&#32;
                обложки не менее {UPLOAD_COVER_MIN_WIDTH}*{UPLOAD_COVER_MIN_HEIGHT} и
                не более {UPLOAD_COVER_MAX_WIDTH}*{UPLOAD_COVER_MAX_HEIGHT} px PNG или JPG.
                Обложка будет отображаться в поисковой выдаче и карточке материала.
                Иначе будет отображаться обложка по умолчанию.
                {shouldProgressBarRender && (uploadSuccess ? (
                    <span className={styles.uploadImageSectionUploadedSuccessful}>
                        Изображение загружено
                    </span>
                ) : (
                    <>
                        <LoadingBar
                            className={styles.uploadImageSectionLoadingBar}
                            valueClassName={classNames({
                                [styles.uploadImageSectionLoadingBarValueError]: errorMessage
                            })}
                            progressRatio={progressRatioWithError}
                        />
                        <span className={styles.uploadImageSectionLoadingBarLabel}>
                            {!errorMessage ? (
                                <>Загружается&hellip;</>
                            ) : (
                                <>
                                    <em className={styles.uploadImageSectionErrorText}>
                                        Ошибка загрузки.
                                    </em>&#32;
                                    {errorMessage}
                                </>
                            )}
                        </span>
                    </>
                ))}
            </div>
            <CropImagePopup
                image={originalImage?.src}
                isVisible={isCropPopupVisible}
                onSave={onCropImageSave}
                onClose={onCropImageClose}
                onError={onCropImageClose}
            />
        </div>
    );
};

export default UploadImageSection;
