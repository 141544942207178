/**
 * Created by lkarmelo on 27.02.2019.
 */

import {FilterName} from 'common/types/FilterName';

export const FilterToTitleNavigationPanel = new Map<FilterName, string>([
    [FilterName.MaterialType, 'Типы материалов'],
    [FilterName.KnowledgeArea, 'Области знаний'],
    [FilterName.PublicationType, 'Издания'],
    [FilterName.Year, 'Год издания']
]);

export const FilterToTitleFilterPanel = new Map<FilterName, string>([
    [FilterName.MaterialType, 'Тип материала'],
    [FilterName.KnowledgeArea, 'Области знаний'],
    [FilterName.PublicationType, 'Издания'],
    [FilterName.Year, 'Год издания'],
    [FilterName.IsPublished, 'Опубликован'],
]);
