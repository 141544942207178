/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {handleActions} from 'redux-actions';

import {Store} from '../../../types';

import {SET_FILTERS_META} from '../../actions/search/filtersMeta';

export default handleActions(
    {
        [SET_FILTERS_META](state: Store.IFiltersMeta, {payload}: {payload: Store.IFiltersMeta; type: string}) {
            return payload;
        }
    },
    {} //- default value for filtersMeta
);
