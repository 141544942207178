/**
 * Created by lkarmelo on 15.03.2019.
 */

import {createDataMockProvider} from 'app/components/common/CreateDataMockProvider';
import BookDetails from './BookDetails';

import IProps from './interfaces/IBookDetailsProps';

export default createDataMockProvider(
    {
        propsMock: {
            book: {
                documentSnippet: {
                    document: {
                        abstract: Array.from(Array(250)).map(() => '_').join(' '),
                        title: Array.from(Array(25)).map(() => '_').join(' '),
                        authors: [{
                            id: '21512521d1f',
                            lastName: '______',
                            middleName: '_',
                            firstName: '_',
                            fullName: '_________'
                        }]
                    },
                    categories: [{
                        id: '21521521521521f1gf23g',
                        name: '__ __ __ __ __ __ __ __ ',
                        score: .8
                    }],
                    keywords: Array.from(Array(10)).map(() => '_ _ _ _ _ _ _ _ _'),
                    tags: Array.from(Array(5)).map((_, i) => ({
                        id: `ph-tag-${i}`,
                        score: .8,
                        name: '_ _ _ _',
                    })),
                }
            }
        },
        passIsMockedProp: true,
        shouldMock: ({book}: IProps): boolean => !book ||
            Object.keys(book).length === 0 ||
            (Object.keys(book).length === 1 && book.hasOwnProperty('preloadFromServer'))
    }
)(BookDetails);
