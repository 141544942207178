/**
 * Created by Lkarmelo on 26.09.2017.
 */

import {DateTimeString} from './helpers';

export enum FileStateEnum {
    Error,
    Uploading,
    Uploaded,
    TmpFile,
    Redo,
    Stored,
    Deleted
}

export const NotificationContext = 'currentUserNotification';
export const RecommendationsContext = 'recommendations';
export const PersonSearchContext = 'userSearch';

export interface IBaseState {
    search: IDocumentSearch;

    filtersMeta: IFiltersMeta;
    defaultFilters: IFilters;

    objectCard?: IObjectCard;

    authorization: IAuthorization;
    passwordRecovery: {
        status?: number;
        error?: string;
    };

    //пользователь системы
    user?: ISystemUser;

    //текущий пользователь, чей профиль просматривается
    currentUser?: IUser;

    loading: ILoadingInfo;
    // добавляется react-redux-loading-bar
    loadingBar: {default: number};
}

export interface IFileStore {
    files: { [key: string]: IFileMeta };
    keys: string[];
}

export interface IFileMeta {
    fid?: string;
    name: string;
    size: number;
    status: FileStateEnum;
    mimeType: string;
    progress?: IProgress;
    error?:  string;

    // info for reupload file
    file?: File;
}

export interface IProgress {
    loaded: number;
    total: number;
}

export interface IFiltersMeta {
    [key: string]: IFilterMeta;
}

export interface IFilterMeta<TVal = IFilterValueDescription[]> {
    title?: string;
    values?: TVal;
    type: FilterType;
    dependencies?: {filter: string, field: string};
    default?: string[] | string | Object;
    isFromServer?: boolean;
    isParam?: boolean;
}

export const enum FilterType {
    Select = 'select',
    MultiSelect = 'multiselect',
    LinearMultiSelect = 'linearMultiselect',
    YearRange = 'yearrange',
    DateRange = 'daterange',
    Tags = 'tags',
    CheckBox = 'checkbox',
    Search = 'search',
    Text = 'textfilter',
}

export interface IFilterValueDescription {
    label: string;
    value: string;
    parent?: string;
}

export interface IFilters {
    [filterName: string]: {
        value: IFilterValue;
        isDisabled?: boolean;
    };
}

export type IFilterValue =  number | string | boolean | string[] | IDateFilterValue;

export interface IDateFilterValue {
    from?: number;
    to?: number;
}

export interface IRangeFilterValue extends IDateFilterValue {}

//фильтры, которые не приходят с сервера
export const enum StaticFilterName {
    Tags = 'category-title',
    Thesaurus = 'thesaurus',
    Search = 'search',
}

export interface IPaging {
    skip: number;
    limit: number;
    isBlocked?: boolean;
}

export interface ILoadingInfo {
    pendingRequests: {[requestName: string]: boolean};
    pendingRequestsCount: number;
    loadingErrors: ILoadingRequestsErrors;
}

export interface ILoadingRequestsErrors {
    [requestName: string]: ILoadingError;
}

export interface ILoadingError {
    message: string;
    showInMainErrorComponent?: boolean;
}

export const enum SearchResultPageEnum {
    searchQuery = 'searchQuery',
    results = 'results',
    paging = 'paging',
    filters = 'filters',
    terms = 'terms',
    hints = 'hints'
}

export interface IFiltersAndPaging {
    filters?: IFilters;
    paging?: IPaging;
}

export interface IFilterSettings {
    filtersMeta: IFiltersMeta;
    defaultFilters: IFilters;
}
export interface ISearch<TResults = any> extends IFiltersAndPaging {
    //в initialState null используется вместо undefined, потому что redux не позволяет иметь undefined как начальное значение
    searchQuery?: string | null;
    /*
        нужно, чтобы работал пейджинг на странице поиска, иначе если изменить запрос, но не нежать "поиск",
        то при переключении страниц будет использована новая строка searchQuery и совсем другие результаты
        показаны на следующей странице, а не те, что должны быть
    */
    lastExecutedSearchQuery?: string | null;

    results?: TResults;

    isQueryExpansion: boolean;
    queryTags?: IQueryTags;
    hints?: ISearchHint;
}

export interface IDocumentSearch extends ISearch<IDocumentResults> {}

export interface IPersonSearch extends ISearch<IPersonResults> {}

export interface IEducationMaterials extends IDocumentSearch {
    statusFilter: EducationMaterialStatusValue;
    numberOfMaterials: INumberOfEducationMaterials;
}

export interface INumberOfEducationMaterials {
    numberOfAll?: number;
    numberOfRecommended?: number;
    numberOfMandatoryRecommended?: number;
    numberOfPlanning?: number;
    numberOfActive?: number;
    numberOfDone?: number;
}

export const enum EducationMaterialStatusValue {
    All = 'All',
    Recommended = 'Recommended',
    Planning = 'Planning',
    Active = 'InProgress',
    Done = 'Done',
}

export interface IRecommendationsWithPagingAndFilters {
    paging: IPaging;
    list: IRecommendations;
    filters: IFilters;
    sorting: ISorting;
}

export interface IRecommendations {
    totalCount: number;
    recommendations: IUserRecommendationDetail[];
}

export interface IUserRecommendationDetail extends IUserRecommendation {
    profile: IUser;
    recommendation: IRecommendationDetail;

    documentStatus?: DocumentEducationStatus;
    learningStatusChanged?: DateTimeString;
}

export interface IValueStats {
    [valueCode: string]: number;
}

export interface IFilterStats {
    [filterName: string]: IValueStats;
}

export interface IAggregationBucket {
    key: string;
    docCount: number;
    buckets: IAggregationBucket[] | IAggregation[];
}

export const GRAPH_STATS_AGGREGATION_NAME = 'graph-stats';
export const YEAR_STATS_AGGREGATION_NAME = 'year';

export interface IAggregation {
    name: string;
    buckets: IAggregationBucket[] | IAggregation[];
}

export interface IResults<T = any> {
    list?: T[];
    count?: number;
}

export interface IDocumentResults extends IResults<ISearchSnippetDocument> {
    modified?: number;
    categories?: any[];
    filterStats?: IFilterStats;
    aggregations?: IAggregation[];
}

export interface IPersonResults extends IResults<IPerson> {}

export interface IDocumentSnippet {
    id: string;
    meta: IDocumentDescription;
    keywords?: IKeyword[];
    categories?: ICategory[];
    tags?: ICategory[];
    favorite?: boolean;
    profiles?: IProfileLink[];
    readLater?: boolean;
    modified?: number;
}

export interface IProfileLink {
    personId: string;
    login: string;
    loginSource: ISourceToLogin[];
}

export interface ISourceToLogin {
    login: string;
    source?: string;
}

export interface IUpdateDocumentSnippet {
    id: string;
    keywords?: IKeyword[];
    categories?: ICategory[];
    favorite?: boolean;
    readLater?: boolean;
    meta?: IDocumentDescription;
}

export interface ISearchSnippetDocument extends IDocumentSnippet {
    highlightedContent?: string[];
}

export interface IObjectCard extends IDocumentSnippet {
    annotation?: IAnnotation[];
    similarity?: ISimilarity[];
    optionalAttrs?: IDocumentAttr[];
    modifed: number;
}

export interface IDocumentAttr {
    code: string;
    title: string;
    value: string;
}

export interface IDocumentDescription {
    link?: string | { url: string, title: string};
    objectType?: string;
    displayObjectType?: string;
    //subType - один из типов, который приходит по запросу educationMaterialTypes
    subType?: string;
    title?: string;
    year?: number;
    orgs?: IOrg[];
    originalId?: string;
    persons?: IDocumentPerson[];
    isTutorial?: boolean;
    isEditable?: boolean;
    status?: DocumentEducationStatus;
    recommendationsInfo?: IUserRecommendationDetail[];
    competenceRequirements?: ICompetence[];
}

export interface INkcDocumentInfo {
    id: string;
    title: string;
    storageSource: string;
    objectType: INkcObjectType;
    persons: ISimplePerson[];
    orgs: IOrg[];
    optionalAttrs: {[attrName: string]: string};

    originalId?: string;
    summary?: string;
    year?: number;
    link?: string;
    creationDate?: number;
    modifyDate?: number;
}

export interface INkcObjectType {
    value: string;

    subType?: {};
}

export type DocumentEducationStatus =
    EducationMaterialStatusValue.Planning
    | EducationMaterialStatusValue.Active
    | EducationMaterialStatusValue.Done;

export interface IRecommendationDetail {
    id: string;
    authorId: string;
    author: IUser;
    documentId: string;
    document: INkcDocumentInfo;
    deleted: boolean;
    created: DateTimeString;
    modified: DateTimeString;
    lastModifier: DateTimeString;
}

export interface IUserRecommendation {
    profileId: string;
    recommendationId: string;
    refused: boolean;
    planDate: DateTimeString;
    statusChanged: DateTimeString;
    accepted: boolean;
    necessity: boolean;

    recommendedForPost?: string;
    recommendedForSubdivision?: string;
}

export interface IOrg {
    id: string;
    title: string;
}

export interface IDocumentPerson {
    fullName: string;
    id: string;
    link?: string;
}

export interface ISimplePerson {
    id: string;
    title: string;
}

export interface IAnnotation {
    sentence: string;
    similarity: number;
    ordering: number;
}

export interface ISimilarity {
    similarity: number;
    document: ICardSnippetDocument;
}

export interface ICardSnippetDocument extends IDocumentSnippet {
    annotation?: IAnnotation[];
}

export interface ICategory {
    id: string;
    name: string;
    score: number;
    parentCategory?: ICategory;
}

export interface IKeyword {
    keyword: string;
}

export interface ISearchHint {
    queries: IQueryHint[];
    persons: IPerson[];
}

export interface IQueryHint {
    query: string;
    highlighted?: string;
    count?: number;
}

export interface IQueryKeyword {
    lemmaId: string;
    similarity: number;
    raw: string;

    selected?: boolean;
    tooltip?: string;
}

export interface IQueryTags {
    queryString: string;
    tags: IQueryNode | IQueryTerm;
}

export interface IQueryTerm {
    term: string;
    expansion: IQueryKeyword[];
}

export interface IQueryNode {
    op: 'OR' | 'AND';
    nodes: (IQueryNode | IQueryTerm)[];
}

export interface IAuthorization {
    isAuthorized: boolean;
    authorizationFailed?: boolean;
    role?: string;
    error?: string;
    retry?: {[actionName: string]: number};
}

export interface IUser {
    id: string;
    userId?: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    birthDate?: string;
    email: string;
    phone?: string[];
    created: string;
    modified: string;
    state: number;
    userLogins?: ISourceToLogin[];
}

export interface ISystemUser extends IUser {
    permissions: EnabledUserRoles;
}

export type EnabledUserRoles = {
    [roleName in PermissionRole]?: boolean;
};

export const enum PermissionRole {
    AccessAdmin = 'AccessAdmin',
    AccessUser = 'AccessUser',
    AccessManager = 'AccessManager',
}

export interface IEmployeeInfo {
    id: string;
    personId: string;
    address: ISimpleCatalogItem;
    room: string;
    phone: string[];
    post?: ISimpleCatalogItem;
    subdivision?: ISimpleCatalogItem;
    targetPost?: ISimpleCatalogItem;
    organization?: IOrganization;
}

export interface IPerson {
    profile: IUser;
    employee?: IEmployeeInfo;
}

export interface ISimpleCatalogItem {
    id: string;
    title: string;
    archived: boolean;
    code: string;
    created: DateTimeString;
    modified: DateTimeString;
    catalogId: string;
    parentId?: string;
    catalog?: string;
    description?: string;
}

export interface IHierarchicalCatalogItem {
    item: ISimpleCatalogItem;
    subItems: IHierarchicalCatalogItem[];
}

export interface IOrganization {
    id: string;
    title: string;
    headDivisionId?: string;
    created?: string;
    modified?: string;
}

export interface ICompetence {
    competenceItem: ISimpleCatalogItem;
    grade?: number;
}

export interface IUserCompetence extends ICompetence {
    requiredGrade?: number;
    targetGrade?: number;
}

export interface IKnowledgeCategory {
    item: ISimpleCatalogItem;
    docsCount: number;
}

export interface IUserSkill {
    skill: ISimpleCatalogItem;
    skilledPersonsAmount: number;
    skilledOfAllUsers: number;
}

export interface IForms {
    [formName: string]: {
        data: {
            [fieldName: string]: any;
        }
        status: FormStatus;
        response?: any;
    };
}

export const enum FormStatus {
    Editing = 'EDITING',
    Invalid = 'INVALID',
    SendingFail = 'SENDING_FAIL',
    SendingSuccess = 'SENDING_SUCCESS',
    Pending = 'PENDING'
}

export interface IMarkDocument {
    documentId: string;
    mark: boolean;
}

export interface IOrganizationsInfo {
    organizations: IOrganization[];
    addresses: ISimpleCatalogItem[];
    posts: ISimpleCatalogItem[];
    subdivisions: IHierarchicalCatalogItem[];
}

export interface INormalizedOrgStructure {
    subDivisions: INormalizedHierarchicalSubDivisions;
    employees: IEmployeesMap;
    subDivisionsToPosts: ISubDivisionsToPostsMap;
    subDivisionsToEmployees: ISubDivisionsToEmployeesMap;
    personToEmployees: IPersonToEmployeeMap;
}

export interface INormalizedHierarchicalSubDivisions {
    topSubDivisions: string[];
    items: INormalizedSubDivisionsMap;
}

export interface INormalizedSubDivisionsMap {
    [subDivisionId: string]: INormalizedHierarchicalSubDivisionItem;
}

export interface INormalizedHierarchicalSubDivisionItem {
    item: ISimpleCatalogItem;
    subItems: string[];
    parent?: string;
}

export interface IEmployeesMap {
    [employeeid: string]: INamedEmployee;
}

export interface ISubDivisionsToPostsMap {
    [subDivisionId: string]: string[];
}

export interface ISubDivisionsToEmployeesMap {
    [subDivisionId: string]: string[];
}

export interface IPersonToEmployeeMap {
    [personId: string]: string[];
}

export interface INormalizedHierarchicalCategories {
    topCategories: string[];
    categories: INormalizedHierarchicalCategoriesMap;
}

export interface INormalizedHierarchicalCategoriesMap {
    [id: string]: INormalizedHierarchicalCatalogItem;
}

export interface ICatalogueCategoryItem extends ISimpleCatalogItem {
    keywords?: IKeyword[];
    description?: string;
}

export interface INormalizedHierarchicalCatalogItem {
    item: ICatalogueCategoryItem;
    subItems: string[];
    countCat: number;
    countWithSubCat: number;
    parent?: string;
}

export interface INamedEmployee {
    id: string;
    personId: string;
    subdivisionId: string;
    postId: string;
    phone: string[];
    created: DateTimeString;
    modified: DateTimeString;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    targetPostId?: string;
    address?: string;
}

export interface INotificationResults extends IFiltersAndPaging, IFilterSettings {
    searchQuery?: string;
    results?: IUserNotificationList;
    displayResults?: IUserNotification[];
}

export interface IUserNotificationList {
    newCount: number;
    notifications: IUserNotification[];
    total: number;
}

export interface IUserNotification {
    id: string;
    notificationCodeTitle: string;
    content: string;
    isRead?: boolean;
    created: string;
    messageCatalogTitle: string;
}

export interface ISorting {
    field?: string;
    order?: SortingOrder;
}

export type SortingOrder = 'asc' | 'desc';
