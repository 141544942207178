/**
 * Created by lkarmelo on 29.03.2019.
 */

import React from 'react';
import {useCallback} from 'react';
import classNames from 'classnames';

import BookSnippetBase from '../Base/BookSnippetBase';

import IProps from '../interfaces/IBookSnippetProps';

import * as styles from './BookSnippetBulletin.scss';

const BookSnippetBulletin: React.FunctionComponent<IProps> = (props) => {
    const {book} = props;
    const isLastNumber = book &&
        book.document &&
        book.document.meta &&
        book.document.meta.isLastNumber;

    const year = book &&
        book.document &&
        book.document.year;

    const renderCategories = useCallback(
        () => (
            <div className={styles.bookSnippetBulletinLabelWrapper}>
                {year &&
                    <span className={styles.bookSnippetBulletinYear}>{year} г.</span>
                }
                {isLastNumber &&
                    <div className={styles.bookSnippetBulletinLastIssueLabel}>
                        Последний номер
                    </div>
                }
            </div>
        ),
        [isLastNumber, year]
    );

    return (
        <BookSnippetBase
            renderCategories={renderCategories}
            {...props}
            className={classNames(props.className, styles.bookSnippetBulletin)}
        />
    );
};

export default BookSnippetBulletin;
