/**
 * Created by lkarmelo on 14.05.2019.
 */
import {Action, handleActions} from 'redux-actions';

import {setHints} from 'app/redux/actions/search/hints';

import {Store} from 'nkc-frontend-tools/types';

export default handleActions<Partial<Store.ISearchHint>>(
    {
        [setHints.toString()]: (_, {payload}: Action<Store.ISearchHint>): Store.ISearchHint => payload
    },
    {}
);
