/**
 * Created by lkarmelo on 06.08.2019.
 */

import {EMAIL_REGEXP} from '../constants';

type FieldValidationFunc<T, TF = any> = (fieldValue: T, values?: TF) => string | undefined;

export const fieldRequiredError = 'Поле должно быть заполнено';

export const requiredArrayStringValidation = (fieldValue: string[]): string | undefined =>
    Array.isArray(fieldValue) && fieldValue.length > 0 ?
        undefined :
        fieldRequiredError;

export const requiredStringValidation = (fieldValue: string): string | undefined =>
    typeof fieldValue === 'string' && !!fieldValue.trim() ?
        undefined :
        fieldRequiredError;

export const requiredBooleanValidation = (fieldValue: boolean): string | undefined => fieldValue ? undefined : fieldRequiredError;

export const emailValidation = (fieldValue: string): string | undefined =>
    EMAIL_REGEXP.test(fieldValue) ? undefined : 'Введен неправильный email';

export const getPasswordsMatchValidator = <T extends {[key: string]: string}>(passwordValueName: string) =>
    (value: string, values: T) =>
        values[passwordValueName] !== value ? 'Введенные пароли не совпадают' : undefined;

export const oneOfValidation = <T, TF>(...validationFuncs: FieldValidationFunc<T, TF>[]): FieldValidationFunc<T, TF> =>
    (fieldValue: T, values: TF): string | undefined => {
        let error;

        for (let i = 0; i < validationFuncs.length; i++) {
            error = validationFuncs[i](fieldValue, values);
            if (error) {
                return error;
            }
        }
    };
