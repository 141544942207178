/**
 * Created by Lkarmelo on 04.09.2017.
 */

import { ClientRoute } from '../nkc-frontend-tools/routing';

export type CatalogQueryParams = 'masterFilterName' | 'masterFilterValue';
export type BookParams = 'id';
export type DocumentParams = 'id';
export type AccountActionQueryParams = 'token' | 'emailType';
export type ReadPageParams = 'id' | 'pageNumber';

const routes = {
    main: new ClientRoute('/'),
    login: new ClientRoute('/login'),
    search: new ClientRoute<{}>('/search'),
    book: new ClientRoute<{}, BookParams>('/book/:id'),
    catalog: new ClientRoute<{}, '', CatalogQueryParams | any>('/catalog'),
    admin: new ClientRoute('/admin'),
    confirmAuthentication: new ClientRoute('/authenticate'),
    profile: new ClientRoute('/profile'),
    favorite: new ClientRoute('/favorite'),
    subscriptions: new ClientRoute('/subscriptions'),
    notifications: new ClientRoute('/notifications'),
    notificationSettings: new ClientRoute('/notifications/settings'),
    forgotPassword: new ClientRoute('/forgot-password'),

    resetPassword: new ClientRoute<{}, '', AccountActionQueryParams>('/confirm/resetPassword/:token'),
    confirmAccountCreation: new ClientRoute<{}, '', AccountActionQueryParams>('/confirm/activation/:token'),
    confirmEmailChange: new ClientRoute<{}, '', AccountActionQueryParams>('/confirm/:emailType/:token'),

    privacy: new ClientRoute('/privacy'),
    terms: new ClientRoute('/terms'),
    about: new ClientRoute('/about'),
    read: new ClientRoute<{}, ReadPageParams>('/read/:id/:pageNumber?'),

    uploads: new ClientRoute('/uploads'),
    newUpload: new ClientRoute('/upload/new'),
    continueNewUpload: new ClientRoute<{}, DocumentParams>('/upload/new/:id'),
    upload: new ClientRoute<{}, DocumentParams>('/upload/:id')
};

export default routes;
