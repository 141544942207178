import React from 'react';
import { renderError } from '../utils/renderError';
import classNames from 'classnames';

import IProps from './interfaces/ITextAreaFormFieldProps';
import * as styles from './TextAreaFormField.scss';

const TextAreaFormField: React.FC<IProps> = (props) => {
    const { className, error, ...rest } = props;
    return (
        <div className={classNames(className, styles.textArea)}>
            <textarea
                {...rest}
                className={classNames(
                    styles.textAreaArea,
                    { [styles.textAreaAreaError]: error }
                )}
            />
            <div className={'form-field-error'}>
                {renderError(error)}
            </div>
        </div>
    );
};

TextAreaFormField.defaultProps = {
  cols: 89,
  rows: 3
};

export default TextAreaFormField;
