/**
 * Created by lkarmelo on 01.03.2019.
 */

import {useCallback, useRef, MouseEventHandler, DependencyList} from 'react';

import {useEventListener} from './useEventListener';

/**
 * Исполняет коллбек, если сделан клик снаружи относительно React DOM tree (а не просто DOMa)
 * Возвращает колбек, который нужно присвоить эвенту onMouseDown элемента, относительно которого
 * ожидаются клики
 */
export const useOnOutsideClick = (callback: Function, deps: DependencyList = []): MouseEventHandler => {

    const isClicked = useRef(false);

    const onDocumentMouseDown = useCallback(
        (e: MouseEvent) => {
            if (isClicked.current) {
                isClicked.current = false;
                return;
            }

            callback();
        },
        deps
    );

    typeof document !== 'undefined' && useEventListener(document, 'mousedown', onDocumentMouseDown);

    return useCallback(
        () => {
            isClicked.current = true;
        },
        []
    );
};
