/**
 * Created by PBorisov on 05.07.2019
 */
import {connect} from 'react-redux';
import {ExtendedStore} from 'common/store';

import permissions, { PermissionChecker, Permissions } from 'common/permissions';
import RestrictedPermissionWrapper from './RestrictedPermissionWrapper';

import { createSelector } from 'reselect';

const permissionSelector = (state:  ExtendedStore.IState) => state.user.permissions;
const pkSelector = (state:  ExtendedStore.IState, ownProps) => {
    const {pk}: {pk: Permissions} = ownProps;

    return pk;
};
const paramsSelector = (state:  ExtendedStore.IState, ownProps) => {
    return ownProps;
};

const userPermissionCheckerSelector = createSelector(
    [permissionSelector],
    (userRoles: string[]) => {
        // console.log("- user permission selector -- pk", userRoles);

        return permissions(userRoles);
    }
);

const makeGetPermissionChecker = () => {
    return createSelector(
        [pkSelector, userPermissionCheckerSelector, paramsSelector],
        (pk: Permissions, userPermissionChecker: (pk: Permissions) => PermissionChecker, params) => {
            // console.log("- get permission checker -- pk", pk);

            return userPermissionChecker(pk)(params);
        }
    );
};

const restrictedPermissionProps = () => {
    const permissionChecker = makeGetPermissionChecker();
    return (state:  ExtendedStore.IState, ownProps) => ({
            isAuthorized: !!state.authorization.isAuthorized,
            permissionChecker: permissionChecker(state, ownProps),
            ...ownProps
    });
};

const withRestrictedPermission = (WrappedComponent) =>
    connect(restrictedPermissionProps)(RestrictedPermissionWrapper(WrappedComponent));

export default withRestrictedPermission;
