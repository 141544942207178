import React, { useState, useCallback, useMemo, useRef, ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { CheckboxOption, CheckboxOptionGroup, ICheckboxOptionGroupProps, ICheckboxOptionProps } from '..';
import { useStateWithCallback } from 'app/hooks/useStateWithCallback';

import IProps, { ISelectOption } from './interfaces/ICheckboxSelectProps';
import * as styles from './Select.scss';

const enum ViewMode {
    PLAIN,
    COLLAPSIBLE,
    SCROLL_AND_SEARCH
}

const maxOptionsCount = {
    [ViewMode.PLAIN]: 3,
    [ViewMode.COLLAPSIBLE]: 11
};

const PREVIEW_CHILDREN_COUNT = maxOptionsCount[ViewMode.PLAIN];

const getViewModeByOptionsCount = (childrenCount: number) => {
    if (childrenCount <= maxOptionsCount[ViewMode.PLAIN]) {
        return ViewMode.PLAIN;
    }

    if (childrenCount > maxOptionsCount[ViewMode.PLAIN] && childrenCount <= maxOptionsCount[ViewMode.COLLAPSIBLE]) {
        return ViewMode.COLLAPSIBLE;
    }

    return ViewMode.SCROLL_AND_SEARCH;
};

const Select: React.FC<IProps> = (props) => {
    const {
        title,
        className,
        disabled,
        options,
        onSelect,
        onDeselect
    } = props;

    useEffect(
        () => {
            if (disabled) {
                setSearchQuery('');
                setIsOpen(false);
            }
        },
        [disabled]
    );

    const viewMode = useMemo(
        () => getViewModeByOptionsCount(options.length),
        [options]
    );

    const [isOpen, setIsOpen] = useStateWithCallback(false);
    const [searchQuery, setSearchQuery] = useState('');

    const searchInputRef = useRef<HTMLInputElement>(null);

    const onShowAllButtonClick = useCallback(
        () => {
            setIsOpen(!isOpen, () => {
                searchInputRef.current && searchInputRef.current.focus();
                setSearchQuery('');
            });
        },
        [isOpen]
    );

    const onSearchInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(event.target.value);
        },
        []
    );

    const filteredOptions = useMemo(
        () => {
            if (!searchQuery) {
                return options;
            }

            const searchQueryLowerCase = searchQuery.toLowerCase();

            return options.reduce(
                (filtered, option) => {
                    const optionNameLowerCase = option.name.toLowerCase();
                    const optionMatch = optionNameLowerCase.includes(searchQueryLowerCase);

                    if (!option.subOptions && optionMatch) {
                        filtered.push(option);

                        return filtered;
                    }

                    const filteredSubOptions = option.subOptions.filter(subOption =>
                        subOption.name.toLowerCase().includes(searchQueryLowerCase)
                    );

                    const subOptionsMatch = filteredSubOptions.length > 0;

                    if (optionMatch && !subOptionsMatch) {
                        filtered.push(option);
                    } else if (subOptionsMatch || optionMatch) {
                        const openOption = {
                            ...option,
                            subOptions: filteredSubOptions,
                            open: true,
                            key: `${option.value}open`
                        };
                        filtered.push(openOption);
                    }

                    return filtered;
                },
                []
            );
        },
        [options, searchQuery]
    );

    const optionElements: ReactElement<ICheckboxOptionGroupProps & ICheckboxOptionProps>[] = useMemo(
        () => filteredOptions.map(option => {
            const subOptionComponents = option.subOptions && option.subOptions.map(subOption => (
                <CheckboxOption
                    {...subOption}
                    labelClassName={styles.checkboxSelectSubOptionLabel}
                    key={subOption.value}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                >
                    {subOption.name}
                </CheckboxOption>
            ));

            return (
                <li key={option.value}>
                    {subOptionComponents ? (
                        <CheckboxOptionGroup
                            {...option}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                        >
                            {subOptionComponents}
                        </CheckboxOptionGroup>
                    ) : (
                        <CheckboxOption
                            {...option}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                        >
                            {option.name}
                        </CheckboxOption>
                    )}
                </li>
            );
        }),
        [filteredOptions]
    );

    const previewOptionElements = useMemo(
        () => optionElements.length > PREVIEW_CHILDREN_COUNT ?
            optionElements.slice(0, PREVIEW_CHILDREN_COUNT) : optionElements,
        [optionElements]
    );

    const scrollOpen = useMemo(
        () => viewMode === ViewMode.SCROLL_AND_SEARCH && isOpen,
        [viewMode, isOpen]
    );

    return (
        <div
            className={classNames(
                className,
                styles.checkboxSelect
            )}
        >
            <h2 className={styles.checkboxSelectTitle}>
                {title}
            </h2>
            {scrollOpen &&
                <input
                    className={styles.checkboxSelectSearchInput}
                    type="search"
                    value={searchQuery}
                    onChange={onSearchInputChange}
                    ref={searchInputRef}
                />
            }
            <div
                className={classNames(
                    styles.checkboxSelectOptions,
                    {
                        [styles.checkboxSelectOptionsScrollOpen]: scrollOpen
                    }
                )}
            >
                <ul
                    className={classNames(
                        styles.checkboxSelectOptionList,
                        {
                            [styles.checkboxSelectOptionListScrollOpen]: scrollOpen
                        }
                    )}
                >
                    {isOpen ? optionElements : previewOptionElements}
                </ul>
            </div>
            {viewMode !== ViewMode.PLAIN &&
                <button
                    className={classNames(
                        'btn-edit',
                        styles.checkboxSelectShowAllBtn
                    )}
                    tabIndex={disabled ? -1 : undefined}
                    onClick={onShowAllButtonClick}
                >
                    {isOpen ? 'Свернуть' : 'Показать все'}
                </button>
            }
        </div>
    );
};

export default Select;
