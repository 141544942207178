import React, { RefAttributes, useCallback } from 'react';
import { useState, useRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';

import { useOnOutsideClick } from 'app/hooks/useOnOutsideClick';
import { useLayoutEffectOnUpdate } from 'app/hooks/useLayoutEffectOnUpdate';

import { getElementOffset } from 'nkc-frontend-tools/utils/getElementOffset';

import IProps, { ILabelProps, IListProps } from './interfaces/ISelectProps';

import * as styles from './SelectBase.scss';
import { IOption } from 'app/components/common/form-fields/Select/interfaces/ISelectProps';

export const DropDownSelect: React.FunctionComponent<IProps> = (props) => {

    const {
        children,
        className,
        dropDownClassName,
        title,
        active,
        isOpened: isOpenedProps,
        onDeselect,
        onSelect,
        onClear,
        onOpen,
        onClose,
        labelRenderer: LabelComponent,
        optionRenderer: OptionListComponent,
        options
    } = props;

    const [_isOpened, setIsOpened] = useState<boolean>(false);
    const useParentOpened = (typeof (isOpenedProps) !== 'undefined');
    const isOpened = useParentOpened ? isOpenedProps : _isOpened;

    const selfRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);

    const onMouseDown = useOnOutsideClick(
        () => {
            onClose();
            setIsOpened(false);
        }
    );

    const onLabelClick = useCallback(
        () => {
            isOpened ? onClose() : onOpen();
            setIsOpened(prev => !prev);
        },
        [isOpened, setIsOpened]
    );

    useLayoutEffectOnUpdate(
        () => {
            const dropDown: HTMLDivElement = dropDownRef.current;
            const selfElement: HTMLDivElement = selfRef.current;

            if (!isOpened || !dropDown || !selfElement) {
                return;
            }

            const selfTopPosition = getElementOffset(selfElement).top;
            const selfBoundingRect = selfElement.getBoundingClientRect();
            const dropDownTop = selfTopPosition + selfBoundingRect.height + 2;

            dropDown.style.top = `${dropDownTop}px`;
            dropDown.style.left = `${selfBoundingRect.left}px`;
            dropDown.style.width = `${selfBoundingRect.width}px`;
            dropDown.style.maxWidth = `${selfBoundingRect.width}px`;
        },
        [isOpened, active]
    );

    const activeValues: string[] = typeof active === 'string' ? [active] : active;

    return (
        <div
            ref={selfRef}
            onMouseDown={onMouseDown}
            className={classNames(
                className,
                {
                    [styles.selectOpened]: isOpened,
                    [styles.selectActive]: activeValues.length > 0
                }
            )}
        >
            <LabelComponent
                title={title}
                onClick={onLabelClick}
                onClear={onClear}
                active={activeValues}
            />
            <Portal>
                <CSSTransition
                    in={isOpened}
                    timeout={200}
                    classNames={'slide-transition-down'}
                >
                    {status => (
                        <OptionListComponent
                            className={dropDownClassName}
                            ref={dropDownRef}
                            active={activeValues}
                            options={options}
                            onDeselect={onDeselect}
                            onSelect={onSelect}
                            isOpen={status !== 'exited'}
                        >
                            {children}
                        </OptionListComponent>
                    )}
                </CSSTransition>
            </Portal>
        </div>
    );
};

const OptionList: React.FC<IListProps & RefAttributes<HTMLDivElement>> = React.forwardRef<HTMLDivElement, IListProps>(
    ({ className, options, isOpen }, ref) => {

        const renderList = (values: IOption[]) =>
            values.map((item: IOption) =>
                (
                    <li id={item.id} key={item.id}>
                        {!!item.children
                            ? (
                                <ul>
                                    renderList(item.children)
                        </ul>
                            )
                            : item.label
                        }
                    </li>
                ));

        const normalOption = options.map((item: (IOption | string)) =>
            typeof item === 'string'
                ? { id: item, label: item }
                : item);

        return (
            <div
                ref={ref}
                className={classNames(
                    className,
                    { [`${className}-hidden`]: !isOpen }
                )}
            >
                <ul className={styles.selectList}>
                    {renderList(normalOption)}
                </ul>
            </div>
        );
    });

const Label: React.FC<ILabelProps> = ({ onClick, onClear, title, active }) => {
    const isActive = typeof active === 'string' || Array.isArray(active) ?
        active.length > 0 :
        (active !== undefined && active !== null);

    return (
        <>
            <button
                type="button"
                className={`btn ${styles.selectSwitcher}`}
                onClick={onClick}
            >
                <span className={styles.selectTitle}>{title}</span>
                <span className={styles.selectArrow} />
            </button>
            {(isActive && Array.isArray(active)) && !!onClear &&
                <button
                    className={`${styles.selectActiveCount} btn`}
                    onClick={onClear}
                >
                    <span className={styles.selectActiveCountNumber}>
                        {active.length}
                    </span>
                </button>
            }
        </>
    );
};

DropDownSelect.defaultProps = {
    labelRenderer: Label,
    optionRenderer: OptionList,

    className: styles.select,
    dropDownClassName: styles.selectDropDown,

    isOpened: false,
};

const PureDropDownSelect: React.FunctionComponent<IProps> = React.memo(DropDownSelect);

export default PureDropDownSelect;
