/**
 * Created by lkarmelo on 17.01.2019.
 */

import React from 'react';
import {useEffect, useMemo, useRef} from 'react';
import queryString from 'qs';
import classNames from 'classnames';

import {Link} from 'react-router-dom';
import {CollapsibleMenu} from 'nkc-frontend-tools/components';
import RFBRLogo from 'app/components/common/RFBRLogo';
import MenuItems from '../MenuItems';

import {usePrevious} from 'app/hooks/usePrevious';

import clientRoutes, {CatalogQueryParams} from 'common/clientRoutes';
import {parseOptions} from 'app/utils/queryStringOptions';

import IProps from './interfaces/INavPanelProps';

import * as styles from './NavPanel.scss';

//можно хранить иконки просто как массив, потому что фильтры приходят упорядоченно тоже массивом
const iconClasses = [
    'icon-a-book',
    'icon-folder',
    'icon-bookmark',
];

const findActiveElement = (): HTMLElement | null => {
    const activeLinks = document.querySelectorAll(`.${styles.navPanelLink}.${styles.navPanelLinkActive}`);
    return activeLinks.length > 0 ? activeLinks[activeLinks.length - 1] as HTMLElement : null;
};

const scrollToActiveMenu = (listWrapper: HTMLElement): void => {
    const activeLink = findActiveElement();
    if (!activeLink) {
        return;
    }

    const activeLinkTop = activeLink.getBoundingClientRect().top;
    const {top: scrollableListTop, height: scrollableListHeight} = listWrapper.getBoundingClientRect();

    const isElementVisible = activeLinkTop > scrollableListTop && activeLinkTop < scrollableListTop + scrollableListHeight;

    if (!isElementVisible) {
        listWrapper.scrollTop = activeLinkTop - scrollableListTop - scrollableListHeight / 2 + listWrapper.scrollTop;
    }
};

const NavPanel: React.FunctionComponent<IProps> = (props) => {
    const {filters, location} = props;

    const listWrapperRef = useRef<HTMLElement>(null);

    const parsedLocationSearch = useMemo(
        () => queryString.parse(location.search, parseOptions),
        [location.search]
    );
    const {
        masterFilterName: activeFilterName,
        masterFilterValue: activeFilterValue
    }: Record<CatalogQueryParams, string> = parsedLocationSearch;

    const prevFilters = usePrevious(filters);

    useEffect(
        () => {
            if (filters.length > 0 && (!prevFilters || prevFilters.length === 0)) {
                scrollToActiveMenu(listWrapperRef.current);
            }
        },
        [filters]
    );

    return (
        <div className={styles.navPanel}>
            <div className={styles.navPanelLogo}>
                <Link to={clientRoutes.main.getUrl()}>
                    <RFBRLogo />
                </Link>
            </div>
            <div className={styles.navPanelScrollHider}>
                <nav
                    ref={listWrapperRef}
                    className={styles.navPanelNavigation}
                >
                    <ul>
                        {filters.map(({name: filterName, title, topValues, values}, filterIndex) =>
                            <li
                                className={styles.navPanelListItem}
                                key={filterName}
                            >
                                <CollapsibleMenu
                                    className={styles.navPanelTopSection}
                                    isInitiallyOpened={true}
                                    renderToggle={(_, toggleOpenedState, cssClassNames) => (
                                        <div className={cssClassNames.toggle}>
                                            <span
                                                className={classNames(
                                                    iconClasses[filterIndex % filters.length],
                                                    styles.navPanelTopSectionIcon,
                                                    {[styles.navPanelTopSectionIconActive]: filterName === activeFilterName}
                                                )}
                                            />
                                            <button
                                                type="button"
                                                className={`btn ${styles.navPanelTopSectionText}`}
                                                onClick={toggleOpenedState}
                                            >
                                                {title}
                                            </button>
                                            <button onClick={toggleOpenedState} className={`btn ${cssClassNames.arrow}`} />
                                        </div>
                                    )}
                                >
                                    {topValues.map(valId =>
                                        <MenuItems
                                            locationPathname={location.pathname}
                                            key={valId}
                                            className={styles.navPanelListItem}
                                            collapsibleMenuClassName={styles.navPanelSubsection}
                                            linkClassName={styles.navPanelLink}
                                            linkActiveClassName={styles.navPanelLinkActive}
                                            filterName={filterName}
                                            filterIndex={filterIndex}
                                            valueDesc={values[valId]}
                                            depth={1}
                                            filters={filters}
                                            activeFilterValue={activeFilterValue}
                                        />
                                    )}
                                </CollapsibleMenu>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default React.memo(NavPanel);
