/**
 * Created by Lkarmelo on 26.02.2018.
 */

import React from 'react';
import {Link} from 'react-router-dom';

import IProps from './interface/IBreadCrumbsProps';

const BreadCrumbs: React.FunctionComponent<IProps> = (props: IProps) => {
    const delimiter = props.delimiter || '/';
    const pathsToRender = props.shouldReversePaths ? props.paths.slice().reverse() : props.paths;

    return (
        <div className={'breadcrumbs'}>
            {pathsToRender
                .map((path, index) =>
                    <span key={path.link + index || index} className={'breadcrumbs__path'}>
                        {index > 0 &&
                            <span className={'breadcrumbs__delimiter'}>
                                {delimiter}
                            </span>
                        }
                        {
                            path.link ? (
                                <Link
                                    className={'breadcrumbs__link'}
                                    to={path.link}
                                    title={path.title}
                                >
                                    {path.title}
                                </Link>
                            ) : (
                                <span className={'breadcrumbs__link'}>
                                    {path.title}
                                </span>
                            )
                        }
                    </span>
                )
            }
        </div>
    );
};

export default BreadCrumbs;
