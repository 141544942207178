import React from 'react';
import classNames from 'classnames';

import * as styles from './UserPanel.scss';

import { INotLoggedPopoverProps } from './interfaces/IUserPopoverPanelProps';

export const UserNotLoggedPopoverPanel: React.FC<INotLoggedPopoverProps> = (props: INotLoggedPopoverProps): JSX.Element => {
    const {onClose, onShowPopupLogin, onShowRegisterPopup} = props;

    return (
        <div className={styles.headerLoginAdvicePopover}>
            <button
                className={`${styles.headerPopoverCloseBtn} btn icon-cross`}
                onClick={onClose}
                onTouchEnd={onClose}
            />
            <div className={styles.headerLoginAdviceText}>
                Авторизируйтесь, чтобы использовать все функции библиотеки
            </div>

            <button onClick={onShowPopupLogin} className={`${styles.headerLoginBtn} btn-primary`}>
                ВОЙТИ
            </button>
            <button className={classNames('btn', styles.headerCreateAccountBtn)} onClick={onShowRegisterPopup}>
                СОЗДАТЬ АККАУНТ
            </button>
        </div>
    );
};
