/**
 * Created by lkarmelo on 05.08.2019.
 */

export enum InputFieldMode {
    MATERIAL,
    REGULAR
}

type BaseInputFormFieldProps = {
    mode: InputFieldMode;
    label: string;
    className?: string;
    error?: string | {[er: string]: string};
} & JSX.IntrinsicElements['input'];

export default BaseInputFormFieldProps;
