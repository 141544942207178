/**
 * Created by Lkarmelo on 23.03.2018.
 */

import {AjaxError} from 'rxjs/observable/dom/AjaxObservable';
import {ActionFunctionAny} from 'redux-actions';
import {createActions} from 'redux-actions';

import {noop} from '../../../utils/noop';

import {camelCaseToCapSnakeCase} from '../../../utils/camelCaseToCapSnakeCase';

type LoadingActions = {
    [actionName: string]: ActionFunctionAny<any>
};

type PayloadProviderCreator = (opt: Option) => (...args: any[]) => any;

type PayloadProviderCreators = {
    request?: PayloadProviderCreator;
    resolve?: PayloadProviderCreator;
    reject?: PayloadProviderCreator;
};

type MetaProviderCreator = (opt: Option) => (...args: any[]) => {requestName: string, [key: string]: any};

type MetaProviderCreators = {
    request?: MetaProviderCreator;
    resolve?: MetaProviderCreator;
    reject?: MetaProviderCreator;
};

type Option = {
    name: string;
    errors?: {[status: number]: string | LoadingErrorInfo | ((er: Error) => LoadingErrorInfo) };
    payloadCreatorsFactory?: PayloadProviderCreators;
    metaCreatorsFactory?: MetaProviderCreators;
    showLoadingBar?: boolean;
    logOutOnUnAuth?: boolean;
    logInOnAuth?: boolean;
    ignoreErrors?: boolean;
    additionalInfo?: any;
};

export type LoadingErrorInfo = {
    errorMessage: string;
    showErrorInMainErrorComponent: boolean;
};

type MetaWithLoadingError = {requestName: string} & LoadingErrorInfo;

export class LoadingActionCreator {
    static defaultErrorMessage = 'Произошла ошибка загрузки';
    static defaultOption = {
        errors: {},
        payloadCreatorsFactory: {},
        metaCreatorsFactory: {},
        showLoadingBar: true,
        logOutOnUnAuth: true,
        logInOnAuth: true,
        ignoreErrors: false,
    };
    static createLoadingActions(
        options: (Option | string)[],
        loadingShowActionCreators: ActionFunctionAny<any>[],
        loadingHideActionCreators: ActionFunctionAny<any>[],
        onErrorTriggers: ActionFunctionAny<any>[],
        logOutOnUnAuthActionCreators: ActionFunctionAny<any>[],
        logInOnAuthActionCreators: ActionFunctionAny<any>[],
    ): LoadingActions {

        let actionCreators = {};

        options.forEach((option: Option | string) => {
            let optionWithDefaults: Option = null;
            if (typeof option === 'string') {
                optionWithDefaults = {...LoadingActionCreator.defaultOption, name: option};
            } else {
                optionWithDefaults = {...LoadingActionCreator.defaultOption, ...option};
            }
            const {
                name, payloadCreatorsFactory, metaCreatorsFactory, showLoadingBar, logOutOnUnAuth,
                ignoreErrors, errors, logInOnAuth
            } = optionWithDefaults;

            const defaultMetaCreator = () => ({requestName: name});
            const defaultRejectMetaCreator = (e: AjaxError) => {
                const meta: MetaWithLoadingError = {
                    requestName: name,
                    errorMessage: undefined,
                    showErrorInMainErrorComponent: undefined,
                };
                if (!e) {
                    return meta;
                }
                const errorOptions = errors[e.status];
                if (errorOptions === undefined) {
                    meta.errorMessage = LoadingActionCreator.defaultErrorMessage;
                    meta.showErrorInMainErrorComponent = true;
                } else if (typeof errorOptions === 'string') {
                    meta.errorMessage = errorOptions;
                    meta.showErrorInMainErrorComponent = true;
                } else if (typeof errorOptions === 'object') {
                    meta.errorMessage = (<LoadingErrorInfo>errorOptions).errorMessage;
                    meta.showErrorInMainErrorComponent = (<LoadingErrorInfo>errorOptions).showErrorInMainErrorComponent;
                } else if (typeof errorOptions === 'function') {
                    meta.errorMessage = errorOptions(e).errorMessage;
                    meta.showErrorInMainErrorComponent = errorOptions(e).showErrorInMainErrorComponent;
                }
                return meta;
            };

            const creators = createActions({
                [`${camelCaseToCapSnakeCase(name)}_REQUEST`]: [
                    payloadCreatorsFactory.request ? payloadCreatorsFactory.request(optionWithDefaults) : noop,
                    metaCreatorsFactory.request ? metaCreatorsFactory.request(optionWithDefaults) : defaultMetaCreator
                ],
                [`${camelCaseToCapSnakeCase(name)}_RESOLVE`]: [
                    payloadCreatorsFactory.resolve ? payloadCreatorsFactory.resolve(optionWithDefaults) : noop,
                    metaCreatorsFactory.resolve ? metaCreatorsFactory.resolve(optionWithDefaults) : defaultMetaCreator
                ],
                [`${camelCaseToCapSnakeCase(name)}_REJECT`]: [
                    payloadCreatorsFactory.reject ? payloadCreatorsFactory.reject(optionWithDefaults) : noop,
                    metaCreatorsFactory.reject ? metaCreatorsFactory.reject(optionWithDefaults) : defaultRejectMetaCreator
                ]
            });

            const requestActionName = `${name}Request`;
            const resolveActionName = `${name}Resolve`;
            const rejectActionName = `${name}Reject`;

            if (showLoadingBar) {
                loadingShowActionCreators.push(creators[requestActionName]);
                loadingHideActionCreators.push(creators[resolveActionName]);
                loadingHideActionCreators.push(creators[rejectActionName]);
            }

            logOutOnUnAuth && (logOutOnUnAuthActionCreators.push(creators[rejectActionName]));
            logInOnAuth && (logInOnAuthActionCreators.push(creators[resolveActionName]));

            !ignoreErrors && (onErrorTriggers.push(creators[rejectActionName]));

            actionCreators = {...actionCreators, ...creators};
        });
        return actionCreators;
    }
}
