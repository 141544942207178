import PermissionKey from './PermissionKey';

const visible = false;
const disabled = true;
const redirect = true;

export interface IPermissionRestriction {
    visible?: boolean;
    disabled?: boolean;
    redirect?: boolean;
}

type Permission = Map<PermissionKey, IPermissionRestriction>;

/**
 *  Список ограничений системы прав
 *      Отношение: ключ доступа -> накладываемые ограничения
 */
const permissionRestriction: Permission = new Map<PermissionKey, IPermissionRestriction>([
    [PermissionKey.viewBook, {visible}],
    [PermissionKey.viewLkLink, {visible}],
    [PermissionKey.viewAdministrationLink, {visible}],
    [PermissionKey.viewDocumentRegistratorLink, {visible}],

    [PermissionKey.viewLikeBookButton, {disabled, redirect}],
    [PermissionKey.viewDownloadBookButton, {disabled, redirect}],
    [PermissionKey.viewReadBookButton, {disabled, redirect}],

    [PermissionKey.editBook, {visible}],
    [PermissionKey.removeBook, {visible}],

    [PermissionKey.viewShareBookButton, {visible}],
    [PermissionKey.viewFavoriteBookButton, {disabled, redirect}],

    [PermissionKey.subscribeToMailingList, {disabled, redirect}]
]);

export default permissionRestriction;
