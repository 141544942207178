import React from 'react';

import { requiredStringValidation } from 'app/utils/forms/validation';

import {
    TextField,
    SelectField,
    TextAreaField
} from 'app/components/common/form-fields/material-fields';

import IProps from '../../interfaces/IAttrDocumentFormProps';
import PersonField from '../../DocumentPersons/PersonField';
import { Field } from 'react-final-form';

const DigestForm: React.FC<IProps> = (
    {
        document: doc,
        editor,
        documentSources: {
            knowledgeCategories: knowledgeCategoriesSource,
            materialTypes: materialTypeSource
        },
        submitting,
        onEditEditor,
        onClearEditor
    }) => {

    return (
        <>
            <TextField
                name={'title'}
                initialValue={doc.title}
                label="Наименование"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <SelectField
                name={'materialType'}
                label="Тип материала"
                disabled={submitting}
                validate={requiredStringValidation}
                initialValue={doc.materialType}
                options={materialTypeSource || []}
                required
            />

            <TextField
                className={'half'}
                name={'year'}
                initialValue={doc.year ?.toString()}
                label="Год"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <TextField
                className={'half'}
                name={'meta.issueInfo.issueIdentifier'}
                initialValue={doc.meta ?.issueInfo ?.issueIdentifier as string}
                label="Номер выпуска"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <TextField
                className={'half'}
                name={'meta.issueInfo.isbn'}
                initialValue={doc.meta ?.issueInfo ?.isbn as string}
                label="ISBN"
                disabled={submitting}
            />

            <Field
                name="meta.issueInfo.editor"
                initialValue={editor}
            >
                {({ meta }) => (
                    <PersonField
                        label="Редактор"
                        error={meta.touched ? meta.error : undefined}
                        person={editor}
                        onEdit={onEditEditor}
                        onDelete={onClearEditor}
                    />
                )}
            </Field>

            <TextAreaField
                name={'abstract'}
                initialValue={doc.abstract}
                disabled={submitting}
                placeholder={'Аннотация к сборнику'}
            />
        </>
    );
};

export default DigestForm;
