/**
 * Created by lkarmelo on 15.08.2019.
 */

import {deleteAccount} from '../../actions/forms/formsSendActions';

import {deleteAccountRequest, deleteAccountResolve, deleteAccountReject} from '../../actions/loading';

import {FormName} from 'app/types/FormName';
import {createFormSendEpic} from './createFormSendEpic';

export const deleteAccountEpic = createFormSendEpic({
    triggers: [deleteAccount.toString()],
    formName: FormName.DeleteAccount,
    actions: {
        requestAction: deleteAccountRequest,
        resolveAction: deleteAccountResolve,
        rejectAction: deleteAccountReject
    }
});
