import { handleActions, Action } from 'redux-actions';
import { setSubscriptions, setSubscriptionStatus, ISetSubscriptionStatusPayload, clearSubscriptions } from '../actions/subscriptions';
import initialState from 'common/store/storeInitialState';
import { ISubscription } from 'common/api/ExtendedApi';
import { IGroupedSubscriptions } from 'common/store/ExtendedStore';
import { ExtendedStore } from 'common/store';
import { normalize, schema } from 'normalizr';

const subscriptionSchema = new schema.Entity(
    'subscriptions',
    undefined,
    {
        idAttribute: sub => sub.identifier.type,
        processStrategy: sub =>
            sub.identifier && sub.identifier.value ? {[sub.identifier.value]: true} : true
    }
);

export default handleActions<IGroupedSubscriptions, ISubscription[] & ISetSubscriptionStatusPayload>(
    {
        [setSubscriptions.toString()]: (_, {payload}: Action<ISubscription[]>) => {
            const normalizedSubscriptions = normalize(payload, [subscriptionSchema]);

            return normalizedSubscriptions.entities.subscriptions;
        },
        [setSubscriptionStatus.toString()]:
            (subscriptions: ExtendedStore.IGroupedSubscriptions, {payload}: Action<ISetSubscriptionStatusPayload>) => {
                const { type, value } = payload.subscription;
                const status = payload.status;
                const newSubscriptions = { ...subscriptions };
                if (!value) {
                    newSubscriptions[type] = status;

                    return newSubscriptions;
                }

                if (!newSubscriptions.hasOwnProperty(type)) {
                    newSubscriptions[type] = {};
                }

                const subscriptionMap = newSubscriptions[type];
                subscriptionMap[value] = status;

                return newSubscriptions;
            },
        [clearSubscriptions.toString()]: () => ({})
    },
    initialState.subscriptions
);
