/**
 * Created by lkarmelo on 31.01.2019.
 */

export const initialState = {
    loading: {
        pendingRequests: {},
        loadingErrors: {},
        pendingRequestsCount: 0
    },
};
