import React from 'react';

import * as styles from './UserPanel.scss';

import { IUserAttrs } from './interfaces/IUserPanelProps';

const getPlaceHolderByUserNames = (firstName: string, lastName: string): string => {
    let res = '';
    if (typeof firstName === 'string') {
        res += firstName.charAt(0);
    }
    if (typeof lastName === 'string') {
        res += lastName.charAt(0);
    }
    return res;
};

const UserMenuPlaceholder: React.SFC<IUserAttrs> = (props?: IUserAttrs) => {
    const { firstName, lastName } = props;

    let placeholderText = getPlaceHolderByUserNames(firstName, lastName);

    return (
        <div className={styles.headerUserMenuPlaceholder}>
            <span>{!!placeholderText ? placeholderText : '\u00A0'}</span>
        </div>
    );
};

export default UserMenuPlaceholder;
