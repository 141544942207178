/**
 * Created by lkarmelo on 29.03.2019.
 */
import {createSimpleLoadingEpic} from 'nkc-frontend-tools/redux/epics/utils';

import {fetchIssueInfoCatalog, setIssueInfoCatalog} from 'app/redux/actions/issueInfoCatalog';
import {issueInfoCatalogRequest, issueInfoCatalogResolve, issueInfoCatalogReject} from 'app/redux/actions/loading';

export const loadIssueInfoCatalog = createSimpleLoadingEpic({
    triggers: [fetchIssueInfoCatalog.toString()],
    apiCallName: 'issueInfoCatalog' as any,
    actions: {
        setAction: setIssueInfoCatalog,
        requestAction: issueInfoCatalogRequest,
        rejectAction: issueInfoCatalogReject,
        resolveAction: issueInfoCatalogResolve
    }
});
