/**
 * Created by lkarmelo on 22.08.2019.
 */

import {Observable} from 'rxjs';
import {MiddlewareAPI} from 'redux';
import {Action} from 'redux-actions';

import {ExtendedApi} from 'common/api';
import {ExtendedStore} from 'common/store';

import {getActionWithAllContexts} from 'app/redux/context/getActionWithAllContexts';

import {IFavoriteActionPayload, requestFavoriteStateChange, setFavoriteState} from '../actions/favorite';
import {favoriteRequest, favoriteResolve, favoriteReject} from '../actions/loading';
import {raiseSystemNotification} from '../actions/systemNotifications';

const getApiCallByPyPayload = ({favoriteState, bookId}: IFavoriteActionPayload, apiCall: ExtendedApi.ApiCalls) => favoriteState ?
    apiCall.addFavorite(bookId) :
    apiCall.removeFavorite(bookId);

export const requestFavoriteStateChangeEpic = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    {apiCall}: {apiCall: ExtendedApi.ApiCalls}
) =>
    action$.ofType(requestFavoriteStateChange.toString())
        //разбиваем на группы, по группе для каждого bookId. Это нужно, чтобы exhaustMap работал корректно для bookId, а не был
        //общим для всех запросов
        .groupBy(
            ({payload}: Action<IFavoriteActionPayload>) => payload.bookId,
            undefined,
            //т.к. мы создаём группу для каждого bookId, нужно удалять её, чтобы не было утечек памяти
            () => Observable.timer(15000)
        )
        .mergeMap(group =>
            group.exhaustMap(({payload}: Action<IFavoriteActionPayload>) =>
                getApiCallByPyPayload(payload, apiCall)
                    .mergeMap(() =>
                        Observable.of(
                            ...getActionWithAllContexts(setFavoriteState(payload.bookId, payload.favoriteState)),
                            favoriteResolve(),
                            raiseSystemNotification(payload.favoriteState ?
                                ExtendedStore.SystemNotificationType.FavoriteAdd :
                                ExtendedStore.SystemNotificationType.FavoriteRemove
                            )
                        )
                    )
                    .catch(e => {
                        console.error(e);
                        return Observable.of(favoriteReject(e));
                    })
                    .startWith(favoriteRequest())
            )
        );
