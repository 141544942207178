/**
 * Created by lkarmelo on 14.05.2019.
 */

import {MiddlewareAPI} from 'redux';
import {Observable} from 'rxjs/Observable';

import {ExtendedStore} from 'common/store';
import {Api} from 'nkc-frontend-tools/api';
import {fetchHints, setHints} from 'app/redux/actions/search/hints';
import {Action} from 'redux-actions';

import {actionWithContext} from '../context/actionWithContext';
import {BookSearchContext} from 'common/store/ExtendedStore';

export const loadSearchHints = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: Api.ApiCalls}) =>
    action$.ofType(fetchHints.toString())
        .switchMap(({payload: searchQuery}: Action<string>) =>
            apiCall.searchHints(searchQuery)
                .map(({response}) => actionWithContext(setHints(response), BookSearchContext.Search))
                .catch(e => {
                    console.error(e);
                    return Observable.empty();
                })
                .takeUntil(action$.ofType(setHints.toString()))
        );
