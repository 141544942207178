/**
 * Created by lkarmelo on 23.08.2019.
 */

import React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Portal} from 'react-portal';

import SystemNotification from '../Notification/SystemNotification';

import IProps from './interfaces/ISystemNotificationsProps';

import * as styles from './SystemNotifications.scss';

const SystemNotifications: React.FC<IProps> = ({notifications, removeNotification}) => (
    <div>
        <Portal>
            <div className={styles.systemNotifications}>
                <TransitionGroup component={null}>
                    {Array.isArray(notifications) && notifications.map(notification => (
                        <CSSTransition
                            key={notification.id}
                            timeout={400}
                            classNames={styles.systemNotificationsNotification}
                        >
                            <SystemNotification
                                notification={notification}
                                onRemove={removeNotification}
                            />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>
        </Portal>
    </div>
);

export default SystemNotifications;
