/**
 * Created by Lkarmelo on 02.10.2017.
 */

import {handleActions, Action, ActionMeta} from 'redux-actions';

import {Store} from '../../../types';

import {
    setSkip, setLimit, blockPaging, unblockPaging, ISetSkipMeta,
    resetPaging, IResetPagingPayload
} from '../../actions/search/searchPaging';

const getSearchPagingReducer = (defaultLimit: number) =>
    handleActions<Partial<Store.IPaging>, any>(
        {
            [setSkip.toString()](state: Store.IPaging, {payload, meta}: ActionMeta<number, ISetSkipMeta>) {
                if (state.isBlocked && !(meta && meta.ignorePagingBlock)) {
                    return state;
                }
                return {...state, skip: payload};
            },
            [setLimit.toString()](state: Store.IPaging, {payload}: Action<number>) {
                if (state.isBlocked) {
                    return state;
                }
                return {...state, limit: payload, skip: 0 };
            },
            [resetPaging.toString()](state: Store.IPaging, {payload}: Action<IResetPagingPayload>) {
                const nextState = {...state, skip: 0};
                if (payload.resetLimit) {
                    nextState.limit = defaultLimit;
                }
                return nextState;
            },
            [blockPaging.toString()](state: Store.IPaging) {
                return {...state, isBlocked: true};
            },
            [unblockPaging.toString()](state: Store.IPaging) {
                return {...state, isBlocked: false};
            }
        },
        {} //- default value for searchPaging
    );

export default getSearchPagingReducer;
