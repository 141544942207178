import { ExtendedStore } from 'common/store';
import { IConfirmEmailChangeStateProps, IConfirmEmailChangeActionProps } from './interfaces/IConfirmEmailChangeProps';
import { FormName } from 'app/types/FormName';
import { connect } from 'react-redux';
import { confirmEmail } from 'app/redux/actions/forms/formsSendActions';

import ConfirmEmailChange from './ConfirmEmailChange';
import { removeForm } from 'app/redux/actions/forms/forms';
import { refreshUser } from 'nkc-frontend-tools/redux/actions/user';

const mapStateToProps = (state: ExtendedStore.IState): IConfirmEmailChangeStateProps => ({
    formStatus: state.forms[FormName.ConfirmEmail] && state.forms[FormName.ConfirmEmail].status
});

const mapDispatchToProps = (dispatch): IConfirmEmailChangeActionProps => ({
    confirmRequest(emailType: string, token: string): void {
        dispatch(confirmEmail({ emailType, token }));
    },
    refreshUser(): void {
        dispatch(refreshUser());
    },
    resetForm(): void {
        dispatch(removeForm(FormName.ConfirmEmail));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailChange);
