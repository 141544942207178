/**
 * Created by lkarmelo on 05.08.2019.
 */

import React from 'react';

import IProps, { InputFieldMode } from './interfaces/IBaseInputFormFieldProps';

import MaterialFormField from './MaterialInput/MaterialFormField';
import RegularFormField from './RegularInput/RegularFormField';

const BaseInputFormField: React.FC<IProps> = (props) => {
    return props.mode === InputFieldMode.MATERIAL ? <MaterialFormField {...props}/> : <RegularFormField {...props}/>;
};

export default BaseInputFormField;
