/**
 * Created by Lkarmelo on 02.10.2017.
 */

import {ActionMeta, createAction} from 'redux-actions';

import {IgnoreSearchActionMeta} from '../../epics/operators';
import {Store} from '../../../types';

export const setQuery = createAction<string | null>('SET_QUERY');

export const setQueryExpansion = createAction<Store.IQueryTags | void>('SET_QUERY_EXPANSION');
export const fetchQueryExpansion = createAction('FETCH_QUERY_EXPANSION');

export const removeTag = createAction<string>('REMOVE_TAG');
export const selectTag = createAction<string>('SELECT_TAG');

interface ISetQueryExpansionMode {
    (isQueryExpansionActive: boolean, ignoreSearchTrigger?: boolean): ActionMeta<boolean, IgnoreSearchActionMeta>;
}
export const setQueryExpansionMode: ISetQueryExpansionMode = createAction<boolean, IgnoreSearchActionMeta, boolean, boolean>(
    'SET_QUERY_EXPANSION_MODE',
    (isQueryExpansionActive) => isQueryExpansionActive,
    (isQueryExpansionActive, ignoreSearchTrigger = false) => ({ignoreSearchTrigger}),
);
