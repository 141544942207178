/**
 * Created by lkarmelo on 26.04.2019.
 */

import {MiddlewareAPI} from 'redux';
import {Action} from 'redux-actions';
import {Observable} from 'rxjs/Observable';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {Subject} from 'rxjs/Subject';
import {AjaxResponse} from 'rxjs/observable/dom/AjaxObservable';
import {concat} from 'rxjs/observable/concat';

import {fetchObjectCard, setObjectCard} from 'nkc-frontend-tools/redux/actions/objectCard';
import {defaultLoadingActions} from 'app/redux/actions/loading';
import {checkDocumentContent} from 'app/redux/actions/objectCard';
import {checkDocumentContentReject, checkDocumentContentRequest, checkDocumentContentResolve} from 'app/redux/actions/loading';

import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';
import {Api} from 'nkc-frontend-tools/api';

import {getObjectCard} from 'common/api-response-payload-mutators/getObjectCard';

const {objectCardReject, objectCardRequest, objectCardResolve} = defaultLoadingActions;

export const loadObjectCard = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    {apiCall}: {apiCall: ExtendedApi.ApiCalls & Api.ApiCalls}
) =>
    action$.ofType(fetchObjectCard.toString())
        .mergeMap(({payload: bookId}: Action<string>) =>
            forkJoin(
                apiCall.objectCard(bookId)
                    .map(({response}) => response),
                apiCall.checkDocumentContent(bookId)
                    .map(({status}: AjaxResponse) => status)
                    .catch(e => Observable.of(e.status)),
            )
                .mergeMap(([objectCard, checkContentStatus]: [ExtendedApi.IObjectCardResponseBody, number]) => {
                    const card = getObjectCard(
                        {
                            ...objectCard,
                            preloadFromServer: false
                        },
                        checkContentStatus
                    );

                    return Observable.of(
                        setObjectCard(<any>card),
                        objectCardResolve()
                    );
                })
                //сюда попадём, если apiCall.objectCard завершится с ошибкой, потому что там нет .catch
                .catch((e) => {
                    console.error(e);
                    return Observable.of(objectCardReject(e));
                })
                .startWith(objectCardRequest())
                .takeUntil(action$.ofType(objectCardReject.toString()))
        );

export const documentContentChecker = new Subject<string>();

export const checkDocumentContentEpic = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    {apiCall}: {apiCall: ExtendedApi.ApiCalls & Api.ApiCalls}
) =>
    action$.ofType(checkDocumentContent.toString())
        .mergeMap(({payload: bookId}: Action<string>) =>
            concat(
                apiCall.checkDocumentContent(bookId)
                    .map(({status}: AjaxResponse) => status === 200)
                    .do((hasContent: boolean) => {
                        const storedBook = store.getState().objectCard;
                        const storedBookId = storedBook &&
                            storedBook.documentSnippet &&
                            storedBook.documentSnippet.document &&
                            storedBook.documentSnippet.document.id;

                        if (hasContent && bookId === storedBookId) {
                            documentContentChecker.next(bookId);
                        }
                    })
                    .mergeMap(() => Observable.empty())
                    .catch(e => {
                        console.error(e);
                        return Observable.of(checkDocumentContentReject(e));
                    })
                ,
                Observable.of(checkDocumentContentResolve())
            )
                .startWith(checkDocumentContentRequest())
                .takeUntil(action$.ofType(checkDocumentContentReject.toString()))
        );
