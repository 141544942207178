import React from 'react';
import classNames from 'classnames';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import styles from './Pagination.scss';

const Pagination: React.FC<ReactPaginateProps> = (props) => {
    return (
        <ReactPaginate
            {...props}
            containerClassName={classNames(props.containerClassName, styles.pagination)}

            pageClassName={styles.paginationItem}
            pageLinkClassName={styles.paginationLink}

            activeClassName={styles.paginationItemCurrent}

            breakClassName={styles.paginationBreak}

            nextClassName={styles.paginationHidden}
            previousClassName={styles.paginationHidden}
        />
    );
};

export default Pagination;
