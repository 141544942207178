import React from 'react';
import {useCallback, useState} from 'react';

import RegularModal from 'app/components/common/modals/RegularModal';

import {useHandleFormStatus} from 'app/hooks/forms';

import clientRoutes from 'common/clientRoutes';

import IProps from './interfaces/IDeleteAccountProps';

import * as styles from './DeleteAccount.scss';

const DeleteAccount: React.FC<IProps> = (props) => {
    const {formStatus, resetFormStatus, deleteAccount, logout, history} = props;

    const [isModalShown, setIsModalShown] = useState(false);
    const [sendFailed, setSendFailed] = useState(false);

    const closeModal = useCallback(
        () => {
            setIsModalShown(false);
            setSendFailed(false);
        },
        []
    );

    const onSendSuccess = useCallback(
        () => {
            logout();
            history.push(clientRoutes.main.getUrl());
        },
        [history]
    );

    const onSendFailed = useCallback(() => setSendFailed(true), []);

    useHandleFormStatus(formStatus, resetFormStatus, onSendSuccess, onSendFailed);

    return (
        <div className={styles.deleteAccount}>
            <button
                className={'btn'}
                onClick={() => setIsModalShown(true)}
            >
                Удалить аккаунт в бибиотеке РФФИ
            </button>
            <RegularModal
                isVisible={isModalShown}
                close={closeModal}
            >
                <div className={styles.deleteAccountModalContent}>
                    <div className={styles.deleteAccountTitle}>Удалить аккаунт?</div>
                    <div className={styles.deleteAccountText}>Все ваши данные будут удалены.</div>
                    <div className={styles.deleteAccountControls}>
                        <button
                            className={`btn-primary`}
                            onClick={deleteAccount}
                        >
                            удалить
                        </button>
                        <button
                            className={'btn-cancel'}
                            onClick={closeModal}
                        >
                            отменить
                        </button>
                    </div>
                    {sendFailed &&
                        <div className={'form-field-error'}>
                            Удаление не удалось
                        </div>
                    }
                </div>
            </RegularModal>
        </div>
    );
};

export default DeleteAccount;
