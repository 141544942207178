/**
 * Created by Lkarmelo on 31.01.2017.
 */

import {createAction} from 'redux-actions';

import {Store} from '../../types';
import {Api} from '../../api';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const fetchUserData = createAction(FETCH_USER_DATA);

export const SET_USER_DATA = 'SET_USER_DATA';
export const setUserData = createAction<Store.IUser>(SET_USER_DATA);

export const refreshUser = createAction('REFRESH_USER');

export const fetchUserPermissions = createAction('FETCH_USER_PERMISSIONS');

export const setUserPermissions = createAction<Api.UserPermissionsResponse>('SET_USER_PERMISSIONS');
