import { handleActions, Action } from 'redux-actions';

import initialState from 'common/store/storeInitialState';
import {
    fileUploadProgress,
    IFileUploadProgressActionPayload,
    fileUploadProgressClean,
    IFileUploadErrorActionPayload,
    fileUploadError,
    fileUploadSuccess
} from '../actions/fileUploadProgress';
import { IFileUploadProgressMap } from 'common/store/ExtendedStore';

export default handleActions<IFileUploadProgressMap, IFileUploadProgressActionPayload & string & IFileUploadErrorActionPayload>(
    {
        [fileUploadProgress.toString()]: (state, { payload }: Action<IFileUploadProgressActionPayload>) => ({
            ...state,
            [payload.id]: {
                fileName: payload.fileName,
                fileSize: payload.fileSize,
                progressRatio: payload.progressRatio
            }
        }),
        [fileUploadProgressClean.toString()]: (state, { payload: id }: Action<string>) => {
            const { [id]: excluded, ...rest } = state;

            return rest;
        },
        [fileUploadSuccess.toString()]: (state, { payload: id }: Action<string>) => ({
            ...state,
            [id]: {
                ...state[id],
                success: true
            }
        }),
        [fileUploadError.toString()]: (state, { payload }: Action<IFileUploadErrorActionPayload>) => ({
            ...state,
            [payload.id]: {
                ...state[payload.id],
                error: {
                    message: payload.error.message,
                    status: payload.error.status
                }
            }
        })
    },
    initialState.fileUploadProgress
);
