/**
 * Created by lkarmelo on 06.08.2019.
 */
import {createAction} from 'redux-actions';

import {ExtendedApi} from 'common/api';
import {Omit} from 'nkc-frontend-tools/types';
import {IResetPasswordPayload} from 'nkc-frontend-tools/redux/actions/authorization';

export interface IUpdateEmailActionPayload {
    email: string;
    raiseNotification: boolean;
}

export const changePassword = createAction<ExtendedApi.IPasswordChangeBody>('CHANGE_PASSWORD');

export const deleteAccount = createAction('DELETE_ACCOUNT');

export const submitProfile = createAction<ExtendedApi.IProfileUpdateBody>('SUBMIT_PROFILE');

export const submitEmail = createAction<IUpdateEmailActionPayload>('UPDATE_EMAIL');

export const confirmAccount = createAction<string>('CONFIRM_ACCOUNT');

export const confirmEmail = createAction<ExtendedApi.IConfirmEmailPayload>('CONFIRM_EMAIL');

export const resetPassword = createAction<IResetPasswordPayload>('RESET_PASSWORD');

export const registerUser = createAction<ExtendedApi.IRegistrationRequestBody>('REGISTER_USER');

export const requestResetPassword = createAction<ExtendedApi.IRequestResetPasswordRequestBody>('REQUEST_RESET_PASSWORD');

export const deleteBook = createAction<string>('DELETE_BOOK');
