/**
 * Created by lkarmelo on 13.05.2019.
 */

import {ExtendedStore} from '../store';

export const getObjectCard = (card: ExtendedStore.IObjectCard, checkContentCode: number): ExtendedStore.IObjectCard => ({
    ...card,
    isContentAvailable: checkContentCode === 200
});
