/**
 * Created by lkarmelo on 06.08.2019.
 */

import React, { useMemo } from 'react';
import {useState} from 'react';
import classNames from 'classnames';

import BaseInputFormField from '../BaseInput';
import { InputFieldMode } from 'app/components/common/form-fields';

import IProps from './interfaces/IPasswordFormFieldProps';

import * as styles from './PasswordFormField.scss';

const PasswordFormField: React.FC<IProps> = ({children, mode, className, ...rest}) => {
    const [isVisible, setIsVisible] = useState(false);
    const eyeButtonClassName = useMemo(
        () => mode === InputFieldMode.MATERIAL ?
            styles.formFieldPasswordToggleMaterial : styles.formFieldPasswordToggleRegular,
        [mode]
    );

    return (
        <BaseInputFormField
            {...rest}
            mode={mode}
            type={isVisible ? 'text' : 'password'}
            className={classNames(styles.formFieldPassword, className)}
        >
            <button
                type={'button'}
                className={`btn ${styles.formFieldPasswordToggle} ${eyeButtonClassName}`}
                onClick={() => setIsVisible(prevIsVisible => !prevIsVisible)}
                title={'Показать пароль'}
            >
                <span
                    className={classNames({
                        'icon-eye': isVisible,
                        'icon-eye-mini': !isVisible,
                    })}
                />
            </button>
        </BaseInputFormField>
    );
};

export default PasswordFormField;
