/**
 * Created by lkarmelo on 01.03.2019.
 */
import {useCallback} from 'react';

export const useOnSelectToggle = (onSelect: Function, onDeselect: Function, isActive: boolean) =>
    useCallback(
        () => {
            if (isActive) {
                onDeselect && onDeselect();
            } else {
                onSelect && onSelect();
            }
        },
        [isActive]
    );
