/**
 * Created by lkarmelo on 25.02.2019.
 */

import {DependencyList, useEffect} from 'react';

export const useResetWindowScroll = (deps?: DependencyList) => {
    useEffect(
        () => {
            window.scrollTo(0, 0);
        },
        deps
    );
};
