import { Epic } from 'redux-observable';
import { Action } from 'redux-actions';
import { ExtendedStore } from 'common/store';
import { publishDocument, fetchDocument, setDocument } from '../actions/document';
import { apiCalls } from 'common/api';
import {
    publishDocumentRequest, publishDocumentResolve, publishDocumentReject,
    documentRequest, documentResolve, documentReject,
} from '../actions/loading';

import { Observable } from 'rxjs';
import { createSimpleLoadingEpic } from 'nkc-frontend-tools/redux/epics/utils';
import { setFormData, setForm, removeForm } from '../actions/forms/forms';
import { FormName } from 'app/types/FormName';
import { IObjectCard } from 'common/store/ExtendedStore';

import history from 'app/history';
import clientRoutes from 'common/clientRoutes';

export const publishDocumentEpic: Epic<Action<string>, ExtendedStore.IState> = action$ => action$
    .ofType(publishDocument.toString())
    .exhaustMap(({ payload }) =>
        apiCalls.publishDocument(payload)
            .mapTo(publishDocumentResolve())
            .do(() => {
                history.push(clientRoutes.uploads.getUrl());
            })
            .catch(e => {
                console.error(e);
                return Observable.of(publishDocumentReject(e));
            })
            .startWith(publishDocumentRequest())
    );

export const loadDocumentEpic = createSimpleLoadingEpic({
    triggers: [fetchDocument.toString()],
    apiCallName: 'loadDocument' as any,
    actions: {
        requestAction: documentRequest,
        resolveAction: documentResolve,
        rejectAction: documentReject,
        setAction: setDocument
    },
    transformResponse: (response: IObjectCard) => {
        const documentLoadedKnowledgeCategories = response?.documentSnippet?.document?.knowledgeCategories ?? [];
        const knowledgeCategories = documentLoadedKnowledgeCategories.length > 0
            ? documentLoadedKnowledgeCategories
            : response?.documentSnippet?.categories;

        const document = response?.documentSnippet?.document ?? {};
        const projectNumbers = document.meta?.issueInfo?.projectNumber;
        const projectNumber = Array.isArray(projectNumbers) ? projectNumbers[0] : undefined;

        return {
            ... response?.documentSnippet?.document,
            knowledgeCategories,
            meta: {
                ... document.meta,
                issueInfo: {
                    ...document.meta.issueInfo,
                    projectNumber
                }
            }
        };
    }
});

export const setDocumentEpic: Epic<Action<any>, ExtendedStore.IState> = action$ => action$
    .ofType(setDocument.toString())
    .mergeMap(({ payload }) =>
        Observable.of(!!payload
            ? setFormData(FormName.AddEditDocumentForm, { ...payload })
            : removeForm(FormName.AddEditDocumentForm)))
    .catch(e => {
        console.error(e);
        return Observable.of(
            setForm(FormName.AddEditDocumentForm, e, ExtendedStore.FormStatus.Rejected)
        );
    });
