/**
 * Created by lkarmelo on 21.08.2019.
 */

import React from 'react';

import Filters from './FavoriteFilters';

import {ExtendedStore} from 'common/store';
import clientRoutes from 'common/clientRoutes';
import {createContextBookList, createContextBookListFilters} from 'app/components/common/ContextBookList';
import {ROOT_BREADCRUMB_NAME} from 'app/utils/constants';

import * as styles from './Favorite.scss';

const ContextFilters = createContextBookListFilters(ExtendedStore.BookSearchContext.Favorite, Filters);
const FavoriteContextBookList = createContextBookList(ExtendedStore.BookSearchContext.Favorite, ContextFilters);

const pageTitle = 'Избранное';

const breadcrumbs = [
    {
        link: clientRoutes.main.getUrl(),
        title: ROOT_BREADCRUMB_NAME
    },
    {
        link: '#',
        title: 'Личный кабинет'
    },
    {
        link: clientRoutes.favorite.getUrl(),
        title: pageTitle
    }
];

const Favorite: React.FC = () => (
    <FavoriteContextBookList
        title={pageTitle}
        breadcrumbsPath={breadcrumbs}
        className={styles.favorite}
    />
);

export default Favorite;
