/**
 * Created by lkarmelo on 18.02.2019.
 */

import React from 'react';
import {useEffect, useMemo} from 'react';

import BreadCrumbs from 'nkc-frontend-tools/components/BreadCrumbs';
import BookDetails from 'app/components/common/BookDetails';
import RelatedBooks from 'app/components/pages/book/RelatedBooks';

import {useResetWindowScroll} from 'app/hooks/useResetWindowScroll';

import {ROOT_BREADCRUMB_NAME} from 'app/utils/constants';
import {FilterName} from 'common/types/FilterName';

import clientRoutes from 'common/clientRoutes';

import {IBookProps as IProps} from './interfaces/IBookProps';

import * as styles from './Book.scss';

//можно не использовать мемоизацию, потому что если компонент ререндерится, то значит и путь почти во всех случаях тоже должен поменяться
const getBreadcrumbsPath = ({book, typeTitles}: IProps) => {
    const path = [];

    if (!book || Object.keys(book).length === 0) {
        return path;
    }

    path.push({
        title: ROOT_BREADCRUMB_NAME,
        link: clientRoutes.main.getUrl()
    });

    if (!book.documentSnippet || !book.documentSnippet.document) {
        return path;
    }

    const document = book.documentSnippet.document;
    const meta = document.meta;

    meta && meta.type && path.push({
        title: typeTitles && typeTitles[meta.type] ? typeTitles[meta.type] : meta.type,
        link: clientRoutes.catalog.getUrl(
            {},
            {
                masterFilterName: FilterName.PublicationType,
                masterFilterValue: meta.type as string,
                [FilterName.PublicationType]: meta.type as string
            }
        )
    });

    path.push({
        title: document.title  || '',
        link: document.id ? clientRoutes.book.getUrl({id: document.id}) : '#'
    });

    return path;
};

const Book: React.FunctionComponent<IProps> = (props: IProps) => {
    const {match, loadBook, resetBook, book} = props;
    const similar = book && book.similar;
    const childrenBooks = book && book.children;
    const parent = book && book.parent;
    const bookId = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.id;

    const hasChildrenBooks = Array.isArray(childrenBooks) && childrenBooks.length > 0;

    useEffect(
        () => {
            if (!book || !book.preloadFromServer || bookId !== match.params.id) {
                loadBook(match.params.id);
            }

            return resetBook;
        },
        [match.params.id]
    );

    useResetWindowScroll([match.params.id]);

    const paths = getBreadcrumbsPath(props);

    const similarBooks = useMemo(
        () => Array.isArray(similar) ? similar.map(s => s.documentSnippet) : undefined,
        [similar]
    );
    const parentBook = useMemo(
        () => parent ? [parent] : undefined,
        [parent]
    );

    return (
        <section className={styles.bookPage}>
            <div className={styles.bookPageBreadcrumbs}>
                <BreadCrumbs
                    paths={paths}
                    delimiter={<i className={'icon-arrow-right'}/> as any} // todo: внести изменения в nkc-frontend-tools
                />
            </div>
            <div className={styles.bookPageBookDetails}>
                <BookDetails book={book} />
            </div>
            {parent &&
                <div className={styles.bookPageParent}>
                    <RelatedBooks title={'Документ опубликован'} books={parentBook} />
                </div>
            }
            {hasChildrenBooks &&
                <div className={styles.bookPageChildren}>
                    <RelatedBooks title={'Содержание'} books={childrenBooks} />
                </div>
            }
            {!hasChildrenBooks && similarBooks &&
                <React.Fragment>
                    {(Array.isArray(similarBooks) && similarBooks.length > 0) &&
                        <div className={styles.bookPageDelimiter} />
                    }
                    <div className={styles.bookPageSimilars}>
                        <RelatedBooks title={'Похожие материалы'} books={similarBooks} />
                    </div>
                </React.Fragment>
            }
        </section>
    );
};

export default Book;
