/**
 * Created by Lkarmelo on 08.11.2017.
 */

import {MiddlewareAPI} from 'redux';

import {Observable} from 'rxjs/Observable';
import {concat} from 'rxjs/observable/concat';
import {History} from 'history';

import {LoadingActionsProvider} from 'nkc-frontend-tools/redux/actions/loading';

import {
    LOG_IN,
    LOG_OUT,
    logInFail,
    logInSuccess,
    refreshAuthenticator,
    refreshAuthenticatorFailed,
    refreshAuthenticatorSuccess,
    setAuthorized,
} from 'nkc-frontend-tools/redux/actions/authorization';

import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';
import {Api} from 'nkc-frontend-tools/api';

import {CONFIRM_AUTHENTICATION, confirmAuthenticationSuccess} from '../actions/authorization';

import {ActionMeta} from 'redux-actions';
import {fetchUserData} from 'nkc-frontend-tools/redux/actions/user';
import {clearUserData, setUserPermissions} from '../actions/user';

import queryString from 'qs';
import {logoutReject, logoutRequest, logoutResolve} from '../actions/loading';
import {closePopup} from '../actions/popups';
import {PopupName} from 'app/types/PopupName';
import clientRoutes from 'common/clientRoutes';

const { logInRequest, logInResolve, logInReject } = LoadingActionsProvider.loadingActions;
const { logOutOnUnAuthActionCreators, logInOnAuthActionCreators } = LoadingActionsProvider.actionLists;

export const logoutOnUnauthorizedError = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>
) =>
    action$.ofType(...logOutOnUnAuthActionCreators.map(a => a.toString()))
        .filter(action => action.error && action.payload.status === 401)
        .mergeMap(action => Observable.empty());

export const logIn = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    { apiCall }: { apiCall: ExtendedApi.ApiCalls & Api.ApiCalls }
) =>
    action$.ofType(LOG_IN)
        .mergeMap(action =>
            concat(
                (<Api.ApiCalls>apiCall).logIn(action.payload.identifier, action.payload.password, action.payload.rememberMe)
                    .map(response => {
                        if (response.response.access === 'GRANTED') {
                            return setAuthorized(true);
                        } else {
                            throw new Error('No auth token');
                        }
                    })
                    .catch(e => {
                        console.error(e);

                        return Observable.of(logInFail(), logInReject(e));
                    }),
                Observable.of(confirmAuthenticationSuccess(), logInResolve())
            )
                .takeUntil(action$.ofType(logInReject.toString()))
                .startWith(logInRequest())
        );

export const getLogOutEpic = (history: History, redirectUrl?: string) =>
(
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    { apiCall }: { apiCall: ExtendedApi.ApiCalls & Api.ApiCalls }
) =>
        action$.ofType(LOG_OUT)
            .filter(() => store.getState().authorization.isAuthorized)
            .mergeMap(() =>
                    (<Api.ApiCalls>apiCall).logOut()
                        .do(() => {
                            if (!!redirectUrl) {
                                history.push(redirectUrl);
                            } else {
                                history.push(clientRoutes.main.getUrl());
                                history.goBack();
                            }
                        })
                        .mergeMap(() =>
                            Observable.of(
                                setAuthorized(false),
                                clearUserData(),
                                logoutResolve()
                            ))
                        .catch(e => {
                            console.error(e);
                            return Observable.of(logoutReject(e));
                        })
                        .takeUntil(action$.ofType(LOG_OUT, logoutReject.toString()))
                        .startWith(logoutRequest())
            );

export const confirmAuthenticationEpic = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    { apiCall }: { apiCall: ExtendedApi.ApiCalls & Api.ApiCalls }
) =>
    action$.ofType(CONFIRM_AUTHENTICATION)
        .mergeMap((action: ActionMeta<string, ExtendedStore.IAuthOptions>) => {
            return apiCall.confirmAuthentication(action.payload)
                .do(response => {
                    // close popup window
                    if (!!action.meta.closeWindow && response.response.access === 'GRANTED') {
                        window.close();
                    }
                })
                .ignoreElements()
                .catch(e => {
                    console.error(e);
                    return Observable.of(logInFail());
                });
        });

export const confirmAuthenticationSuccessEpic = (action$) =>
    action$.ofType(confirmAuthenticationSuccess.toString())
        .mergeMap((action) => {
            return concat(
                Observable.of(fetchUserData()),
                Observable.of(action)
                    .switchMap(() => action$.ofType(setUserPermissions.toString()).take(1))
                    //тут закрываем и логин и регистрацию, потому что авторизоваться можно из окна регистрации через социальные сети
                    .mergeMap(() => Observable.of(logInSuccess(), closePopup([PopupName.Login, PopupName.Registration])))
            );
        });

export const getRedirectToPreviousPageOnLoginSuccessEpic = (
    history: History,
    defaultRedirectUrl: string,
    queryStringParseOptions: any,
) =>
    (action$, store: MiddlewareAPI<ExtendedStore.IState>) =>
        action$.ofType(logInSuccess.toString())
            .filter(() => location.pathname.startsWith(clientRoutes.login.getUrl()))
            .do(() => {
                let params = queryString.parse(location.search, queryStringParseOptions);

                let newUrl = params.backUrl ? decodeURIComponent(params.backUrl) : defaultRedirectUrl;

                if (newUrl.startsWith('/admin')) {
                    // use native redirect from React to external link
                    window.location.href = newUrl;
                } else {
                    history.push(newUrl);
                }
            })
            .ignoreElements();

export const authorizeOnFirstRequestSuccess = (action$, store: MiddlewareAPI<ExtendedStore.IState>) =>
    action$.ofType(...logInOnAuthActionCreators.map(a => a.toString()))
        .filter(action =>
            !store.getState().authorization.isAuthorized
            && (
                (!action.error && !action.payload)
                || (action.error && action.payload.status !== 401)
            )
        )
        .mapTo(setAuthorized(true))
        .take(1);

export const refreshAuthenticatorEpic = (action$, store: MiddlewareAPI<ExtendedStore.IState>, { apiCall }) =>
    action$.ofType(refreshAuthenticator.toString())
        .exhaustMap(() =>
            (<Api.ApiCalls>apiCall).refreshAuthenticator()
                .mergeMap((response) => {
                    if (response.response.access === 'GRANTED') {
                        return Observable.of(
                            setAuthorized(true),
                            refreshAuthenticatorSuccess()
                        );
                    }

                    return Observable.of(
                        setAuthorized(false),
                        refreshAuthenticatorFailed()
                    );
                })
                .catch(e => {
                    console.error(e);
                    if (e.status === 401) {
                        return Observable.of(
                            setAuthorized(false),
                            refreshAuthenticatorFailed()
                        );
                    }
                    return Observable.of(refreshAuthenticatorFailed());
                })
        );
