import React, { useMemo, useEffect, useState, useCallback } from 'react';
import IProps, { ConfirmationStatus } from './interfaces/IConfirmEmailChangeProps';

import { useFormSenderHelper } from 'app/hooks/forms';
import { Link, Router } from 'react-router-dom';

import clientRoutes, { AccountActionQueryParams } from 'common/clientRoutes';
import RFBRVerticalLogo from 'app/components/common/RFBRVertivalLogo';

import * as styles from './ConfirmEmailChange.scss';

const ConfirmEmailChange: React.FC<IProps> = (props) => {
    const {
        formStatus,
        confirmRequest,
        refreshUser,
        resetForm,
        location,
        history
    } = props;

    const { emailType, token } = props.match.params;

    const [confirmationStatus, setConfirmationStatus] = useState<ConfirmationStatus>(ConfirmationStatus.PENDING);

    useEffect(
        () => {
            token ? confirmRequest(emailType, token) : setConfirmationStatus(ConfirmationStatus.ERROR);
        },
        [token]
    );

    const onSuccess = useCallback(
        () => {
            refreshUser();
            setConfirmationStatus(ConfirmationStatus.SUBMITED);
        },
        [setConfirmationStatus, refreshUser]
    );

    const onCompleted = useCallback(() => setConfirmationStatus(ConfirmationStatus.SUCCESS), [setConfirmationStatus]);
    const onFail = useCallback(() => setConfirmationStatus(ConfirmationStatus.ERROR), [setConfirmationStatus]);

    useFormSenderHelper(
        formStatus,
        resetForm,
        onSuccess,
        onFail,
        undefined,
        undefined,
        onCompleted
    );

    if (confirmationStatus === ConfirmationStatus.PENDING) {
        return <div className={styles.confirmAccountPageLoading}>Загрузка...</div>;
    }

    if (confirmationStatus === ConfirmationStatus.SUBMITED) {
        return <div className={styles.confirmAccountPageLoading}>Обновление данных...</div>;
    }

    return (
        <div className={styles.confirmAccountPage}>
            <Link
                to={clientRoutes.main.getUrl()}
                className={styles.confirmAccountPageLogo}
            >
                <RFBRVerticalLogo />
            </Link>
            <h1 className={styles.confirmAccountPageTitle}>
                {
                    confirmationStatus === ConfirmationStatus.SUCCESS ?
                        'Ваш email успешно подтвержден!' : 'Ваш email не подтвержден'
                }
            </h1>
        </div>
    );
};

export default ConfirmEmailChange;
