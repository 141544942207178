import { ISubscriptionActionProps } from './interfaces/ISubscriptionProps';
import { subscribeToAllMailingLists } from 'app/redux/actions/subscriptions';
import { connect } from 'react-redux';
import Subscription from './Subscription';

const mapDispatchToProps = (dispatch): ISubscriptionActionProps => ({
    subscribe() {
        dispatch(subscribeToAllMailingLists());
    }
});

export default connect(null, mapDispatchToProps)(Subscription);
