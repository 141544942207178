/**
 * Created by lkarmelo on 05.08.2019.
 */

import React from 'react';
import {useCallback, useMemo} from 'react';
import classNames from 'classnames';

import {useEditablePanelControls, styles as editablePanelStyles} from 'app/hooks/useEditablePanel';
import {useFormSenderHelper} from 'app/hooks/forms';

import PasswordChangeForm from '../Form';

import IProps from './interfaces/IPasswordChangeProps';

import * as styles from './PasswordChange.scss';

const PasswordChange: React.FC<IProps> = ({formResponse, save, formStatus, resetFormStatus}) => {
    const [editablePaneControlsRenderer, editingState] = useEditablePanelControls();
    const [isEditing, setIsEditing] = editingState;

    const onSendSuccess = useCallback(() => setIsEditing(false), []);

    const onFail = useCallback(
        () => {
            if (!formResponse || !formResponse.message) {
                return;
            }

            if (formResponse.message.startsWith('400')) {
                return 'Неправильный текущий пароль';
            }

            return;
        },
        [formResponse]
    );

    const formSubmitCbRef = useFormSenderHelper(formStatus, resetFormStatus, onSendSuccess, onFail);

    const onSubmit = useCallback(
        (values, _, cb) => {
            save(values);
            formSubmitCbRef.current = cb;
        },
        [save]
    );

    return (
        <div
            className={classNames(
                styles.passwordChange,
                {[styles.passwordChangeEditing]: isEditing}
            )}
        >
            {isEditing ?
                <PasswordChangeForm
                    renderControls={editablePaneControlsRenderer}
                    onSubmit={onSubmit}
                />
                :
                <button
                    className={'btn-edit'}
                    onClick={() => setIsEditing(true)}
                >
                    ИЗМЕНИТЬ ПАРОЛЬ
                </button>
            }
        </div>
    );
};

export default PasswordChange;
