/**
 * Created by lkarmelo on 19.03.2019.
 */

import React from 'react';
import {useMemo} from 'react';
import queryString from 'qs';

import CatalogFilters from './CatalogFilters';

import {useResetWindowScroll} from 'app/hooks/useResetWindowScroll';

import {parseOptions} from 'app/utils/queryStringOptions';
import {ROOT_BREADCRUMB_NAME} from 'app/utils/constants';

import clientRoutes from 'common/clientRoutes';

import IProps from './interfaces/ICatalogProps';

import {createContextBookList, createContextBookListFilters} from 'app/components/common/ContextBookList';
import {ExtendedStore} from 'common/store';

const ContextFilters = createContextBookListFilters(ExtendedStore.BookSearchContext.Catalog, CatalogFilters);
const CatalogContextBookList = createContextBookList(ExtendedStore.BookSearchContext.Catalog, ContextFilters);

const Catalog: React.FunctionComponent<IProps> = (props) => {
    const {location, sectionTitles} = props;

    const currentSection: {title: string; link: string; filterName: string; filterValue: string; } = useMemo(
        () => {
            const {masterFilterName: filterName, masterFilterValue: filterValue} = queryString.parse(location.search, parseOptions);
            const emptySection = {title: '', link: '', filterName: '', filterValue: ''};

            if (!filterName || !filterValue || !sectionTitles) {
                return emptySection;
            }

            return {
                title: sectionTitles[filterValue] ? sectionTitles[filterValue] : filterValue,
                link: `${location.pathname}${location.search}`,
                filterName,
                filterValue,
            };
        },
        [location.search, sectionTitles]
    );

    const breadCrumbsPaths = useMemo(
        () => {
            return [
                {
                    title: ROOT_BREADCRUMB_NAME,
                    link: clientRoutes.main.getUrl()
                },
                {
                    title: currentSection.title,
                    link: currentSection.link
                }
            ];
        },
        [currentSection.title, currentSection.link]
    );

    useResetWindowScroll([currentSection.filterName, currentSection.filterValue]);

    return <CatalogContextBookList title={currentSection.title} breadcrumbsPath={breadCrumbsPaths} />;
};

export default Catalog;
