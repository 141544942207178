/**
 * Created by lkarmelo on 26.08.2019.
 */

import React from 'react';
import {Link} from 'react-router-dom';

import clientRoutes from 'common/clientRoutes';

export const FavoriteAddedNotification: React.FC = () => (
    <span>
        Материал добавлен в <Link to={clientRoutes.favorite.getUrl()}>Избранное</Link>
    </span>
);

export const FavoriteRemovedNotification: React.FC = () => (
    <span>
        Материал удален из <Link to={clientRoutes.favorite.getUrl()}>Избранного</Link>
    </span>
);

export const PasswordChangeNotification: React.FC = () => (
    <span>
        Пароль успешно изменен
    </span>
);

export const EmailChangeNotification: React.FC = () => (
    <span>
        Ваш Email был изменен
    </span>
);

export const EmailConfirmSentNotification: React.FC = () => (
    <span>
        На ваш Email высланно подтверждение
    </span>
);

export const SubscribeToAllNewsNotification: React.FC = () => (
    <span>
        Подписка успешно оформлена. <Link to={clientRoutes.subscriptions.getUrl()}>Подписки</Link>
    </span>
);

export const BookmarkAddedNotification: React.FC = () => (
    <span>
        Страница добавлена в закладки
    </span>
);

export const DocumentAddNotification: React.FC = () => (
    <span>
        Новое издание добавлено
    </span>
);

export const DocumentUpdateNotification: React.FC = () => (
    <span>
        Издание обновлёно
    </span>
);

export const DocumentContentNotUploadedNotification: React.FC = () => (
    <span>
        Необходимо загрузить файл оригинала материала
    </span>
);
