/**
 * Created by Lkarmelo on 05.09.2017.
 */
import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';

import { FETCH_USER_DATA, setUserData, refreshUser, fetchUserData } from 'nkc-frontend-tools/redux/actions/user';

import { LoadingActionsProvider } from 'nkc-frontend-tools/redux/actions/loading';
import {
    setAuthorized,
    refreshAuthenticatorSuccess,
    refreshAuthenticatorFailed
} from 'nkc-frontend-tools/redux/actions/authorization';

import { createSimpleLoadingEpic } from 'nkc-frontend-tools/redux/epics/utils';

import { ExtendedStore } from 'common/store';
import { ExtendedApi } from 'common/api';
import { Api } from 'nkc-frontend-tools/api';
import { fetchUserPermissions, setUserPermissions, fetchUserSocial, setUserSocial } from '../actions/user';
import { setProfile } from '../actions/profile';
import { refreshUserResolve, refreshUserReject, refreshUserRequest } from '../actions/loading';

const { userReject, userRequest, userResolve } = LoadingActionsProvider.loadingActions;

export const loadUser = createSimpleLoadingEpic({
    triggers: [FETCH_USER_DATA],
    apiCallName: 'userData',
    actions: {
        requestAction: userRequest,
        resolveAction: userResolve,
        rejectAction: userReject,
        setAction: setUserData,
        spawnOnSuccess: [() => setAuthorized(true), (response) => setProfile(response), fetchUserPermissions, fetchUserSocial]
    }
});

export const loadUserPermissions = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    { apiCall }: { apiCall: ExtendedApi.ApiCalls & Api.ApiCalls }
) =>
    action$.ofType(fetchUserPermissions.toString())
        .switchMap(() =>
            apiCall.userPermissions()
                .map(({ response }) => setUserPermissions(response))
                .catch(e => {
                    console.error(e);
                    return Observable.empty();
                })
        );

export const loadUserSocial = (action$, _, { apiCall }: { apiCall: ExtendedApi.ApiCalls & Api.ApiCalls }) =>
    action$.ofType(fetchUserSocial.toString())
        .switchMap(() =>
            apiCall.userSocial()
                .map(({ response }) => setUserSocial(response))
                .catch(e => {
                    console.error(e);
                    return Observable.empty();
                })
        );

export const refreshUserEpic = (action$, store: MiddlewareAPI<ExtendedStore.IState>, { apiCall }) =>
    action$.ofType(refreshUser.toString())
        .exhaustMap(() =>
            apiCall.refreshUser()
                .mergeMap((response) => {
                    if (response.response.access === 'GRANTED') {
                        return Observable.of(
                            fetchUserData(),
                            refreshUserResolve(),
                            refreshAuthenticatorSuccess()
                        );
                    }

                    return Observable.of(
                        refreshUserResolve(),
                        setAuthorized(false),
                        refreshAuthenticatorFailed()
                    );
                })
                .catch(e => {
                    console.error(e);
                    if (e.status === 401) {
                        return Observable.of(
                            refreshUserReject(e),
                            setAuthorized(false),
                            refreshAuthenticatorFailed()
                        );
                    }
                    return Observable.of(refreshAuthenticatorFailed());
                })
                .startWith(refreshUserRequest())
        );
