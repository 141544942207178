import {deleteBook} from '../../actions/forms/formsSendActions';

import {deleteBookReject, deleteBookRequest, deleteBookResolve} from '../../actions/loading';

import {FormName} from 'app/types/FormName';
import {createFormSendEpic} from './createFormSendEpic';

export const deleteBookEpic = createFormSendEpic({
    triggers: [deleteBook.toString()],
    formName: FormName.ConfirmDeleteBook,
    actions: {
        requestAction: deleteBookRequest,
        resolveAction: deleteBookResolve,
        rejectAction: deleteBookReject
    }
});
