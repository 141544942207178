
/**
 * Created by lkarmelo on 06.02.2019.
 */

import {Store} from '../../nkc-frontend-tools/types';

import apiCalls from './apiCalls';
import {ExtendedStore} from '../store';

export type ApiCalls = typeof apiCalls;

export interface IShowcaseDocumentsResponseBody {
    lastCreatedPopScience?: ExtendedStore.IDocumentSnippet[];
    lastCreatedMaterials?: ExtendedStore.IDocumentSnippet[];
    lastBulletins?: {
        TechnicalBulletin?: ExtendedStore.IDocumentSnippet[];
        SocialBulletin?: ExtendedStore.IDocumentSnippet[];
    };
}

export interface IObjectCardResponseBody {
    similar?: ExtendedStore.ISimilarity[];
    documentSnippet?: ExtendedStore.IDocumentSnippet;
    children?: ExtendedStore.IDocumentSnippet[];
    parent?: ExtendedStore.IDocumentSnippet;
}

export interface IFilter {
    autoOrdering: boolean;
    code: string;
    isMultiSelect: boolean;
    name: string;
    type: string;
    value: {
        values: {
            code: string;
            displayValue: string;
            parent: string;
            type: string;
        }[];
    };
}

export interface IFiltersParamsResponseBody {
    filters: IFilter[];
    params: IFilter[];
}

export type LibraryStatsResponseBody = Store.IAggregation[];

export interface IProfileUpdateBody {
    firstName: string;
    middleName?: string;
    lastName: string;
}

export interface IEmailUpdateBody {
    email: string;
}

export interface ILoginUpdateBody {
    email: string;
}

export interface IConfirmEmailPayload {
    emailType: string;
    token: string;
}

export interface IPasswordChangeBody  {
    oldPassword: string;
    newPassword: string;
    confirmation: string;
}

export const enum UserActionStatus {
    TOKEN_NOT_FOUND = 'token.not.found',
    TOKEN_INVALID = 'token.invalid',
    TOKEN_EXPIRED = 'token.expired',
    USER_NOT_FOUND = 'user.not.found'
}

export interface IResetPasswordResponseBody {
    success?: 'password.changed';
    status?: UserActionStatus;
}

export interface IRegistrationRequestBody {
    email: string;
    password: string;
}

export interface IRegistrationResponseBody {
    login: string;
}

export interface IRequestResetPasswordRequestBody {
    email: string;
}

export interface IRequestResetPasswordResponseBody {
    login: string;
}

export enum SubscriptionType {
    NEWS = 'news',
    MATERIAL_TYPE = 'materialType',
    STORAGE_SOURCE = 'storageSource',
    CATEGORY = 'category'
}

export interface ISubscriptionIdentifier {
    type: SubscriptionType;
    value?: string;
}

export interface ISubscription {
    identifier: ISubscriptionIdentifier;
    created: number;
    unreadCount: number;
}

export interface INotificationSetting {
    notificationType: string;
    sendToUserPage: boolean;
    sendToEmail: boolean;
}

export interface INotification {
    id: string;
    isRead: boolean;
    notificationType: string;
    date: number; // millis
    content: string; // HTML
}

export interface INotificationsResponseBody {
    totalCount: number;
    unreadCount: number;
    notifications: INotification[];
}

export interface INotificationsRequestParams {
    skip: number;
    limit: number;
}

export interface IPerson {
    id: string;
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
}
