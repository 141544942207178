/**
 * Created by lkarmelo on 04.10.2019.
 */
import {connect} from 'react-redux';

import Reader from './Reader';
import {IReaderActionProps, IReaderStateProps} from './interfaces/IReaderProps';

import {
    requestAddBookmark, readerNextPage, readerPrevPage,
    requestRemoveBookmark, resetReader, setReaderBookScale,
    fetchBookmarks, setReaderCurrentPageNumber
} from 'app/redux/actions/bookReader';

import {ExtendedStore} from 'common/store';

const mapStateToProps = (state: ExtendedStore.IState): IReaderStateProps => ({
    pageContent: state.bookReader.pages.currentPage,
    currentPageNumber: state.bookReader.currentPageNumber,
    bookMeta: state.bookReader.meta,
    bookmarks: state.bookReader.bookmarks,
});

const mapDispatchToProps = (dispatch): IReaderActionProps => ({
    setPage(page: number): void {
        dispatch(setReaderCurrentPageNumber(page));
    },
    prevPage(): void {
        dispatch(readerPrevPage());
    },
    nextPage(): void {
        dispatch(readerNextPage());
    },
    resetReader(): void {
        dispatch(resetReader());
    },
    setPageScale(scale: number): void {
        dispatch(setReaderBookScale(scale));
    },
    addBookmark(bookId: string, page: number, title: string): void {
        dispatch(requestAddBookmark({bookId, page, title}));
    },
    removeBookmark(bookId: string, page: number): void {
        dispatch(requestRemoveBookmark({bookId, page}));
    },
    fetchBookmarks(bookId: string): void {
        dispatch(fetchBookmarks(bookId));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Reader);
