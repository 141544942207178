/**
 * Created by lkarmelo on 06.05.2019.
 */
import {createAction} from 'redux-actions';

import {ExtendedStore} from 'common/store';

export const fetchLibraryStats = createAction('FETCH_LIBRARY_STATS');

export const setLibraryStats = createAction<ExtendedStore.ILibraryStats>('SET_LIBRARY_STATS');
