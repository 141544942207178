/**
 * Created by Lkarmelo on 25.01.2018.
 */

import {retryOnRejectEpic} from './retryOnRejectEpic';

import { MiddlewareAPI } from 'redux';

import { Store } from '../../../types';
import { merge } from 'rxjs/observable/merge';
import { BaseActionFunctions } from 'redux-actions';

type Triggers = BaseActionFunctions<any>[] | BaseActionFunctions<any> | string | string[];

export interface ISecuredActionEpicWrapper {
    action: Triggers;
    resolve: Triggers;
    reject: Triggers;
}

const toString = (triggers: Triggers): string[] => {
    let aTriggers = Array.isArray(triggers) ? triggers : [triggers];
    return aTriggers.map((trigger: BaseActionFunctions<any> | string) => trigger.toString());
};

const createSecuredEpics = (securedActions: ISecuredActionEpicWrapper[]) => {

    const securedActionEpics = securedActions.map((actionEpicWrapper: ISecuredActionEpicWrapper) => {
        const {action, resolve, reject} = actionEpicWrapper;

        return retryOnRejectEpic(toString(action), toString(resolve), toString(reject));
    });

    return (
        action$,
        store: MiddlewareAPI<Store.IBaseState>,
        {apiCall}
    ) => {

        return merge(... (securedActionEpics.map((epic) => epic(action$, store))));
    };
};

export default createSecuredEpics;
