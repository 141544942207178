/**
 * Created by lkarmelo on 18.02.2019.
 */

import {handleActions, Action} from 'redux-actions';

import {setObjectCard} from 'nkc-frontend-tools/redux/actions/objectCard';
import {ISetLikeStatusPayload, setLikeState} from 'app/redux/actions/like';

import {ExtendedStore} from 'common/store';

import { setShareStatus, ISetShareStatusPayload } from '../actions/share';
import {IFavoriteActionPayload, setFavoriteState} from '../actions/favorite';
import {updateSnippetContainerShare, updateSnippetContainerLike, updateSnippetContainerFavorite} from './utils/updateDocummentSnippet';

const updateFieldsWithDocumentSnippets = (state: ExtendedStore.IObjectCard, updater: Function) => {
    if (!state) {
        return state;
    }
    return {
        ...state,
        documentSnippet: updater(state.documentSnippet),
        similar: updater(state.similar),
        children: updater(state.children),
        parent: updater(state.parent),
    };
};

export default handleActions<ExtendedStore.IObjectCard, any>(
    {
        [setObjectCard.toString()]: (_, {payload}: Action<ExtendedStore.IObjectCard>): ExtendedStore.IObjectCard => payload,

        [setLikeState.toString()]: (
            state: ExtendedStore.IObjectCard,
            {payload: {nextLikeState, id: bookId}}: Action<ISetLikeStatusPayload>
        ):  ExtendedStore.IObjectCard =>
            updateFieldsWithDocumentSnippets(
                state,
                field => updateSnippetContainerLike(field, bookId, nextLikeState)
            ),

        [setShareStatus.toString()]: (
            state: ExtendedStore.IObjectCard,
            {payload: {shareState, id: bookId}}: Action<ISetShareStatusPayload>
        ):  ExtendedStore.IObjectCard =>
            updateFieldsWithDocumentSnippets(
                state,
                field => updateSnippetContainerShare(field, bookId, shareState)
            ),

        [setFavoriteState.toString()]: (
            state: ExtendedStore.IObjectCard,
            {payload: {favoriteState, bookId}}: Action<IFavoriteActionPayload>
        ): ExtendedStore.IObjectCard =>
            updateFieldsWithDocumentSnippets(
                state,
                field => updateSnippetContainerFavorite(field, bookId, favoriteState)
            )
    },
    {}
);
