/**
 * Created by lkarmelo on 19.03.2019.
 */

import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {filtersMapDispatchToProps} from './filtersMapDispatchToProps';

import IFiltersProps, {IFiltersActionProps} from './interfaces/IFiltersProps';
import DefaultFiltersComponent from './Filters';

import {dispatchWithContext} from 'app/redux/context/dispatchWithContext';
import {getFiltersMapStateToProps} from './getFiltersMapStateToProps';

import {ExtendedStore} from 'common/store';

const getContextMapDispatchToProps = (context: ExtendedStore.BookSearchContext) =>
    (defaultDispatch) => {
        const contextDispatch = dispatchWithContext(defaultDispatch, context);
        const mappedDispatch = filtersMapDispatchToProps(contextDispatch);

        return (): IFiltersActionProps => ({...mappedDispatch});
    };

export const createContextBookListFilters = (
    context: ExtendedStore.BookSearchContext,
    FiltersComponent: React.ComponentType<IFiltersProps> = DefaultFiltersComponent,
    filtersSelector?
) => {
    const mapStateToProps = getFiltersMapStateToProps(context, filtersSelector);
    const mapDispatchToProps = getContextMapDispatchToProps(context);

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(FiltersComponent));
};
