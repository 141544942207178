/**
 * Created by lkarmelo on 23.09.2019.
 */

import React from 'react';
import classNames from 'classnames';

import Modal from 'app/components/common/modals/Modal';

import {IMessageModalContentProps, IMessageModalProps} from './interfaces/IMessageModalProps';

import * as styles from './MessageModal.scss';

export const MessageModalContent: React.FC<IMessageModalContentProps> = ({header, children, btnText, onBtnClick}) => (
    <div>
        <div className={styles.messageModalHeader}>{header}</div>
        <div className={styles.messageModalText}>{children}</div>
        <div className={styles.messageModalControls}>
            <button className={'btn-primary'} onClick={onBtnClick}>{btnText}</button>
        </div>
    </div>
);

const MessageModal: React.FC<IMessageModalProps> = (props) => {
    const {header, children, btnText, onBtnClick, className, ...modalProps} = props;

    return  (
        <Modal className={classNames(className, styles.messageModal)} {...modalProps}>
            <div className={styles.messageModalContentContainer}>
                <MessageModalContent header={header} btnText={btnText} onBtnClick={onBtnClick} >
                    {children}
                </MessageModalContent>
            </div>
        </Modal>
    );
};

export default MessageModal;
