import {Route} from 'react-router-dom';
import React from 'react';

/**
 * Created by lkarmelo on 22.08.2018.
 */

const RouteWithSubRoutes: React.SFC<any> = ({component: Component, subRoutes, ...rest}) => (
    <Route
        {...rest}
        render={props => Component ? <Component {...props}>{subRoutes}</Component> : null}
    />
);

export default RouteWithSubRoutes;
