import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';

import IProps from './interfaces/ILoadingBarProps';
import * as styles from './LoadingBar.scss';

const LoadingBar: React.FC<IProps> = (props) => {
    const { className, valueClassName, progressRatio } = props;

    const progressValueElementRef = useRef<HTMLDivElement>(null);

    useEffect(
        () => {
            if (progressValueElementRef.current) {
                progressValueElementRef.current.style.width = `${100 * progressRatio}%`;
            }
        },
        [progressRatio]
    );

    return (
        <div className={classNames(className, styles.minorLoadingBar)}>
            <div
                ref={progressValueElementRef}
                className={classNames(
                    valueClassName,
                    styles.minorLoadingBarValue
                )}
            />
        </div>
    );
};

export default LoadingBar;
