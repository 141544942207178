import {createAction} from 'redux-actions';
import {IShareStat} from 'common/store/ExtendedStore';

export interface ISharePayload {
    id: string;
    provider: string;
}

export interface ISetShareStatusPayload {
    id: string;
    shareState: IShareStat[];
}

export const shareDocument = createAction<ISharePayload>('SHARE_DOCUMENT');
export const setShareStatus = createAction<ISetShareStatusPayload, string, IShareStat[]>(
    'SET_SHARE_STATUS',
    (id: string, shareState: IShareStat[]): ISetShareStatusPayload => ({id, shareState})
);
