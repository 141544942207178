import React, { useMemo, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import clientRoutes from 'common/clientRoutes';
import { ROOT_BREADCRUMB_NAME } from 'app/utils/constants';
import PageHeader from 'app/components/common/PageHeader';

import IProps from './interfaces/INotificationsProps';
import * as styles from './Notifications.scss';

const pageTitle = 'Уведомления';

const breadcrumbsBase = [
    {
        link: clientRoutes.main.getUrl(),
        title: ROOT_BREADCRUMB_NAME
    },
    {
        title: 'Личный кабинет'
    },
    {
        title: pageTitle
    }
];

const breadcrumbsSettings = [
    {
        link: clientRoutes.main.getUrl(),
        title: ROOT_BREADCRUMB_NAME
    },
    {
        link: clientRoutes.notifications.getUrl(),
        title: 'Личный кабинет',
    },
    {
        link: clientRoutes.notifications.getUrl(),
        title: pageTitle
    },
    {
        title: 'Настройки уведомлений'
    }
];

const Notifications: React.FC<IProps> = (props) => {
    const {
        location,
        fetchNotificationTypes,
        fetchNotificationSettings,
        children
    } = props;

    useEffect(
        () => {
            fetchNotificationTypes();
            fetchNotificationSettings();
        },
        []
    );

    const isSettingsOpen = useMemo(
        () => location.pathname === clientRoutes.notificationSettings.getUrl(),
        [location.pathname]
    );

    // нужен только чтобы скидывать фокус с кнопки при возврате назад из настроек
    const settingsLinkRef = useRef<HTMLAnchorElement>(null);

    const breadcrumbs = useMemo(
        () => isSettingsOpen ?
            breadcrumbsSettings : breadcrumbsBase,
        [location.pathname]
    );

    return (
        <div className={styles.notificationsPage}>
            <PageHeader
                breadcrumbsPath={breadcrumbs}
                header={pageTitle}
            />
            <div className={styles.notificationsPageContent}>
                <Link
                    className={classNames(
                        styles.notificationsPageSettingsLink,
                        { [styles.notificationsPageSettingsLinkVisited]: isSettingsOpen }
                    )}
                    to={clientRoutes.notificationSettings.getUrl()}
                    innerRef={ref => {
                        settingsLinkRef.current = ref;
                    }}
                    onClick={isSettingsOpen ? e => e.preventDefault() : undefined}
                >
                    <i className={classNames('icon-settings', styles.notificationsPageSettingsIcon)}/>&#32;
                    <span className={styles.notificationsPageSettingsText}>Настройки уведомлений</span>
                </Link>
                {children}
            </div>
        </div>
    );
};

export default Notifications;
