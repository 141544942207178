import { createFormSendEpic } from './createFormSendEpic';
import { confirmAccount } from 'app/redux/actions/forms/formsSendActions';
import { FormName } from 'app/types/FormName';
import { confirmAccountRequest, confirmAccountReject, confirmAccountResolve } from 'app/redux/actions/loading';

export const confirmAccountEpic = createFormSendEpic({
    triggers: [confirmAccount.toString()],
    formName: FormName.ConfirmAccount,
    actions: {
        requestAction: confirmAccountRequest,
        resolveAction: confirmAccountResolve,
        rejectAction: confirmAccountReject
    }
});
