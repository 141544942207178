/**
 * Created by lkarmelo on 06.05.2019.
 */
import {createSimpleLoadingEpic} from 'nkc-frontend-tools/redux/epics/utils';

import {fetchLibraryStats, setLibraryStats} from 'app/redux/actions/libraryStats';
import {libraryStatsRequest, libraryStatsReject, libraryStatsResolve} from 'app/redux/actions/loading';

export const loadLibraryStats = createSimpleLoadingEpic({
    triggers: [fetchLibraryStats.toString()],
    apiCallName: 'libraryStats' as any,
    actions: {
        setAction: setLibraryStats,
        requestAction: libraryStatsRequest,
        rejectAction: libraryStatsReject,
        resolveAction: libraryStatsResolve
    }
});
