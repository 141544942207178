import React, { useState, useCallback, useRef, useEffect } from 'react';
import classNames from 'classnames';
import ImageCrop from 'react-avatar-editor';

import Modal from 'app/components/common/modals/Modal';
import Slider from 'app/components/common/Slider';
import { UPLOAD_COVER_WIDTH, UPLOAD_COVER_HEIGHT } from 'app/utils/constants';
import { clampNumber } from 'app/utils/clampNumber';

import IProps from './interfaces/ICropImagePopupProps';
import * as styles from './CropImagePopup.scss';

const CropImagePopup: React.FC<IProps> = (props) => {
    const { isVisible, onSave, onClose, onError, image } = props;

    const imageCropRef = useRef<ImageCrop>(null);
    const [scale, setScale] = useState(1);

    useEffect(
        () => {
            isVisible && setScale(1);
        },
        [isVisible]
    );

    const onSaveButtonClick = useCallback(
        () => {
            const canvas = imageCropRef.current?.getImageScaledToCanvas();
            canvas && onSave(canvas);
        },
        [onSave]
    );

    const onSliderChange = useCallback(
        (value: number) => {
            setScale(value);
        },
        []
    );

    return (
        <Modal
            className={classNames(styles.cropImagePopup)}
            centered={false}
            showCross={true}
            isVisible={isVisible}
            close={onClose}
        >
            <h2 className={styles.cropImagePopupTitle}>
                Настройка обложки
            </h2>
            <div className={styles.cropImagePopupImageBox}>
                <ImageCrop
                    className={styles.cropImagePopupCanvas}
                    ref={imageCropRef}
                    image={image}
                    width={UPLOAD_COVER_WIDTH}
                    height={UPLOAD_COVER_HEIGHT}
                    border={[220, 122]}
                    scale={scale}
                    color={[21, 42, 58, .5]}
                    onLoadFailure={onError}
                />
                <div className={styles.cropImagePopupScaleControl}>
                    <button
                        className={classNames('btn', styles.cropImagePopupMinus)}
                        onClick={() => { setScale(clampNumber(scale - .15, .5, 2)); }}
                    >
                        -
                    </button>
                    <Slider
                        className={styles.cropImagePopupScaleSlider}
                        min={.5}
                        max={2}
                        step={.01}
                        value={scale}
                        onChange={onSliderChange}
                    />
                    <button
                        className={classNames('btn', styles.cropImagePopupPlus)}
                        onClick={() => { setScale(clampNumber(scale + .15, .5, 2)); }}
                    >
                        +
                    </button>
                </div>
            </div>
            <div className={styles.cropImagePopupButtons}>
                <button
                    className={classNames('btn-primary', styles.cropImagePopupSaveBtn)}
                    onClick={onSaveButtonClick}
                >
                    Кадрировать и сохранить
                </button>
                <button
                    className={'btn-cancel'}
                    onClick={onClose}
                >
                    Отменить
                </button>
            </div>
        </Modal>
    );
};

export default CropImagePopup;
