/**
 * Created by Lkarmelo on 08.11.2017.
 */

import {createAction} from 'redux-actions';

export const SET_AUTHORIZED = 'SET_AUTHORIZED';
export const setAuthorized = createAction<boolean>(SET_AUTHORIZED);

export const refreshAuthenticator = createAction('REFRESH_AUTHENTICATOR');
export const refreshAuthenticatorSuccess = createAction('REFRESH_AUTHENTICATOR_SUCCESS');
export const refreshAuthenticatorFailed = createAction('REFRESH_AUTHENTICATOR_FAILED');

export interface ILogInPayload {
    identifier: string;
    password: string;
    rememberMe: boolean;
}
export const LOG_IN = 'LOG_IN';
export const logIn =
    createAction<ILogInPayload, string, string>(
        LOG_IN,
        (identifier: string, password: string, rememberMe: boolean = true) => ({identifier, password, rememberMe})
    );

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const logInSuccess = createAction(LOG_IN_SUCCESS);

export const LOG_IN_FAIL = 'LOG_IN_FAIL';
export const logInFail = createAction(LOG_IN_FAIL);

export const LOG_OUT = 'LOG_OUT';
export const logOut = createAction(LOG_OUT);

export const RECOVERY_PASSWORD_FAIL = 'RECOVERY_PASSWORD_FAIL';
export const recoveryPasswordFail = createAction<string>( RECOVERY_PASSWORD_FAIL );

export const PASSWORD_RECOVERY = 'PASSWORD_RECOVERY';
export const passwordRecovery = createAction<string>( PASSWORD_RECOVERY );

export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const resetPasswordFail = createAction<string>( RESET_PASSWORD_FAIL );

export interface IResetPasswordPayload {
    token: string;
    password: string;
}
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const resetPassword = createAction<IResetPasswordPayload, string, string>(
    RESET_PASSWORD,
    (token: string, password: string) => ({token, password})
);
