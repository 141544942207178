/**
 * Created by lkarmelo on 19.08.2019.
 */

import React, {useCallback, useMemo} from 'react';
import classNames from 'classnames';

import NameInfoForm from '../Form';

import {useEditablePanelControls} from 'app/hooks/useEditablePanel';
import {useFormSenderHelper} from 'app/hooks/forms';

import {INameInfoValues} from '../Form';
import IProps from './interfaces/INameInfoProps';

import * as styles from './NameInfo.scss';
import { IProfile } from 'common/store/ExtendedStore';

const getUserFullName = (firstName?: string, middleName?: string, lastName?: string): string => {
    let fullName = '';
    lastName && (fullName += lastName);
    firstName && (fullName += ` ${firstName}`);
    middleName && (fullName += ` ${middleName}`);

    return fullName;
};

const NameInfo: React.FC<IProps> = ({profile, save, formStatus, resetForm}) => {
    const {firstName, middleName, lastName} = profile || {firstName: '', middleName: '', lastName: ''};

    const fullName = useMemo(
        () => getUserFullName(firstName, middleName, lastName),
        [profile]
    );

    const [editablePanelControlsRenderer, editingState] = useEditablePanelControls();
    const [isEditing, setIsEditing] = editingState;

    const onSendSuccess = useCallback(() => setIsEditing(false), []);

    const formSubmitCbRef = useFormSenderHelper(formStatus, resetForm, onSendSuccess);

    const onFormSubmit = useCallback(
        (values: INameInfoValues, _, cb) => {
            formSubmitCbRef.current = cb;
            save(values);
        },
        [save]
    );
    const formInitialValues = useMemo(() => ({firstName, lastName, middleName}), [profile]);

    return (
        <div
            className={classNames(
                styles.nameInfo,
                {[styles.nameInfoEditing]: isEditing}
            )}
        >
            {isEditing ?
                <NameInfoForm
                    onSubmit={onFormSubmit}
                    renderControls={editablePanelControlsRenderer}
                    initialValues={formInitialValues}
                />
                :
                <>
                    <div className={styles.nameInfoNameEditContainer}>
                        <div className={`field-label`}>ФИО</div>
                        <button
                            className={'btn-edit'}
                            onClick={() => setIsEditing(true)}
                        >
                            изменить
                        </button>
                    </div>
                    <div>{fullName}</div>
                </>
            }
        </div>
    );
};

export default NameInfo;
