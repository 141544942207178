/**
 * Created by Lkarmelo on 08.09.2017.
 */

import {Route} from 'nkc-frontend-tools/routing';

const common = {
    allowDots: true
};

export const stringifyOptions = {
    ...common,
    arrayFormat: 'repeat',
    addQueryPrefix: true,
};

Route.stringifyOptions = stringifyOptions;

export const parseOptions = {
    ...common,
    ignoreQueryPrefix: true,
};
