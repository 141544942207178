/**
 * Created by lkarmelo on 03.07.2019.
 */

import React from 'react';
import {NavLink} from 'react-router-dom';

import clientRoutes from 'common/clientRoutes';

import IProps from './interfaces/ICategoryLinkProps';

const CategoryLink: React.FunctionComponent<IProps> = (props) => {
    const {title, filterName, filterValue, isActive = false, className, activeClassName} = props;
    const url = clientRoutes.catalog.getUrl(
        {},
        {masterFilterValue: filterValue, masterFilterName: filterName, [filterName]: filterValue}
    );
    return (
        <NavLink
            className={className}
            activeClassName={activeClassName}
            isActive={() => isActive}
            to={url}
            title={title}
        >
            {title}
        </NavLink>
    );
};

export default React.memo(CategoryLink);
