/**
 * Created by lkarmelo on 08.11.2019.
 */

import React, {useCallback, useState} from 'react';
import classNames from 'classnames';

import Sidebox from 'app/modules/components/Sidebox';
import 'app/modules/components/Sidebox/Sidebox.scss';

import {ExtendedStore} from 'common/store';

import * as styles from './ReaderBookmarks.scss';

interface IProps {
    bookmarks: ExtendedStore.IBookmark[];
    removeBookmark(bookId: string, page: number): void;
    setPage(page: number): void;
}

const ReaderBookmarks: React.FC<IProps> = (props) => {
    const {bookmarks, removeBookmark, setPage} = props;
    const [isSideboxOpen, setIsSideboxOpen] = useState(false);

    const closeSidebox = useCallback(
        () => setIsSideboxOpen(false),
        []
    );

    return (
        <>
            <button
                className={'btn icon-bookmark-list'}
                onClick={() => setIsSideboxOpen(true)}
                title={'Закладки'}
            />
            <Sidebox
                opened={isSideboxOpen}
                onOutsideContentClick={closeSidebox}
                className={styles.readerBookmarksSidebox}
            >
                <div className={styles.readerBookmarksSideboxContent}>
                    <button
                        className={classNames('btn', 'icon-cross', styles.readerBookmarksCloseBtn)}
                        onClick={closeSidebox}
                    />
                    <div className={styles.readerBookmarksHeader}>
                        <div className={styles.readerBookmarksTitle}>Мои закладки</div>
                    </div>
                    {!bookmarks || bookmarks.length === 0 &&
                        <div className={styles.readerBookmarksPlaceholder}>Здесь будут ваши закладки</div>
                    }
                    <div className={styles.readerBookmarksList}>
                        {Array.isArray(bookmarks) && bookmarks.map(bookmark => (
                            <div className={styles.readerBookmarksBookmark} key={bookmark.page}>
                                <button
                                    className={classNames('btn', styles.readerBookmarksBookmarkTitle)}
                                    onClick={() => {
                                        setPage(bookmark.page);
                                        closeSidebox();
                                    }}
                                >
                                    {bookmark.title}
                                </button>
                                <div className={styles.readerBookmarksBookmarkPage}>
                                    Страница {bookmark.page}
                                </div>
                                <button
                                    className={classNames('btn', 'icon-bin', styles.readerBookmarksBookmarkDeleteBtn)}
                                    onClick={() => removeBookmark(bookmark.bookId, bookmark.page)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Sidebox>
        </>
    );
};

ReaderBookmarks.defaultProps = {
    bookmarks: []
};

export default ReaderBookmarks;
