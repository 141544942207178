/**
 * Created by lkarmelo on 15.08.2019.
 */

import React from 'react';
import classNames from 'classnames';

import Modal, {IModalProps} from '../Modal';

import {Omit} from 'nkc-frontend-tools/types';

import * as styles from './RegularModal.scss';

export interface IRegularModalProps extends Omit<IModalProps, 'showCross' | 'centered'> {
}

const RegularModal: React.FC<IRegularModalProps> = (props) => (
    <Modal
        centered={false}
        showCross={true}
        {...props}
        className={classNames(props.className, styles.regularModal)}
    >
        <div className={styles.regularModalContent}>
            {props.children}
        </div>
    </Modal>
);

export default RegularModal;
