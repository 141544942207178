import SubscriptionPopup from './SubscriptionPopup';
import { ExtendedStore } from 'common/store';
import { ISubscriptionPopupStateProps, ISubscriptionPopupActionProps } from './interfaces/ISubscriptionPopupProps';
import { PopupName } from 'app/types/PopupName';
import { closePopup } from 'app/redux/actions/popups';
import { connect } from 'react-redux';
import { submitEmail } from 'app/redux/actions/forms/formsSendActions';
import { FormName } from 'app/types/FormName';
import { removeForm } from 'app/redux/actions/forms/forms';
import { SubscriptionType } from 'common/api/ExtendedApi';
import { subscribeToMailingList } from 'app/redux/actions/subscriptions';

const mapStateToProps = (state: ExtendedStore.IState): ISubscriptionPopupStateProps => ({
    isVisible: state.popups[PopupName.SubscribeToMailingList],
    emailFormStatus: state.forms[FormName.UpdateEmail] && state.forms[FormName.UpdateEmail].status
});

const mapDispatchToProps = (dispatch): ISubscriptionPopupActionProps => ({
    closePopup() {
        dispatch(closePopup(PopupName.SubscribeToMailingList));
    },
    submitEmail(email: string) {
        dispatch(submitEmail({ email, raiseNotification: false }));
    },
    subscribeToAll() {
        dispatch(subscribeToMailingList({
            subscription: { type: SubscriptionType.NEWS },
            raiseNotification: true
        }));
    },
    resetEmailForm() {
        dispatch(removeForm(FormName.UpdateEmail));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPopup);
