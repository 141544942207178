/**
 * Created by lkarmelo on 06.02.2019.
 */

import {normalize, schema} from 'normalizr';

import {IShowcaseDocumentsResponseBody} from '../api/ExtendedApi';
import {ExtendedStore} from '../store';

export const getShowcaseDocuments = (payload: IShowcaseDocumentsResponseBody): ExtendedStore.IShowcaseDocuments => {
    const documentSchema = new schema.Entity(
        'items',
        undefined,
        {
            idAttribute: (item => item.document.id)
        }
    );

    const dataSchema = {
        lastCreatedPopScience: [documentSchema],
        lastCreatedMaterials: [documentSchema],
        lastBulletins: {
            TechnicalBulletin: documentSchema,
            SocialBulletin: documentSchema,
        },
    };

    const normalizedData = normalize(
        payload,
        dataSchema
    );

    return {
        latest: normalizedData.result.lastCreatedMaterials,
        journalsTechnical: normalizedData.result.lastBulletins.TechnicalBulletin,
        journalsSocial: normalizedData.result.lastBulletins.SocialBulletin,
        popularScience: normalizedData.result.lastCreatedPopScience,
        items: normalizedData.entities.items
    };
};
