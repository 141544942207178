import { IPerson } from 'common/api/ExtendedApi';
import { fieldRequiredError } from 'app/utils/forms/validation';

export const requiredPersonValidation = (fieldValue: IPerson): string | undefined =>
    (!!fieldValue && !!fieldValue.firstName && !!fieldValue.lastName)
        ? undefined
        : fieldRequiredError;

export const requiredPersonBlockValidation = (fieldValue: IPerson[]): string | undefined => {
    if (!Array.isArray(fieldValue) || fieldValue.length === 0) {
        return fieldRequiredError;
    }

    if (fieldValue.findIndex((item: IPerson) => !!requiredPersonValidation(item)) !== -1) {
        return fieldRequiredError;
    }
};
