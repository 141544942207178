/**
 * Created by Lkarmelo on 05.09.2017
 * -----
 * Last Modified: 29.06.2018 11:08:28
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import {ajax} from 'rxjs/observable/dom/ajax';
import {Observable, Subscriber} from 'rxjs';

import * as Api from './Api';
import {Store} from '../types';

import apiRoutes from '../routing/apiRoutes';
import {ParamMap} from '../routing/';
import { IDocument } from 'common/store/ExtendedStore';

//
export {Store};

const headersJson = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

const headersUrlEncoded = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json'
};

export default {
    search: (payload: Api.IGlobalSearchRequestBody) => ajax.post(apiRoutes.globalSearch.getUrl(), payload || {}, headersJson),
    [Store.PersonSearchContext]: (payload: Api.IPersonSearchRequestBody) =>
        ajax.post(apiRoutes.personSearch.getUrl(), payload, headersJson),

    queryExpansion: query => ajax.get(apiRoutes.queryExpansion.getUrl({query}), headersJson),
    objectCard: id => ajax.get(apiRoutes.objectCard.getUrl({id}), headersJson),
    filters: () => ajax.get(apiRoutes.filtersMeta.getUrl(), headersJson),
    params: () => ajax.get(apiRoutes.paramsMeta.getUrl(), headersJson),
    //TODO: убрать захардкоженный лимит, если нужно
    searchHints: (query: string) => ajax.post(apiRoutes.searchHints.getUrl(), {query, limit: 10} , headersJson),

    forgotPassword: login => ajax.post(
        apiRoutes.forgotPassword.getUrl({location: window.location.origin }),
        JSON.stringify({email: login}),
        headersJson),

    resetPassword: (token, password) => ajax.post(
        apiRoutes.resetPassword.getUrl({token}),
        JSON.stringify({password1: password, password2: password}),
        headersJson
    ),

    logIn: (identifier, password, rememberMe) => ajax.post(
        apiRoutes.logIn.getUrl(),
        JSON.stringify({identifier, password, rememberMe}) ,
        headersJson
    ),
    logOut: () => ajax.get( apiRoutes.logOut.getUrl(), headersJson),
    refreshAuthenticator: () => ajax.get( apiRoutes.refreshAuthenticator.getUrl(), headersJson),

    searchByCategory: (categoryCode, payload) => ajax.post(apiRoutes.searchByCategory.getUrl({categoryCode}), payload || {}, headersJson),

    markFavorites: documentId => ajax.post(apiRoutes.markFavorite.getUrl(), { documentId }, headersJson),
    unmarkFavorites: documentId => ajax.delete(apiRoutes.unmarkFavorite.getUrl({documentId}), headersJson),
    markReadLater: documentId => ajax.post(apiRoutes.markReadLater.getUrl(), { documentId }, headersJson),
    unmarkReadLater: documentId => ajax.delete(apiRoutes.unmarkReadLater.getUrl({documentId}), headersJson),

    userData: () => ajax.get(apiRoutes.userData.getUrl(), headersJson),
    userPermissions: () => ajax.get(apiRoutes.userPermissions.getUrl(), headersJson),

    currentUserData: (id) => ajax.get(apiRoutes.currentUserData.getUrl({id}), headersJson),
    currentUserWorkInfo: (id) => ajax.get(apiRoutes.currentUserWorkInfo.getUrl({id}), headersJson),
    currentUserCompetencies: (id) => ajax.get(apiRoutes.currentUserCompetencies.getUrl({id}), headersJson),
    currentUserCategories: (id) => ajax.get(apiRoutes.currentUserCategories.getUrl({id}), headersJson),
    currentUserSkills: (id) => ajax.get(apiRoutes.currentUserSkills.getUrl({id}), headersJson),

    userNotification: () => ajax.get(apiRoutes.userNotification.getUrl(), headersJson),
    userNotificationCount: () => ajax.get(apiRoutes.userNotificationCount.getUrl(), headersJson),
    markNotificationReaded: (messageIds) =>
        ajax.get(apiRoutes.markNotificationReaded.getUrl({}, {messageIds}), headersJson),
    markNotificationUnreaded: (messageIds) =>
        ajax.get(apiRoutes.markNotificationUnreaded.getUrl({}, {messageIds}), headersJson),

    competencies: () => ajax.get(apiRoutes.competencies.getUrl(), headersJson),

    organizations: () => ajax.get(apiRoutes.organizations.getUrl(), headersJson),
    addresses: () => ajax.get(apiRoutes.addresses.getUrl(), headersJson),
    posts: () => ajax.get(apiRoutes.posts.getUrl(), headersJson),
    subdivisions: () => ajax.get(apiRoutes.subdivisions.getUrl(), headersJson),

    skills: () => ajax.get(apiRoutes.skills.getUrl(), headersJson),
    createSkill: (title: string) => ajax.post(apiRoutes.createSkill.getUrl(), `"${title}"`, headersJson),

    catalogueCategories: () => ajax.get(apiRoutes.catalogueCategories.getUrl(), headersJson),
    catalogueCategory: (code: string) => ajax.get(apiRoutes.catalogueCategory.getUrl({code}), headersJson),

    educationMaterialTypes: () => ajax.get(apiRoutes.educationMaterialTypes.getUrl(), headersJson),
    numberOfEducationMaterials: () => ajax.get(apiRoutes.numberOfEducationMaterials.getUrl(), headersJson),

    orgStructureWithEmployees: () => ajax.get(apiRoutes.orgStructureWithEmployees.getUrl(), headersJson),

    changeEducationMaterialStatus: (documentId: string, payload: Api.IChangeEducationMaterialStatus) => ajax.post(
        apiRoutes.changeEducationMaterialStatus.getUrl({documentId}),
        payload,
        headersJson
    ),
    resetEducationMaterialStatus: (documentId: string) =>
        ajax.delete(apiRoutes.resetEducationMaterialStatus.getUrl({documentId}), headersJson),

    recommendations: (params: Api.IRecommendationsRequestParameters) =>
        ajax.get(apiRoutes.recommendations.getUrl({}, <ParamMap>params), headersJson),

    userIdByLogin: ({login, source: sourceId}: {login: string; source: string}) =>
        ajax.get(apiRoutes.userIdByLogin.getUrl({login, sourceId}, ), headersJson),

    uploadFile: (path: string, files: File[], progressSubscriber: Subscriber<any>) => {
        let fd = new FormData();
        fd.append('location', path);
        files.forEach((file, idx) => {
            fd.append(`file${idx}`, file );
        });

        let conf = {
            'Accept': 'application/json'
            // cancelToken: CancelCallback.token,
            // onUploadProgress: onProgress
        };

        return Observable.ajax({
            url: apiRoutes.uploadFile.getUrl({path}),
            method: 'POST',
            body: fd,
            async: true,
            headers: conf,
            progressSubscriber
        });
    },
    processFile: (file: string) => ajax.post(apiRoutes.processFile.getUrl({fid: file})),
    deleteFile: (file: string) => ajax.delete(apiRoutes.deleteFile.getUrl({fid: file})),
    downloadFile: (file: string) => ajax.get(apiRoutes.downloadFile.getUrl({fid: file})),
    listFile: (path?: string) => ajax.get(apiRoutes.listFile.getUrl({location: path}))
};
