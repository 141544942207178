/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {Observable} from 'rxjs/Observable';
import {concat} from 'rxjs/observable/concat';
import {MiddlewareAPI, Dispatch} from 'redux';
import {merge} from 'rxjs/observable/merge';

import {FETCH_FILTERS_META, fetchFiltersMeta, setFiltersMeta} from 'nkc-frontend-tools/redux/actions/search/filtersMeta';
import {setDefaultFilters} from 'nkc-frontend-tools/redux/actions/search/defaultFilters';
import {LoadingActionsProvider} from 'nkc-frontend-tools/redux/actions/loading';

import {retryOnAuthorizedEpic} from 'nkc-frontend-tools/redux/epics/utils';

import {getFiltersMeta} from 'common/api-response-payload-mutators/getFiltersMeta';
import {ExtendedApi} from 'common/api';
import {getActionWithAllContexts} from 'app/redux/context/getActionWithAllContexts';
import {withIgnoreSearchActionMeta} from 'nkc-frontend-tools/redux/actions/utils/withIgnoreSearchActionMeta';
import {initialFiltersToDefaults} from 'app/redux//actions/search/filters';

const {filtersMetaReject, filtersMetaRequest, filtersMetaResolve} = LoadingActionsProvider.loadingActions;

export const loadFiltersMeta = (action$, _, {apiCall}) =>
    merge(
        retryOnAuthorizedEpic(action$, filtersMetaReject.toString(), fetchFiltersMeta)
        ,
        action$.ofType(FETCH_FILTERS_META)
            .hasContext(false)
            .mergeMap(() =>
                concat(
                    apiCall.filters()
                        .mergeMap(({response}: {response: ExtendedApi.IFiltersParamsResponseBody}) => {
                            const filterMap = getFiltersMeta(response);

                            return Observable.of(
                                setFiltersMeta(filterMap),
                                setDefaultFilters(filterMap),
                                ...getActionWithAllContexts(withIgnoreSearchActionMeta(initialFiltersToDefaults()), false)
                            );
                        })
                        .catch(e => {
                            console.error(e);
                            return Observable.of(filtersMetaReject(e));
                        }),
                    Observable.of(filtersMetaResolve())
                )
                    .takeUntil(action$.ofType(filtersMetaReject.toString()))
                    .startWith(filtersMetaRequest()),
        )
    );
