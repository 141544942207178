import { createAction } from 'redux-actions';
import { INotificationSetting, INotificationsResponseBody, INotificationsRequestParams } from 'common/api/ExtendedApi';

export const pollNotifications = createAction('POLL_NOTIFICATIONS');
export const stopPollingNotifications = createAction('STOP_POLLING_NOTIFICATIONS');

export const setNotificationsRequestParams = createAction<INotificationsRequestParams>('SET_NOTIFICATIONS_REQUEST_PARAMS');

export const fetchNotifications = createAction('FETCH_NOTIFICATIONS');
export const setNotifications = createAction<INotificationsResponseBody>('SET_NOTIFICATIONS');

export const markNotificationsAsRead = createAction<string[]>('MARK_NOTIFICATIONS_AS_READ');

export const fetchNotificationTypes = createAction('FETCH_NOTIFICATION_TYPES');
export const setNotificationTypes = createAction<Record<string, string>>('SET_NOTIFICATION_TYPES');

export const fetchNotificationSettings = createAction('FETCH_NOTIFICATION_SETTINGS');
export const setNotificationSettings = createAction<INotificationSetting[]>('SET_NOTIFICATION_SETTINGS');

export const saveNotificationSetting = createAction<INotificationSetting>('SAVE_NOTIFICATION_SETTING');
export const setNotificationSetting = createAction<INotificationSetting>('SET_NOTIFICATION_SETTING');
