/**
 * Created by lkarmelo on 26.03.2019.
 */

import * as Cookies from 'common/cookies/Cookies';

import {DEFAULT_PAGING_ITEMS_LIMIT, PREVIEW_PAGING_ITEMS_LIMIT} from './constants';

export const getDocumentsLimitByViewMode = (view: Cookies.ViewModeCookie): number => {
    if (view === 'preview') {
        return PREVIEW_PAGING_ITEMS_LIMIT;
    }
    return DEFAULT_PAGING_ITEMS_LIMIT;
};
