/**
 * Created by lkarmelo on 06.08.2019.
 */

import React from 'react';
import {Form, Field} from 'react-final-form';

import {PasswordFormField, InputFieldMode} from 'app/components/common/form-fields';

import {requiredStringValidation, getPasswordsMatchValidator, oneOfValidation} from 'app/utils/forms/validation';

import IProps from './interfaces/IPasswordChangeFormProps';

import * as styles from './PasswordChangeForm.scss';
import { submitEmail } from 'app/redux/actions/forms/formsSendActions';

const passwordsMatchValidation = getPasswordsMatchValidator('password1');

const PasswordChangeForm: React.FC<IProps> = ({onSubmit, renderControls}) => {
    return (
        <Form onSubmit={onSubmit}>
            {({handleSubmit, submitting, errors, submitErrors}) => {
                const formHasErrors = errors && Object.keys(errors).length > 0;
                return (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.passwordChangeFormTitle}>
                            Изменение пароля
                        </div>
                        <div className={styles.passwordChangeFormOldPass}>
                            <div className={styles.passwordChangeFormFieldWrapper}>
                                 <Field name={'current'} validate={requiredStringValidation}>
                                    {({input, meta}) =>
                                        <div className={'styles.personalInfoFormFieldWrapper'}>
                                            <PasswordFormField
                                                {...input}
                                                mode={InputFieldMode.MATERIAL}
                                                label={'Старый пароль'}
                                                disabled={submitting}
                                                autoComplete={'current-password'}
                                                error={meta.touched ? meta.error : undefined}
                                            />
                                        </div>
                                    }
                                </Field>
                            </div>
                        </div>
                        <div className={styles.passwordChangeFormNewPassConfirmBlock}>
                            <div className={styles.passwordChangeFormFieldWrapper}>
                                <Field name={'password1'} validate={requiredStringValidation}>
                                    {({input, meta}) =>
                                        <div className={'styles.personalInfoFormFieldWrapper'}>
                                            <PasswordFormField
                                                {...input}
                                                mode={InputFieldMode.MATERIAL}
                                                label={'Новый пароль'}
                                                disabled={submitting}
                                                autoComplete={'new-password'}
                                                error={meta.touched ? meta.error : undefined}
                                            />
                                        </div>
                                    }
                                </Field>
                            </div>
                            <div className={styles.passwordChangeFormFieldWrapper}>
                                <Field name={'password2'} validate={oneOfValidation(requiredStringValidation, passwordsMatchValidation)}>
                                    {({input, meta}) =>
                                        <div>
                                            <PasswordFormField
                                                {...input}
                                                mode={InputFieldMode.MATERIAL}
                                                label={'Подтверждение пароля'}
                                                disabled={submitting}
                                                autoComplete={'new-password'}
                                                error={meta.touched ? meta.error : undefined}
                                            />
                                        </div>
                                    }
                                </Field>
                            </div>
                        </div>
                        {submitErrors &&
                            <div className={'form-field-error'}>
                                {submitErrors}
                            </div>
                        }
                        {renderControls(handleSubmit, formHasErrors || submitting, submitting)}
                    </form>
                );
            }}
        </Form>
    );
};

export default PasswordChangeForm;
