import React from 'react';

import IProps from './interfaces/ILibraryStatProps';
import * as styles from './LibraryStat.scss';

const LibraryStat: React.FC<IProps> = ({libraryStat}) => {
    return (
        <div className={styles.libraryStat}>
            <div className={styles.libraryStatSectionWrapper}>
                <div className={styles.libraryStatSection}>
                    <div className={styles.libraryStatNumber}>{libraryStat.rfbrBooks}</div>
                    <div className={styles.libraryStatText}>Книг, изданных при поддержке РФФИ</div>
                </div>
            </div>
            <div className={styles.libraryStatSectionWrapper}>
                <div className={styles.libraryStatSection}>
                    <div className={styles.libraryStatNumber}>{libraryStat.popularScienceArticles}</div>
                    <div className={styles.libraryStatText}>научно-популярных статей</div>
                </div>
            </div>
            <div className={styles.libraryStatSectionWrapper}>
                <div className={styles.libraryStatSection}>
                    <div className={styles.libraryStatNumber}>{libraryStat.annotationReports}</div>
                    <div className={styles.libraryStatText}>аннотированных отчетов</div>
                </div>
            </div>
            <div className={styles.libraryStatSectionWrapper}>
                <div className={styles.libraryStatSection}>
                    <div className={styles.libraryStatNumber}>{libraryStat.authors}</div>
                    <div className={styles.libraryStatText}>авторов</div>
                </div>
            </div>
        </div>
    );
};

export default LibraryStat;
