/**
 * Created by lkarmelo on 04.03.2019.
 */

import {connect} from 'react-redux';

import SearchPanel from './SearchPanel';
import {ISearchPanelActionProps as IActionProps, ISearchPanelStateProps as IStateProps} from './interfaces/ISearchPanelProps';

import {fetchSearchResults} from 'app/redux/actions/search/results';
import {setQuery} from 'nkc-frontend-tools/redux/actions/search/searchQuery';
import {setFilterValue} from 'nkc-frontend-tools/redux/actions/search/filters';
import {withIgnoreSearchActionMeta} from 'nkc-frontend-tools/redux/actions/utils/withIgnoreSearchActionMeta';
import {setLimit} from 'nkc-frontend-tools/redux/actions/search/searchPaging';

import clientRoutes from 'common/clientRoutes';
import {ExtendedStore} from 'common/store';
import {fetchHints, setHints} from 'app/redux/actions/search/hints';

import {getDefaultLimitFromCookie} from 'app/utils/getDefaultLimitFromCookie';
import {StaticFilterName} from 'common/types/FilterName';

import {BookSearchContext} from 'common/store/ExtendedStore';
import {dispatchWithContext} from '../../redux/context/dispatchWithContext';

const mapStateToProps = (state: ExtendedStore.IState): IStateProps => ({
    query: state[BookSearchContext.Search].searchQuery,
    hints: state[BookSearchContext.Search].hints,
});

const mapDispatchToProps = (defaultDispatch, _): IActionProps => {
    const dispatch = dispatchWithContext(defaultDispatch, BookSearchContext.Search);

    return {
        fetchSearchResults(query: string): void {
            if (query === undefined || query === null) {
                dispatch(setQuery(''));
            }
            if (!query) {
                dispatch(withIgnoreSearchActionMeta(setFilterValue(StaticFilterName.Sort, ExtendedStore.SortFilterValues.Date)));
            } else {
                dispatch(withIgnoreSearchActionMeta(
                    setFilterValue(StaticFilterName.Sort, ExtendedStore.SortFilterValues.Relevancy)
                ));
            }
            dispatch(setLimit(getDefaultLimitFromCookie()));
            dispatch(fetchSearchResults(true, clientRoutes.search.getUrl()));
        },
        setQuery(nextQuery: string): void {
            dispatch(setQuery(nextQuery));
        },
        fetchHints(query: string): void {
            dispatch(fetchHints(query));
        },
        clearHints(): void {
            dispatch(setHints({ queries: [], persons: [] }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPanel);
