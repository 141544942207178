/**
 * Created by lkarmelo on 13.03.2019.
 */

import React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';

import Popover from 'antd/es/popover';
import {Link} from 'react-router-dom';
import BookDetails, {IBookDetailsProps, ClsNames, Events} from 'app/components/common/BookDetails';

import {useEventListener} from 'app/hooks/useEventListener';

import {MAX_AUTHORS_UNTIL_TOOLTIP_PREVIEW} from 'app/utils/constants';
import {getAuthorsOrEditor} from 'app/utils/getAuthorsOrEditor';
import clientRoutes from 'common/clientRoutes';

import IProps from './interfaces/IPreviewProps';

import * as styles from './Preview.scss';

const renderDetailsBody = (props: IBookDetailsProps, clsNames: ClsNames): JSX.Element => {
    const {book, renderAnnotation} = props;
    const id = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.id;
    return (
        <div className={clsNames.bookDetailsBody}>
            {renderAnnotation(props, clsNames)}
            {typeof id === 'string' &&
                <Link
                    to={clientRoutes.book.getUrl({id})}
                    className={styles.previewReadMore}
                >
                    Читать далее
                </Link>
            }
        </div>
    );
};

const renderDetailsRightColumn = (props: IBookDetailsProps, clsNames: ClsNames): JSX.Element => (
    <div className={clsNames.bookDetailsRightColumn}>
        {props.renderAuthorsRow(props, clsNames)}
        {props.renderHeader(props, clsNames)}
        {props.renderStats(props, clsNames)}
        {props.renderDocumentType(props, clsNames)}
        {props.renderCategories(props, clsNames)}
        {props.renderMaterialType(props, clsNames)}
        {props.renderSocialActions(props, clsNames)}
    </div>
);

const MoreAuthorsPopover: React.FunctionComponent<{authors: string[], clsNames: ClsNames}> = (props) => {
    const {authors, clsNames} = props;

    const [isVisible, setIsVisible] = useState(false);

    typeof document !== 'undefined' && useEventListener(
        document,
        'scroll',
        () => {
            isVisible && setIsVisible(false);
        },
        undefined,
        [isVisible]
    );

    const onVisibleChange = useCallback(
        (visible: boolean) => setIsVisible(visible),
        []
    );

    const content = useMemo(
        () => (
            <div className={styles.previewMoreAuthors}>
                {authors
                    .slice(MAX_AUTHORS_UNTIL_TOOLTIP_PREVIEW)
                    .map((author, i) => (
                        <div key={`${author}-${i}`}>
                            <Link to={''} className={`${clsNames.bookDetailsAuthor} ${styles.previewExtraAuthor}`}>
                                {author}
                            </Link>
                        </div>
                    ))
                }
            </div>
        ),
        [authors]
    );

    return (
        <Popover
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
            visible={isVisible}
            onVisibleChange={onVisibleChange}
            content={content}
        >
            <button className={`${styles.previewMoreAuthorsTrigger} btn`}>
                ЕЩЁ {authors.length - MAX_AUTHORS_UNTIL_TOOLTIP_PREVIEW}
            </button>
        </Popover>
    );
};

const renderDetailsAuthorNames = ({book}: IBookDetailsProps, clsNames: ClsNames) => {
    const authors = book && getAuthorsOrEditor(book.documentSnippet);
    if (!Array.isArray(authors) || authors.length === 0) {
        return null;
    }

    return (
        <div className={clsNames.bookDetailsAuthorNames}>
            {authors
                .slice(0, MAX_AUTHORS_UNTIL_TOOLTIP_PREVIEW)
                .map((author, i) => (
                    <div key={`${author}-${i}`}>
                        <Link to={''} className={clsNames.bookDetailsAuthor}>
                            {author}
                        </Link>
                    </div>
                ))
            }
            {authors.length > MAX_AUTHORS_UNTIL_TOOLTIP_PREVIEW &&
                <MoreAuthorsPopover authors={authors} clsNames={clsNames} />
            }
        </div>
    );
};

const renderBookDetails = (props: IBookDetailsProps, clsNames: ClsNames, events: Events): JSX.Element => {
    const {isMocked: isSkeleton, renderRightColumn, renderLeftColumn, renderBody, className} = props;
    return (
        <article
            className={classNames(
                className,
                clsNames.bookDetails,
                {[clsNames.bookDetailsSkeleton]: isSkeleton}
            )}
        >
            {renderLeftColumn(props, clsNames, events)}
            {renderRightColumn(props, clsNames)}
            {renderBody(props, clsNames)}
        </article>
    );
};

const renderDetailsHeader = ({book}: IBookDetailsProps, clsNames: ClsNames): JSX.Element => {
    const id = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.id;
    const title = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.title || '';
    return (
        <header>
            <h1 className={clsNames.bookDetailsTitle}>
                {id ?
                    <Link to={clientRoutes.book.getUrl({id})}>
                        {title}
                    </Link>
                    :
                    <React.Fragment>
                        {title}
                    </React.Fragment>
                }
            </h1>
        </header>
    );
};

const Preview: React.FunctionComponent<IProps> = (props) => {
    const {bookId, loadBook, resetBook, book, onLoadingBookStart, onLoadingBookEnd} = props;

    useEffect(
        () => {
            if (typeof bookId !== 'string') {
                return;
            }
            loadBook(bookId);

            return resetBook;
        },
        [bookId]
    );

    return (
        <div className={styles.preview}>
            <BookDetails
                book={book}
                renderRightColumn={renderDetailsRightColumn}
                renderBody={renderDetailsBody}
                renderAuthorNames={renderDetailsAuthorNames}
                renderHeader={renderDetailsHeader}
                onLoadingBookStart={onLoadingBookStart}
                onLoadingBookEnd={onLoadingBookEnd}
            >
                {renderBookDetails}
            </BookDetails>
        </div>
    );
};

export default Preview;
