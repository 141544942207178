enum PermissionKey {
    viewBook = 1,
    viewLkLink,
    viewAdministrationLink,
    viewDocumentRegistratorLink,

    viewShareBookButton,
    viewLikeBookButton,
    viewFavoriteBookButton,

    viewDownloadBookButton,
    viewReadBookButton,

    editBook,
    removeBook,

    subscribeToMailingList
}

export default PermissionKey;
