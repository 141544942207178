/**
 * Created by Lkarmelo on 08.11.2017.
 */

import {createAction} from 'redux-actions';

import { ExtendedStore } from 'common/store';

export const CONFIRM_AUTHENTICATION = 'CONFIRM_AUTHENTICATION';

export const confirmAuthenticationSuccess = createAction('CONFIRM_AUTHENTICATION_SUCCESS');

export const confirmAuthenticationInPopup = createAction<string, ExtendedStore.IAuthOptions>(
    CONFIRM_AUTHENTICATION,
    (accessToken: string) => accessToken,
    () => ({closeWindow: true})
);

export const confirmAuthenticationOnLogin = createAction<string, ExtendedStore.IAuthOptions>(
    CONFIRM_AUTHENTICATION,
    (accessToken: string) => accessToken,
    () => ({})
);
