import { IDocumentPreviewActionProps } from './interfaces/IDocumentPreviewProps';
import { publishDocument } from 'app/redux/actions/document';
import { connect } from 'react-redux';
import DocumentPreview from './DocumentPreview';

const mapDispatchToProps = (dispatch): IDocumentPreviewActionProps => ({
    publishDocument(id: string) {
        dispatch(publishDocument(id));
    },
});

export default connect(null, mapDispatchToProps)(DocumentPreview);
