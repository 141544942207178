import {Observable, ObservableInput} from 'rxjs/Observable';
import {Action} from 'redux-actions';
import {MiddlewareAPI, Dispatch} from 'redux';

import {Store} from '../../../types';
import {setUserPermissions} from '../../actions/user';

export const waitForSetPermissions = (
    action$,
    originalAction: Action<any>,
    store: MiddlewareAPI<Store.IBaseState>
): ObservableInput<Action<any>> => {
    const state = store.getState();
    if (state.user && state.user.permissions) {
        return Observable.of(originalAction);
    }
    return action$.ofType(setUserPermissions.toString())
        .mapTo(originalAction)
        .take(1);
};
