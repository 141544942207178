/**
 * Created by lkarmelo on 08.04.2019.
 */

import {connect} from 'react-redux';

import BookSnippet from './BookSnippet';

import {ExtendedStore} from 'common/store';
import {IBookSnippetStateProps, IBookSnippetActionProps} from './interfaces/IBookSnippetProps';

import {filtersValueToLabelSelector} from 'app/redux/selectors/filtersValueToLabelSelector';
import {requestFavoriteStateChange} from 'app/redux/actions/favorite';

const mapStateToProps = (state: ExtendedStore.IState): IBookSnippetStateProps => ({
    categoryTitles: filtersValueToLabelSelector(state),
});

const mapDispatchToProps = (dispatch): IBookSnippetActionProps => ({
    changeFavoriteStatus(bookId: string, nextFavoriteStatus: boolean) {
        dispatch(requestFavoriteStateChange(bookId, nextFavoriteStatus));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(BookSnippet);
