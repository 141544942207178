import {registerUser} from '../../actions/forms/formsSendActions';

import {registrationReject, registrationRequest, registrationResolve} from '../../actions/loading';

import {FormName} from 'app/types/FormName';
import {createFormSendEpic} from './createFormSendEpic';

export const registrationEpic = createFormSendEpic({
    triggers: [registerUser.toString()],
    formName: FormName.Registration,
    actions: {
        requestAction: registrationRequest,
        resolveAction: registrationResolve,
        rejectAction: registrationReject,
    }
});
