import { createAction } from 'redux-actions';

export interface IUploadDocumentActionPayload {
    uploadId: string;
    documentId: string;
    file: File;
}

export const uploadDocumentContent = createAction<IUploadDocumentActionPayload>('UPLOAD_DOCUMENT_CONTENT');
export const uploadDocumentCover = createAction<IUploadDocumentActionPayload>('UPLOAD_DOCUMENT_COVER');
