/**
 * Created by Lkarmelo on 23.01.2019
 */

import React from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import Popover from 'antd/es/popover';
import BookCover from 'app/components/common/BookCover';
import BookStats from 'app/components/common/BookStats/BookStats';
import { RestrictedPermission } from 'app/components/high-order/RestrictedPermission';

import PermissionKey from 'common/permissions/PermissionKey';

import IProps, { ClsNames } from '../interfaces/IBookSnippetProps';
import { Store } from 'nkc-frontend-tools/types';

import { getAuthorsOrEditor } from 'app/utils/getAuthorsOrEditor';
import clientRoutes from 'common/clientRoutes';

import * as styles from './BookSnippetBase.scss';
import isStyleSupport from 'app/utils/styleChecker';
import {FilterName} from 'common/types/FilterName';
import {capitalizeFirstLetter} from 'app/utils/capitalizeFirstLetter';

const BookSnippetBase: React.FunctionComponent<IProps> = (props) => {
    const { book, className, renderCategories, isAdaptive, coverSize, renderHeader, changeFavoriteStatus } = props;
    const categories = book && book.categories;
    const document = book && book.document;

    const id = document && document.id;

    const title = document && document.title;

    const objectType = document &&
        document.meta &&
        document.meta.type;

    const isFavorite = book &&
        book.bookmarked &&
        book.bookmarked.FAVORITE;

    const covers = book && book.covers;

    const stats = book && book.stats;

    const isPublished = document && document.isPublished;

    const isSkeleton = book === BookSnippetBase.defaultProps.book;

    const authorsOrEditor = getAuthorsOrEditor(book);

    return (
        <article
            itemScope
            itemType="http://schema.org/Book"

            className={classNames(
                styles.bookSnippet,
                className,
                {
                    [styles.bookSnippetSkeleton]: isSkeleton,
                    [styles.bookSnippetAdaptive]: isAdaptive,
                    [styles.bookSnippetHasExtraCategories]: Array.isArray(categories) && categories.length > 1,
                    [styles.bookSnippetFavored]: isFavorite,
                }
            )}
        >
            <RestrictedPermission pk={PermissionKey.viewFavoriteBookButton}>
                <button
                    className={classNames(
                        'btn',
                        styles.bookSnippetFavorite,
                        isFavorite ? 'icon-heart-filled' : 'icon-heart'
                    )}
                    onClick={() => changeFavoriteStatus(id, !isFavorite)}
                />
            </RestrictedPermission>
            <Link to={clientRoutes.book.getUrl({ id })} className={styles.bookSnippetCoverLink}>
                {isSkeleton ?
                    <div
                        className={classNames(
                            styles.bookSnippetCover,
                            styles.bookSnippetCoverPlaceholder
                        )}
                    />
                    :
                    <BookCover
                        id={id}
                        objectType={objectType}
                        title={title}
                        author={Array.isArray(authorsOrEditor) && authorsOrEditor.length > 0 ? authorsOrEditor[0] : undefined}
                        size={coverSize}
                        covers={covers}
                        loadingPlaceholderClassName={classNames(styles.bookSnippetCover, styles.bookSnippetCoverPlaceholder)}
                        imgClassName={styles.bookSnippetCover}
                        showBookType={true}
                    />
                }
            </Link>
            <div className={styles.bookSnippetBody}>
                <div className={styles.bookSnippetAuthors}>
                    {(Array.isArray(authorsOrEditor) && authorsOrEditor.length > 0) &&
                        <>
                            <span
                                itemProp="author"
                                itemScope
                                itemType="https://schema.org/Person"

                                className={styles.bookSnippetAuthor}
                            >
                                <span itemProp="name" title={authorsOrEditor[0]}>
                                    {authorsOrEditor[0]}
                                </span>
                            </span>
                            {authorsOrEditor.length > 1 &&
                                <span> и др.</span>
                            }
                        </>
                    }
                </div>
                {renderHeader(props, styles)}
                <BookStats stats={stats} isPublished={isPublished} />
                <div>
                    {isSkeleton ?
                        <span className={styles.bookSnippetYearCategory}/>
                        :
                        renderCategories(props, styles)
                    }
                </div>
            </div>
        </article>
    );
};

const getCategoryTitle = (
    category: Store.ICategory,
    titles: IProps['categoryTitles']
): string => titles[category.id] ? capitalizeFirstLetter(titles[category.id]) : capitalizeFirstLetter(category.name);

BookSnippetBase.defaultProps = {
    renderCategories(props: IProps, clsNames: ClsNames): JSX.Element {
        const { book, categoryTitles, isAdaptive, popoverClassName } = props;
        const categories = book && book.categories;
        const year = book &&
            book.document &&
            book.document.year;

        const popoverContent = (
            <div>
                {Array.isArray(categories) && categories.map(cat => (
                    <Link
                        key={cat.id}
                        to={clientRoutes.search.getUrl(null, {[FilterName.KnowledgeArea]: cat.id, query: ''})}
                        onClick={e => e.stopPropagation()}
                        className={`${clsNames.bookSnippetCategory} ${clsNames.bookSnippetExtraCategory}`}
                        title={getCategoryTitle(cat, categoryTitles)}
                    >
                        {getCategoryTitle(cat, categoryTitles)}
                    </Link>
                ))}
            </div>
        );

        return (
            <span className={clsNames.bookSnippetYearCategory}>
                {year &&
                    <span className={clsNames.bookSnippetYear}>{year} г.</span>
                }
                {Array.isArray(categories) && categories.length > 0 &&
                    <React.Fragment>
                        {categories.length > 1 ?
                            <Popover
                                placement="bottomLeft"
                                content={popoverContent}
                                overlayClassName={classNames(
                                    clsNames.bookSnippetPopover,
                                    popoverClassName,
                                    {[clsNames.bookSnippetPopoverAdaptive]: isAdaptive}
                                )}
                            >
                                <span className={styles.bookSnippetCategoryBlock}>
                                    <span className={`icon-folder ${clsNames.bookSnippetMoreCategoriesIcon}`} />&nbsp;
                                    {categories.length}:
                                    <span
                                        className={clsNames.bookSnippetCategory}
                                        title={getCategoryTitle(categories[0], categoryTitles)}
                                    >
                                        {getCategoryTitle(categories[0], categoryTitles)}
                                    </span>
                                </span>
                            </Popover>
                            :
                            <span className={styles.bookSnippetCategoryBlock}>
                                <Link
                                    to={clientRoutes.search.getUrl(null, {[FilterName.KnowledgeArea]: categories[0].id, query: ''})}
                                    onClick={e => e.stopPropagation()}
                                    className={clsNames.bookSnippetCategory}
                                    title={getCategoryTitle(categories[0], categoryTitles)}
                                >
                                    {getCategoryTitle(categories[0], categoryTitles)}
                                </Link>
                            </span>
                        }
                    </React.Fragment>
                }
            </span>
        );
    },

    renderHeader({ book }: IProps, clsNames: ClsNames) {
        const document = book && book.document;
        const id = document && document.id;
        const title = document && document.title;

        return (
            <header>
                <h2
                    itemProp="headline"
                    className={clsNames.bookSnippetTitle}
                >
                    <Link
                        title={title}
                        to={clientRoutes.book.getUrl({ id })}
                    >
                        {title}
                    </Link>
                </h2>
            </header>
        );
    },
    isAdaptive: true,
    book: {},
    coverSize: 'SMALL'
};

export default BookSnippetBase;
