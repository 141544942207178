/**
 * Created by lkarmelo on 04.10.2019.
 */

import React from 'react';
import classNames from 'classnames';

import IProps from './interfaces/IReaderFooterProps';

import * as styles from './ReaderFooter.scss';

const ReaderFooter: React.FC<IProps> = ({className, totalPages, currentPage}) => {

    const percentCompleted = currentPage / totalPages * 100;

    return (
        <div className={classNames(className, styles.readerFooter)}>
            <div className={styles.readerFooterProgressBar}>
                <div className={styles.readerFooterCompletedBar} style={{width: `${percentCompleted}%`}} />
            </div>
            {typeof currentPage === 'number' && typeof totalPages === 'number' &&
                <div className={styles.readerFooterText}>
                    Страница {currentPage} из {totalPages}
                </div>
            }
        </div>
    );
};

export default React.memo(ReaderFooter);
