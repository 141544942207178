import React, { useEffect, useMemo, useCallback } from 'react';
import PageHeader from 'app/components/common/PageHeader';
import clientRoutes from 'common/clientRoutes';
import { ROOT_BREADCRUMB_NAME } from 'app/utils/constants';
import { SubscriptionType } from 'common/api/ExtendedApi';
import classNames from 'classnames';
import { CheckboxOption, CheckboxSelect } from 'app/components/common/select/CheckboxSelect';

import IProps from './interfaces/ISubscriptionsProps';
import * as styles from './Subscriptions.scss';

const pageTitle = 'Подписки';

const breadcrumbs = [
    {
        link: clientRoutes.main.getUrl(),
        title: ROOT_BREADCRUMB_NAME
    },
    {
        link: '#',
        title: 'Личный кабинет'
    },
    {
        link: clientRoutes.subscriptions.getUrl(),
        title: pageTitle
    }
];

const Subscriptions: React.FC<IProps> = (props) => {
    const {
        subscriptionOptions,
        subscriptions,
        fetchSubscriptions,
        subscribe,
        unsubscribe,
        unsubscribeFromAll
    } = props;

    useEffect(
        () => {
            fetchSubscriptions();
        },
        []
    );

    const isUserSubscribedToAll = useMemo(
        () => !!subscriptions.news,
        [subscriptions]
    );

    const onDeleteButtonClick = useCallback(
        () => {
            subscriptions && Object.keys(subscriptions).length > 0 && unsubscribeFromAll();
        },
        [subscriptions]
    );

    const onSelectCallbacks: Record<SubscriptionType, (value: string) => void> = useMemo(
        () => Object.values(SubscriptionType).reduce<any>(
            (callbacks, type) => {
                callbacks[type] = (value: string) => {
                    subscribe(type, value);
                };

                return callbacks;
            },
            {}
        ),
        []
    );

    const onDeselectCallbacks: Record<SubscriptionType, (value: string) => void> = useMemo(
        () => Object.values(SubscriptionType).reduce<any>(
            (callbacks, type) => {
                callbacks[type] = (value: string) => {
                    unsubscribe(type, value);
                };

                return callbacks;
            },
            {}
        ),
        []
    );

    return (
        <div className={styles.subscriptions}>
            <PageHeader
                breadcrumbsPath={breadcrumbs}
                header={pageTitle}
            />
            <div className={styles.subscriptionsContent}>
                <div
                    className={styles.subscriptionsSubscribeToAll}
                >
                    <CheckboxOption
                        labelClassName={styles.subscriptionsSubscribeToAllCheckboxLabel}
                        name="Все новые поступления"
                        checked={isUserSubscribedToAll}
                        onSelect={onSelectCallbacks[SubscriptionType.NEWS]}
                        onDeselect={onDeselectCallbacks[SubscriptionType.NEWS]}
                    />
                    {isUserSubscribedToAll &&
                        <div className={styles.subscriptionsHint}>
                            Вы сможете выбрать отдельные подписки, если уберете галочку "Все новые поступления".
                        </div>
                    }
                </div>
                <div
                    className={classNames(
                        styles.subscriptionsSelects,
                        { [styles.subscriptionsSelectsDisabled]: isUserSubscribedToAll }
                    )}
                >
                    {subscriptionOptions.map(subscription => (
                        <CheckboxSelect
                            className={styles.subscriptionsSelect}
                            disabled={isUserSubscribedToAll}
                            key={subscription.categoryName}
                            onSelect={onSelectCallbacks[subscription.type]}
                            onDeselect={onDeselectCallbacks[subscription.type]}
                            {...subscription}
                        />
                    ))}
                </div>
                <button
                    className={classNames(
                        'btn-edit',
                        styles.subscriptionsDeleteAllBtn
                    )}
                    onClick={onDeleteButtonClick}
                >
                    Сбросить все подписки
                </button>
            </div>
        </div>
    );
};

export default Subscriptions;
