import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import UploadImageSection from '../UploadImageSection';
import UploadDocumentSection from '../UploadDocumentSection';

import clientRoutes from 'common/clientRoutes';
import { SubmitPanelFormControls } from 'app/components/common/form-controls';

import IProps from './interfaces/IUploadFilesProps';
import * as styles from './UploadFiles.scss';

const UploadFiles: React.FC<IProps> = (props) => {
    const {
        className,
        document,
        onComplete,
        onClose,
        matchId,
        matchUrl,
        raiseDocumentNotUploadedNotification
    } = props;

    const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);

    useEffect(
        () => {
            if (!!matchId && matchUrl === clientRoutes.upload.getUrl({ id: matchId })) {
                setIsDocumentUploaded(true);
            }
        },
        [matchId, matchUrl]
    );

    return (
        <div className={classNames(className, styles.uploadFiles)}>
            <UploadImageSection className={styles.uploadFilesUploadImage} document={document}/>
            <UploadDocumentSection
                className={styles.uploadFilesUploadDocument}
                documentId={document.id}
                setIsDocumentUploaded={setIsDocumentUploaded}
            />
            <SubmitPanelFormControls
                onSaveClick={isDocumentUploaded ? onComplete : raiseDocumentNotUploadedNotification}
                onCancelClick={onClose}
                saveLabel={'Сохранить и продолжить'}
                cancelLabel={'Отменить'}
            />
        </div>
    );
};

UploadFiles.defaultProps = {
    document: {}
};

export default UploadFiles;
