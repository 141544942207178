/**
 * Created by lkarmelo on 26.08.2019.
 */
import {connect} from 'react-redux';
import {ExtendedStore} from 'common/store';
import {removeSystemNotification} from 'app/redux/actions/systemNotifications';

import {ISystemNotificationsActionProps, ISystemNotificationsStateProps} from './interfaces/ISystemNotificationsProps';
import SystemNotifications from './SystemNotifications';

const mapStateToProps = (state: ExtendedStore.IState): ISystemNotificationsStateProps => ({
    notifications: state.systemNotifications
});

const mapDispatchToProps = (dispatch): ISystemNotificationsActionProps => ({
    removeNotification(id: string): void {
        dispatch(removeSystemNotification(id));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemNotifications);
