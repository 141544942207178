import PermissionKey from './PermissionKey';
import permissionRestriction, {IPermissionRestriction} from './PermissionRestriction';
import rbam, { IAccessDescription } from './rbam';
import {defaultRoles} from './PermissionRole';

interface IPermissionChecker {
    isAccess(): boolean;
    isVisible(): boolean;
    isDisabled(): boolean;
    hasRedirect(): boolean;
}

export interface IDefferPermission {
    pk: PermissionKey;
    accessFunction(... args: any[]): boolean;
}

export type PermissionChecker = (... args: any[]) => IPermissionChecker;
export type Permissions = PermissionKey | IDefferPermission | (PermissionKey | IDefferPermission)[];

interface IAggregatePermissionRestriction {
    restriction: IPermissionRestriction;
    defferRestriction: Map<(... args: any[]) => boolean, IPermissionRestriction>;
}

interface IAggregateUserPermission {
    permissions: Set<PermissionKey>;
    defferPermissions: Map<PermissionKey, IAccessDescription>;
}

/**
 *  Создааёт функцию проверки прав по набору ролей и ключу доступа
 *
 *  @param roles - массив ролей ползователя
 *
 *  @param pk - набор привелегий, которые необходимо проверить
 *
 *  @param args - дополнительные параметры, которые
 *    могут быть использованны для отложенной проверки доступа
 *
 *  (roles?: string[]) => (pk: Permissions) => (...args: any[]) =>  IPermissionChecker
 */
const permissions = (roles?: string[]) => {
    const userPermissions = getAggregateUserPermissions(roles);

    // console.log("- permissions -- init", userPermissions);

    // cache for permission keys
    const permissionCache = new Map<Permissions, PermissionChecker>();
    const calculatePermissionChecker =  (pk: Permissions): PermissionChecker => {
        const aggregatePermissionRestriction = getAggreagatePermissionRestriction(pk, userPermissions);

        // console.log("-- permissions checker -- pks");

        return makePermissionChecker(aggregatePermissionRestriction);
    };

    return (pk: Permissions) => {
        if (!permissionCache.has(pk)) {
            permissionCache.set(pk, calculatePermissionChecker(pk));
        }

        return permissionCache.get(pk);
    };
};

const makePermissionChecker = (aggregatePermissionRestriction: IAggregatePermissionRestriction) =>
(...args: any[]):  IPermissionChecker => {

    let actualRestriction: IPermissionRestriction = aggregatePermissionRestriction.restriction;
    aggregatePermissionRestriction.defferRestriction.forEach((value: IPermissionRestriction, key: (... args: any[]) => boolean) =>
        !key(... args) && Object.assign(actualRestriction, value));

    // console.log("--- permissions checker --- args",args);

    return {
        isAccess: () => actualRestriction.visible && !actualRestriction.disabled,
        isVisible: () => actualRestriction.visible,
        isDisabled: () => actualRestriction.disabled,
        hasRedirect: () => actualRestriction.redirect && actualRestriction.disabled,
    };
};

/**
 * Собирает по ролям уникальный набор ключей дотсупа
 *  если для ключа доступа передаются дополнительные параметры добавляет
 *  описание отложенной проверки разрешений
 *
 * @param roles - роли пользователя
 */
const getAggregateUserPermissions = (roles: string[]): IAggregateUserPermission => {
    const userPermissions = new Set((roles || defaultRoles)
        .reduce((sum: PermissionKey[], role: string) => {
            const pks: PermissionKey[] = rbam[role]
                .filter(item => typeof item !== 'object');
            return sum.concat(pks);
        },      []));

    const defferUserPermissions = (roles || defaultRoles)
        .reduce((sum: Map<PermissionKey, IAccessDescription>, role: string) => {
            rbam[role]
                .filter(item => typeof item === 'object')
                .forEach((roleAccessDescription: IAccessDescription) => {
                    const params = (sum.get(roleAccessDescription.pk) || { params: null }).params;
                    sum.set(roleAccessDescription.pk, {
                        pk: roleAccessDescription.pk,
                        params: Object.assign({}, params, roleAccessDescription.params)
                    });
                });
            return sum;
        },      new Map<PermissionKey, IAccessDescription>());

    // remove keys with params
    defferUserPermissions.forEach((_, key) => userPermissions.delete(key));

    return {
        permissions: userPermissions,
        defferPermissions: defferUserPermissions
    };
};

/**
 * Собирает применяемые к данному правилу ограничения системы безопастности
 *  Для простых ключей доступа ограничения агрегируются IPermissionRestriction
 *  Для отложенных правил, собираются в массив функция вида (...args: any[]) => boolean
 *
 * @param pk - ключ доступа (требуемое правило доступа)
 * @param aggregateUserPermissions - предварительно подготовленный список разрешений пользователя
 */
const getAggreagatePermissionRestriction = (
    pk: Permissions,
    aggregateUserPermissions: IAggregateUserPermission
): IAggregatePermissionRestriction => {

    const { permissions: userPermissions, defferPermissions: defferUserPermissions} = aggregateUserPermissions;
    const pks = !Array.isArray(pk) ? [pk] : pk;

    const restriction: IPermissionRestriction = (pks
        .filter((item) => (typeof item !== 'object')) as PermissionKey[])
        .filter((item) => !userPermissions.has(item))
        .reduce((sum: IPermissionRestriction, item: PermissionKey) => {

            const currentRestriction = permissionRestriction.get(item);
            if (defferUserPermissions.has(item)) {
                throw 'Component can\'t use permission params';
            }

            return Object.assign(sum, currentRestriction);
        },      { visible: true, redirect: false });

    const defferRestriction = (pks
        .filter(item => (typeof item === 'object')) as IDefferPermission[])
        .filter(item => !userPermissions.has(item.pk))
        .reduce((sum: Map<(...args: any[]) => boolean, IPermissionRestriction>, item: IDefferPermission) => {
            const currentPermission = (item as IDefferPermission);
            const accessFunction = defferUserPermissions.has(item.pk) ?
                (...args: any[]) => {
                    const newArgs = [
                        defferUserPermissions.get(item.pk).params,
                        ...args
                    ];
                    // console.log("accessFunction", args, newArgs);
                    return currentPermission.accessFunction(...newArgs);
                } : currentPermission.accessFunction;

            sum.set(accessFunction, permissionRestriction.get(currentPermission.pk));
            return sum;
        },      new Map<(...args: any[]) => boolean, IPermissionRestriction>());

    // console.log("-- aggreagatePermissionRestriction --", pk, aggregateUserPermissions);

    return { restriction, defferRestriction };
};

export default permissions;
