/**
 * Created by lkarmelo on 27.02.2019.
 */

export enum FilterName {
    PublicationType = 'objectType',
    KnowledgeArea = 'category-id',
    MaterialType = 'materialType',
    Year = 'year',
    IsPublished = 'isPublished',
}

export const enum StaticFilterName {
    Sort = 'sort',
}
