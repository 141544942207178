/**
 * Created by lkarmelo on 28.02.2019.
 */

import {DependencyList, useEffect} from 'react';

export const useEventListener = (
    element: EventTarget,
    type: string,
    callback: EventListenerOrEventListenerObject,
    addEventListenerOptions?: boolean | AddEventListenerOptions,
    deps: DependencyList = [],
): void => {
    useEffect(
        () => {
            element.addEventListener(type, callback, addEventListenerOptions);

            return () => element.removeEventListener(type, callback);
        },
        deps
    );
};
