/**
 * Created by lkarmelo on 27.02.2019.
 */
import {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    setFilterValue,
    ISetFiltersByNamePayloadItem,
    setFiltersByNames,
} from 'nkc-frontend-tools/redux/actions/search/filters';
import {setLimit} from 'nkc-frontend-tools/redux/actions/search/searchPaging';

import {IFiltersActionProps} from './interfaces/IFiltersProps';

import {Store} from 'nkc-frontend-tools/types';

import {getDefaultLimitFromCookie} from 'app/utils/getDefaultLimitFromCookie';

export const filtersMapDispatchToProps = (dispatch): IFiltersActionProps => ({
    onSelectMultiSelectFilter(filterName: string, value: string, setDefaultLimit: boolean = true): void {
        setDefaultLimit && dispatch(setLimit(getDefaultLimitFromCookie()));
        dispatch(addMultiSelectFilterValue(filterName, value));
    },
    onDeselectMultiSelectFilter(filterName: string, value: string, setDefaultLimit: boolean = true): void {
        setDefaultLimit && dispatch(setLimit(getDefaultLimitFromCookie()));
        dispatch(removeMultiSelectFilterValue(filterName, value));
    },
    onSelectFilter(filterName: string, value: Store.IFilterValue, setDefaultLimit: boolean = true): void {
        setDefaultLimit && dispatch(setLimit(getDefaultLimitFromCookie()));
        dispatch(setFilterValue(filterName, value));
    },
    setFilters(filters: ISetFiltersByNamePayloadItem[], setDefaultLimit: boolean = true): void {
        setDefaultLimit && dispatch(setLimit(getDefaultLimitFromCookie()));
        dispatch(setFiltersByNames(filters));
    }
});
