/**
 * Created by Lkarmelo on 21.02.2018.
 */

import React from 'react';
import {PureComponent} from 'react';
import {boundMethod} from 'autobind-decorator';
import classNames from 'classnames';

import Collapse from '../Collapse';

import IProps from './interfaces/ICollapsibleMenuProps';
import IState from './interfaces/ICollapsibleMenuState';

import styles from './CollapsibleMenu.scss';

export default class CollapsibleMenu extends PureComponent<IProps, IState> {
    static defaultProps: Pick<IProps, 'toggleOnArrowOnly' | 'renderToggle'> = {
        toggleOnArrowOnly: true,
        renderToggle(props: IProps, toggle: () => void, cssClassNames: {toggle: string; arrow: string; text: string; }): JSX.Element {
            const {toggleElement, toggleOnArrowOnly} = props;
            return toggleOnArrowOnly ? (
                <div className={cssClassNames.toggle}>
                    <button onClick={toggle} className={`btn ${cssClassNames.arrow}`} />
                    <span className={cssClassNames.text}>
                        {toggleElement}
                    </span>
                </div>
            ) : (
                <button onClick={toggle} className={`btn ${cssClassNames.toggle}`}>
                    <span className={cssClassNames.arrow} />
                    <span className={cssClassNames.text}>
                        {toggleElement}
                    </span>
                </button>
            );
        }
    };
    state = {
        isOpened: false
    };
    constructor(props: IProps) {
        super(props);

        if (props.isInitiallyOpened) {
            this.state.isOpened = true;
        }
    }
    render(): JSX.Element {
        const {className, renderToggle} = this.props;
        const isOpened = this.props.isOpened !== undefined ? this.props.isOpened : this.state.isOpened;

        return (
            <div
                className={classNames(styles.collapsibleMenu, className, {[styles.collapsibleMenuOpened]: isOpened})}
            >
                {renderToggle(
                    this.props,
                    this.toggleOpenedState,
                    {
                        toggle: styles.collapsibleMenuToggle,
                        arrow: styles.collapsibleMenuArrow,
                        text: styles.collapsibleMenuToggleText
                    }
                )}
                <Collapse isOpen={isOpened}>
                    <ul className={'collapsible-menu__list'}>
                        {this.props.children}
                    </ul>
                </Collapse>
            </div>
        );
    }
    componentDidUpdate(): void {
        const {onAfterCollapseChange} = this.props;
        const isOpened = this.props.isOpened !== undefined ? this.props.isOpened : this.state.isOpened;

        onAfterCollapseChange && onAfterCollapseChange(isOpened);
    }
    @boundMethod
    toggleOpenedState(): void {
        const onCollapseChange = this.props.onCollapseChange;
        if (this.props.isOpened !== undefined) {
            onCollapseChange && onCollapseChange(this.props.isOpened);
        } else {
            this.setState(state => ({isOpened: !state.isOpened}));
        }
    }
    /**
     * работает только если CollapsibleMenu неконтролируема (когда не передан isOpened в props)
     */
    @boundMethod
    open(): void {
        if (this.props.isOpened === undefined && !this.state.isOpened) {
            this.setState({isOpened: true});
        }
    }
}
