/**
 * Created by lkarmelo on 15.04.2019.
 */

import {createSelector} from 'reselect';
import {ExtendedStore} from 'common/store';
import {Store} from 'nkc-frontend-tools/types';
import {replaceDotsWithDashes} from 'nkc-frontend-tools/utils/filterNamesEscape';

export const createFilterAggregationDocCountSelector = (context: string) =>
    createSelector(
        (state: ExtendedStore.IState): Store.IFiltersMeta => state.filtersMeta,
        (state: ExtendedStore.IState): Store.IAggregation[] => {
            const searchState: ExtendedStore.IDocumentSearch = state[context];
            return searchState && searchState.results && searchState.results.aggregations;
        },
        (
            filtersMeta: Store.IFiltersMeta,
            aggregations: Store.IAggregation[]
        ): {[filterName: string]: {[value: string]: number}} => {
            const filtersValuesDocCount = {};
            Array.isArray(aggregations) && aggregations
                .filter(aggr => !!filtersMeta[replaceDotsWithDashes(aggr.name)])
                .forEach(aggr => {
                    filtersValuesDocCount[replaceDotsWithDashes(aggr.name)] = (aggr.buckets as Store.IAggregationBucket[]).reduce(
                        (valueToDocCountMap, bucket) => {
                            valueToDocCountMap[bucket.key] = bucket.docCount;
                            return valueToDocCountMap;
                        },
                        {}
                    );
                });
            return filtersValuesDocCount;
        }
    );
