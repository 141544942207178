import {connect} from 'react-redux';

import {ILibraryStatStateProps} from './interfaces/ILibraryStatProps';

import LibraryStat from './LibraryStat';
import {ExtendedStore} from 'common/store';

const mapStateToProps = (store: ExtendedStore.IState): ILibraryStatStateProps => ({
    libraryStat: store.libraryStats,
});

export default connect(mapStateToProps)(LibraryStat);
