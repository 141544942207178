/**
 * Created by lkarmelo on 06.08.2019.
 */
import {Action, handleActions} from 'redux-actions';
import initialState from 'common/store/storeInitialState';
import {
    ISetFormPayload, ISetFormStatusPayload, setForm, setFormStatus,
    removeForm, setFormData, ISetFormDataPayload, setFormBackURL, ISetFormBackURLPayload
} from '../actions/forms/forms';
import {ExtendedStore} from 'common/store';
import {FormName} from 'app/types/FormName';

export default handleActions<ExtendedStore.IForms, any>(
    {
        [setForm.toString()](state: ExtendedStore.IForms, {payload}: Action<ISetFormPayload>): ExtendedStore.IForms {
            const prevForm = state[payload.formName];
            if (!prevForm) {
                return {
                    ...state,
                    [payload.formName]: {
                        status: payload.status,
                        response: payload.response,
                        data: payload.data,
                    }
                };
            }

            return {
                ...state,
                [payload.formName]: {
                    status: payload.status ? payload.status : prevForm.status,
                    response: payload.response ? payload.response : prevForm.response,
                    data: payload.data ? payload.data : prevForm.data,
                }
            };
        },
        [setFormStatus.toString()](state: ExtendedStore.IForms, {payload}: Action<ISetFormStatusPayload>): ExtendedStore.IForms {
            const {formName, status} = payload;
            const nextState = {...state};
            nextState[formName] = {
                ...nextState[formName],
                status,
            };
            const statusChanged: boolean = !state || !state[formName] || state[formName].status !== nextState[formName].status;

            return statusChanged ? nextState : state;
        },
        [setFormData.toString()](state: ExtendedStore.IForms, {payload}: Action<ISetFormDataPayload>): ExtendedStore.IForms {
            const {formName, data} = payload;
            const nextState = {...state};

            nextState[formName] = {
                ...nextState[formName],
                data,
            };

            return nextState;
        },
        [setFormBackURL.toString()](state: ExtendedStore.IForms, {payload}: Action<ISetFormBackURLPayload>): ExtendedStore.IForms {
            const {formName, backUrl} = payload;
            const nextState = {...state};

            nextState[formName] = {
                ...nextState[formName],
                backUrl
            };

            return nextState;
        },

        [removeForm.toString()](state: ExtendedStore.IForms, {payload}: Action<FormName>): ExtendedStore.IForms {
            if (!state[payload]) {
                return state;
            }
            const nextState = {...state};
            delete nextState[payload];
            return nextState;
        }
    },
    initialState.forms
);
