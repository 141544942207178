/**
 * Created by Lkarmelo on 28.06.2018.
 */

import * as cssVars from 'stylessheets/vars.scss';

export const ROOT_BREADCRUMB_NAME = 'Библиотека РФФИ';

export const SEARCH_QUERY_PARAM_NAME = 'query';
export const DOCUMENTS_COUNT_PARAM_NAME = 'count';
export const PREVIEW_DOCUMENT_PARAM_NAME = 'pv';

export const MAX_AUTHORS_UNTIL_TOOLTIP_PREVIEW = 5;

export const MAX_ITEMS_SINGLE_SEARCH = 100;

export const MAX_KEYWORDS_BOOK_DETAILS = 10;

export const READER_SCALE_STEP = .25;

export const VIEWPORT_XXL = parseInt(cssVars.viewportXxl, 10);
export const VIEWPORT_XL = parseInt(cssVars.viewportXl, 10);
export const VIEWPORT_L = parseInt(cssVars.viewportL, 10);
export const VIEWPORT_M = parseInt(cssVars.viewportM, 10);
export const VIEWPORT_S = parseInt(cssVars.viewportS, 10);
export const VIEWPORT_XS = parseInt(cssVars.viewportXs, 10);
export const VIEWPORT_MOBILE = parseInt(cssVars.viewportMobile, 10);

export const HEADER_HEIGHT_XL = parseInt(cssVars.headerHeightXl, 10);

{/* tslint:disable-next-line */}
export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const UPLOAD_COVER_WIDTH = 408;
export const UPLOAD_COVER_HEIGHT = 612;

export const UPLOAD_COVER_MIN_WIDTH = 200;
export const UPLOAD_COVER_MIN_HEIGHT = 300;

export const UPLOAD_COVER_MAX_WIDTH = 16000;
export const UPLOAD_COVER_MAX_HEIGHT = 16000;
