/**
 * Created by lkarmelo on 27.03.2019.
 */

import ApiRoute from '../../nkc-frontend-tools/routing/Route/ApiRoute';
const prefix = '/api';
const authPrefix = '/auth';

export type NotificationsQueryParams = 'skip' | 'limit';
export type MarkNotificationsAsReadQueryParams = 'id';

export type UploadDocumentQueryParams = 'id';

export default {
    search: new ApiRoute(`${prefix}/searchDoc`),
    issueInfoCatalog: new ApiRoute(`${prefix}/catalog/objectCardFields`),
    bookCover: new ApiRoute(`${prefix}/cover/{id}{?size}`),
    socialNetworkBookCover: new ApiRoute(`${prefix}/cover/{provider}/{id}`),
    checkDocumentContent: new ApiRoute(`${prefix}/content/check/{id}`),
    downloadBook: new ApiRoute(`${prefix}/content/{id}/{title}`),
    libraryStats: new ApiRoute(`${prefix}/stats/documents`),
    like: new ApiRoute(`${prefix}/document/like/{id}`),
    share: new ApiRoute(`${prefix}/document/share/{provider}/{id}`),

    logIn: new ApiRoute(`${authPrefix}/signIn?redirectUrl=${prefix}/authenticate`),
    logOut: new ApiRoute(`${prefix}/logout`),

    registerUser: new ApiRoute(`${authPrefix}/signUp`),

    activateUser: new ApiRoute(`${authPrefix}/signUp/{token}`),
    resetPassword: new ApiRoute(`${authPrefix}/resetPassword/{token}`),

    requestResetPassword: new ApiRoute(`${authPrefix}/forgotPassword`),

    authenticate: new ApiRoute(`${authPrefix}/authenticate/{provider}?redirectUrl=/authenticate`),
    confirmAuthentication: new ApiRoute(`${prefix}/authenticate?Access-Token={accessToken}`),

    userPermissions: new ApiRoute(`${prefix}/user/permissions`),

    documentsShowcase: new ApiRoute(`${prefix}/stats/main`),

    user: new ApiRoute(`${prefix}/user`),
    updateUser: new ApiRoute(`${prefix}/user`),
    deleteUser: new ApiRoute(`${prefix}/user`),

    updateEmail: new ApiRoute(`${prefix}/user/email`),
    updateLogin: new ApiRoute(`${prefix}/user/login`),
    changePassword: new ApiRoute(`${prefix}/user/password`),
    refreshUser: new ApiRoute(`${prefix}/user/refresh`),

    confirmEmail: new ApiRoute(`${authPrefix}/user/{emailType}/{token}`),

    favorite: new ApiRoute(`${prefix}/bookmarks/favorite`),
    addRemoveFavorite: new ApiRoute(`${prefix}/bookmarks/favorite/{id}`),

    userSocial: new ApiRoute(`${prefix}/user/social`),

    subscribeToMailingList: new ApiRoute(`${prefix}/subscribe`),
    subscriptions: new ApiRoute(`${prefix}/subscriptions`),
    unsubscribeFromMailingList: new ApiRoute(`${prefix}/subscription`),
    unsubscribeFromAllMailingLists: new ApiRoute(`${prefix}/subscriptions/all`),

    loadDocument: new ApiRoute(`${prefix}/document/{id}`),
    createDocument: new ApiRoute(`${prefix}/document/create`),
    updateDocument: new ApiRoute(`${prefix}/document/update/{id}`),
    deleteDocument: new ApiRoute(`${prefix}/document/safedelete/{id}`),

    bookPage: new ApiRoute(`${prefix}/page/{id}/{page}`),
    bookReaderMeta: new ApiRoute(`${prefix}/book-reader/{id}`),
    bookReaderCurrentPage: new ApiRoute(`${prefix}/book-reader/{id}/currentPage`),
    bookReaderScale: new ApiRoute(`${prefix}/book-reader/{id}/scale`),

    addRemoveBookmark: new ApiRoute(`${prefix}/book-reader/{id}/bookmark/{page}`),
    bookmarks: new ApiRoute(`${prefix}/book-reader/{id}/bookmarks{?skip,limit}`),

    notificationSettings: new ApiRoute(`${prefix}/notifications/settings`),
    notificationTypes: new ApiRoute(`${prefix}/notifications/types`),
    notifications: new ApiRoute(`${prefix}/notifications`),
    markNotificationsAsRead: new ApiRoute<'', MarkNotificationsAsReadQueryParams>(`${prefix}/notifications/read`),

    searchUploads: new ApiRoute(`${prefix}/searchDoc/all`),

    uploadDocumentContent: new ApiRoute<'', UploadDocumentQueryParams>(`${prefix}/document/content`),
    uploadDocumentCover: new ApiRoute<'', UploadDocumentQueryParams>(`${prefix}/document/cover`),

    publishDocument: new ApiRoute(`${prefix}/document/publish/{id}`),
    unpublishDocument: new ApiRoute(`${prefix}/document/unpublish/{id}`)
};
