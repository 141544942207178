import React, { useMemo, useCallback } from 'react';
import { TextFormField, InputFieldMode } from 'app/components/common/form-fields';
import classNames from 'classnames';
import { useEditablePanelControls } from 'app/hooks/useEditablePanel';
import PersonForm from '../PersonForm/PersonForm';

import IProps from './interfaces/IPersonFieldProps';
import * as styles from './PersonField.scss';

const EMPTY_PERSON = { firstName: null, lastName: null, middleName: null };

const PersonField: React.FC<IProps> = (props) => {
    const { className, error, label, onEdit: onEditProps, onDelete, person, required } = props;

    const personFullName = useMemo(
        () =>
            !!person
                ? [person.lastName, person.firstName, person.middleName].filter(Boolean).join(' ')
                : '',
        [person]
    );

    const onEdit = useCallback(
        (personFIO: { firstName: string, lastName: string, middleName: string }) => {
            onEditProps({
                ...person,
                ...EMPTY_PERSON,
                ...personFIO
            });
            setIsEditing(false);
        },
        [person, onEditProps]
    );

    const onDeleteButtonClick = useCallback(
        () => onDelete(person),
        [person, onDelete]
    );

    const [renderControls, editingState] = useEditablePanelControls();
    const [isEditing, setIsEditing] = editingState;

    return (
        <div className={className}>
            {isEditing ? (
                <PersonForm
                    onSubmit={onEdit}
                    person={person}
                    title={`Редактирование ${label}`}
                    renderControls={renderControls}
                />
            ) : (
                    <div className={styles.personField}>

                        <TextFormField
                            value={personFullName}
                            title={personFullName}
                            label={label}
                            error={error}
                            className={styles.personFieldInput}
                            mode={InputFieldMode.MATERIAL}
                            readOnly
                            required={required}
                            tabIndex={-1}
                        />
                        {!!person && (
                            <div className={styles.personFieldIcons}>
                                <button
                                    className={classNames('btn', 'icon-pencil', styles.personFieldEditIcon)}
                                    onClick={() => setIsEditing(true)}
                                />
                                {!!onDelete && (
                                    <button
                                        className={classNames('btn', 'icon-cross', styles.personFieldDeleteIcon)}
                                        onClick={onDeleteButtonClick}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}
        </div>
    );
};

export default PersonField;
