/**
 * Created by lkarmelo on 31.07.2019.
 */
import {handleActions, Action} from 'redux-actions';

import {setProfile, setEmail, updateProfile} from '../actions/profile';
import {setReducer} from './utils/setReducer';

import initialState from 'common/store/storeInitialState';
import { ExtendedStore } from 'common/store';
import { ExtendedApi } from 'common/api';

export default handleActions(
    {
        [setProfile.toString()]: setReducer,
        [updateProfile.toString()]: (
            state: ExtendedStore.IProfile,
            {payload}: Action<ExtendedApi.IProfileUpdateBody>
        ): ExtendedStore.IProfile => ({
            ...state,
            middleName: null, // empty middleName clear original one
            ...payload
        }),
        [setEmail.toString()]:  (
            state: ExtendedStore.IProfile,
            {payload}: Action<string>
        ): ExtendedStore.IProfile => ({
            ...state,
            email: payload,
            confirmedEmail: false
        }),
    },
    null
);
