import { MiddlewareAPI } from 'redux';
import { Subscriber } from 'rxjs';

import { ExtendedStore } from 'common/store';
import { fileUploadProgress } from '../actions/fileUploadProgress';

export const createFileUploadProgressSubscriber =
    (uploadId: string, file: File, store: MiddlewareAPI<ExtendedStore.IState>) => new Subscriber<ProgressEvent>(
        event => {
            const progressRatio = event.loaded / event.total;
            const { name, size } = file;

            store.dispatch(fileUploadProgress({
                id: uploadId,
                fileName: name,
                fileSize: size,
                progressRatio
            }));
        }
    );
