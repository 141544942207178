import React from 'react';

export type CloseEvent<Element extends HTMLElement> = React.MouseEvent<Element> | React.TouchEvent<Element>;

export enum PanelShowTrigger {
    OnHover = 'hover',
    OnClick = 'click'
}

export default interface IPopoverPanelProps {
    showTrigger?: PanelShowTrigger;
    onShowPopup?(): void;
    onClosePopup?(): void;
    content?(onClose: () => void): React.ReactElement<any & {close(event: CloseEvent<HTMLElement>): void}>;
}
