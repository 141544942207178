import { ExtendedStore } from 'common/store';
import {
    INotificationsTableStateProps,
    INotificationsTableActionProps
} from './interfaces/INotificationsTableProps';
import { fetchNotifications, markNotificationsAsRead, setNotificationsRequestParams } from 'app/redux/actions/notifications';
import { connect } from 'react-redux';
import NotificationsTable from './NotificationsTable';
import { INotificationsRequestParams } from 'common/api/ExtendedApi';

const mapStateToProps = (state: ExtendedStore.IState): INotificationsTableStateProps => ({
    totalCount: state.notifications.totalCount,
    notificationTypes: state.notifications.notificationTypes,
    notifications: state.notifications.notifications
});

const mapDispatchToProps = (dispatch): INotificationsTableActionProps => ({
    setNotificationsRequestParams(params: INotificationsRequestParams) {
        dispatch(setNotificationsRequestParams(params));
    },
    fetchNotifications() {
        dispatch(fetchNotifications());
    },
    markNotificationsAsRead(id: string[]) {
        dispatch(markNotificationsAsRead(id));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable);
