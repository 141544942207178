import React from 'react';
import {useCallback} from 'react';
import classNames from 'classnames';

import * as loginPanelStyles from './LoginPanel.scss';
import * as styles from './LoginPanelPopup.scss';
import ILoginProps from './interfaces/ILoginProps';
import LoginPanel from './LoginPanel';

import Modal from 'app/components/common/modals/Modal';
import { useOnPopStateAndUnmountEffect } from 'app/hooks/useOnPopStateAndUnmountEffect';

const LoginPanelPopup: React.FC<ILoginProps> = (props: ILoginProps) => {
    const {onPanelClose, authorizationFailed, openRegistrationPopup, showLogin, openResetPasswordPopup} = props;

    const onRegisterClick = useCallback(
        () => {
            onPanelClose();
            openRegistrationPopup();
        },
        [onPanelClose, openRegistrationPopup]
    );

    const onResetPasswordClick = useCallback(
        () => {
            onPanelClose();
            openResetPasswordPopup();
        },
        [onPanelClose, openResetPasswordPopup]
    );

    // back button click hook
    useOnPopStateAndUnmountEffect(onPanelClose);

    return (
        <Modal
            onExited={onPanelClose}
            isVisible={showLogin}
            close={onPanelClose}
            centered={false}
            className={styles.loginPopupModal}
        >
            <div className={styles.loginPopupWrapper}>
                <div
                    className={classNames(loginPanelStyles.login, {[loginPanelStyles.loginFailed]: authorizationFailed === true})}
                >
                    <LoginPanel {...props} onRegisterClick={onRegisterClick} onResetPasswordClick={onResetPasswordClick} />
                </div>
            </div>
        </Modal>
    );
};

export default LoginPanelPopup;
