import React from 'react';
import * as styles from './MaterialSelectFormField.scss';

const SelectLabel = ({ onClick, onClear, title, active }) => {
    const isActive = active.length > 0;

    return (
        <div
            className={`btn ${styles.materialSelectFormFieldSwitcher}`}
            onClick={onClick}
            title={title}
        >
            <span className={styles.materialSelectFormFieldTitle}>{title}</span>
            { isActive && !!onClear &&
                <button
                    className={`${styles.materialSelectFormFieldClear} btn`}
                    onClick={onClear}
                />
            }
            <span className={styles.materialSelectFormFieldArrow} />
        </div>
    );
};

export default SelectLabel;
