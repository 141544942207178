/**
 * Created by lkarmelo on 19.08.2019.
 */

import {MiddlewareAPI} from 'redux';

import {profileUpdateReject, profileUpdateRequest, profileUpdateResolve} from 'app/redux/actions/loading';
import {submitProfile} from '../../actions/forms/formsSendActions';
import {setProfile, updateProfile} from '../../actions/profile';

import {FormName} from 'app/types/FormName';
import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';

import {createFormSendEpic} from './createFormSendEpic';

export const updateProfileEpic = createFormSendEpic({
    triggers: [submitProfile.toString()],
    formName: FormName.UpdateProfile,
    actions: {
        resolveAction: profileUpdateResolve,
        requestAction: profileUpdateRequest,
        rejectAction: profileUpdateReject,
        spawnOnSuccess: [(response, {payload}) => updateProfile(payload)],
    }
});
