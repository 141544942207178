import React, { useMemo } from 'react';

import BookDetails from 'app/components/common/BookDetails/';
import { IObjectCard } from 'common/store/ExtendedStore';
import clientRoutes from 'common/clientRoutes';
import { SubmitPanelFormControls } from 'app/components/common/form-controls';
import { Link } from 'react-router-dom';

import IProps from './interfaces/IDocumentPreviewProps';

const DocumentPreview: React.FC<IProps> = (props) => {
    const { document, publishDocument } = props;

    const book: IObjectCard = useMemo(
        () => ({
            documentSnippet: {
                document
            }
        }),
        [document]
    );

    return (
        <>
            <BookDetails
                book={book}
                renderSocialActions={() => null}
                renderBookEditActions={() => null}
            />
            <SubmitPanelFormControls
                onSaveClick={() => { document.id && publishDocument(document.id); }}
                saveLabel="Опубликовать материал"
                cancelLabel={(
                    <Link to={clientRoutes.uploads.getUrl()}>
                        Опубликовать потом
                    </Link>
                )}
            />
        </>
    );
};

DocumentPreview.defaultProps = {
    document: {}
};

export default DocumentPreview;
