import React, { useMemo, useEffect, useCallback } from 'react';

import clientRoutes from 'common/clientRoutes';

import { ROOT_BREADCRUMB_NAME } from 'app/utils/constants';

import PageHeader from 'app/components/common/PageHeader';
import ProgressBar from 'app/components/common/Wizard/ProgressBar';
import DocumentFormConnected from '../DocumentForm/DocumentFormConnected';
import UploadFiles from '../UploadFiles';
import DocumentPreview from '../DocumentPreview';

import * as styles from './AddEditDocument.scss';
import IAddEditDocumentProps from './interfaces/IAddEditDocumentProps';

const breadcrumbs = (pageTitle: string) => [
    {
        link: clientRoutes.main.getUrl(),
        title: ROOT_BREADCRUMB_NAME
    },
    {
        link: clientRoutes.uploads.getUrl(),
        title: 'Добавленные материалы'
    },
    {
        title: pageTitle
    }
];

const renderHeader = (pageTitle: string) => (
    <div className={''}>
        <div>{pageTitle}</div>
    </div>
);

const AddEditDocument: React.FC<IAddEditDocumentProps> = ({
    currentStep, nextStep, document, pageTitle,
    match, resetDocument, loadDocument, backUrl,
    history, raiseDocumentNotUploadedNotification
}) => {

    useEffect(
        () => {
            if (!match.params.id && !!document) {
                resetDocument();
            } else if (!document || document.id !== match.params.id) {
                loadDocument(match.params.id);
            }
        },
        [match.params.id, document]
    );

    const onComplete = useCallback(() => { nextStep(currentStep); }, [currentStep]);
    const onClose = () => {
        history.push(backUrl ?? clientRoutes.uploads.getUrl());
    };

    const currentPage = useMemo(
        () => {
            switch (currentStep) {
                case 1:
                    return (
                        <DocumentFormConnected
                            document={document}
                            onComplete={onComplete}
                            onClose={onClose}
                        />
                    );
                case 2:
                    return (
                        <UploadFiles
                            className={styles.createDocumentUploadFilesForm}
                            document={document}
                            onComplete={onComplete}
                            onClose={onClose}
                            matchId={match.params.id}
                            matchUrl={match.url}
                            raiseDocumentNotUploadedNotification={raiseDocumentNotUploadedNotification}
                        />
                    );
                case 3:
                    return (
                        <DocumentPreview
                            document={document}
                        />
                    );
                default:
                    return null;
            }
        },
        [currentStep, nextStep, document]
    );

    return (
        <div className={styles.createDocument}>
            <PageHeader
                breadcrumbsPath={breadcrumbs(pageTitle)}
                header={renderHeader(pageTitle)}
            />
            <ProgressBar
                currentStep={currentStep.toString()}
                steps={[
                    { id: '1', title: 'Данные\nматериала' },
                    { id: '2', title: 'Оригинал материала и обложка' },
                    { id: '3', title: 'Проверка и публикация' }
                ]}
            />
            <div className={styles.createDocumentContent}>
                {currentPage}
            </div>
        </div>
    );
};

export default AddEditDocument;
