/**
 * Created by lkarmelo on 05.09.2019.
 */
import React, {useCallback, useState} from 'react';
import {AjaxError} from 'rxjs/observable/dom/AjaxObservable';

import RegularModal from 'app/components/common/modals/RegularModal';
import SocialAuthPanel from 'app/components/SocialAuth';
import RegistrationForm, {IRegistrationFormValues} from '../Form';
import RegistrationSuccess from '../SuccessMessage';

import { useOnPopStateAndUnmountEffect } from 'app/hooks/useOnPopStateAndUnmountEffect';
import {useFormSenderHelper} from 'app/hooks/forms';

import IProps from './interfaces/IRegistrationPopupProps';
import {ExtendedApi} from 'common/api';

import * as styles from './RegistrationPopup.scss';

const RegistrationPopup: React.FC<IProps> = (props) => {
    const {
        isVisible, closePopup, returnToLoginPopup, formStatus, formResponse,
        resetFormStatus, register, resetForm
    } = props;

    const [sendSuccess, setSendSuccess] = useState(false);

    const onExited = useCallback(
        () => {
            resetForm();
            setSendSuccess(false);
        },
        []
    );

    const onSuccess = useCallback(() => setSendSuccess(true), [formResponse]);

    const onFail = useCallback(
        () => formResponse && (formResponse as AjaxError).status === 409 ? 'Email уже зарегистрирован' : undefined,
        [formResponse]
    );

    const formSubmitCbRef = useFormSenderHelper(formStatus, resetFormStatus, onSuccess, onFail);

    const onSubmit = useCallback(
        (values: IRegistrationFormValues, _, cb) => {
            register(values.email, values.password);
            formSubmitCbRef.current = cb;
        },
        []
    );

    useOnPopStateAndUnmountEffect(closePopup);

    return (
        <RegularModal
            isVisible={isVisible}
            close={closePopup}
            onExited={onExited}
        >
            <div className={styles.registration}>
                {sendSuccess ?
                    <RegistrationSuccess
                        email={formResponse && (formResponse as ExtendedApi.IRegistrationResponseBody).login}
                        onBtnClick={closePopup}
                    />
                    :
                    <>
                        <div className={styles.registrationHeader}>СОЗДАТЬ АККАУНТ</div>
                        <div className={styles.registrationText}>Создайте аккаунт с помощью</div>
                        <SocialAuthPanel />
                        <div className={styles.registrationDelimiter}>
                            <div className={styles.registrationDelimiterLine} />
                            <span>или</span>
                            <div className={styles.registrationDelimiterLine} />
                        </div>
                        <RegistrationForm onSubmit={onSubmit} />
                        <div className={styles.registrationReturnToLogin}>
                            Уже есть аккаунт?&nbsp;
                            <button className={'btn'} onClick={returnToLoginPopup}>Войти</button>
                        </div>
                    </>
                }
            </div>
        </RegularModal>
    );
};

export default RegistrationPopup;
