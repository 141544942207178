import React, { useCallback } from 'react';
import Sticky from 'react-stickynode';
import { HashLink } from 'react-router-hash-link';

import { ROOT_BREADCRUMB_NAME, HEADER_HEIGHT_XL } from 'app/utils/constants';
import clientRoutes from 'common/clientRoutes';
import BreadCrumbs from 'nkc-frontend-tools/components/BreadCrumbs/BreadCrumbs';
import { useResetWindowScroll } from 'app/hooks/useResetWindowScroll';
import LibraryStat from 'app/components/common/LibraryStat';

import * as styles from './About.scss';

const breadCrumbsPaths = [
    {
        title: ROOT_BREADCRUMB_NAME,
        link: clientRoutes.main.getUrl()
    },
    {
        title: 'О библиотеке',
        link: clientRoutes.about.getUrl()
    }
];

const enum AboutSectionID {
    RFBR_TODAY = 'rfbr-today',
    PURPOSE = 'purpose'
}

const About = () => {
    useResetWindowScroll([]);

    const scrollIntoView = useCallback(
        (el) => {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        []
    );

    return (
        <div className={styles.aboutPage}>
            <div className={styles.aboutPageBreadcrumbs}>
                <BreadCrumbs
                    paths={breadCrumbsPaths}
                    delimiter={<i className={'icon-arrow-right'}/> as any}
                />
            </div>
            <header className={styles.aboutPageHeader}>
                <h1 className={styles.aboutPageTitle}>О библиотеке</h1>
            </header>
            <div className={styles.aboutPageMain}>
                <nav className={styles.aboutPageNavigation}>
                    <Sticky
                        top={HEADER_HEIGHT_XL}
                    >
                        <ul className={styles.aboutPageNavigationList}>
                            <li className={styles.aboutPageNavigationItem}>
                                <HashLink
                                    className={styles.aboutPageNavigationLink}
                                    to={`#${AboutSectionID.RFBR_TODAY}`}
                                    scroll={scrollIntoView}
                                >
                                    Библиотека РФФИ сегодня
                                </HashLink>
                            </li>
                            <li className={styles.aboutPageNavigationItem}>
                                <HashLink
                                    className={styles.aboutPageNavigationLink}
                                    to={`#${AboutSectionID.PURPOSE}`}
                                    scroll={scrollIntoView}
                                >
                                    Цель
                                </HashLink>
                            </li>
                        </ul>
                    </Sticky>
                </nav>
                <article className={styles.aboutPageText}>
                    <section className={styles.aboutPageSection} id={AboutSectionID.RFBR_TODAY}>
                        <h2 className={styles.aboutPageSectionTitle}>
                            Библиотека РФФИ сегодня
                        </h2>
                        <p className={styles.aboutPageSectionParagraph}>
                            Электронная библиотека Российского фонда фундаментальных исследований (РФФИ)
                            содержит в свободном доступе издания, выпущенные при поддержке Фонда. Она
                            стала первой и пока единственной реализованной некоммерческой научной
                            электронной библиотекой общероссийского масштаба.
                        </p>
                        <p className={styles.aboutPageSectionParagraph}>
                            Библиотека РФФИ включает:
                        </p>
                        <div className={styles.aboutPageSectionParagraph}>
                            <LibraryStat/>
                        </div>
                    </section>
                    <section className={styles.aboutPageSection} id={AboutSectionID.PURPOSE}>
                        <h2 className={styles.aboutPageSectionTitle}>
                            Цель
                        </h2>
                        <p className={styles.aboutPageSectionParagraph}>
                            В 1998 г. Российский фонд фундаментальных исследований и Российский фонд
                            технологического развития запустили программу «Российские электронные
                            библиотеки» (РЭБ). Фонды реализовали 150 проектов и провели семинары
                            по проблемам электронных библиотек. В разработке концепции программы РЭБ
                            участвовал ряд российских министерств и ведомств.
                        </p>
                        <p className={styles.aboutPageSectionParagraph}>
                            Цель библиотеки РФФИ — сделать научные издания доступными для российских
                            ученых, профессоров и преподавателей, аспирантов и студентов.
                        </p>
                        <p className={styles.aboutPageSectionParagraph}>
                            Приоритетная задача библиотеки - предоставить российским ученым бесплатный
                            доступ к полным текстам журналов по всем направлениям фундаментальной науки.
                        </p>
                        <p className={styles.aboutPageSectionParagraph}>
                            Материалами Научной электронной библиотеки пользуются ученые из 350 научных
                            организаций РАН, университетов и вузов, областных научно-технических библиотек.
                            Ежедневно к материалам библиотеки обращаются тысячи ученых, которые
                            просматривают и читают десятки тысяч журнальных страниц. Число электронных
                            журналов на библиотечном сервере РФФИ приблизилось к 1000. На библиотечном
                            сервере РФФИ разместят все журналы издательства “Шпрингер”. В свободном доступе
                            находятся базы данных по математике, биологии и медицине, база данных по
                            индексам цитирования Института научной информации США. Создана база кратких
                            отчетов о научных проектах, которые финансирует РФФИ.
                        </p>
                    </section>
                </article>
            </div>
        </div>
    );
};

export default About;
