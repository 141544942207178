/**
 * Created by Lkarmelo on 02.10.2017.
 */

import {createAction, ActionMeta, Action} from 'redux-actions';

import {IgnoreSearchActionMeta} from '../../epics/operators';

export interface ISetSkipMeta extends IgnoreSearchActionMeta {
    ignorePagingBlock: boolean;
}
interface ISetSkip {
    (skip: number, ignoreSearchTrigger?: boolean, ignorePagingBlock?: boolean): ActionMeta<number, ISetSkipMeta>;
}
export const setSkip: ISetSkip = createAction<number, ISetSkipMeta, number, boolean, boolean>(
    'SET_SKIP',
    skip => skip,
    (skip, ignoreSearchTrigger = false, ignorePagingBlock = false) => ({ignoreSearchTrigger, ignorePagingBlock})
);
interface ISetLimit {
    (limit: number, ignoreSearchTrigger?: boolean): ActionMeta<number, IgnoreSearchActionMeta>;
}
export const setLimit: ISetLimit = createAction<number, IgnoreSearchActionMeta, number, boolean>(
    'SET_LIMIT',
    (limit: number): number => limit,
    (limit: number, ignoreSearchTrigger: boolean = false): IgnoreSearchActionMeta => ({ignoreSearchTrigger})
);
export const blockPaging = createAction('BLOCK_PAGING');
export const unblockPaging = createAction('UNBLOCK_PAGING');

export interface IResetPagingPayload {
    resetLimit?: boolean;
}
interface IResetPaging {
    (resetLimit?: boolean): Action<IResetPagingPayload>;
}
export const resetPaging: IResetPaging = createAction<IResetPagingPayload, boolean>(
    'RESET_PAGING',
    (resetLimit = false) => ({resetLimit})
);
