import React, {useCallback, useState} from 'react';

import RegularModal from 'app/components/common/modals/RegularModal';

import {useHandleFormStatus} from 'app/hooks/forms';

import IProps from './interfaces/IConfirmDeleteBookProps';

import * as styles from './ConfirmDeleteBook.scss';

export const ConfirmDeleteBook: React.FC<IProps> = (props) => {
    const {isVisible, requestDelete, closePopup, formStatus, bookId, resetFormStatus, resetForm} = props;
    const [sendFailed, setSendFailed] = useState(false);

    const onExited = useCallback(
        () => {
            resetForm();
            setSendFailed(false);
        },
        []
    );

    const sendRequestDelete = useCallback(
        () => {
            bookId && requestDelete(bookId);
        },
        [bookId]
    );

    const onSendSuccess = useCallback(
        () => {
            //пока не ясно, что именно делать при удалении
            window.location.reload();
        },
        []
    );

    const onSendFail = useCallback(
        () => {
            setSendFailed(true);
        },
        []
    );

    useHandleFormStatus(formStatus, resetFormStatus, onSendSuccess, onSendFail);

    return (
        <RegularModal
            isVisible={isVisible}
            close={closePopup}
            onExited={onExited}
        >
            <div className={styles.confirmDeleteBookModalContent}>
                    <div className={styles.confirmDeleteBookTitle}>Удалить материал?</div>
                    <div className={styles.confirmDeleteBookText}>Все данные будут удалены.</div>
                    <div className={styles.confirmDeleteBookControls}>
                        <button
                            className={`btn-primary`}
                            onClick={sendRequestDelete}
                        >
                            удалить
                        </button>
                        <button
                            className={'btn-cancel'}
                            onClick={closePopup}
                        >
                            отменить
                        </button>
                    </div>
                    {sendFailed &&
                        <div className={'form-field-error'}>
                            Удаление не удалось
                        </div>
                    }
                </div>
        </RegularModal>
    );
};

export default ConfirmDeleteBook;
