/**
 * Created by lkarmelo on 20.08.2019.
 */

import React from 'react';

import {Form, Field} from 'react-final-form';
import {TextFormField, InputFieldMode} from 'app/components/common/form-fields';

import {emailValidation} from 'app/utils/forms/validation';

import IProps from './interfaces/IEmailInfoFormProps';

import * as styles from './EmailInfoForm.scss';

const EmailInfoForm: React.FC<IProps> = ({onSubmit, renderControls, initEmail}) => (
    <Form onSubmit={onSubmit}>
        {({handleSubmit, submitting, pristine, errors}) => {
            const formHasErrors = errors && Object.keys(errors).length > 0;
            return (
                <form onSubmit={handleSubmit} className={styles.emailInfoForm}>
                    <div className={styles.emailInfoFormTitle}>
                        Изменение email для уведомлений
                    </div>
                    <Field name={'email'} initialValue={initEmail} validate={emailValidation}>
                        {({input, meta}) => (
                            <TextFormField
                                {...input}
                                mode={InputFieldMode.MATERIAL}
                                label={'Email для уведомлений'}
                                disabled={submitting}
                                error={meta.error}
                            />
                        )}
                    </Field>
                    <div className={styles.emailInfoFormHint}>
                        На указанный Вами электронный адрес будет выслано письмо со ссылкой,&#32;
                        перейдите по ней для подтверждения адреса электронной почты.
                    </div>
                    {renderControls(handleSubmit, submitting || formHasErrors || pristine, submitting)}
                </form>
            );
        }}
    </Form>
);

export default EmailInfoForm;
