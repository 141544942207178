/**
 * Created by lkarmelo on 18.10.2019.
 */

import {IBookListActionProps} from '../List';

import {setSearchResults} from 'nkc-frontend-tools/redux/actions/search/results';
import {searchReject} from 'app/redux/actions/loading';
import {filtersToDefaults} from 'nkc-frontend-tools/redux/actions/search/defaultFilters';
import {withIgnoreSearchActionMeta} from 'nkc-frontend-tools/redux/actions/utils/withIgnoreSearchActionMeta';
import {resetPaging, setLimit, setSkip} from 'nkc-frontend-tools/redux/actions/search/searchPaging';
import {fetchSearchResults} from 'app/redux/actions/search/results';
import {setDefaultLimit} from 'app/redux/actions/search/paging';
import {fetchMoreSearchResults} from 'app/redux/actions/search/results';

import {getDocumentsLimitByViewMode} from 'common/getDocumentsLimitByViewMode';
import * as Cookies from 'common/cookies/Cookies';

export const contextListMapDispatchToProps = (dispatch): IBookListActionProps => ({
    fetchSearchResults(): void {
        dispatch(fetchSearchResults());
    },
    setLimit(limit: number): void {
        dispatch(setLimit(limit));
    },
    setQuery() {},
    onUnMount(): void {
        dispatch(searchReject());
        dispatch(setSearchResults({}));
        dispatch(withIgnoreSearchActionMeta(filtersToDefaults()));
        dispatch(resetPaging(true));
    },
    loadMoreBooks(currentBookCount: number, limit: number): void {
        dispatch(withIgnoreSearchActionMeta(setLimit(limit)));
        dispatch(withIgnoreSearchActionMeta(setSkip(currentBookCount)));
        dispatch(fetchMoreSearchResults());
    },
    onChangeViewMode(viewMode: Cookies.ViewModeCookie): void {
        dispatch(setDefaultLimit(getDocumentsLimitByViewMode(viewMode)));
    }
});
