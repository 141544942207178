/**
 * Created by lkarmelo on 23.08.2019.
 */
import {Action, createAction, handleActions} from 'redux-actions';
import initialState from 'common/store/storeInitialState';
import {raiseSystemNotification, removeSystemNotification} from '../actions/systemNotifications';
import {ExtendedStore} from 'common/store';
import uniqueId from 'lodash.uniqueid';

export default handleActions(
    {
        [raiseSystemNotification.toString()](
            state: ExtendedStore.ISystemNotification[],
            {payload: type}: Action<ExtendedStore.SystemNotificationType>
        ): ExtendedStore.ISystemNotification[] {
            const nextState = state.slice();
            nextState.push({
                id: uniqueId('system-notification-'),
                type
            });

            return nextState;
        },
        [removeSystemNotification.toString()](
            state: ExtendedStore.ISystemNotification[],
            {payload: id}: Action<string>
        ): ExtendedStore.ISystemNotification[] {
            const notificationIndex = state.findIndex(notification => id === notification.id);
            if (notificationIndex < 0) {
                return state;
            }
            const nextState = state.slice();
            nextState.splice(notificationIndex, 1);
            return nextState;
        }
    },
    initialState.systemNotifications
);
