/**
 * Created by lkarmelo on 30.10.2019.
 */

import {useEffect} from 'react';

export const setBodyClassOnCondition = (cls: string, condition: boolean): void => {

    useEffect(
        () => {
            if (condition) {
                document.body.classList.add(cls);

                return () => document.body.classList.remove(cls);
            }
        },
        [cls, condition]
    );
};
