/**
 * Created by lkarmelo on 07.10.2019.
 */

import createStore from './createStore';
import {ExtendedStore, storeInitialState} from 'common/store';

declare global {
    //tslint:disable-next-line
    interface Window {
        __STATE__: ExtendedStore.IState;
    }
}

const store = createStore(typeof window !== 'undefined' && window.__STATE__ ? window.__STATE__ : storeInitialState);

export default store;
