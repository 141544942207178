/**
 * Created by lkarmelo on 05.08.2019.
 */

import React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';

import {renderError} from '../../utils/renderError';

import IProps from '../interfaces/IBaseInputFormFieldProps';

import * as styles from './RegularFormField.scss';

const RegularFormField: React.FC<IProps> = ({className, label, children, ...rest}) => {
    const {disabled, error} = rest;

    const id = useMemo(() => uniqueId('text-field-'), []);

    return (
        <div
            className={classNames(
                className,
                'form-field',
                styles.regularFormFieldBase,
                {[styles.regularFormFieldBaseDisabled]: disabled}
            )}
        >
            <label
                htmlFor={id}
                className={styles.regularFormFieldBaseLabel}
            >
                {label}
            </label>
            <input
                {...rest}
                id={id}
                className={`${styles.regularFormFieldBaseInput} ${error ? styles.regularFormFieldBaseInputError : ''}`}
            />
            {children}
            <div className={'form-field-error'}>
                {renderError(error)}
            </div>
        </div>
    );
};

export default RegularFormField;
