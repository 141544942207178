import { IOption } from './interfaces/ISelectProps';

export const normalizeOption = (values: (IOption | string)[]) =>
    values.map((itemValue: (IOption | string)) => {
        if (typeof itemValue === 'string') {
            return {
                id: itemValue.toString(),
                label: itemValue.toString()
            };
        }

        if (!itemValue.children ) {
            return {...itemValue};
        }

        return {
            ...itemValue,
            children: normalizeOption(itemValue.children)
        };
    });

export const findOption = (searchIds: string[], values: IOption[], result: IOption[]) => {
    values.forEach((currentValue: IOption) => {
        if (searchIds.includes(currentValue.id)) {
            result.push(currentValue);
        }

        !!currentValue.children &&
            findOption(searchIds, currentValue.children, result);
    });

    return result;
};

export const filterOption = (search: string, values: Array<IOption>): Array<IOption> =>
    values.reduce(
        (filtered: Array<IOption>, option: IOption) => {
            const optionNameLowerCase = option.label.toLowerCase();

            const optionMatch = optionNameLowerCase.includes(search);
            const filteredChildren = !!option.children ? filterOption(search, option.children) : [];
            const childrenMatch = filteredChildren.length > 0;

            if (optionMatch && !childrenMatch) {
                filtered.push(option);
            } else if (childrenMatch || optionMatch) {
                const openOption = {
                    ...option,
                    children: filteredChildren,
                    open: true,
                    key: `${option.id}open`
                };
                filtered.push(openOption);
            }

            return filtered;
        },
        []
    );
