/**
 * Created by lkarmelo on 05.08.2019.
 */

import React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import {renderError} from '../../utils/renderError';

import IProps from '../interfaces/IBaseInputFormFieldProps';

import * as styles from './MaterialFormField.scss';

const MaterialFormField: React.FC<IProps> = ({className, label, children, required, ...rest}) => {
    const {value, onBlur, onFocus, disabled, readOnly, error} = rest;

    const [isFocused, setIsFocused] = useState(false);

    const shrinkLabel: boolean = !!value || (isFocused && !readOnly);

    const onInputFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(true);
            onFocus && onFocus(e);
        },
        [onFocus]
    );

    const onInputBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(false);
            onBlur && onBlur(e);
        },
        [onBlur]
    );

    const id = useMemo(() => uniqueId('text-field-'), []);

    return (
        <div
            className={classNames(
                className,
                'form-field',
                styles.materialFormFieldBase,
                {[styles.materialFormFieldBaseDisabled]: disabled}
            )}
        >
            <label
                htmlFor={id}
                className={classNames(
                    styles.materialFormFieldBaseLabel,
                    {
                        [styles.materialFormFieldBaseLabelShrink]: shrinkLabel,
                        [styles.materialFormFieldBaseLabelRequired]: required
                    }
                )}
            >
                {label}
            </label>
            <input
                {...rest}
                id={id}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                className={classNames(
                    styles.materialFormFieldBaseInput,
                    {[styles.materialFormFieldBaseInputError]: error}
                )}
            />
            {children}
            <div className={'form-field-error'}>
                {renderError(error)}
            </div>
        </div>
    );
};

export default MaterialFormField;
