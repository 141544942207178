/**
 * Created by lkarmelo on 18.01.2019.
 */

import {Store} from '../../nkc-frontend-tools/types';
import { INotificationSetting, INotificationsResponseBody } from 'common/api/ExtendedApi';
import { AjaxError } from 'rxjs';

export type CoverSize = 'SMALL' | 'MEDIUM' | 'BIG' | 'DEFAULT';

export const enum ObjectType {
    TechnicalBulletin = 'TechnicalBulletin',
    SocialBulletin = 'SocialBulletin',
    AnnotationReport = 'AnnotationReport',
    PublicationReferenceBook = 'PublicationReferenceBook',
    Catalog = 'Catalog',
    Book = 'Book',
    Digest = 'Digest',
    ScienceArticle = 'ScienceArticle',
    Article = 'Article',
    InformationalBulletin = 'InformationalBulletin',
}

export const objectTypeCoverShortTitles = {
    [ObjectType.TechnicalBulletin]: 'Вестник РФФИ', // на обложке не отображается
    [ObjectType.SocialBulletin]: 'Вестник РФФИ. Гуманитарные и общественные науки', // на обложке не отображается
    [ObjectType.AnnotationReport]: 'Отчет',
    [ObjectType.PublicationReferenceBook]: 'Публикации',
    [ObjectType.Catalog]: 'Каталог',
    [ObjectType.Book]: 'Книга',
    [ObjectType.Digest]: 'Сборник',
    [ObjectType.ScienceArticle]: 'Статья',
    [ObjectType.InformationalBulletin]: 'Бюллетень',
    [ObjectType.Article]: 'Другое', // Фильтр - Другие материалы, displayValue: Статья из Вестника РФФИ
};

export const objectTypeColors = {
    [ObjectType.TechnicalBulletin]: '#094074',
    [ObjectType.SocialBulletin]: '#094074',
    [ObjectType.AnnotationReport]: '#5c946e',
    [ObjectType.PublicationReferenceBook]: '#959791',
    [ObjectType.Catalog]: '#71a2b6',
    [ObjectType.Book]: '#136f63',
    [ObjectType.Digest]: '#574980',
    [ObjectType.ScienceArticle]: '#094074',
    [ObjectType.Article]: '#2b4141',
    [ObjectType.InformationalBulletin]: '#615e60',
};

export enum BookSearchContext {
    Search = 'search',
    Catalog = 'catalog',
    Favorite = 'favorite',
    UploadedList = 'uploadedList',
}

export const enum SortFilterValues {
    Relevancy = 'rel',
    Date = 'creationDate',
}

declare module '../../nkc-frontend-tools/types/Store' {

    export interface IFilterValueDescription {
        children?: string[];
    }

    export interface IDocumentPerson {
        firstName?: string;
        middleName?: string;
        lastName?: string;
    }

    export interface IDocumentDescription {
        likesCount?: number;
        sharesCount?: number;
        viewsCount?: number;
    }

    export interface IDocumentSnippet {
        optionalAttrs?: IBookAttr[];
    }

    export interface IPaging {
        defaultLimit?: number;
    }
}

export interface IFiltersMetaWithNormalizedValues {
    [key: string]: IFilterMetaWithNormalizedValues;
}

export interface IExtendedFilterMeta<T = Store.IFilterValueDescription[]> extends Store.IFilterMeta<T> {
    name: string;
}

export interface IFilterMetaWithNormalizedValues extends IExtendedFilterMeta<IFilterValuesDescriptionMap> {
    topValues: string[];
}

export interface IFilterValuesDescriptionMap {
    [filterVal: string]: Store.IFilterValueDescription;
}

export interface ISocialDocument {
    id: string;
    url: string;
    image?: string;
    imageVk?: string;
    title?: string;
    description?: string;
    updateTime?: number;
}

export interface IShowcaseDocuments {
    latest?: string [];
    journalsTechnical?: string;
    journalsSocial?: string;
    popularScience?: string[];

    items?: IDocumentMap;
}

export interface IDocumentMap {
    [id: string]: IDocumentSnippet;
}

export interface IBookAttr {
    code: string;
    title: string;
    value: any;
}

export interface IDocument {
    id?: string;
    projectNumber?: string[];
    year?: number;
    title?: string;
    shortTitle?: string;
    abstract?: string;
    materialType?: string;
    authors?: Store.IDocumentPerson[];
    originalId?: string;
    storageUrl?: string;
    tags?: string[];
    knowledgeCategories?: Store.ICategory[];
    grnti?: Store.ICategory[];
    creationDate?: number;
    modifyDate?: number;
    isPublished?: boolean | null;
    meta?: {
        type?: ObjectType;
        isLastNumber?: boolean;
        issueInfo: {
            editor?: string;
            issueDate?: {
                nanos: number;
                seconds: number;
            }
            [key: string]: string | {seconds: number, nanos: number} | string[];
        }
    };
}

export interface IDocumentStats {
    viewsCount?: number;
    likesCount?: number;
    sharesCount?: number;
    shares?: IShareStat[];
}

export interface IShareStat {
    externalSystemId: string;
    count: number;
}

export interface IDocumentSnippet {
    annotation?: {content: string; ordering: number; similarity: number; }[];
    categories?: Store.ICategory[];
    keywords?: string[];
    tags?: Store.ICategory[];
    document?: IDocument;
    stats?: IDocumentStats;
    liked?: boolean;
    covers?: ICover[];
    bookmarked?: {
        FAVORITE?: boolean
    };
}

export interface ICover {
    coverSize: CoverSize;
    link: string;
}

export interface ISimilarity {
    similarity: number;
    documentSnippet?: IDocumentSnippet;
}

export interface IObjectCard {
    similar?: ISimilarity[];
    documentSnippet?: IDocumentSnippet;
    children?: IDocumentSnippet[];
    parent?: IDocumentSnippet;
    isContentAvailable?: boolean;
    preloadFromServer?: boolean;
}

export interface IDocumentResults extends Store.IResults<IDocumentSnippet> {
    aggregations?: Store.IAggregation[];
}

export interface IDocumentSearch extends Store.ISearch<IDocumentResults> {
    //нужно, чтобы определять дефолтные значения для фильтров, у которых дефолтное значение зависит от контекста
    contextName?: string;
}

export interface IIssueInfoCatalogItem {
    code: string;
    displayValue: string;
    parent?: string;
}

export interface ICatalogItem {
    code: string;
    title: string;
}

export interface ILibraryStats {
    rfbrBooks?: number;
    popularScienceArticles?: number;
    annotationReports?: number;
    authors?: number;
}

export interface IAuthOptions {
    closeWindow?: boolean;
}

export interface ISystemUser extends Store.IUser {
    permissions: string[];
    login?: string;
    userSocial?: IUserSocial[];
}

export interface IProfile extends ISystemUser {
    confirmedEmail?: boolean;
}

export type IUserSocial = string;

export interface IForms {
    [formName: string]: IForm;
}

export interface IForm<TR = any, TD = any> {
    //все поля формы обычно редактируются в state компонента, но если нужно между несколькими компонентами разделять данные, можно
    //записывть сюда
    data?: TD;
    response?: TR;
    status?: FormStatus | null;
    backUrl?: string;
}

export const enum FormStatus {
    Pending = 'pending',
    Accepted = 'accepted',
    Rejected = 'rejected',
    Edited = 'edited',
    Completed = 'completed'
}

export interface ISystemNotification {
    id: string;
    type: SystemNotificationType;
}

export const enum SystemNotificationType {
    FavoriteAdd = 'favoriteAdd',
    FavoriteRemove = 'favoriteRemove',
    PasswordChange = 'changePassword',
    EmailChange = 'emailChange',
    EmailConfirmSent = 'emailConfirmSent',
    SubscribeToAllNews = 'subscribeToAllNews',
    BookmarkAdd = 'bookmarkAdd',
    DocumentAdd = 'documentAdd',
    DocumentUpdate = 'documentUpdate',
    DocumentContentNotUploaded = 'documentContentNotUploaded'
}

export interface IPopups {
    [popupName: string]: boolean;
}

export interface IBookReader {
    meta?: IReadingBookMeta;
    pages: {
        currentPage?: SVGSVGElement | null;
    };
    currentPageNumber?: number;
    currentBookId?: string;
    bookmarks?: IBookmark[];
}

export interface IReadingBookMeta {
    bookId: string;
    title: string;
    currentPage: number;
    totalPages: number;
    currentScale: number;
}

export interface IBookmark {
    bookId: string;
    page: number;
    title: string;
}

export interface ISubscriptionMap {
    [value: string]: boolean;
}

export interface IGroupedSubscriptions {
    [group: string]: ISubscriptionMap | boolean;
}

export interface INotifications extends INotificationsResponseBody {
    requestParams: {
        skip: number;
        limit: number;
    };
    notificationSettings: Record<string, INotificationSetting>;
    notificationTypes: Record<string, string>;
}

export interface IFileUploadProgressMap {
    [id: string]: IFileUploadProgress;
}

export type FileUploadAjaxError = Pick<AjaxError, 'message' | 'status'>;

export interface IFileUploadProgress {
    fileName: string;
    progressRatio: number; // currentProgress / total
    fileSize: number;
    success?: boolean;
    error?: FileUploadAjaxError;
}

export interface IState {
    [BookSearchContext.Search]: IDocumentSearch;

    filtersMeta: Store.IFiltersMeta;
    defaultFilters: Store.IFilters;

    objectCard?: IObjectCard;

    issueInfoCatalog?: IIssueInfoCatalogItem[];

    authorization: Store.IAuthorization;
    passwordRecovery: {
        status?: number;
        error?: string;
    };

    //пользователь системы
    user?: ISystemUser;

    //профиль пользователя
    profile: IProfile | null;

    subscriptions: IGroupedSubscriptions;

    notifications: INotifications;

    loading: Store.ILoadingInfo;
    // добавляется react-redux-loading-bar
    loadingBar: {default: number};

    showcaseDocuments?: IShowcaseDocuments;

    libraryStats?: ILibraryStats;

    [BookSearchContext.Catalog]: IDocumentSearch;
    [BookSearchContext.Favorite]: IDocumentSearch;
    [BookSearchContext.UploadedList]: IDocumentSearch;

    systemNotifications?: ISystemNotification[];

    forms: IForms;

    bookReader: IBookReader;

    fileUploadProgress: IFileUploadProgressMap;

    //тут содержатся некоторые всплывающие элементы интерфейса. не все контролируются редаксом, для некоторых просто хвататет внутреннего
    //состояния реакт компонента
    popups: IPopups;
}
