/**
 * Created by lkarmelo on 13.06.2019.
 */

import {ExtendedStore} from 'common/store';
import {Store} from 'nkc-frontend-tools/types';
import {SEARCH_QUERY_PARAM_NAME} from 'app/utils/constants';
import {StaticFilterName} from 'common/types/FilterName';

/*
    TODO: сделать чтобы работали с любыми фильтрами, если понадобится. сейчас рассчитаны только на фильтр сортировки,
        потому что для других фильтров не нужно определять дефолтное значение по состоянию
 */

const defaultFromSearch = (contextState: ExtendedStore.IDocumentSearch, parsedLocationString?: {[key: string]: any}): string => {
    const query = parsedLocationString ? parsedLocationString[SEARCH_QUERY_PARAM_NAME] : contextState.searchQuery;
    if (!query) {
        return ExtendedStore.SortFilterValues.Date;
    }
    return ExtendedStore.SortFilterValues.Relevancy;
};

const defaultFromCatalog = (contextState: ExtendedStore.IDocumentSearch): string => {
    return ExtendedStore.SortFilterValues.Date;
};

export const getDefaultFilterValue = (
    contextState: ExtendedStore.IDocumentSearch,
    filterMeta: ExtendedStore.IExtendedFilterMeta | ExtendedStore.IFilterMetaWithNormalizedValues,
    parsedLocationString?: {[key: string]: any}
): Store.IFilterValue => {
    if (!contextState || filterMeta.name !== StaticFilterName.Sort) {
        return filterMeta ? filterMeta.default : undefined;
    }

    switch (contextState.contextName) {
        case ExtendedStore.BookSearchContext.Favorite:
        case ExtendedStore.BookSearchContext.Catalog: return defaultFromCatalog(contextState);
        default: return defaultFromSearch(contextState, parsedLocationString);
    }
};
