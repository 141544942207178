/**
 * Created by lkarmelo on 29.03.2019.
 */

import {createSelector} from 'reselect';

import {ExtendedStore} from 'common/store';
import {Store} from 'nkc-frontend-tools/types';

export const filtersValueToLabelSelector = createSelector(
    (state: ExtendedStore.IState): Store.IFiltersMeta => state.filtersMeta,
    (filtersMeta: Store.IFiltersMeta): {[code: string]: string} => {
        const valLabelMap = {};
        if (!filtersMeta) {
            return valLabelMap;
        }
        return Object.values(filtersMeta)
            .filter(val => val.type === Store.FilterType.MultiSelect)
            .reduce(
                (res, meta) => {
                    Array.isArray(meta.values) && meta.values.forEach(val => {
                        if (res[val.value]) {
                            console.warn('Different filters with same value codes encountered');
                        }
                        res[val.value] = val.label;
                    });
                    return res;
                },
                valLabelMap
            );
    }
);
