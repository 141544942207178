import { Action } from 'redux-actions';
/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {createStore, applyMiddleware, compose} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import { MiddlewareAPI } from 'redux';

import reducers from '../reducers';
import epics from '../epics';
import {apiCalls} from 'nkc-frontend-tools/api';
import {apiCalls as rfbrApiCalls} from 'common/api';

import {ExtendedStore} from 'common/store';
import {Dispatch} from 'redux';

const dependencies: {apiCall: any} = {
    apiCall: {...apiCalls, ...rfbrApiCalls},
};

const epicMiddleware = createEpicMiddleware(
    epics,
    {
        dependencies,
    });

type ErrorHandler = (error: Error, state: () => ExtendedStore.IState, action: Action<any>, dispatchFunction: Dispatch<any>) => void;

const createCatchMiddleware = (errorHandler: ErrorHandler) =>
    (store: MiddlewareAPI<any>) =>
        (next: Dispatch<any>) =>
            (action) => {
                try {
                    return next(action);
                } catch (err) {
                    errorHandler(err, store.getState, action, store.dispatch);
                    return err;
                }
            };

const reducerErrorHandler: ErrorHandler = (error, getState, lastAction) => {
    console.error(`THE ERROR\n ${error}\n last action  was: ${JSON.stringify(lastAction)}`);
    console.trace(error.stack);
};

const catchMiddleware = createCatchMiddleware(reducerErrorHandler);

export default (initialState) =>
    createStore(
        reducers,
        initialState,
        compose(
            applyMiddleware(
                catchMiddleware,
                epicMiddleware
            ),
            (<any>window).__REDUX_DEVTOOLS_EXTENSION__ ? (<any>window).__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );
