/**
 * Created by lkarmelo on 11.04.2019.
 */

import React from 'react';

import BookDetailsBase from '../Base/BookDetailsBase';

import IProps from '../interfaces/IBookDetailsProps';

const renderCategories = () => null;

const BookDetailsDigest: React.FunctionComponent<IProps> = (props) => (
    <BookDetailsBase renderCategories={renderCategories} {...props} />
);

export default BookDetailsDigest;
