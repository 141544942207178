import {createAction} from 'redux-actions';

import { IDocument } from 'common/store/ExtendedStore';
import { IDocumentAttrs } from 'app/components/pages/documents-upload/interfaces/IAttrDocumentFormProps';

export const updateDocument = createAction<{[key: string]: any}>('UPDATE_DOCUMENT');

export const createDocument = createAction<{[key: string]: any}>('CREATE_DOCUMENT');

export const fetchDocument = createAction<string>('FETCH_DOCUMENT');

export const setDocument = createAction<{[key: string]: any}>('SET_DOCUMENT');

export const publishDocument = createAction<string>('PUBLISH_DOCUMENT');

export const unpublishDocument = createAction<string>('UNPUBLISH_DOCUMENT');
