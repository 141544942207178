import { createFormSendEpic } from './createFormSendEpic';
import { resetPassword } from 'app/redux/actions/forms/formsSendActions';
import { FormName } from 'app/types/FormName';
import { resetPasswordRequest, resetPasswordResolve, resetPasswordReject } from 'app/redux/actions/loading';

export const resetPasswordEpic = createFormSendEpic({
    triggers: [resetPassword.toString()],
    formName: FormName.ResetPassword,
    actions: {
        requestAction: resetPasswordRequest,
        resolveAction: resetPasswordResolve,
        rejectAction: resetPasswordReject
    }
});
