/**
 * Created by Lkarmelo on 14.03.2018.
 */

import { Dispatch, MiddlewareAPI } from 'redux';

import { Store } from '../../../types';

import { refreshAuthenticator } from '../../actions/authorization';
import { refreshAuthenticatorSuccess, refreshAuthenticatorFailed } from '../../actions/authorization';

import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';

export const retryOnRejectEpic = (
    triggers: string[],
    resolveTriggers: string[],
    rejectTriggers: string[]
) => (
    action$,
    store: MiddlewareAPI<Store.IBaseState>
) => {

    let untilTriggers = [... resolveTriggers, refreshAuthenticatorFailed.toString()];

    return merge(
        action$.ofType(...triggers)
            .switchMap(originalAction => {
                return action$.ofType(refreshAuthenticatorSuccess.toString())
                    .mapTo(originalAction)
                    .take(1)
                    .takeUntil(action$.ofType(...untilTriggers));
            })
            .do(action => store.dispatch(action))
            .ignoreElements(),

        action$.ofType(... rejectTriggers)
            .filter(action => {
                const state = store.getState();

                return (!!action.payload && !!action.payload.status && action.payload.status === 401) &&
                    state.authorization.isAuthorized !== false;
            })
            .mergeMap(() =>
                Observable.of(refreshAuthenticator())
                    .takeUntil(action$.ofType(... rejectTriggers))
            )
    );
};
