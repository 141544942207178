/**
 * Created by Lkarmelo on 25.01.2018.
 */

import {Dispatch, MiddlewareAPI} from 'redux';
import {Observable} from 'rxjs/Observable';
import {concat} from 'rxjs/observable/concat';
import {merge} from 'rxjs/observable/merge';
import {Action} from 'redux-actions';

import {retryOnAuthorizedEpic} from './retryOnAuthorizedEpic';
import {retryOnRejectEpic} from './retryOnRejectEpic';

import {Store} from '../../../types';
import {Api} from '../../../api';

interface ITransformResponse<TResult = any, TResponse = any> {
    (response: TResponse): TResult;
}

type options = {
    triggers: string[];
    apiCallName: keyof Api.ApiCalls;
    actions: {
        requestAction: Function;
        resolveAction: Function;
        rejectAction: Function;
        setAction: Function;
        spawnOnSuccess?: Function[];
    };
    retryOnAfterAuth?: boolean;
    retryOnReject?: boolean;
    fetchAction?: Function;
    filterTriggering?: (state: Store.IBaseState, action: Action<any>) => boolean;
    transformRequest?: (state: Store.IBaseState, action: Action<any>) => any;
    transformResponse?: ITransformResponse;
};

const defaultOptions = {
    filterTriggering: () => true,
    transformResponse: resp => resp,
    retryOnReject: true,
};

const createSimpleLoadingEpic = (opts: options) => {
    const mergedOptions: options = {...defaultOptions, ...opts};
    const {
        triggers, apiCallName, actions,
        filterTriggering, transformResponse,
        transformRequest, retryOnAfterAuth, retryOnReject, fetchAction,
    } = mergedOptions;
    const {rejectAction, requestAction, resolveAction, setAction, spawnOnSuccess} = actions;

    const retryOnRejectWrapper = retryOnRejectEpic(Array.isArray(triggers)
        ? triggers
        : [triggers],                              [resolveAction.toString()], [rejectAction.toString()]);

    return (action$, store: MiddlewareAPI<Store.IBaseState>, {apiCall}) =>
        merge(
            retryOnAfterAuth ? retryOnAuthorizedEpic(action$, rejectAction.toString(), fetchAction) : Observable.empty(),
            retryOnReject ? retryOnRejectWrapper(action$, store) : Observable.empty(),
            action$.ofType(...triggers)
                .filter(action => filterTriggering(store.getState(), action))
                .map(action => transformRequest ? transformRequest(store.getState(), action) : action)
                .mergeMap(originalAction =>
                    concat(
                        apiCall[apiCallName](originalAction.payload)
                            .mergeMap(response => {
                                const processedResponse = transformResponse(response.response);
                                return spawnOnSuccess
                                    ? Observable.from([
                                        setAction(processedResponse, originalAction),
                                        ...spawnOnSuccess.map(a => a(processedResponse))
                                        ])
                                    : Observable.of(setAction(processedResponse, originalAction));
                            })
                            .catch(e => {
                                console.error(e);
                                return Observable.of(rejectAction(e));
                            }),
                        Observable.of(resolveAction())
                    )
                        .takeUntil(action$.ofType(rejectAction.toString(), ...triggers))
                        .startWith(requestAction())
                ),
        );
};

export default createSimpleLoadingEpic;
