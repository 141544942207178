/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {handleActions, combineActions} from 'redux-actions';

import {initialState} from '../initial-state';

import {Store} from '../../types';

import {
    SHOW_LOADING_ERROR, REMOVE_LOADING_ERROR, LoadingActionsProvider,
    ILoadingActionMeta, IRemoveLoadingErrorPayload, IShowLoadingErrorPayload
} from '../actions/loading';

const {loadingShowActionCreators, loadingHideActionCreators} = LoadingActionsProvider.actionLists;

export default handleActions<Store.ILoadingInfo, IShowLoadingErrorPayload | IShowLoadingErrorPayload, ILoadingActionMeta | undefined>(
    {
        [combineActions(...loadingShowActionCreators).toString()](state: Store.ILoadingInfo, {meta}: {meta: ILoadingActionMeta }) {
            return <Store.ILoadingInfo>{
                ...state,

                pendingRequests: {
                    ...state.pendingRequests,
                    [meta.requestName]: true
                },

                pendingRequestsCount: 1 + Object
                    .keys(state.pendingRequests)
                    .filter(reqName => state.pendingRequests[reqName] === true)
                    .length
            };
        },
        [SHOW_LOADING_ERROR](state: Store.ILoadingInfo, {payload}: {payload: IShowLoadingErrorPayload; meta: undefined}) {
            return <Store.ILoadingInfo>{
                ...state,
                loadingErrors: {
                    ...state.loadingErrors,
                    [payload.requestName]: {
                        message: payload.message,
                        showInMainErrorComponent: payload.showInMainErrorComponent
                    }
                }
            };
        },
        [combineActions(...loadingHideActionCreators).toString()](
            state: Store.ILoadingInfo,
            {error, meta}: {error?: boolean; meta: ILoadingActionMeta }
        ) {
            const oldPendingRequestsCount = Object
                .keys(state.pendingRequests)
                .filter(reqName => state.pendingRequests[reqName] === true)
                .length;

            return <Store.ILoadingInfo>{
                ...state,
                pendingRequests: {
                    ...state.pendingRequests,
                    [meta.requestName]: false
                },

                pendingRequestsCount: oldPendingRequestsCount > 0 ? oldPendingRequestsCount - 1 : oldPendingRequestsCount
            };
        },

        [REMOVE_LOADING_ERROR](state: Store.ILoadingInfo, {payload}: {payload: IRemoveLoadingErrorPayload, meta: undefined}) {
            const nextState: Store.ILoadingInfo = {
                ...state,
                loadingErrors: {
                    ... state.loadingErrors
                }
            };

            payload.requestNames.forEach((requestName) => {
                delete nextState.loadingErrors[requestName];
            });

            return nextState;
        }
    },
    initialState.loading //- default value for loading
);
