/**
 * Created by lkarmelo on 23.01.2019.
 */

import {createSimpleLoadingEpic} from 'nkc-frontend-tools/redux/epics/utils';

import {fetchShowcaseDocuments, setShowcaseDocuments} from 'app/redux/actions/showcaseDocuments';
import {documentsShowcaseRequest, documentsShowcaseReject, documentsShowcaseResolve} from 'app/redux/actions/loading';

import {getShowcaseDocuments} from 'common/api-response-payload-mutators/getShowcaseDocuments';

import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';

export const loadShowcaseDocuments = createSimpleLoadingEpic({
    triggers: [fetchShowcaseDocuments.toString()],
    apiCallName: 'documentsShowcase' as any,
    actions: {
        setAction: setShowcaseDocuments,
        requestAction: documentsShowcaseRequest,
        rejectAction: documentsShowcaseReject,
        resolveAction: documentsShowcaseResolve
    },
    transformResponse: (response: ExtendedApi.IShowcaseDocumentsResponseBody): ExtendedStore.IShowcaseDocuments =>
        getShowcaseDocuments(response)
});
