/**
 * Created by Lkarmelo on 11.09.2017.
 */

import createHistory from 'history/createBrowserHistory';
import {History} from 'history';

const history: History = createHistory();

export default history;
