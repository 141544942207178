/**
 * Created by lkarmelo on 10.01.2019.
 */

import {connect} from 'react-redux';

import Main from './Main';
import {
    IMainActionProps as IActionProps,
    IMainStateProps as IStateProps
} from './interfaces/IMainProps';

import {fetchShowcaseDocuments} from 'app/redux/actions/showcaseDocuments';

import {ExtendedStore} from 'common/store';

const mapStateToProps = (state: ExtendedStore.IState): IStateProps => ({
    showcaseDocuments: state.showcaseDocuments,
});

const mapDispatchToProps = (dispatch): IActionProps => ({
    fetchShowcaseDocuments(): void {
        dispatch(fetchShowcaseDocuments());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
