/**
 * Created by lkarmelo on 06.02.2019.
 */

export const DEFAULT_PAGING_ITEMS_LIMIT = 12;
export const PREVIEW_PAGING_ITEMS_LIMIT = 12;

export const SEARCH_VIEW_MODE_COOKIE_NAME = 'viewMode';

export const APP_ROOT_URL = 'https://irfbr.knowledge.cat';

export const FACEBOOK_APP_ID = '2230212770567090';

export const VK_PROVIDER_ID = 'vk';
export const FACEBOOK_PROVIDER_ID = 'facebook';
export const TWITTER_PROVIDER_ID = 'twitter';

export const NOTIFICATION_BADGE_OVERFLOW_COUNT = 99;
export const NOTIFICATION_BADGE_IN_MENU_OVERFLOW_COUNT = 999;

export const NOTIFICATIONS_DEFAULT_LIMIT = 15;

export const NOTIFICATIONS_POLLING_INTERVAL_MS = 30000;
