import {connect} from 'react-redux';

import PasswordResetEmailPopup from './PasswordResetEmailPopup';

import {closePopup} from 'app/redux/actions/popups';
import {requestResetPassword} from 'app/redux/actions/forms/formsSendActions';
import {removeForm, setFormStatus} from 'app/redux/actions/forms/forms';

import {FormName} from 'app/types/FormName';
import {PopupName} from 'app/types/PopupName';
import {ExtendedStore} from 'common/store';

import {IPasswordResetEmailPopupActionProps, IPasswordResetEmailPopupStateProps} from './interfaces/IPasswordResetEmailPopupProps';

const mapStateToProps = (state: ExtendedStore.IState): IPasswordResetEmailPopupStateProps => {
    const form = state.forms[FormName.ResetPasswordEmail];

    return {
        isVisible: state.popups[PopupName.ResetPassword],
        formStatus: form && form.status,
        formResponse: form && form.response
    };
};

const mapDispatchToProps = (dispatch): IPasswordResetEmailPopupActionProps => ({
    closePopup() {
        dispatch(closePopup(PopupName.ResetPassword));
    },
    requestReset(email: string): void {
        dispatch(requestResetPassword({email}));
    },
    resetForm(): void {
        dispatch(removeForm(FormName.ResetPasswordEmail));
    },
    resetFormStatus(): void {
        dispatch(setFormStatus(FormName.ResetPasswordEmail, null));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetEmailPopup);
