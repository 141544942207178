import { ExtendedStore } from 'common/store';
import { IConfirmAccountCreationStateProps, IConfirmAccountCreationActionProps } from './interfaces/IConfirmAccountCreationProps';
import { FormName } from 'app/types/FormName';
import { removeForm } from 'app/redux/actions/forms/forms';
import { connect } from 'react-redux';
import { confirmAccount } from 'app/redux/actions/forms/formsSendActions';
import {showPopup} from 'app/redux/actions/popups';
import {PopupName} from 'app/types/PopupName';

import ConfirmAccountCreation from './ConfirmAccountCreation';

const mapStateToProps = (state: ExtendedStore.IState): IConfirmAccountCreationStateProps => ({
    formStatus: state.forms[FormName.ConfirmAccount] && state.forms[FormName.ConfirmAccount].status
});

const mapDispatchToProps = (dispatch): IConfirmAccountCreationActionProps => ({
    confirmRequest(token: string): void {
        dispatch(confirmAccount(token));
    },
    resetForm(): void {
        dispatch(removeForm(FormName.ConfirmAccount));
    },
    showLoginPopup(): void {
        dispatch(showPopup(PopupName.Login));
    },
    showResetPasswordPopup(): void {
        dispatch(showPopup(PopupName.ResetPassword));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAccountCreation);
