/**
 * Created by PBorisov on 05.07.2019
 */
import React from 'react';
import {MouseEvent} from 'react';

import { connect } from 'react-redux';

import {showPopup} from 'app/redux/actions/popups';
import IInitAuthorizeOnClickWrapperProps from './interfaces/IInitAuthorizeOnClickWrapperProps';
import initAuthorizeOnClickWrapper from './InitAuthorizeOnClickWrapper';
import {PopupName} from 'app/types/PopupName';

const showLoginMapDispatchToProps = (dispatch): IInitAuthorizeOnClickWrapperProps => ({
    onInitAuthorize(e: MouseEvent<any>): void {
        e.preventDefault();

        dispatch(showPopup(PopupName.Login));
    }
});

export default (WrappedComponent): React.SFC<any> => {

    const WrapperComponentWithShowLogin = connect(null, showLoginMapDispatchToProps)(initAuthorizeOnClickWrapper(WrappedComponent));
    const WrapperComponentNoRedirect = WrappedComponent;

    return ({permissionChecker, isAuthorized, viewOnDisallowed, ...props}) => {

        const nextProps = Object.assign({},
                                        props,
                                        permissionChecker.isDisabled() ? {disabled: permissionChecker.isDisabled()} : {}
        );

        return (!!viewOnDisallowed ? permissionChecker.isVisible() : !permissionChecker.isVisible())
            ? null
            : permissionChecker.hasRedirect()
                ? <WrapperComponentWithShowLogin {...nextProps}/>
                : <WrapperComponentNoRedirect {...nextProps}/>;
    };
};
