import React from 'react';

import { Link } from 'react-router-dom';

import clientRoutes from 'common/clientRoutes';

import * as styles from './UserPanel.scss';

import PermissionKey from 'common/permissions/PermissionKey';
import { RestrictedPermission } from '../high-order/RestrictedPermission';
import { IUserMenuPopoverProps } from './interfaces/IUserPopoverPanelProps';
import Badge from '../common/Badge';
import { NOTIFICATION_BADGE_IN_MENU_OVERFLOW_COUNT } from 'common/constants';

export const UserMenuPopoverPanel: React.FC<IUserMenuPopoverProps> = (props: IUserMenuPopoverProps): JSX.Element => {
    const {onClose, onLogOut, notificationCount} = props;

    return (
        <React.Fragment>
            <button
                className={`${styles.headerPopoverCloseBtn} btn icon-cross`}
                onClick={onClose}
                onTouchEnd={onClose}
            />

            <ul className={styles.headerUserMenu}>
                <RestrictedPermission pk={PermissionKey.viewLkLink}>
                    <li className={styles.headerUserMenuListItem}>
                        <Link
                            onClick={onClose}
                            to={clientRoutes.profile.getUrl()}
                            className={styles.headerUserMenuItemTitle}
                        >
                            Личный кабинет
                        </Link>
                        <ul>
                            <li className={styles.headerUserMenuListSubItem}>
                                <Link
                                    onClick={onClose}
                                    to={clientRoutes.profile.getUrl()}
                                >
                                    Профиль
                                </Link>
                            </li>
                            <li className={styles.headerUserMenuListSubItem}>
                                <Link
                                    onClick={onClose}
                                    to={clientRoutes.favorite.getUrl()}
                                >
                                    Избранное
                                </Link>
                            </li>
                            <li className={styles.headerUserMenuListSubItem}>
                                <Link
                                    onClick={onClose}
                                    to={clientRoutes.subscriptions.getUrl()}
                                >
                                    Подписки
                                </Link>
                            </li>
                            <li className={styles.headerUserMenuListSubItem}>
                                <Link
                                    onClick={onClose}
                                    to={clientRoutes.notifications.getUrl()}
                                >
                                    Уведомления
                                    <Badge
                                        className={styles.headerUserMenuNotificationCountBadge}
                                        count={notificationCount}
                                        overflowCount={NOTIFICATION_BADGE_IN_MENU_OVERFLOW_COUNT}
                                    />
                                </Link>
                            </li>
                        </ul>
                    </li>
                </RestrictedPermission>
                <RestrictedPermission pk={PermissionKey.viewDocumentRegistratorLink}>
                    <li className={styles.headerUserMenuListItem}>
                        <Link
                            onClick={onClose}
                            to={clientRoutes.uploads.getUrl()}
                            className={styles.headerUserMenuItemTitle}
                        >
                            Загрузка документов
                        </Link>
                    </li>
                </RestrictedPermission>
                <RestrictedPermission pk={PermissionKey.viewAdministrationLink}>
                    <li className={styles.headerUserMenuListItem}>
                        <a
                            onClick={onClose}
                            href={clientRoutes.admin.getUrl()}
                            className={styles.headerUserMenuItemTitle}
                        >
                            Администрирование
                        </a>
                </li>
                </RestrictedPermission>
                <li className={styles.headerUserMenuListItem}>
                    <a
                        onClick={onLogOut}
                        href="#"
                        className={styles.headerUserMenuItemQuit}
                    >
                        Выйти
                    </a>
                </li>
            </ul>
        </React.Fragment>
    );
};
