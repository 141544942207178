/**
 * Created by lkarmelo on 30.08.2019.
 */

import {SnippetOrContainer, updateAnySnippetContainer} from './updateAnySnippetContainer';
import {ExtendedStore} from 'common/store';
import {getDocumentSnippetWithModifiedStat} from './getDocumentSnippetWithModifiedStat';
import {IShareStat} from 'common/store/ExtendedStore';

//функции для обновления модели IDocumentSnippet во всех местах редакса, т.е. в массивах/мапах или по одиночке. Подробности в
//updateAnySnippetContainer

export const updateSnippetContainerLike = <T extends SnippetOrContainer>(snippetContainer: T, id: string, nextLikeState: boolean): T =>
    <T>updateAnySnippetContainer(
        snippetContainer,
        (snippet: ExtendedStore.IDocumentSnippet) => {
            if (!snippet || !snippet.document || snippet.document.id !== id || snippet.liked === nextLikeState) {
                return snippet;
            }
            let nextDocumentSnippet: ExtendedStore.IDocumentSnippet = {...snippet, liked: nextLikeState};
            nextDocumentSnippet = getDocumentSnippetWithModifiedStat(nextDocumentSnippet, 'likesCount',  nextLikeState ? 1 : -1);

            return nextDocumentSnippet;
        }
    );

export const updateSnippetContainerFavorite = <T extends SnippetOrContainer>(
    snippetContainer: T,
    id: string,
    nextFavoriteState: boolean
): T =>
    <T>updateAnySnippetContainer(
        snippetContainer,
        (snippet: ExtendedStore.IDocumentSnippet) => {
            if (
                !snippet || !snippet.document || snippet.document.id !== id ||
                (snippet.bookmarked && snippet.bookmarked.FAVORITE === nextFavoriteState)
            ) {
                return snippet;
            }

            return {
                ...snippet,
                bookmarked: {
                    ...snippet.bookmarked,
                    FAVORITE: nextFavoriteState
                }
            };
        }
    );

export const updateSnippetContainerShare = <T extends SnippetOrContainer>(
    snippetContainer: T,
    id: string,
    nextShareState: IShareStat[]
): T =>
    <T>updateAnySnippetContainer(
        snippetContainer,
        (snippet: ExtendedStore.IDocumentSnippet) => {
            if (!snippet || !snippet.document || snippet.document.id !== id) {
                return snippet;
            }

            let nextDocumentSnippet: ExtendedStore.IDocumentSnippet = { ... snippet };
            nextDocumentSnippet.stats.shares = nextShareState;
            nextDocumentSnippet.stats.sharesCount = nextShareState.reduce((sum, item) => item.count + sum, 0);

            return nextDocumentSnippet;
        }
    );
