/**
 * Created by lkarmelo on 21.08.2019.
 */

import React from 'react';

import {setSearchResults} from 'nkc-frontend-tools/redux/actions/search/results';
import {searchReject} from 'app/redux/actions/loading';
import {setQuery} from 'nkc-frontend-tools/redux/actions/search/searchQuery';
import {setLastExecutedSearchQuery} from 'nkc-frontend-tools/redux/actions/search/lastExecutedSearchQuery';
import {filtersToDefaults} from 'nkc-frontend-tools/redux/actions/search/defaultFilters';
import {withIgnoreSearchActionMeta} from 'nkc-frontend-tools/redux/actions/utils/withIgnoreSearchActionMeta';
import {resetPaging} from 'nkc-frontend-tools/redux/actions/search/searchPaging';

import {ExtendedStore} from 'common/store';

import {
    createContextBookList, createContextBookListFilters, FiltersComponent, IBookListProps, BookListClsNames
} from 'app/components/common/ContextBookList';

import * as styles from './Search.scss';

const SearchContextBookList = createContextBookList(
    ExtendedStore.BookSearchContext.Search,
    createContextBookListFilters(ExtendedStore.BookSearchContext.Search, FiltersComponent),
    (dispatch) => ({
        setQuery(query: string): void {
            dispatch(setQuery(query));
        },
        onUnMount(): void {
            dispatch(searchReject());
            dispatch(setSearchResults({}));
            dispatch(setQuery(null));
            dispatch(setLastExecutedSearchQuery(null));
            dispatch(withIgnoreSearchActionMeta(filtersToDefaults()));
            dispatch(resetPaging(true));
        }
    })
);

const breadcrumbs = [];

const renderHeaderContent = (_, {totalFoundResults, lastExecutedQuery}: IBookListProps, clsNames: BookListClsNames): JSX.Element => (
    <div>
        {typeof lastExecutedQuery === 'string' &&
            <>
                Результаты поиска&nbsp;
                {lastExecutedQuery &&
                    <React.Fragment>
                        &laquo;<span className={styles.searchHeaderSearchQuery}>{lastExecutedQuery}</span>&raquo;
                    </React.Fragment>
                }
                {typeof totalFoundResults === 'number' &&
                    <span className={clsNames.bookListResultCount}>{totalFoundResults}</span>
                }
            </>
        }
    </div>
);

const Search: React.FC = () => (
    <SearchContextBookList
        breadcrumbsPath={breadcrumbs}
        className={styles.search}
        renderHeaderContent={renderHeaderContent}
    />
);

export default Search;
