/**
 * Created by lkarmelo on 06.08.2019.
 */

import React from 'react';

import BaseInputFormField from '../BaseInput';

import IProps from './interfaces/ITextFormFieldProps';

const TextFormField: React.FC<IProps> = ({children, ...rest}) =>
        <BaseInputFormField autoComplete={'off'} {...rest} type={'text'} />;

export default TextFormField;
