import {Action, combineActions, handleActions} from 'redux-actions';
import {closePopup, showPopup} from '../actions/popups';
import {ExtendedStore} from 'common/store';

export default handleActions(
    {
        [combineActions(showPopup, closePopup).toString()](
            state: ExtendedStore.IPopups,
            {payload: popupName, type}: Action<string | string[]>
        ): ExtendedStore.IPopups {
            if (typeof popupName === 'string') {
                return {
                    ...state,
                    [popupName]: type === showPopup.toString()
                };
            } else if (Array.isArray(popupName)) {
                return popupName.reduce(
                    (nextState, name) => {
                        nextState[name] = type === showPopup.toString();
                        return nextState;
                    },
                    {...state}
                );
            }

            return state;
        }
    },
    {}
);
