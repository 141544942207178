/**
 * Created by lkarmelo on 15.08.2019.
 */

import React from 'react';
import {useCallback, useRef} from 'react';
import { CSSTransition } from 'react-transition-group';
import {Portal} from 'react-portal';
import classNames from 'classnames';

import {useOnOutsideClick} from 'app/hooks/useOnOutsideClick';

import * as styles from './Modal.scss';

export interface IModalProps {
    isVisible: boolean;
    showCross?: boolean;
    centered?: boolean;
    className?: string;
    close(): void;
    onExited?(): void;
}

const Modal: React.FC<IModalProps> = (props) => {
    const {children, isVisible, onExited, close, showCross, centered, className} = props;

    const hasEntered = useRef(false);
    const onMouseDown = useOnOutsideClick(() => hasEntered.current && close());

    const onEntered = useCallback(
        () => {
            hasEntered.current = true;
        },
        []
    );

    const ownOnExited = useCallback(
        () => {
            hasEntered.current = false;
            onExited && onExited();
        },
        [onExited]
    );

    return (
        <Portal>
            <CSSTransition
                classNames={styles.modal}
                mountOnEnter={true}
                unmountOnExit={true}
                onExited={ownOnExited}
                onEntered={onEntered}
                timeout={200}
                in={isVisible}
            >
                {() => (
                    <div
                        className={classNames(
                            className,
                            styles.modal,
                            {[styles.modalCentered]: centered}
                        )}
                    >
                        <div className={styles.modalOuterContainer} >
                            <div className={styles.modalInnerContainer} onMouseDown={onMouseDown}>
                                {showCross &&
                                    <button
                                        className={`${styles.modalClose} icon-cross btn`}
                                        onClick={close}
                                    />
                                }
                                <div className={styles.modalContent}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </CSSTransition>
        </Portal>
    );
};

Modal.defaultProps = {
    showCross: true,
    centered: true,
};

export default Modal;
