import React from 'react';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import { TextFormField, InputFieldMode } from 'app/components/common/form-fields';
import { requiredStringValidation } from 'app/utils/forms/validation';

import IProps from './interfaces/IPersonFormProps';
import * as styles from './PersonForm.scss';

const PersonForm: React.FC<IProps> = (props) => {
    const { className, onSubmit, renderControls, title, person } = props;

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, hasValidationErrors, pristine }) => (
                <form
                    onSubmit={handleSubmit}
                    className={classNames(className, styles.personForm)}
                >
                    <h2 className={styles.personFormTitle}>
                        {title}
                    </h2>
                    <Field
                        name="lastName"
                        validate={requiredStringValidation}
                        initialValue={person ?.lastName}
                    >
                        {({ input, meta }) => (
                            <TextFormField
                                {...input}
                                required
                                autoFocus
                                className={styles.personFormField}
                                label={'Фамилия'}
                                mode={InputFieldMode.MATERIAL}
                                error={meta.touched && meta.error}
                            />
                        )}
                    </Field>
                    <Field
                        name="firstName"
                        validate={requiredStringValidation}
                        initialValue={person ?.firstName}
                    >
                        {({ input, meta }) => (
                            <TextFormField
                                {...input}
                                required
                                className={styles.personFormField}
                                label={'Имя'}
                                mode={InputFieldMode.MATERIAL}
                                error={meta.touched && meta.error}
                            />
                        )}
                    </Field>
                    <Field
                        name="middleName"
                        initialValue={person ?.middleName}
                    >
                        {({ input, meta }) => (
                            <TextFormField
                                {...input}
                                className={styles.personFormField}
                                label={'Отчество'}
                                mode={InputFieldMode.MATERIAL}
                                error={meta.touched && meta.error}
                            />
                        )}
                    </Field>
                    {renderControls(handleSubmit, submitting || hasValidationErrors || pristine, submitting)}
                </form>
            )}
        />
    );
};

export default PersonForm;
