/**
 * Created by lkarmelo on 12.03.2019.
 */

import {Action, handleActions} from 'redux-actions';

import {IResetPagingPayload, resetPaging} from 'nkc-frontend-tools/redux/actions/search/searchPaging';
import {setDefaultLimit} from 'app/redux/actions/search/paging';

import _getPagingReducer from 'nkc-frontend-tools/redux/reducers/search/searchPaging';

import {Store} from 'nkc-frontend-tools/types';

const customReducer = handleActions<Partial<Store.IPaging>, any>(
    {
        [setDefaultLimit.toString()](state: Store.IPaging, {payload}: Action<number>): Store.IPaging {
            if (payload === state.defaultLimit) {
                return state;
            }
            return {...state, defaultLimit: payload};
        },
        [resetPaging.toString()](state: Store.IPaging, {payload}: Action<IResetPagingPayload>): Store.IPaging {
            const nextState = {...state, skip: 0};
            if (payload.resetLimit) {
                nextState.limit = state.defaultLimit;
            }
            return nextState;
        }
    },
    {}
);

export const getPagingReducer = (initialState: Store.IPaging) => {
    const defaultPagingReducer = _getPagingReducer(initialState.defaultLimit);

    return  (state = initialState, action: Action<any>) => {
        if (action.type === setDefaultLimit.toString() || action.type === resetPaging.toString()) {
            return customReducer(state, action);
        }
        return defaultPagingReducer(state, action);
    };
};
