import React from 'react';
import * as styles from './UserPanel.scss';

const LoginIcon: React.SFC<any> = (props) => {
    return (
        <div className={styles.headerIconWrapper}>
            <span className={`${styles.headerLoginIcon} icon-login`} />
        </div>
    );
};

export default LoginIcon;
