/**
 * Created by lkarmelo on 28.02.2019.
 */

import React from 'react';
import {useState, useRef, useEffect, useMemo} from 'react';
import classNames from 'classnames';

import {Portal} from 'react-portal';
import {CSSTransition} from 'react-transition-group';

import {useOnOutsideClick} from 'app/hooks/useOnOutsideClick';
import {useLayoutEffectOnUpdate} from 'app/hooks/useLayoutEffectOnUpdate';

import {getEnhancedReactChildren} from 'app/utils/getEnhancedReactChildren';
import {getElementOffset} from 'nkc-frontend-tools/utils/getElementOffset';

import IProps from './interfaces/ISelectProps';

import * as styles from './Select.scss';

export const Select: React.FunctionComponent<IProps> = (props) => {

    const {children, title, active, onDeselect, onSelect, onClear, showActiveCount} = props;

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const selfRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLUListElement>(null);

    const onMouseDown = useOnOutsideClick(
        () => {
            setIsOpened(false);
        }
    );

    useLayoutEffectOnUpdate(
        () => {
            const dropDown: HTMLDivElement = dropDownRef.current;
            const selfElement: HTMLDivElement = selfRef.current;
            const listElement: HTMLUListElement = listRef.current;

            if (!isOpened || !dropDown || !selfElement || !listElement) {
                return;
            }

            const selfTopPosition = getElementOffset(selfElement).top;
            const selfBoundingRect = selfElement.getBoundingClientRect();
            const dropDownTop = selfTopPosition + selfBoundingRect.height + 5;

            dropDown.style.top = `${dropDownTop}px`;
            dropDown.style.left = `${selfBoundingRect.left}px`;

            dropDown.style.display = 'block';
            listElement.style.maxHeight = `${window.innerHeight - dropDownTop}px`;
            dropDown.style.display = '';
        },
        [isOpened]
    );

    if (typeof children === 'string' || !children) {
        console.warn('Select children have to be React Elements');
        return null;
    }

    const enhancedChildren = useMemo(
        () => getEnhancedReactChildren(
            children,
            (childProps) => ({
                onSelect,
                onDeselect,
                isActive: Array.isArray(active) ? active.includes(childProps.value) : childProps.value === active
            })
        ),
        [children]
    );

    const isActive =  typeof active === 'string' || Array.isArray(active) ?
        active.length > 0 :
        (active !== undefined && active !== null);

    return (
        <div
            ref={selfRef}
            onMouseDown={onMouseDown}
            className={classNames(
                styles.select,
                {
                    [styles.selectOpened]: isOpened,
                    [styles.selectActive]: isActive,
                    [styles.selectWithActiveCount]: showActiveCount,
                }
            )}
        >
            <button
                type="button"
                className={`btn ${styles.selectSwitcher}`}
                onClick={() => setIsOpened(prev => !prev)}
            >
                <span className={styles.selectTitle}>{title}</span>
                <span className={styles.selectArrow} />
            </button>
            {(isActive && Array.isArray(active)) &&
                <button
                    className={`${styles.selectActiveCount} btn`}
                    onClick={() => onClear(props)}
                >
                    <span className={styles.selectActiveCountNumber}>
                        {active.length}
                    </span>
                </button>
            }
            <Portal>
                <CSSTransition
                    in={isOpened}
                    timeout={200}
                    classNames={'slide-transition-down'}
                >
                    {status => (
                        <div
                            ref={dropDownRef}
                            className={classNames(
                                styles.selectDropDown,
                                {[styles.selectDropDownHidden]: status === 'exited'}
                            )}
                        >
                            <ul
                                ref={listRef}
                                className={styles.selectList}
                            >
                                {enhancedChildren}
                            </ul>
                        </div>
                    )}
                </CSSTransition>
            </Portal>
        </div>
    );
};

export const PureSelect: React.FunctionComponent<IProps> = React.memo(Select);
