/**
 * Created by lkarmelo on 11.03.2019.
 */

import {Action, handleActions} from 'redux-actions';

import {setSearchResults} from 'nkc-frontend-tools/redux/actions/search/results';
import {addToSearchResults} from 'app/redux/actions/search/results';

import {setLikeState, ISetLikeStatusPayload} from 'app/redux/actions/like';
import {IFavoriteActionPayload, setFavoriteState} from '../../actions/favorite';

import {ExtendedStore} from 'common/store';

import {updateSnippetContainerLike, updateSnippetContainerFavorite} from '../utils/updateDocummentSnippet';

const results = handleActions<ExtendedStore.IDocumentResults, any>(
    {
        [setSearchResults.toString()]: (_, {payload}: Action<ExtendedStore.IDocumentResults>): ExtendedStore.IDocumentResults => payload,

        [addToSearchResults.toString()](
            state: ExtendedStore.IDocumentResults,
            {payload}: Action<ExtendedStore.IDocumentResults>
        ): ExtendedStore.IDocumentResults {
            const isStateListArray = Array.isArray(state.list);
            const isPayloadListArray = Array.isArray(payload.list);

            if (isPayloadListArray && isStateListArray) {
                return {...state, list: state.list.concat(payload.list)};
            }
            if (!isPayloadListArray && isStateListArray) {
                return state;
            }
            if (isPayloadListArray && !isStateListArray) {
                return {...state, list: payload.list};
            }
            return {...state, list: []};
        },

        [setLikeState.toString()]: (
            state: ExtendedStore.IDocumentResults,
            {payload: {nextLikeState, id: bookId}}: Action<ISetLikeStatusPayload>
        ): ExtendedStore.IDocumentResults => ({
            ...state,
            list: state && state.list ? updateSnippetContainerLike(state.list, bookId, nextLikeState) : undefined
        }),
        [setFavoriteState.toString()]: (
            state: ExtendedStore.IDocumentResults,
            {payload: {favoriteState, bookId}}: Action<IFavoriteActionPayload>
        ): ExtendedStore.IDocumentResults => ({
            ...state,
            list: state && state.list ? updateSnippetContainerFavorite(state.list, bookId, favoriteState) : undefined
        }),
    },
    {}
);

export default results;
