import React from 'react';

import IProps from './interfaces/ICheckboxProps';
import * as styles from './Checkbox.scss';

const Checkbox: React.FC<IProps> = ({ checked, onChange }) => {
    return (
        <label className={styles.subscriptionCheckboxLabel}>
            <input
                className={styles.subscriptionCheckboxInput}
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <div className={styles.subscriptionCheckboxBox}/>
        </label>
    );
};

export default Checkbox;
