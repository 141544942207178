/**
 * Created by lkarmelo on 21.05.2019.
 */

import React from 'react';

import {IArrowFocusContextValue, IListItem, OutOfBoundarySide} from './interfaces/IArrowFocusContext';

const ArrowsFocusContext = React.createContext<IArrowFocusContextValue>(
    {
        onFocusItemUnMount(index: number) {},
        getNextFocusElement(index: number): IListItem | undefined {
            return undefined;
        },
        getPrevFocusElement(index: number): IListItem | undefined {
            return undefined;
        },
        onOutOfBoundary(side: OutOfBoundarySide): void {},
        list: {current: null},
    }
);

export default ArrowsFocusContext;
