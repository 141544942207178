import React from 'react';

import BookSnippet from 'app/components/common/BookSnippet';

import IProps from './interfaces/IRelatedBooksProps';

import * as styles from './RelatedBooks.scss';

const RelatedBooks: React.FunctionComponent<IProps> = ({books, title}: IProps) => (
    <div>
        {(Array.isArray(books) && books.length > 0) &&
            <React.Fragment>
                <header>
                    <h2 className={styles.relatedBooksTitle}>
                        {title}
                    </h2>
                </header>
                <div>
                    {books.map((documentSnippet, i) => (
                        <div
                            key={documentSnippet.document ? documentSnippet.document.id : i}
                            className={styles.relatedBooksBook}
                        >
                            <BookSnippet book={documentSnippet}/>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        }
    </div>
);

export default RelatedBooks;
