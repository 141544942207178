/**
 * Created by lkarmelo on 18.02.2019.
 */

import {connect} from 'react-redux';

import Book from './Book';

import {IBookActionProps, IBookStateProps} from './interfaces/IBookProps';
import {ExtendedStore} from 'common/store';

import {fetchObjectCard, setObjectCard} from 'nkc-frontend-tools/redux/actions/objectCard';
import {defaultLoadingActions} from 'app/redux/actions/loading';

import {filtersValueToLabelSelector} from 'app/redux/selectors/filtersValueToLabelSelector';

export const mapStateToProps = (state: ExtendedStore.IState): IBookStateProps => ({
    book: state.objectCard,
    typeTitles: filtersValueToLabelSelector(state),
});

export const mapDispatchToProps = (dispatch): IBookActionProps => ({
    loadBook(id: string): void {
        dispatch(fetchObjectCard(id));
    },
    resetBook(): void {
        dispatch(setObjectCard(null));
        dispatch(defaultLoadingActions.objectCardReject());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Book);
