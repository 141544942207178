/**
 * Created by lkarmelo on 29.03.2019.
 */

import React from 'react';

import BookDetailsBulletin from './Bulletin/BookDetailsBulletin';
import BookDetailsBase from './Base/BookDetailsBase';
import BookDetailsDigest from './Digest/BookDetailsDigest';

import {ObjectType} from 'common/store/ExtendedStore';
import IProps from './interfaces/IBookDetailsProps';

const BookDetails: React.FunctionComponent<IProps> = (props) => {
    const {book} = props;
    const type = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.meta &&
        book.documentSnippet.document.meta.type;

    switch (type) {
        case ObjectType.SocialBulletin:
        case ObjectType.TechnicalBulletin: return <BookDetailsBulletin {...props} />;
        case ObjectType.Digest: return <BookDetailsDigest {...props} />;
        default: return <BookDetailsBase {...props} />;
    }
};

export default BookDetails;
