import React from 'react';
import { useCallback } from 'react';
import Sticky from 'react-stickynode';
import { HashLink } from 'react-router-hash-link';

import { ROOT_BREADCRUMB_NAME, HEADER_HEIGHT_XL } from 'app/utils/constants';
import clientRoutes from 'common/clientRoutes';
import BreadCrumbs from 'nkc-frontend-tools/components/BreadCrumbs/BreadCrumbs';
import { useResetWindowScroll } from 'app/hooks/useResetWindowScroll';

import * as styles from './Terms.scss';

const enum TermsSectionID {
    ABOUT = 'about-terms',
    COPYRIGHT_FOR_MATERIALS = 'copyright-for-materials',
    USE_OF_MATERIALS = 'use-of-materials',
    CHANGE_OF_TERMS = 'change-of-terms',
    DISPUTE_RESOLUTION = 'dispute-resolution'
}

const breadCrumbsPaths = [
    {
        title: ROOT_BREADCRUMB_NAME,
        link: clientRoutes.main.getUrl()
    },
    {
        title: 'Пользовательское соглашение',
        link: clientRoutes.terms.getUrl()
    }
];

const Terms: React.FC = () => {

    useResetWindowScroll([]);

    const scrollIntoView = useCallback(
        (el) => {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        []
    );

    return (
        <div className={styles.termsPage}>
            <div className={styles.termsPageBreadcrumbs}>
                <BreadCrumbs
                    paths={breadCrumbsPaths}
                    delimiter={<i className={'icon-arrow-right'}/> as any}
                />
            </div>
            <header className={styles.termsPageHeader}>
                <h1 className={styles.termsPageTitle}>Пользовательское соглашение</h1>
                <div className={styles.termsPageDate}>
                    <time dateTime="2010-11-11">11 ноября 2010 г.</time>
                </div>
            </header>
            <div className={styles.termsPageMain}>
                <nav className={`${styles.termsPageNavigation}`}>
                    <Sticky
                        top={HEADER_HEIGHT_XL}
                    >
                        <ul className={styles.termsPageNavigationList}>
                            <li className={styles.termsPageNavigationItem}>
                                <HashLink
                                    className={styles.termsPageNavigationLink}
                                    to={`#${TermsSectionID.ABOUT}`}
                                    scroll={scrollIntoView}
                                >
                                    Об использовании соглашения
                                </HashLink>
                            </li>
                            <li className={styles.termsPageNavigationItem}>
                                <HashLink
                                    className={styles.termsPageNavigationLink}
                                    to={`#${TermsSectionID.COPYRIGHT_FOR_MATERIALS}`}
                                    scroll={scrollIntoView}
                                >
                                    Авторские права на материалы ЭБ
                                </HashLink>
                            </li>
                            <li className={styles.termsPageNavigationItem}>
                                <HashLink
                                    className={styles.termsPageNavigationLink}
                                    to={`#${TermsSectionID.USE_OF_MATERIALS}`}
                                    scroll={scrollIntoView}
                                >
                                    Использование материалов ЭБ
                                </HashLink>
                            </li>
                            <li className={styles.termsPageNavigationItem}>
                                <HashLink
                                    className={styles.termsPageNavigationLink}
                                    to={`#${TermsSectionID.CHANGE_OF_TERMS}`}
                                    scroll={scrollIntoView}
                                >
                                    Изменение условий
                                </HashLink>
                            </li>
                            <li className={styles.termsPageNavigationItem}>
                                <HashLink
                                    className={styles.termsPageNavigationLink}
                                    to={`#${TermsSectionID.DISPUTE_RESOLUTION}`}
                                    scroll={scrollIntoView}
                                >
                                    Разрешение спорных вопросов
                                </HashLink>
                            </li>
                        </ul>
                    </Sticky>
                </nav>
                <article className={styles.termsPageTerms}>
                    <section className={styles.termsPageSection} id={TermsSectionID.ABOUT}>
                        <h2 className={styles.termsPageSectionTitle}>
                            Об использовании соглашения
                        </h2>
                        <p className={styles.termsPageSectionParagraph}>
                            Настоящее Соглашение регулирует отношения между Российским фондом фундаментальных исследований
                            (далее - Фонд) и физическим лицом (далее - Пользователь), использующим официальный сайт РФФИ (далее - Сайт)
                            по&nbsp;адресу:&nbsp;
                            <a
                                className={styles.termsPageSectionLink}
                                href="http://www.rffi.ru/"
                                target="_blank"
                                rel="nofollow noopener"
                            >
                                http://www.rffi.ru/
                            </a>
                            &nbsp;
                            (<a
                                className={styles.termsPageSectionLink}
                                href="http://www.rfbr.ru/"
                                target="_blank"
                                rel="nofollow noopener"
                            >
                                http://www.rfbr.ru/
                            </a>).
                        </p>
                        <p className={styles.termsPageSectionParagraph}>
                            Воспользовавшись возможностью просмотра, копирования и размещения материалов Сайта,
                            в том числе просмотра электронных полнотекстовых произведений (далее –  материалов ЭБ),
                            размещенных в разделе Библиотека Сайта (далее – ЭБ), а также другого использования Сайта
                            Пользователь выражает свое согласие со всеми условиями настоящего Соглашения
                            и обязуется их соблюдать или прекратить использование Сайта.
                            Использование Сайта регулируется настоящим Соглашением.
                        </p>
                        <p className={styles.termsPageSectionParagraph}>
                            Пользователь несет исключительную ответственность за любое нарушение принятых на себя обязательств,
                            установленных настоящим Соглашением, а также за все последствия таких нарушений
                            (включая любые убытки или ущерб, которые может понести РФФИ).
                        </p>
                    </section>
                    <section className={styles.termsPageSection} id={TermsSectionID.COPYRIGHT_FOR_MATERIALS}>
                        <h2 className={styles.termsPageSectionTitle}>
                            Авторские права на материалы ЭБ
                        </h2>
                        <p className={styles.termsPageSectionParagraph}>
                            Материалы ЭБ, размещенные для свободного просмотра (во временное безвозмездное пользование)
                            в ЭБ представляют собой электронные версии отдельных произведений научной литературы,
                            носители авторской ответственности (далее – Авторы) которых являются победителями конкурса
                            издательских проектов РФФИ. Размещение материалов ЭБ осуществляется в соответствии с ГК РФ
                            (редакция от 1 января 2008 года), «Общими положениями» Положения о конкурсах РФФИ,
                            в соответствии с которыми условием предоставления Фондом финансовой поддержки является
                            обязательство ученого (Автора) сделать результаты исследований общественным достоянием,
                            непременно опубликовав их, в том числе обязательно в российских изданиях.
                        </p>
                        <p className={styles.termsPageSectionParagraph}>
                            В случае несогласия Автора произведения с публикации его произведения в виде материала ЭБ,
                            указанный материалы будет удален из ЭБ по письменному заявлению Автора в РФФИ.
                        </p>
                    </section>
                    <section className={styles.termsPageSection} id={TermsSectionID.USE_OF_MATERIALS}>
                        <h2 className={styles.termsPageSectionTitle}>
                            Использование материалов ЭБ
                        </h2>
                        <p className={styles.termsPageSectionParagraph}>
                            Пользователь вправе использовать материалы ЭБ исключительно для целей,
                            разрешенных Соглашением и любыми применимыми законами, положениями,
                            общепринятой практикой или руководствами в соответствующей юрисдикции
                            (включая любые законы, касающиеся экспорта данных и программного обеспечения
                            из или в Российскую Федерацию или иные соответствующие государства).
                        </p>
                        <p className={styles.termsPageSectionParagraph}>
                            Воспроизведение материалов из ЭБ в любой форме требует письменного разрешения РФФИ.
                            Пользователи вправе в индивидуальном порядке использовать материалы,
                            находящиеся на сайте РФФИ, для некоммерческого использования.
                        </p>
                        <p className={styles.termsPageSectionParagraph}>
                            Пользователь обязуется не осуществлять (и не пытаться получить)
                            доступ к каким-либо материалам ЭБ иным способом, кроме как через интерфейс Сайта.
                        </p>
                        <p className={styles.termsPageSectionParagraph}>
                            Пользователь обязуется не воспроизводить, не дублировать, не копировать, не продавать,
                            не осуществлять торговые операции и не перепродавать материалы ЭБ для каких-либо целей.
                        </p>
                        <p className={styles.termsPageSectionParagraph}>
                            Содержимое материалов ЭБ (далее - Содержимое), может являться объектом интеллектуальной собственности,
                            права на который защищены и принадлежат другим лицам. Пользователь не вправе вносить изменения,
                            передавать в аренду, передавать на условиях займа, продавать, распространять или создавать производные
                            работы на основе такого содержимого (полностью или в части).
                        </p>
                    </section>
                    <section className={styles.termsPageSection} id={TermsSectionID.CHANGE_OF_TERMS}>
                        <h2 className={styles.termsPageSectionTitle}>
                            Изменение условий
                        </h2>
                        <p className={styles.termsPageSectionParagraph}>
                            РФФИ вправе в одностороннем порядке периодически вносить изменения в Соглашение.
                        </p>
                        <p className={styles.termsPageSectionParagraph}>
                            В случае если Пользователь использует сайт после изменения Соглашения,
                            он тем самым выражает свое согласие со всеми условиями нового Соглашения и
                            обязуется их соблюдать или прекратить использование Сайта.
                        </p>
                    </section>
                    <section className={styles.termsPageSection} id={TermsSectionID.DISPUTE_RESOLUTION}>
                        <h2 className={styles.termsPageSectionTitle}>
                            Разрешение спорных вопросов
                        </h2>
                        <p className={styles.termsPageSectionParagraph}>
                            Разрешение спорных вопросов, связанных с различными аспектами функционирования Сайта,
                            осуществляется в соответствии с действующими законами и иными
                            нормативными правовыми актами Российской Федерации и города Москвы.
                        </p>
                    </section>
                </article>
            </div>
        </div>
    );
};

export default Terms;
