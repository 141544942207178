/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 08.11.2017
 * -----
 * Last Modified: 07.02.2018 18:42:03
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React from 'react';
import {Component} from 'react';
import {Provider} from 'react-redux';
import ru from 'antd/es/locale-provider/ru_RU';
import LocaleProvider from 'antd/es/locale-provider';

import {Router} from 'react-router-dom';
import history from 'app/history';

import store from 'app/redux/store/store';

import Root from 'app/components/Root/Root';

class App extends Component {
    render(): JSX.Element {
        return (
            <Provider store={store}>
                <LocaleProvider locale={ru}>
                    <Router history={history}>
                        <Root/>
                    </Router>
                </LocaleProvider>
            </Provider>
        );
    }
}

export default App;
