import { Epic } from 'redux-observable';
import { Action } from 'redux-actions';
import { ExtendedStore } from 'common/store';
import {
    IUploadDocumentActionPayload,
    uploadDocumentContent,
    uploadDocumentCover
} from '../actions/uploadDocument';
import { Observable } from 'rxjs';
import { apiCalls } from 'common/api';
import {
    uploadDocumentContentResolve,
    uploadDocumentContentReject,
    uploadDocumentContentRequest,
    uploadDocumentCoverResolve,
    uploadDocumentCoverReject,
    uploadDocumentCoverRequest
} from '../actions/loading';
import { createFileUploadProgressSubscriber } from '../utils/createFileUploadProgressSubscriber';
import { fileUploadError, fileUploadSuccess } from '../actions/fileUploadProgress';

export const uploadDocumentContentEpic: Epic<Action<IUploadDocumentActionPayload>, ExtendedStore.IState> = (action$, store) => {
    return action$
        .ofType(uploadDocumentContent.toString())
        .switchMap(({ payload }: Action<IUploadDocumentActionPayload>) => {
            const progressSubscriber$ = createFileUploadProgressSubscriber(payload.uploadId, payload.file, store);

            const formData = new FormData();
            formData.append('File', payload.file);

            return apiCalls.uploadDocumentContent(payload.documentId, formData, progressSubscriber$)
                .mergeMapTo(Observable.of(
                    uploadDocumentContentResolve(),
                    fileUploadSuccess(payload.uploadId)
                ))
                .catch(error => {
                    console.error(error);
                    return Observable.of(
                        uploadDocumentContentReject(error),
                        fileUploadError({
                            id: payload.uploadId,
                            error
                        })
                    );
                })
                .startWith(uploadDocumentContentRequest());
        });
};

export const uploadDocumentCoverEpic: Epic<Action<IUploadDocumentActionPayload>, ExtendedStore.IState> = (action$, store) => {
    return action$
        .ofType(uploadDocumentCover.toString())
        .switchMap(({ payload }: Action<IUploadDocumentActionPayload>) => {
            const progressSubscriber$ = createFileUploadProgressSubscriber(payload.uploadId, payload.file, store);

            const formData = new FormData();
            formData.append('File', payload.file);

            return apiCalls.uploadDocumentCover(payload.documentId, formData, progressSubscriber$)
                .mergeMapTo(Observable.of(
                    uploadDocumentCoverResolve(),
                    fileUploadSuccess(payload.uploadId)
                ))
                .catch(error => {
                    console.error(error);
                    return Observable.of(
                        uploadDocumentCoverReject(error),
                        fileUploadError({
                            id: payload.uploadId,
                            error
                        })
                    );
                })
                .startWith(uploadDocumentCoverRequest());
        });
};
