/**
 * Created by lkarmelo on 20.03.2019.
 */

import deepEqual from 'deep-equal';
import {Store} from 'nkc-frontend-tools/types';

const compareMultiSelect = (valA: string[], valB: string[]): boolean => {
    if (!Array.isArray(valA) || !Array.isArray(valB)) {
        return Object.is(valA, valB);
    }
    if (valA === valB) {
        return true;
    }
    const sortedValA = valA.slice().sort();
    const sortedValB = valB.slice().sort();
    return deepEqual(sortedValA, sortedValB);
};

const compareSelect = (valA: string, valB: string): boolean => Object.is(valA, valB);

export const compareFilterValues = (valA: Store.IFilterValue, valB: Store.IFilterValue, filterType: Store.FilterType): boolean => {
    switch (filterType) {
        case Store.FilterType.MultiSelect: {
            return compareMultiSelect(valA as string[], valB as string[]);
        }
        case Store.FilterType.Select: {
            return compareSelect(valA as string, valB as string);
        }
        default: return Object.is(valA, valB);
    }
};
