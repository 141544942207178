/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {handleActions, combineActions, Action} from 'redux-actions';

import {Store} from '../../../types';
import {ISetFiltersByNamePayloadItem} from '../../actions/search/filters';

import {
    IChangeFilterValuePayload,
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    toggleMultiSelectFilterValue,
    setFilterValue,
    setFilters,
    setFiltersByNames,
    setDateRangeFrom,
    setDateRangeTo,
} from '../../actions/search/filters';

export default handleActions<Partial<Store.IPaging>, any>(
    {
        [addMultiSelectFilterValue.toString()](state: Store.IFilters, {payload}: Action<IChangeFilterValuePayload>) {
            const nextState: Store.IFilters = {...state};
            const value: string = <string>payload.value;

            if (state[payload.filterName] && (<string[]>state[payload.filterName].value).indexOf(<string>payload.value) >= 0) {
                return state;
            }

            if (!nextState[payload.filterName]) {
                nextState[payload.filterName] = {value: []};
            }

            const filter = nextState[payload.filterName];
            let filterValue: string[] = <string[]>filter.value;
            if (!Array.isArray(filterValue)) {
                filterValue = [value];
            } else {
                filterValue = filterValue.slice();
                filterValue.push(value);
            }

            filter.value = filterValue;
            return nextState;
        },
        [removeMultiSelectFilterValue.toString()](state: Store.IFilters, {payload}: Action<IChangeFilterValuePayload>) {
            const nextState: Store.IFilters = {...state};
            const filter = nextState[payload.filterName];
            let filterValue: string[] = <string[]>filter.value;

            filter.value = (Array.isArray(filterValue)) ? filterValue.slice().filter(v => v !== payload.value) : [];

            return nextState;
        },
        [toggleMultiSelectFilterValue.toString()](state: Store.IFilters, {payload}: Action<IChangeFilterValuePayload>) {
            const nextState: Store.IFilters = {...state};
            const filter = nextState[payload.filterName];

            if (!filter || !Array.isArray(filter.value)) {
                nextState[payload.filterName] = {
                    value: <string[]>[payload.value]
                };
                return nextState;
            }

            const filterValue =  (<string[]>filter.value);
            const payloadValue = <string>payload.value;
            let indexOfVal = filterValue.indexOf(payloadValue);

            filter.value = filterValue.slice();
            if (indexOfVal < 0) {
                filter.value.push(payloadValue);
            } else {
                filter.value.splice(indexOfVal, 1);
            }

            return nextState;
        },
        [setFilterValue.toString()](state: Store.IFilters, {payload}: Action<IChangeFilterValuePayload>) {
            const nextState: Store.IFilters = {...state};

            // add filter
            !nextState[payload.filterName] && (nextState[payload.filterName] = {value: null});

            nextState[payload.filterName].value = payload.value;
            return nextState;
        },
        [combineActions(setDateRangeFrom, setDateRangeTo).toString()](
            state: Store.IFilters,
            {payload, type}: Action<IChangeFilterValuePayload>
        ) {
            const nextState: Store.IFilters = {...state};

            // add filter
            !nextState[payload.filterName] && (nextState[payload.filterName] = {value: {}});

            const filter: Store.IDateFilterValue = {...<Store.IDateFilterValue>nextState[payload.filterName].value};

            if (type === setDateRangeFrom.toString()) {
                if (payload.value === undefined) {
                    delete filter.from;
                } else {
                    filter.from = <number>payload.value;
                }
            }

            if (type === setDateRangeTo.toString()) {
                if (payload.value === undefined) {
                    delete filter.to;
                } else {
                    filter.to = <number>payload.value;
                }
            }

            nextState[payload.filterName].value = filter;
            return nextState;
        },
        [setFilters.toString()](state: Store.IFilters, {payload}: Action<Store.IFilters>) {
            const nextFilters = {...payload};

            Object.keys(nextFilters).forEach(key => {
                nextFilters[key] = {...nextFilters[key]};
                const filter = nextFilters[key];

                if (Array.isArray(filter.value)) {
                    filter.value = (<string[]>filter.value).slice();
                } else if (typeof filter.value === 'object') {
                    filter.value = {...filter.value};
                }
            });
            return nextFilters;
        },
        [setFiltersByNames.toString()](state: Store.IFilters, {payload}: Action<ISetFiltersByNamePayloadItem[]>) {
            const nextState: Store.IFilters = {...state};
            for (let nextFilter of payload) {
                if (!nextState[nextFilter.filterName]) {
                    nextState[nextFilter.filterName] = {value: undefined};
                }
                nextState[nextFilter.filterName].value = nextFilter.value;
                nextState[nextFilter.filterName].isDisabled = nextFilter.isDisabled;
            }
            return nextState;
        }
    },
    {} //- default value for filters
);
