/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {createAction} from 'redux-actions';

import {Store} from '../../../types';

export const FETCH_FILTERS_META = 'FETCH_FILTERS_META';
export const SET_FILTERS_META = 'SET_FILTERS_META';

export const fetchFiltersMeta = createAction(FETCH_FILTERS_META);
export const setFiltersMeta = createAction<Store.IFiltersMeta>(SET_FILTERS_META);
