import { createAction } from 'redux-actions';
import { FileUploadAjaxError } from 'common/store/ExtendedStore';

export interface IFileUploadProgressActionPayload {
    id: string;
    fileName: string;
    fileSize: number;
    progressRatio: number;
}

export interface IFileUploadErrorActionPayload {
    id: string;
    error: FileUploadAjaxError;
}

type id = string;

export const fileUploadProgress = createAction<IFileUploadProgressActionPayload>('FILE_UPLOAD_PROGRESS');
export const fileUploadProgressClean = createAction<id>('FILE_UPLOAD_PROGRESS_CLEAN');
export const fileUploadSuccess = createAction<id>('FILE_UPLOAD_SUCCESS');
export const fileUploadError = createAction<IFileUploadErrorActionPayload>('FILE_UPLOAD_ERROR');
