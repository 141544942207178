import { createSimpleLoadingEpic } from 'nkc-frontend-tools/redux/epics/utils';
import {
    setNotificationTypes,
    fetchNotificationTypes,
    fetchNotificationSettings,
    setNotificationSettings,
    saveNotificationSetting,
    setNotificationSetting,
    fetchNotifications,
    setNotifications,
    markNotificationsAsRead,
    pollNotifications,
    stopPollingNotifications
} from '../actions/notifications';
import {
    notificationTypesRequest,
    notificationTypesResolve,
    notificationTypesReject,
    notificationSettingsRequest,
    notificationSettingsResolve,
    notificationSettingsReject,
    saveNotificationSettingResolve,
    saveNotificationSettingReject,
    saveNotificationSettingRequest,
    notificationsRequest,
    notificationsResolve,
    notificationsReject,
    markNotificationsAsReadRequest,
    markNotificationsAsReadResolve,
    markNotificationsAsReadReject
} from '../actions/loading';
import { Action } from 'redux-actions';
import { INotificationSetting } from 'common/api/ExtendedApi';
import { Observable } from 'rxjs';
import { apiCalls } from 'common/api';
import { ExtendedStore } from 'common/store';
import { MiddlewareAPI } from 'redux';
import { NOTIFICATIONS_POLLING_INTERVAL_MS } from 'common/constants';
import { SET_AUTHORIZED } from 'nkc-frontend-tools/redux/actions/authorization';

export const loadNotificationTypesEpic = createSimpleLoadingEpic({
    triggers: [fetchNotificationTypes.toString()],
    apiCallName: 'notificationTypes' as any,
    actions: {
        requestAction: notificationTypesRequest,
        resolveAction: notificationTypesResolve,
        rejectAction: notificationTypesReject,
        setAction: setNotificationTypes
    }
});

export const loadNotificationSettingsEpic = createSimpleLoadingEpic({
    triggers: [fetchNotificationSettings.toString()],
    apiCallName: 'notificationSettings' as any,
    actions: {
        requestAction: notificationSettingsRequest,
        resolveAction: notificationSettingsResolve,
        rejectAction: notificationSettingsReject,
        setAction: setNotificationSettings
    }
});

export const loadNotificationsEpic = createSimpleLoadingEpic({
    triggers: [fetchNotifications.toString()],
    apiCallName: 'notifications' as any,
    actions: {
        requestAction: notificationsRequest,
        resolveAction: notificationsResolve,
        rejectAction: notificationsReject,
        setAction: setNotifications
    },
    transformRequest: (state: any) => ({
        payload: state.notifications.requestParams
    })
});

export const notificationsPollingEpic = (action$, store: MiddlewareAPI<ExtendedStore.IState>) => action$
    .ofType(pollNotifications.toString())
    .exhaustMap(action => {
        const isAuthorized = store.getState().authorization.isAuthorized;
        if (isAuthorized) {
            return Observable.of(action);
        }
        return action$
            .ofType(SET_AUTHORIZED)
            .mapTo(action)
            .take(1);
    })
    .do(() => {
        store.dispatch(fetchNotifications());
    })
    .delay(NOTIFICATIONS_POLLING_INTERVAL_MS)
    .map(() => pollNotifications())
    .takeUntil(action$.ofType(stopPollingNotifications.toString()));

export const markNotificationsAsReadEpic = action$ => action$
    .ofType(markNotificationsAsRead.toString())
    .filter(({ payload }) => Array.isArray(payload) && payload.length > 0)
    .groupBy(
        ({ payload }: Action<string[]>) => payload[0], //уникальная страница определяется по любому уведомлению на ней
        undefined,
        () => Observable.timer(15000)
    )
    .mergeMap(group => group
        .exhaustMap(({payload}: Action<string[]>) =>
            apiCalls.markNotificationsAsRead(payload)
                .mapTo(markNotificationsAsReadResolve())
                .catch(e => {
                    console.error(e);
                    return Observable.of(markNotificationsAsReadReject(e));
                })
                .startWith(markNotificationsAsReadRequest())
        )
    );

export const saveNotificationSettingEpic = action$ => action$
    .ofType(saveNotificationSetting.toString())
    .groupBy(
        ({payload}: Action<INotificationSetting>) => payload.notificationType,
        undefined,
        () => Observable.timer(15000)
    )
    .mergeMap(group => group
        .exhaustMap(({ payload }: Action<INotificationSetting>) =>
            apiCalls.saveNotificationSetting(payload)
                .mergeMap(() => Observable.of(
                    saveNotificationSettingResolve(),
                    setNotificationSetting(payload)
                ))
                .catch(e => {
                    console.error(e);
                    return Observable.of(saveNotificationSettingReject(e));
                })
                .startWith(saveNotificationSettingRequest())
        )
    );
