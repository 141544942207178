/**
 * Created by lkarmelo on 31.07.2019.
 */

import React from 'react';

import BreadCrumbs from 'nkc-frontend-tools/components/BreadCrumbs';

import IProps from './interfaces/IPageHeaderProps';

import * as styles from './PageHeader.scss';

const PageHeader: React.FC<IProps> = (props) => {
    const {breadcrumbsPath, renderHeader, header} = props;

    return (
        <div className={styles.pageHeader}>
            {Array.isArray(breadcrumbsPath) &&
                <div className={styles.pageHeaderBreadcrumbs}>
                    <BreadCrumbs
                        paths={breadcrumbsPath}
                        delimiter={<i className={'icon-arrow-right'}/> as any}
                    />
                </div>
            }
            <header className={styles.pageHeaderHeader}>
                <h1>
                    {renderHeader ?
                        renderHeader() :
                        header
                    }
                </h1>
            </header>
        </div>
    );
};

export default React.memo(PageHeader);
