/**
 * Created by Lkarmelo on 05.09.2017.
 */

import React from 'react';
import {renderRoutesComponents, RouteWithLayout} from 'nkc-frontend-tools/routing';
import { Route } from 'react-router-dom';

import { DefaultLayout, LoginLayout } from 'app/components/layouts';
import Login from 'app/components/Login';
import ConfirmAuthentication from 'app/components/Login/ConfirmAuthentication';
import Main from 'app/components/pages/main/Main';
import Book from 'app/components/pages/book/Book';
import Search from 'app/components/pages/search/Search';
import Catalog from 'app/components/pages/catalog';
import Profile from 'app/components/pages/profile/Profile';
import Terms from 'app/components/pages/terms/Terms';
import PrivacyPolicy from 'app/components/pages/privacy/PrivacyPolicy';
import Favorite from 'app/components/pages/favorite';
import PasswordReset from 'app/components/pages/passwordReset';
import ConfirmAccountCreation from 'app/components/pages/confirmAccountCreation';
import ConfirmEmailChange from 'app/components/pages/confirmEmailChange';
import About from 'app/components/pages/about/About';
import Reader from 'app/components/pages/read';
import Subscriptions from 'app/components/pages/subscriptions/Subscriptions';
import DocumentsUploadedList from 'app/components/pages/documents-upload/DocumentsUploadedList';
import Notifications from 'app/components/pages/notifications';
import NotificationsTable from 'app/components/pages/notifications/NotificationsTable';
import NotificationSettings from 'app/components/pages/notifications/NotificationSettings';

import clientRoutes from 'common/clientRoutes';
import withRedirectUnauthorized from 'app/components/high-order/redirectUnauthorized';
import AddEditDocument, { CreateDocument } from 'app/components/pages/documents-upload/AddEditDocument';

const AuthRoute = withRedirectUnauthorized(Route);
const AuthRouteWithLayout = withRedirectUnauthorized(RouteWithLayout);

const config = [
    {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.main.reactRouterTemplate,
        component: Main,
        options: {
            exact: true
        },
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.search.reactRouterTemplate,
        component: Search,
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.catalog.reactRouterTemplate,
        component: Catalog,
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.book.reactRouterTemplate,
        component: Book,
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.terms.reactRouterTemplate,
        component: Terms
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.privacy.reactRouterTemplate,
        component: PrivacyPolicy
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.profile.reactRouterTemplate,
        component: Profile,
        authOnly: true,
    },  {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.favorite.reactRouterTemplate,
        component: Favorite,
        authOnly: true
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.subscriptions.reactRouterTemplate,
        component: Subscriptions,
        authOnly: true
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.notifications.reactRouterTemplate,
        component: Notifications,
        authOnly: true,
        subRoutes: [
            {
                key: 'same-key',
                path: clientRoutes.notificationSettings.reactRouterTemplate,
                component: NotificationSettings,
            },
            {
                key: 'same-key',
                path: clientRoutes.notifications.reactRouterTemplate,
                component: NotificationsTable
            }
        ]
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.about.reactRouterTemplate,
        component: About
    }, {
        path: clientRoutes.read.reactRouterTemplate,
        component: Reader,
        authOnly: true,
    }, {
        layout: LoginLayout,
        path: clientRoutes.login.reactRouterTemplate,
        component: Login,
    }, {
        layout: LoginLayout,
        path: clientRoutes.confirmAuthentication.reactRouterTemplate,
        component: ConfirmAuthentication,
    }, {
        layout: LoginLayout,
        path: clientRoutes.resetPassword.reactRouterTemplate,
        component: PasswordReset
    }, {
        layout: LoginLayout,
        path: clientRoutes.confirmAccountCreation.reactRouterTemplate,
        component: ConfirmAccountCreation
    }, {
        layout: LoginLayout,
        path: clientRoutes.confirmEmailChange.reactRouterTemplate,
        component: ConfirmEmailChange
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.uploads.reactRouterTemplate,
        component: DocumentsUploadedList,
        authOnly: false
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.continueNewUpload.reactRouterTemplate,
        component: CreateDocument,
        authOnly: true
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.newUpload.reactRouterTemplate,
        component: CreateDocument,
        authOnly: true
    }, {
        layout: DefaultLayout,
        key: 'same-key',
        path: clientRoutes.upload.reactRouterTemplate,
        component: AddEditDocument,
        authOnly: true
    }
];

export default renderRoutesComponents(config, {AuthRoute, AuthRouteWithLayout});
