/**
 * Created by lkarmelo on 30.10.2019.
 */

import * as React from 'react';
import classNames from 'classnames';
import {Portal} from 'react-portal';
import {setBodyClassOnCondition} from '../../hooks/setBodyClassOnCondition';
import {useOnOutsideClick} from '../../../hooks/useOnOutsideClick';

interface IProps {
    opened: boolean;
    className?: string;
    onOutsideContentClick?(): void;
}

const Sidebox: React.FC<IProps> = ({opened, className, children, onOutsideContentClick}) => {

    setBodyClassOnCondition('nkcm-sidebox--opened', opened);

    const onMouseDown = useOnOutsideClick(() => onOutsideContentClick(), [onOutsideContentClick]);

    return (
        <Portal>
            {opened &&
                <div className={classNames('nkcm-sidebox', className)}>
                    <div className={'nkcm-sidebox__window'} />
                    <div className={'nkcm-sidebox__content'} onMouseDown={onMouseDown}>
                        {children}
                    </div>
                </div>
            }
        </Portal>
    );
};

export default Sidebox;
