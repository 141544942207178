/**
 * Created by lkarmelo on 22.05.2019.
 */
import {ExtendedApi} from '../api';
import {Store} from '../../nkc-frontend-tools/types';
import {replaceDotsWithDashes} from '../../nkc-frontend-tools/utils/filterNamesEscape';
import {ExtendedStore} from '../store';
import {StaticFilterName} from '../types/FilterName';

const trim = (text: string): string => !!text && text.replace(/^"?(.*?)"?$/g, '$1');

const staticFilters: Store.IFiltersMeta = {
    [StaticFilterName.Sort]: {
        default: undefined,
        type: Store.FilterType.Select,
        title: 'Сортировать',
        values: [
            {
                label: 'По релевантности',
                value: ExtendedStore.SortFilterValues.Relevancy,
            },
            {
                label: 'Последние добавленные',
                value: ExtendedStore.SortFilterValues.Date,
            },
        ],
    }
};

const convertType = (type: String): Store.FilterType => {switch (type) {
    case 'selectfilter': return Store.FilterType.Select;
    default: return <Store.FilterType>type;
}};

const copyFiltersMeta = (item: any): {[key: string]: Store.IFilterMeta} => {
    const filter = {
        title: trim(item.name),
        type: convertType(item.type),
        isParam: item.isParam,
        isFromServer: true
    };

    if (item.isMultiSelect) {
        Object.assign(filter, {
            values: ((Array.isArray(item.value) ? item.value : item.value.values) || []).map(value =>
                ({
                    label: value.displayValue || '',
                    value: value.code,
                    parent: value.parent
                })),
            type: Store.FilterType.MultiSelect,
            default: []
        });
    }

    if (typeof((item.value || {}).from) !== 'undefined') {
        Object.assign(filter, {
            type: Store.FilterType.YearRange,
            from: item.value.from,
            to: item.value.to,
            default: {}
        });
    }

    return {[replaceDotsWithDashes(trim(item.code))]: filter};
};

export const getFiltersMeta = ({filters, params}: ExtendedApi.IFiltersParamsResponseBody): Store.IFiltersMeta => {
    const filterMap: Store.IFiltersMeta = Object.assign(
        {},
        staticFilters,
        ...filters.map(copyFiltersMeta),
        ...params.map(copyFiltersMeta),
    );

    return filterMap;
};
