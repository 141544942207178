import { ExtendedStore } from 'common/store';
import { INotificationSettingsStateProps, INotificationSettingsActionProps } from './interfaces/INotificationSettingsProps';
import { INotificationSetting } from 'common/api/ExtendedApi';
import { saveNotificationSetting } from 'app/redux/actions/notifications';
import { connect } from 'react-redux';
import NotificationSettings from './NotificationSettings';

const mapStateToProps = (state: ExtendedStore.IState): INotificationSettingsStateProps => ({
    notificationTypes: state.notifications.notificationTypes,
    notificationSettings: state.notifications.notificationSettings
});

const mapDispatchToProps = (dispatch): INotificationSettingsActionProps => ({
    saveNotificationSetting(setting: INotificationSetting) {
        dispatch(saveNotificationSetting(setting));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
