import React, {useCallback, useState} from 'react';

import {Field, Form} from 'react-final-form';
import {InputFieldMode, TextFormField} from 'app/components/common/form-fields';
import RegularModal from 'app/components/common/modals/RegularModal';
import {MessageModalContent} from 'app/components/common/modals/MessageModal';

import { useOnPopStateAndUnmountEffect } from 'app/hooks/useOnPopStateAndUnmountEffect';
import {useFormSenderHelper} from 'app/hooks/forms';

import {emailValidation} from 'app/utils/forms/validation';

import {ExtendedApi} from 'common/api';
import IProps from './interfaces/IPasswordResetEmailPopupProps';

import * as styles from './PasswordResetEmailPopup.scss';

const headerText = 'ВОССТАНОВЛЕНИЕ ПАРОЛЯ';

const PasswordResetEmailPopup: React.FC<IProps> = (props) => {
    const {
        isVisible, closePopup, formResponse, formStatus, resetFormStatus, resetForm,
        requestReset
    } = props;

    const [sendSuccess, setSendSuccess] = useState(false);

    const onExited = useCallback(
        () => {
            resetForm();
            setSendSuccess(false);
        },
        []
    );

    const onSuccess = useCallback(() => setSendSuccess(true), [formResponse]);

    const formSubmitCbRef = useFormSenderHelper(formStatus, resetFormStatus, onSuccess);

    const onSubmit = useCallback(
        (values, _, cb) => {
            formSubmitCbRef.current = cb;
            requestReset(values.email);
        },
        []
    );

    useOnPopStateAndUnmountEffect(closePopup);

    return (
        <RegularModal
            isVisible={isVisible}
            close={closePopup}
            onExited={onExited}
        >
            <div className={styles.passwordResetEmailPopup}>
                {sendSuccess ?
                    <MessageModalContent header={headerText} btnText={'Продолжить'} onBtnClick={closePopup}>
                        Письмо со ссылкой для восстановления пароля отправлено на почту&#32;
                        {formResponse && (formResponse as ExtendedApi.IRequestResetPasswordResponseBody).login}
                    </MessageModalContent>
                    :
                    <>
                        <div className={styles.passwordResetEmailPopupHeader}>
                            {headerText}
                        </div>
                        <div className={styles.passwordResetEmailPopupText}>
                            Введите email, указанный при регистрации.&#32;
                            На указанный вами email придёт письмо со ссылкой для восстановления пароля.
                        </div>
                        <Form onSubmit={onSubmit}>
                            {({handleSubmit, submitErrors}) => (
                                <form onSubmit={handleSubmit}>
                                    <Field name={'email'} validate={emailValidation}>
                                        {({input, meta}) => (
                                            <TextFormField
                                                {...input}
                                                mode={InputFieldMode.REGULAR}
                                                label={'Email'}
                                                error={meta.touched ? meta.error : undefined}
                                            />
                                        )}
                                    </Field>
                                    {submitErrors &&
                                        <div className={'form-field-error'}>
                                            {submitErrors}
                                        </div>
                                    }
                                    <div className={styles.passwordResetEmailPopupControls}>
                                        <button
                                            className={'btn-primary'}
                                        >
                                            Отправить
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Form>
                    </>
                }
            </div>
        </RegularModal>
    );
};

export default PasswordResetEmailPopup;
