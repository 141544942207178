import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { Form } from 'react-final-form';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import SelectFormField from 'app/components/common/form-fields/Select/SelectFormField';
import { SubmitPanelFormControls } from 'app/components/common/form-controls';

import { PublicationType } from 'common/types/PublicationType';
import { ViewMode } from 'app/components/common/form-fields/Select/interfaces/ISelectProps';
import clientRoutes from 'common/clientRoutes';

import { AttrForms } from '../forms';
import IAttrDocumentFormProps, { IDocumentAttrs } from '../interfaces/IAttrDocumentFormProps';
import { useFormSenderHelper } from 'app/hooks/forms';

import * as styles from './DocumentForm.scss';

const DocumentFormView: React.FC<IAttrDocumentFormProps> = (props) => {

    const {
        document,
        formStatus,
        documentSources: { publicationTypes },
        onSubmit,
        onComplete,
        onClose
    } = props;

    const [issueType, setIssueType] = useState<string>(PublicationType.Book);
    const [isDisable, setIsDisable] = useState<boolean>(false);

    useEffect(
        () => { !!document?.meta?.type && setIssueType(document.meta.type); },
        [document?.meta?.type]
    );

    const onSendSuccess = useCallback(
        () => {
            setIsDisable(false);
            onComplete();
        },
        [onComplete]
    );
    const formSubmitCbRef = useFormSenderHelper(formStatus, () => { }, onSendSuccess);

    const onFormSubmit = useCallback(
        (values: IDocumentAttrs, _, cb) => {
            formSubmitCbRef.current = cb;
            setIsDisable(true);
            onSubmit({
                ...values,
                meta: {
                    ...values.meta,
                    type: issueType
                }
            });
        },
        [issueType, onSubmit]
    );

    const AttrsForm = useMemo(
        () =>
            AttrForms.hasOwnProperty(issueType)
                ? AttrForms[issueType]
                : null,
        [issueType]
    );

    return (
        <Form onSubmit={onFormSubmit}>
            {({
                handleSubmit,
                submitting,
                submitErrors,
                hasValidationErrors
            }) =>
                (
                    <form
                        onSubmit={(handleSubmit)}
                        className={classNames(
                            styles.documentForm,
                            { [styles.documentFormIsEditing]: !isDisable }
                        )}
                    >
                        <SelectFormField
                            className=""
                            title="Издание"
                            active={issueType}
                            mode={ViewMode.LABEL}
                            options={publicationTypes}
                            onSelect={(value: string) => setIssueType(value)}
                            isMultiSelect={false}
                        />
                        <AttrsForm {...props}/>
                        {submitErrors &&
                            <div className={'form-field-error'}>
                                {submitErrors}
                            </div>
                        }
                        <SubmitPanelFormControls
                            onSaveClick={(event: React.SyntheticEvent<HTMLFormElement, Event>) => {
                                hasValidationErrors && scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                                handleSubmit(event);
                            }}
                            onCancelClick={onClose}
                            saveButtonDisabled={submitting}
                            cancelButtonDisabled={submitting}
                            saveLabel={'Сохранить и продолжить'}
                            cancelLabel={'Отменить'}
                        />
                    </form>
                )
            }
        </Form>
    );
};

export default DocumentFormView;
