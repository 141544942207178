/**
 * Created by Lkarmelo on 27.03.2018.
 */

export const camelCaseToCapSnakeCase = (str: string): string => {
    let res = '';
    for (let i = 0; i < str.length; i++) {
        const char = str[i];
        if (char === char.toLowerCase()) {
            res += char.toUpperCase();
        } else {
            res += `_${char}`;
        }
    }
    return res;
};
