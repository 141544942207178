/**
 * Created by lkarmelo on 02.10.2019.
 */

import {Observable} from 'rxjs/Observable';
import {Action} from 'redux-actions';
import {merge} from 'rxjs/observable/merge';
import {MiddlewareAPI} from 'redux';
import {matchPath, match as Match} from 'react-router';

import {
    setReaderCurrentPageNumber, setReaderCurrentBookId, setReaderPageContent,
    setReaderCurrentPageNumberAndBookId, readerNextPage, readerPrevPage,
    fetchReadingBookMeta, setReadingBookMeta, setReaderBookScale,
    IFetchReadingBookMetaPayload, saveReaderLastPage, ISetReaderPageNumberAndBookIdPayload,
    requestAddBookmark, requestRemoveBookmark, fetchBookmarks, setBookmarks, IAddBookmarkPayload, addBookmark, removeBookmark
} from '../actions/bookReader';
import {
    bookPageRequest, bookPageReject, bookPageResolve,
    bookReaderMetaReject, bookReaderMetaRequest, bookReaderMetaResolve,
    saveBookReaderScaleReject, saveBookReaderScaleResolve,
    saveBookReaderLastPageResolve, saveBookReaderLastPageReject,
    addBookmarkReject, addBookmarkResolve, removeBookmarkReject, removeBookmarkResolve,
    bookmarksReject, bookmarksResolve
} from '../actions/loading';

import {raiseSystemNotification} from '../actions/systemNotifications';

import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';

import history from 'app/history';

import clientRoutes, {ReadPageParams} from 'common/clientRoutes';

export const loadReaderPageContent = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
    action$.ofType(
        setReaderCurrentPageNumberAndBookId.toString(),
        readerNextPage.toString(),
        readerPrevPage.toString(),
        setReaderCurrentPageNumber.toString(),
        setReaderCurrentBookId.toString(),
    )
        .map(() => {
            const readerState = store.getState().bookReader;
            return {bookId: readerState.currentBookId, pageNumber: readerState.currentPageNumber};
        })
        .filter(({bookId, pageNumber}) => !!bookId && !!pageNumber)
        .do(({bookId, pageNumber}) => {//меняем url в адресной строке
            const match: Match<Record<ReadPageParams, string>> = matchPath(
                history.location.pathname,
                clientRoutes.read.reactRouterTemplate
            );

            const nextPathname = clientRoutes.read.getUrl({id: bookId, pageNumber});

            if (history.location.pathname === nextPathname) {
                return;
            }

            if (match.params.id && !match.params.pageNumber) {
                //если перешли по ссылке на книгу, не указав страниц и мы подставляем номер страницы, то делаем реплейс,
                //чтобы корректно работала кнопка "назад"
                history.replace(nextPathname);
            } else {
                history.push(nextPathname);
            }
        })
        .switchMap(({bookId, pageNumber}) =>
            merge(
                apiCall.bookPage(bookId, pageNumber)
                    .mergeMap(({response}: {response: HTMLDocument | null}) =>
                        Observable.of(
                            setReaderPageContent( response ? response.documentElement : null),
                            bookPageResolve(),
                        )
                    )
                    .catch(e => {
                        console.error(e);
                        return Observable.of(bookPageReject(e));
                    })
                    .startWith(bookPageRequest())
                ,
                Observable.of(saveReaderLastPage({bookId, page: pageNumber}))
            )
        );

export const loadReaderBookMeta = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
    action$.ofType(fetchReadingBookMeta.toString())
        .switchMap(({payload}: Action<IFetchReadingBookMetaPayload>) =>
            apiCall.bookReaderMeta(payload.bookId)
                .mergeMap(({response}: {response: ExtendedStore.IReadingBookMeta}) => {
                    const actions = [
                        bookReaderMetaResolve(),
                        setReadingBookMeta(response),
                    ];
                    payload.loadSavedPage && actions.push(setReaderCurrentPageNumberAndBookId({
                        bookId: payload.bookId,
                        page: store.getState().bookReader.currentPageNumber || response.currentPage || 1
                    }));

                    return Observable.from(actions);
                })
                .catch(e => {
                    console.error(e);
                    return Observable.of(bookReaderMetaReject(e));
                })
                .startWith(bookReaderMetaRequest())
        );

export const saveReaderBookScale = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
    action$.ofType(setReaderBookScale.toString())
    //делаем побольше debounceTime, потому что юзер может много раз сменить масштаб, пока не найдёт подходящий
    //и нам не нужно сохранять каждое состояние
        .debounceTime(2000)
        .filter(() => {
            const readerState = store.getState().bookReader;
            return readerState && !!readerState.meta && !!readerState.meta.bookId;
        })
        .switchMap(({payload}: Action<number>) =>
            apiCall.bookReaderSaveScale(store.getState().bookReader.meta.bookId, payload)
                .mapTo(saveBookReaderScaleResolve())
                .catch(e => {
                    console.error(e);
                    return Observable.of(saveBookReaderScaleReject(e));
                })
        );

export const saveReaderLastPageEpic = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
    action$.ofType(saveReaderLastPage.toString())
        .switchMap(({payload}: Action<ISetReaderPageNumberAndBookIdPayload>) =>
            apiCall.bookReaderSavePage(payload.bookId, payload.page)
                .mapTo(saveBookReaderLastPageResolve())
                .catch(e => {
                    console.error(e);
                    return Observable.of(saveBookReaderLastPageReject());
                })
        ) ;

export const addBookmarkEpic = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
    action$.ofType(requestAddBookmark.toString())
        .exhaustMap(({payload}: Action<IAddBookmarkPayload>) =>
            apiCall.addBookmark(payload.bookId, payload.page)
                .mergeMap(() => Observable.of(
                    raiseSystemNotification(ExtendedStore.SystemNotificationType.BookmarkAdd),
                    addBookmark(payload),
                    addBookmarkResolve(),
                ))
                .catch(e => {
                    console.error(e);
                    return Observable.of(addBookmarkReject(e));
                })
        );

export const removeBookmarkEpic = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
    action$.ofType(requestRemoveBookmark.toString())
        .exhaustMap(({payload}: Action<ISetReaderPageNumberAndBookIdPayload>) =>
            apiCall.removeBookmark(payload.bookId, payload.page)
                .mergeMap(() => Observable.of(
                    removeBookmark(payload),
                    removeBookmarkResolve(),
                ))
                .catch(e => {
                    console.error(e);
                    return Observable.of(removeBookmarkReject(e));
                })
        );

export const loadBookmarks = (action$, store: MiddlewareAPI<ExtendedStore.IState>, {apiCall}: {apiCall: ExtendedApi.ApiCalls}) =>
    action$.ofType(fetchBookmarks.toString())
        .switchMap(({payload}: Action<string>) =>
            apiCall.bookmarks(payload)
                .mergeMap(({response}) => Observable.of(
                    setBookmarks(response),
                    bookmarksResolve(),
                ))
                .catch(e => {
                    console.error(e);
                    return Observable.of(bookmarksReject(e));
                })
        );
