/**
 * Created by lkarmelo on 06.08.2019.
 */

import {changePassword} from '../../actions/forms/formsSendActions';

import {changePasswordReject, changePasswordRequest, changePasswordResolve} from '../../actions/loading';
import {raiseSystemNotification} from '../../actions/systemNotifications';

import {FormName} from 'app/types/FormName';
import {ExtendedStore} from 'common/store';

import {createFormSendEpic} from './createFormSendEpic';

export const changePasswordEpic = createFormSendEpic({
    triggers: [changePassword.toString()],
    formName: FormName.ChangePassword,
    actions: {
        requestAction: changePasswordRequest,
        resolveAction: changePasswordResolve,
        rejectAction: changePasswordReject,
        spawnOnSuccess: [() => raiseSystemNotification(ExtendedStore.SystemNotificationType.PasswordChange)]
    }
});
