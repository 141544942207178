/**
 * Created by lkarmelo on 21.05.2019.
 */

import React from 'react';
import {useContext} from 'react';
import {useRef} from 'react';
import {useEffect} from 'react';
import {useCallback} from 'react';

import ArrowsFocusContext from './ArrowsFocusContext';

import {KeyCode} from 'app/types/KeyCode';
import IProps from './interfaces/IArrowsFocusContextItemProps';
import {OutOfBoundarySide} from './interfaces/IArrowFocusContext';

const ArrowsFocusContextItem: React.FunctionComponent<IProps> = ({children, passRefToChild}) => {
    if (typeof children !== 'object' || Array.isArray(children) ) {
        console.error('ArrowsFocusContextItem should wrap single html element');
        return children;
    }

    const {list, onFocusItemUnMount, getNextFocusElement, getPrevFocusElement, onOutOfBoundary} = useContext(ArrowsFocusContext);

    const childRef = useRef<HTMLElement>(null);

    const selfIndex = useRef(0);

    useEffect(
        () => {
            selfIndex.current = list.current.length;
            list.current.push({indexRef: selfIndex, childRef});

            return () => onFocusItemUnMount(selfIndex.current);
        },
        [onFocusItemUnMount, list]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (children.props.onKeyDown) {
                children.props.onKeyDown(e);
            }

            const {key} = e;

            let nextFocusItem;
            let outOfBoundariesSide: OutOfBoundarySide;
            if (key === KeyCode.ArrowDown) {
                nextFocusItem = getNextFocusElement(selfIndex.current);
                if (selfIndex.current + 1 >= list.current.length) {
                    outOfBoundariesSide = OutOfBoundarySide.End;
                }
                e.preventDefault();
            }
            if (key === KeyCode.ArrowUp) {
                nextFocusItem = getPrevFocusElement(selfIndex.current);
                if (selfIndex.current - 1 < 0) {
                    outOfBoundariesSide = OutOfBoundarySide.Start;
                }
                e.preventDefault();
            }
            nextFocusItem && nextFocusItem.childRef && nextFocusItem.childRef.current &&
                nextFocusItem.childRef.current.focus();

            onOutOfBoundary && outOfBoundariesSide && onOutOfBoundary(outOfBoundariesSide);
        },
        [children.props.onKeyDown, list, getNextFocusElement, getPrevFocusElement, onOutOfBoundary]
    );

    const setRef = useCallback(
        (el) => {
            if (passRefToChild) {
                passRefToChild.current = el;
            }
            childRef.current = el;
        },
        [passRefToChild]
    );

    return React.cloneElement(children, {ref: setRef, onKeyDown});
};

export default ArrowsFocusContextItem;
