import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import {IOptionGroupProps} from '../interfaces/ISelectProps';

import * as styles from '../MaterialSelectFormField.scss';
import Checkbox from 'app/components/common/Checkbox';

const OptionGroup: React.FC<IOptionGroupProps> = (props) => {
    const {
        title,
        children,
        onDeselect,
        onSelect,
        isActive,
        level,
        value
    } = props;

    const [isOpen, setIsOpen] = useState(!!open);

    const onTextClick = useCallback(
        () => {
            !isActive ? onSelect(value) : onDeselect(value);
        },
        [isActive, onSelect, onDeselect]
    );

    const onArrowClick = useCallback(
        () => {
            setIsOpen(!isOpen);
        },
        [isOpen]
    );

    return (
        <div>
            <div
                className={classNames(
                    `select__form-field-col${level}`,
                    styles.selectFormFieldDropDownOption,
                    {[styles.selectFormFieldDropDownOptionSelected]: isActive}
                )}
            >
                <i className={styles.selectFormFieldDropDownOptionIcon}/>
                <div
                    className={classNames(
                        styles.selectFormFieldDropDownOptionGroupArrow,
                        { [styles.selectFormFieldDropDownOptionGroupArrowOpen]: isOpen }
                    )}
                />
                <div
                    className={styles.selectFormFieldDropDownOptionGroupText}
                    onClick={onTextClick}
                >
                    {title}
                </div>
            </div>
            <div
                className={classNames(
                    styles.selectFormFieldDropDownSubOptions,
                    { [styles.selectFormFieldDropDownSubOptionsHidden]: !isOpen }
                )}
            >
                {children}
            </div>
        </div>
    );
};

OptionGroup.defaultProps = {
    level: 0
};

export default OptionGroup;
