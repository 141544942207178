import { createAction } from 'redux-actions';
import { ExtendedApi } from 'common/api';
import { ISubscriptionIdentifier } from 'common/api/ExtendedApi';

export interface ISetSubscriptionStatusPayload {
    subscription: ExtendedApi.ISubscriptionIdentifier;
    status: boolean;
}

export interface ISubscribeToMailingListPayload {
    subscription: ExtendedApi.ISubscriptionIdentifier;
    raiseNotification: boolean;
}

export const subscribeToAllMailingLists = createAction('SUBSCRIBE_TO_ALL_MAILING_LISTS');

export const unsubscribeFromAllMailingLists = createAction('UNSUBSCRIBE_FROM_ALL_MAILING_LISTS');

export const subscribeToMailingList = createAction<ISubscribeToMailingListPayload>('SUBSCRIBE_TO_MAILING_LIST');

export const unsubscribeFromMailingList = createAction<ISubscriptionIdentifier>('UNSUBSCRIBE_FROM_MAILING_LIST');

export const setSubscriptionStatus = createAction<ISetSubscriptionStatusPayload>('SET_SUBSCRIPTION_STATUS');

export const fetchSubscriptions = createAction('FETCH_SUBSCRIPTIONS');

export const setSubscriptions = createAction('SET_SUBSCRIPTIONS');

export const clearSubscriptions = createAction('CLEAR_SUBSCRIPTIONS');
