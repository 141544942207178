/**
 * Created by lkarmelo on 15.04.2019.
 */

import {FilterName, StaticFilterName} from 'common/types/FilterName';
import {FilterToTitleFilterPanel} from 'app/types/FilterToTitle';

import {createOrderedFiltersSelector} from 'app/redux/selectors/createNormalizedFiltersSelector';
import {createFilterAggregationDocCountSelector} from 'app/redux/selectors/createFilterAggregationDocCountSelector';

import {ExtendedStore} from 'common/store';
import {IFiltersStateProps} from './interfaces/IFiltersProps';

export const defaultFiltersSelector = createOrderedFiltersSelector(
    [StaticFilterName.Sort, FilterName.Year, FilterName.PublicationType, FilterName.MaterialType, FilterName.KnowledgeArea],
    FilterToTitleFilterPanel
);

export const getFiltersMapStateToProps = (context: string, filtersSelector = defaultFiltersSelector) => {
    const filterValuesDocCountSelector = createFilterAggregationDocCountSelector(context);

    return (state: ExtendedStore.IState): IFiltersStateProps => ({
        filters: filtersSelector(state),
        activeFilters: state[context] && state[context].filters,
        filterValuesDocCount: filterValuesDocCountSelector(state),
        contextState: state[context],
    });
};
