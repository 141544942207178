/**
 * Created by lkarmelo on 16.01.2019.
 */

import React from 'react';

import * as styles from './RFBRLogo.scss';
import RFBRLogoTextSvg from './TextSvg/RFBRLogoTextSvg';

const RFBRLogo: React.FunctionComponent = () => (
    <div className={styles.rfbrLogo}>
        <div className={styles.rfbrLogoImageWrapper}>
            <div className={styles.rfbrLogoImage} />
        </div>
        <div className={styles.rfbrLogoTextWrapper}>
            <RFBRLogoTextSvg />
        </div>
    </div>
);

export default RFBRLogo;
