'use strict';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
// require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

//React 16 depends on the collection types Map and Set.
require('core-js/es6/map');
require('core-js/es6/set');
require('core-js/es6/array');
require('core-js/es7/array');
require('core-js/es6/string');
require('core-js/es6/math');

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
require('core-js/modules/es7.object.entries');
require('core-js/modules/es7.object.values');
require('core-js/modules/es6.object.is');
