/**
 * Created by lkarmelo on 29.03.2019.
 */
import {Action, handleActions} from 'redux-actions';

import {setIssueInfoCatalog} from '../actions/issueInfoCatalog';
import {ExtendedStore} from 'common/store';

export default handleActions(
    {
        [setIssueInfoCatalog.toString()]: (
            state: ExtendedStore.IIssueInfoCatalogItem[],
            {payload}: Action<ExtendedStore.IIssueInfoCatalogItem[]>
        ) => payload
    },
    []
);
