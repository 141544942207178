import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import Checkbox from 'app/components/common/Checkbox';

import IProps from './interfaces/ICheckboxOptionGroupProps';
import * as styles from './OptionGroup.scss';

const OptionGroup: React.FC<IProps> = (props) => {
    const {
        name,
        checked,
        value,
        children,
        meta,
        open,
        onSelect,
        onDeselect
    } = props;

    const [isOpen, setIsOpen] = useState(!!open);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.target.checked ? onSelect(value, meta) : onDeselect(value);
        },
        []
    );

    const onTextClick = useCallback(
        () => {
            setIsOpen(!isOpen);
        },
        [isOpen]
    );

    return (
        <>
            <div className={styles.checkboxOptionGroup}>
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                />
                <div
                    className={styles.checkboxOptionGroupText}
                    onClick={onTextClick}
                >
                    <div
                        className={classNames(
                            styles.checkboxOptionGroupArrow,
                            { [styles.checkboxOptionGroupArrowOpen]: isOpen }
                        )}
                    />
                    {name}
                </div>
            </div>
            <div
                className={classNames(
                    styles.checkboxOptionGroupSubOptions,
                    { [styles.checkboxOptionGroupSubOptionsHidden]: !isOpen }
                )}
            >
                {children}
            </div>
        </>
    );
};

export default OptionGroup;
