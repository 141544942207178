/**
 * Created by lkarmelo on 19.03.2019.
 */

import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {contextListMapDispatchToProps} from './contextListMapDispatchToProps';

import ContextBookList from './ContextBookList';
import {
    IContextBookListActionProps,
    IContextBookListStateProps,
    IContextBookListOwnProps,
    IContextListCreatorProps
} from './interfaces/IContextBookListProps';

import {ExtendedStore} from 'common/store';

import {dispatchWithContext} from 'app/redux/context/dispatchWithContext';

type MapDispatchToPropsOverrides = (dispatch: any) => Partial<IContextBookListActionProps>;

const getContextMapStateToProps = (context: ExtendedStore.BookSearchContext, filtersComponent) =>
    (state: ExtendedStore.IState, ownProps: IContextBookListOwnProps): IContextBookListStateProps & IContextListCreatorProps => {
        const contextState = state[context];
        return {
            resultDocuments: contextState.results && contextState.results.list,
            query: contextState.searchQuery,
            lastExecutedQuery: contextState.lastExecutedSearchQuery,
            totalFoundResults: contextState.results && contextState.results.count,
            filtersComponent,
        };
    };

const getContextMapDispatchToProps = (
    context: ExtendedStore.BookSearchContext,
    mapDispatchToPropsOverrides?: MapDispatchToPropsOverrides
) =>
    defaultDispatch => {
        const contextDispatch = dispatchWithContext(defaultDispatch, context);
        const searchMappedDispatch = contextListMapDispatchToProps(contextDispatch);

        const dispatchOverrides = mapDispatchToPropsOverrides ? mapDispatchToPropsOverrides(contextDispatch) : {};

        return (): IContextBookListActionProps => ({
            ...searchMappedDispatch,
            ...dispatchOverrides
        });
    };

export const createContextBookList = (
    context: ExtendedStore.BookSearchContext,
    FiltersComponent: React.ComponentType,
    mapDispatchToPropsOverrides?: MapDispatchToPropsOverrides
) => {
    const mapStateToProps = getContextMapStateToProps(context, FiltersComponent);
    const mapDispatchToProps = getContextMapDispatchToProps(context, mapDispatchToPropsOverrides);

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(ContextBookList));
};
