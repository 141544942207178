/**
 * Created by lkarmelo on 02.09.2019.
 */

import {MiddlewareAPI} from 'redux';

import {emailSubmitReject, emailSubmitRequest, emailSubmitResolve} from 'app/redux/actions/loading';
import {raiseSystemNotification} from 'app/redux/actions/systemNotifications';
import {submitEmail, IUpdateEmailActionPayload} from 'app/redux/actions/forms/formsSendActions';
import {setProfile, setEmail} from 'app/redux/actions/profile';

import {FormName} from 'app/types/FormName';
import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';

import {createFormSendEpic} from './createFormSendEpic';
import { Action } from 'redux-actions';

export const updateEmailEpic = createFormSendEpic({
    triggers: [submitEmail.toString()],
    formName: FormName.UpdateEmail,
    actions: {
        resolveAction: emailSubmitResolve,
        requestAction: emailSubmitRequest,
        rejectAction: emailSubmitReject,
        spawnOnSuccess: [
            (response, {payload}: Action<IUpdateEmailActionPayload>) =>
                payload.raiseNotification &&
                    raiseSystemNotification(ExtendedStore.SystemNotificationType.EmailConfirmSent),

            (response, {payload}: Action<IUpdateEmailActionPayload>) => setEmail(payload.email)
        ]
    },
    getPayload:
        ({email}: IUpdateEmailActionPayload, store: MiddlewareAPI<ExtendedStore.IState>): ExtendedApi.IEmailUpdateBody =>
            ({ email })
});
