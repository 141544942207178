/**
 * Created by lkarmelo on 07.05.2019.
 */

import {MiddlewareAPI} from 'redux';
import {Action} from 'redux-actions';
import {concat} from 'rxjs/observable/concat';
import {Observable} from 'rxjs/Observable';

import {ISetLikeStatusPayload, requestLikeStateChange, setLikeState} from 'app/redux/actions/like';
import {likeRequest, likeReject, likeResolve} from 'app/redux/actions/loading';

import {getActionWithAllContexts} from 'app/redux/context/getActionWithAllContexts';

import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';

export const requestLikeStateChangeEpic = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    {apiCall}: {apiCall: ExtendedApi.ApiCalls}
) =>
    action$.ofType(requestLikeStateChange.toString())
        .mergeMap(({payload: {id, nextLikeState}}: Action<ISetLikeStatusPayload>) =>
            concat(
                apiCall.changeLikeStatus(id, nextLikeState)
                    .mergeMap(() =>
                        Observable.of(...getActionWithAllContexts(setLikeState(id, nextLikeState)))
                    )
                    .catch(e => {
                        console.error(e);
                        // retry on reject on 401 status code
                        //TODO: сделать нормальный вывод ошибки
                        if (e.status === 403) {
                            window.alert('Недостаточно прав');
                        }

                        return Observable.of(likeReject(e));
                    })
                ,
                Observable.of(likeResolve())
            )
                .takeUntil(action$.ofType(likeReject.toString()))
                .startWith(likeRequest())
        );
