import { createFormSendEpic } from './createFormSendEpic';
import { confirmEmail } from 'app/redux/actions/forms/formsSendActions';
import { FormName } from 'app/types/FormName';
import {
    confirmEmailRequest,
    confirmEmailResolve,
    confirmEmailReject,
    refreshUserResolve,
    refreshUserReject
} from 'app/redux/actions/loading';
import { ExtendedApi } from 'common/api';
import { refreshUser } from 'nkc-frontend-tools/redux/actions/user';
import { ExtendedStore } from 'common/store';
import { Observable } from 'rxjs';
import { setForm } from 'app/redux/actions/forms/forms';
import { merge } from 'rxjs/observable/merge';
import { LoadingActionsProvider } from 'nkc-frontend-tools/redux/actions/loading';

const { userResolve, userReject } = LoadingActionsProvider.loadingActions;

const confirmEmailChangeDefaultEpic =
    createFormSendEpic({
        triggers: [confirmEmail.toString()],
        formName: FormName.ConfirmEmail,
        actions: {
            requestAction: confirmEmailRequest,
            resolveAction: confirmEmailResolve,
            rejectAction: confirmEmailReject
        }
    });

const refreshAfterConfirmEmailChangeEpic =
    (action$, store, { apiCall }: { apiCall: ExtendedApi.ApiCalls }) =>
        action$.ofType(confirmEmail.toString())
            .switchMap(originalAction =>
                action$.ofType(refreshUserResolve.toString())
                    .mapTo(originalAction)
                    .take(1)
                    .takeUntil(action$.ofType(refreshUserReject.toString()))
            )
            .switchMap(originalAction =>
                action$.ofType(userResolve.toString())
                    .mapTo(originalAction)
                    .take(1)
                    .takeUntil(action$.ofType(userReject.toString()))
            )
            .mergeMap(() =>
                Observable.of(
                    setForm(FormName.ConfirmEmail, null, ExtendedStore.FormStatus.Completed)
                ))
            .catch(e => {
                console.error(e);
                return Observable.of(
                    setForm(FormName.ConfirmEmail, e, ExtendedStore.FormStatus.Rejected)
                );
            });

export const confirmEmailChangeEpic =
    (action$, store, { apiCall }: { apiCall: ExtendedApi.ApiCalls }) =>
        merge(
            confirmEmailChangeDefaultEpic(action$, store, { apiCall }),
            refreshAfterConfirmEmailChangeEpic(action$, store, { apiCall })
        );
