/**
 * Created by lkarmelo on 01.03.2019.
 */

import React from 'react';

const getEnhancedReactChildElement = <T>(child: React.ReactElement, getProps: Function): React.ReactElement => {
    return React.cloneElement(child, getProps(child.props));
};

export const getEnhancedReactChildren = (children: React.ReactNode, getProps: Function, filter: (props: any) => boolean = () => true) => {
    if (!children || typeof children === 'string' || typeof children === 'number') {
        return children;
    }
    if (Array.isArray(children)) {
        return React.Children.map(
            children,
            child => getEnhancedReactChildren(child, getProps, filter)
        );
    }
    if (typeof children === 'function') {
        return getEnhancedReactChildren(children(), getProps, filter);
    }
    if (typeof children === 'object') {
        const element = <React.ReactElement>children;
        if (filter(element.props)) {
            return getEnhancedReactChildElement(element, getProps);
        }

        return element;
    }
};
