/**
 * Created by lkarmelo on 11.03.2019.
 */
import {createAction, Action} from 'redux-actions';
import {Api} from 'nkc-frontend-tools/api';

export interface IFetchSearchResultsPayload {
    redirectPathname?: string;
    clearUrlBeforeRedirect?: boolean;
}
export interface IFetchSearchResults {
    (clearUrlBeforeRedirect?: boolean, redirectPathname?: string): Action<IFetchSearchResultsPayload>;
}
export const fetchSearchResults: IFetchSearchResults = createAction<IFetchSearchResultsPayload, boolean, string>(
    'FETCH_SEARCH_RESULTS',
    (clearUrlBeforeRedirect: boolean, redirectPathname: string): IFetchSearchResultsPayload =>
        ({redirectPathname, clearUrlBeforeRedirect})
);

export const fetchMoreSearchResults = createAction('LOAD_MORE_SEARCH_RESULTS');

export const executeAdditionalSearchRequest = createAction('EXECUTE_ADDITIONAL_SEARCH_REQUEST');

export const addToSearchResults = createAction<Api.IDocumentSearchResponseBody>('ADD_TO_SEARCH_RESULTS');

export interface IUpdateLocationFromFiltersPayload extends IFetchSearchResultsPayload {
    isNewSearch: boolean;
    replace?: boolean;
}
interface IUpdateLocationFromFilters {
    (
        isNewSearch: boolean,
        clearUrlBeforeRedirect?: boolean,
        redirectPathname?: string,
        replace?: boolean
    ): Action<IUpdateLocationFromFiltersPayload>;
}
export const updateLocationFromFilters: IUpdateLocationFromFilters =
    createAction<IUpdateLocationFromFiltersPayload, boolean, boolean, string, boolean>(
        'UPDATE_LOCATION_FROM_FILTERS',
        (
            isNewSearch: boolean,
            clearUrlBeforeRedirect: boolean,
            redirectPathname: string,
            replace: boolean
        ): IUpdateLocationFromFiltersPayload => ({redirectPathname, isNewSearch, clearUrlBeforeRedirect, replace})
    );
