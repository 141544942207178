/**
 * Created by lkarmelo on 12.03.2019.
 */

import {DependencyList, useEffect, useRef} from 'react';

/*
* Колбек срабатывает только на update, в отличии от useEffect, который срабатывает ещё и после mount'а
* */

export const useEffectOnUpdate = (callback: Function, deps: DependencyList) => {
    const isOnMountCompleted = useRef(false);

    useEffect(
        () => {
            if (isOnMountCompleted.current) {
                callback();
            } else {
                isOnMountCompleted.current = true;
            }
        },
        deps
    );
};
