import {connect} from 'react-redux';

import RegistrationPopup from './RegistrationPopup';

import {closePopup, showPopup} from 'app/redux/actions/popups';
import {registerUser} from 'app/redux/actions/forms/formsSendActions';
import {setFormStatus, removeForm} from 'app/redux/actions/forms/forms';

import {FormName} from 'app/types/FormName';
import {PopupName} from 'app/types/PopupName';
import {ExtendedStore} from 'common/store';

import {IRegistrationPopupActionProps, IRegistrationPopupStateProps} from './interfaces/IRegistrationPopupProps';

const mapStateToProps = (state: ExtendedStore.IState): IRegistrationPopupStateProps => {
    const form = state.forms[FormName.Registration];

    return {
        isVisible: state.popups[PopupName.Registration],
        formStatus: form && form.status,
        formResponse: form && form.response,
    };
};

const mapDispatchToProps = (dispatch): IRegistrationPopupActionProps => ({
    closePopup() {
        dispatch(closePopup(PopupName.Registration));
    },
    returnToLoginPopup() {
        dispatch(closePopup(PopupName.Registration));
        dispatch(showPopup(PopupName.Login));
    },
    register(email: string, password: string) {
        dispatch(registerUser({email, password}));
    },
    resetFormStatus() {
        dispatch(setFormStatus(FormName.Registration, null));
    },
    resetForm() {
        dispatch(removeForm(FormName.Registration));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPopup);
