/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { combineReducers } from 'redux';

import {BookSearchContext} from 'common/store/ExtendedStore';

import {loadingBarReducer} from 'react-redux-loading-bar';

import filtersMeta from 'nkc-frontend-tools/redux/reducers/search/filtersMeta';
import loading from 'nkc-frontend-tools/redux/reducers/loading';
import defaultFilters from 'nkc-frontend-tools/redux/reducers/search/defaultFilters';
import authorization from 'nkc-frontend-tools/redux/reducers/authorization';

import {getSearchReducer} from './search/search';
import showcaseDocuments from './showcaseDocuments';
import objectCard from './objectCard';
import user from './user';
import issueInfoCatalog from './issueInfoCatalog';
import profile from './profile';
import forms from './forms';
import systemNotifications from './systemNotifications';
import libraryStats from './libraryStats';
import popups from './popups';
import bookReader from './bookReader';
import subscriptions from './subscriptions';
import notifications from './notifications';
import fileUploadProgress from './fileUploadProgress';

import {createContextReducer, createNoContextReducer} from 'app/redux/context/createContextReducer';

import initialState from 'common/store/storeInitialState';

const searchReducer = getSearchReducer(initialState[BookSearchContext.Search]);
const catalogReducer = getSearchReducer(initialState[BookSearchContext.Catalog]);
const favoriteReducer = getSearchReducer(initialState[BookSearchContext.Favorite]);
const uploadedReducer = getSearchReducer(initialState[BookSearchContext.UploadedList]);

export default combineReducers({
    loadingBar: loadingBarReducer,
    filtersMeta,
    search: createContextReducer(searchReducer, BookSearchContext.Search),
    [BookSearchContext.Catalog]: createContextReducer(catalogReducer, BookSearchContext.Catalog),
    [BookSearchContext.Favorite]: createContextReducer(favoriteReducer, BookSearchContext.Favorite),
    [BookSearchContext.UploadedList]: createContextReducer(uploadedReducer, BookSearchContext.UploadedList),
    loading,
    showcaseDocuments: createNoContextReducer(showcaseDocuments),
    authorization,
    user,
    profile,
    subscriptions,
    notifications,
    forms,
    defaultFilters,
    objectCard: createNoContextReducer(objectCard),
    issueInfoCatalog,
    libraryStats,
    systemNotifications,
    popups,
    bookReader,
    fileUploadProgress
});
