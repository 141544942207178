/**
 * Created by lkarmelo on 04.10.2019.
 */

import React, {useEffect} from 'react';
import {RouteComponentProps, matchPath, match as Match} from 'react-router';
import {withRouter} from 'react-router-dom';

import {setReaderCurrentPageNumberAndBookId, fetchReadingBookMeta} from 'app/redux/actions/bookReader';

import {ExtendedStore} from 'common/store';

import clientRoutes, {ReadPageParams} from 'common/clientRoutes';

let store;
if (process.env.RENDER_TYPE === 'client') {
    store = require('app/redux/store/store').default;
}

type MatchParams = Record<ReadPageParams, string>;

export const onLocationChange = (match: Match<MatchParams>) => {
    const state: ExtendedStore.IState = store.getState();
    const readerState = state.bookReader;

    const bookId = match.params.id;
    const parsedPage = parseInt(match.params.pageNumber, 10);
    const page = isNaN(parsedPage) ? undefined : parsedPage;

    if (readerState.currentPageNumber === page && readerState.currentBookId === bookId) {
        return;
    }

    //если открыли url со страницей и не нужно ждать, пока последняя страница придёт, то сразу загружаем
    const shouldLoadImmediately = bookId !== undefined && page !== undefined;
    shouldLoadImmediately && store.dispatch(setReaderCurrentPageNumberAndBookId({bookId, page}));

    if (bookId !== undefined && (!readerState.meta || Object.keys(readerState.meta).length === 0)) {
        store.dispatch(fetchReadingBookMeta({bookId, loadSavedPage: !shouldLoadImmediately}));
    }
};

const ReaderLocationObserver: React.FC<RouteComponentProps<MatchParams>> = ({history, match}) => {

    useEffect(
        () => {
            onLocationChange(match);
        },
        []
    );

    useEffect(
        () => {
            //подписываемся на историю, чтобы не полагаться на рендер компоненты, потому что актуальные props могут прийти позднее
            const unlisten = history.listen((location, action) => {
                const currentMatch: Match<MatchParams> = matchPath(location.pathname, {path: clientRoutes.read.reactRouterTemplate});
                currentMatch && onLocationChange(currentMatch);
            });

            return () => unlisten();
        },
        []
    );

    return null;
};

export default withRouter(ReaderLocationObserver);
