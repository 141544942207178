import React from 'react';

import * as styles from './RFBRVerticalLogo.scss';

const RFBRVerticalLogo: React.FC = () => (
    <div
        className={styles.rfbrVerticalLogoBox}
    >
        <div
            className={styles.rfbrVerticalLogoImage}

        />
        <div className={styles.rfbrVerticalLogoText}>
            Библиотека российского фонда фундаментальных исследований
        </div>
    </div>
);

export default RFBRVerticalLogo;
