/**
 * Created by lkarmelo on 26.10.2018.
 */
import {Observable} from 'rxjs/Observable';
import {ActionMeta, Action} from 'redux-actions';
import {ContextOrNonContextAction} from '../../../types';

export type IgnoreSearchActionMeta = {ignoreSearchTrigger: boolean};

/**
 * Фильтрует экшены по наличию поля meta.ignoreSearchTrigger
 */
function ignoreSearchActionsByMeta(this: Observable<ContextOrNonContextAction>): Observable<ContextOrNonContextAction> {
    return this.filter((action: ActionMeta<any, IgnoreSearchActionMeta>) => {
        const meta = action.meta;
        const actionHasMeta = meta !== undefined && meta !== null && typeof meta === 'object';
        return !actionHasMeta || (actionHasMeta && !meta.ignoreSearchTrigger);
    });
}

Observable.prototype.ignoreSearchActionsByMeta = ignoreSearchActionsByMeta;

declare module 'rxjs/Observable' {
    /* tslint:disable-next-line: interface-name */
    interface Observable<T> {
        ignoreSearchActionsByMeta: typeof ignoreSearchActionsByMeta;
    }
}
