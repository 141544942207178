/**
 * Created by lkarmelo on 22.05.2019.
 */

import {filtersToDefaults} from 'nkc-frontend-tools/redux/actions/search/defaultFilters';
import {setFilters} from 'nkc-frontend-tools/redux/actions/search/filters';
import {actionWithContext} from 'app/redux/context/actionWithContext';
import {initialFiltersToDefaults} from 'app/redux/actions/search/filters';
import {getStateByContext} from 'app/utils/getStateByContext';

export const filtersToDefaultsEpic = (action$, store) =>
    action$.ofType(filtersToDefaults.toString(), initialFiltersToDefaults.toString())
        .filter(({context, type}) => {
            const state = getStateByContext(store, context);
            //если в момент экшена initialFiltersToDefaults() в store у фильтров уже были выставлены значения, то игноририуем экшен
            return type !== initialFiltersToDefaults.toString() ||
                (!state.filters || Object.keys(state.filters).length === 0);
        })
        .map(({context, meta}) =>
            actionWithContext(
                //добавляем поле meta, потому что filtersToDefaults мог быть обёрнут в withIgnoreSearchActionMeta
                {...setFilters(store.getState().defaultFilters), meta},
                context
            )
        );
