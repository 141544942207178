/**
 * Created by lkarmelo on 26.10.2018.
 */
import {Observable} from 'rxjs/Observable';
import {ContextAction} from '../../context/connectWithContext';
import {ContextOrNonContextAction} from '../../../types';

/**
 * Фильтрует экшены по наличию или отсутствию объекта context
 * @param {boolean} withContext: true - пропустит только экшены с context, false - только экшены без context
 * @param {string[]} contextList список контекстов, которые будут дальше пропускаться
 */
function hasContext(
    this: Observable<ContextOrNonContextAction>,
    withContext: boolean,
    contextList?: string[]
): Observable<ContextOrNonContextAction> {
    return this.filter((action: ContextAction<any>) => {
        if (withContext) {
            return contextList ? !!action.context && action.context[0] && contextList.indexOf(action.context[0]) >= 0 : !!action.context;
        } else {
            return contextList ?
                !action.context || !!action.context && action.context[0] && contextList.indexOf(action.context[0]) >= 0 :
                !action.context;
        }
    });
}

Observable.prototype.hasContext = hasContext;

declare module 'rxjs/Observable' {
    /* tslint:disable-next-line: interface-name */
    interface Observable<T> {
        hasContext: typeof hasContext;
    }
}
