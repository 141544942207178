/**
 * Created by Lkarmelo on 05.09.2017.
 */

import ApiRoute from './Route/ApiRoute';
const prefix = '/api';

const routes = {
    filtersMeta: new ApiRoute(`${prefix}/filters`),
    paramsMeta: new ApiRoute(`${prefix}/params`),

    globalSearch: new ApiRoute(`${prefix}/search`),
    personSearch: new ApiRoute(`${prefix}/search/person`),
    queryExpansion: new ApiRoute(`${prefix}/buildQueryExpansion/{query}`),
    searchHints: new ApiRoute(`${prefix}/search/suggestions`),

    searchByCategory: new ApiRoute(`${prefix}/documents/{categoryCode}`),

    objectCard: new ApiRoute(`${prefix}/document/{id}`),

    logIn: new ApiRoute(`${prefix}/signIn`),
    logOut: new ApiRoute(`${prefix}/signOut`),
    changePassword: new ApiRoute(`${prefix}/user/changePassword`),
    resetPassword: new ApiRoute(`${prefix}/resetPassword/{token}`),
    forgotPassword: new ApiRoute( `${prefix}/forgetPassword`),
    refreshAuthenticator: new ApiRoute( `${prefix}/authenticate/refresh`),

    userData: new ApiRoute(`${prefix}/user`),
    userPermissions: new ApiRoute(`${prefix}/user/roles`),

    currentUserData: new ApiRoute(`${prefix}/user/profile/{id}`),
    updatePersonalInfo: new ApiRoute(`${prefix}/user/profile/edit{?profileId}`),
    currentUserWorkInfo: new ApiRoute(`${prefix}/user/employees/{id}`),
    updateUserWorkInfo: new ApiRoute(`${prefix}/user/employee{?profileId}{&employeeId}`),
    currentUserCompetencies: new ApiRoute(`${prefix}/user/competencies/requirement/{id}`),
    updateUserCompetencies: new ApiRoute(`${prefix}/user/competencies{?profileId}`),
    currentUserCategories: new ApiRoute(`${prefix}/user/knowledgeCategories/documents/{id}`),
    currentUserSkills: new ApiRoute(`${prefix}/user/skills/{id}`),
    updateUserSkills: new ApiRoute(`${prefix}/user/skills{?profileId}`),

    userNotification: new ApiRoute(`${prefix}/user/notifications/list`),
    userNotificationCount: new ApiRoute(`${prefix}/user/notifications/unreadCount`),
    markNotificationReaded: new ApiRoute(`${prefix}/user/notifications/read`),
    markNotificationUnreaded: new ApiRoute(`${prefix}/user/notifications/unread`),

    relatedDocuments: new ApiRoute(`${prefix}/relatedDocuments{?id}`),

    favorites: new ApiRoute(`${prefix}/favoriteDocuments`),
    markFavorite: new ApiRoute(`${prefix}/favorites`),
    unmarkFavorite: new ApiRoute(`${prefix}/favorites/{documentId}`),

    readLater: new ApiRoute(`${prefix}/readLaterDocuments`),
    markReadLater: new ApiRoute(`${prefix}/readLater`),
    unmarkReadLater: new ApiRoute(`${prefix}/readLater/{documentId}`),

    organizations: new ApiRoute(`${prefix}/organizations`),
    addresses: new ApiRoute(`${prefix}/workAddresses`),
    posts: new ApiRoute(`${prefix}/posts`),
    subdivisions: new ApiRoute(`${prefix}/orgstructure`),

    orgStructureWithEmployees: new ApiRoute(`${prefix}/orgstruct`),

    competencies: new ApiRoute(`${prefix}/competencies`),
    skills: new ApiRoute(`${prefix}/skills`),
    createSkill: new ApiRoute(`${prefix}/skills`),

    catalogueCategories: new ApiRoute(`${prefix}/knowledgeCategories`),
    catalogueCategory: new ApiRoute(`${prefix}/category/{code}`),
    editCatalogueCategory: new ApiRoute(`${prefix}/category`),

    // education -----
    educationMaterials: new ApiRoute(`${prefix}/education/materials/all`),
    ownEducationMaterials: new ApiRoute(`${prefix}/education/materials/own`),
    addNewDocument: new ApiRoute(`${prefix}/education/material`),
    editDocument: new ApiRoute(`${prefix}/education/material/{documentId}`),
    deleteDocument: new ApiRoute(`${prefix}/education/material/{documentId}`),
    educationMaterialTypes: new ApiRoute(`${prefix}/education/materialTypes`),
    numberOfEducationMaterials: new ApiRoute(`${prefix}/education/recommendations/ownCount`),

    recommendDocumentByProfiles: new ApiRoute(`${prefix}/education/recommendations/manage/byProfiles`),
    recommendDocumentBySubdivisions: new ApiRoute(`${prefix}/education/recommendations/manage/bySubdivisions`),

    educationMaterialsByStatus: new ApiRoute(`${prefix}/education/recommendations/own{?recommendationsOnly,documentStatus}`),

    changeEducationMaterialStatus: new ApiRoute(`${prefix}/education/tutorial/{documentId}`),
    resetEducationMaterialStatus: new ApiRoute(`${prefix}/education/tutorial/{documentId}`),

    refuseRecommendation: new ApiRoute(`${prefix}/education/recommendations/own/{documentId}/refuse`),

    recommendations: new ApiRoute(
        `${prefix}/education/recommendations/manage` +
        '{?document,createdDateFrom,createdDateTo,planDateFrom,planDateTo,learnedDateTo,author,' +
        'learnedDateFrom,necessity,status,recommendationStatus,receiver,orderBy,orderAsc,page,count}'
    ),
    editRecommendation: new ApiRoute(`${prefix}/education/recommendations/manage/{id}{?forUser}`),
    deleteRecommendation: new ApiRoute(`${prefix}/education/recommendations/manage/{id}{?forUser}`),

    userIdByLogin: new ApiRoute(`${prefix}/profile/{login}{?sourceId}`),

    uploadFile: new ApiRoute(`${prefix}/files/upload`),
    processFile: new ApiRoute(`${prefix}/files/{fid}`, { paramsWithBackslash: true }),
    downloadFile: new ApiRoute(`${prefix}/files/{fid}`, { paramsWithBackslash: true }),
    deleteFile: new ApiRoute(`${prefix}/files/{fid}`, { paramsWithBackslash: true }),
    listFile: new ApiRoute(`${prefix}/files/list/{location}`, { paramsWithBackslash: true }),

    publicFileLocation: new ApiRoute(`${prefix}/public/{location}`, { paramsWithBackslash: true })

};

export default routes;
