import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { IOptionGroupProps } from '../interfaces/ISelectProps';

import * as styles from '../MaterialSelectFormField.scss';
import Checkbox from 'app/components/common/Checkbox';

const OptionGroup: React.FC<IOptionGroupProps> = (props) => {
    const {
        title,
        children,
        onDeselect,
        onSelect,
        isActive,
        level,
        value
    } = props;

    const [isOpen, setIsOpen] = useState(!!open);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.target.checked ? onSelect(value) : onDeselect(value);
        },
        [onSelect, onDeselect]
    );

    const onTextClick = useCallback(
        () => {
            setIsOpen(!isOpen);
        },
        [isOpen]
    );

    return (
        <div>
            <div className={styles.selectFormFieldCheckboxOption}>
                <Checkbox
                    checked={isActive}
                    onChange={onChange}
                />
                <div
                    className={classNames(
                        `select__form-field-col${level}`,
                        styles.selectFormFieldCheckboxOptionGroupText
                    )}
                    onClick={onTextClick}
                >
                    <div
                        className={classNames(
                            styles.selectFormFieldCheckboxOptionGroupArrow,
                            { [styles.selectFormFieldCheckboxOptionGroupArrowOpen]: isOpen }
                        )}
                    />
                    {title}
                </div>
            </div>
            <div
                className={classNames(
                    styles.selectFormFieldCheckboxSubOptions,
                    { [styles.selectFormFieldCheckboxSubOptionsHidden]: !isOpen }
                )}
            >
                {children}
            </div>
        </div>
    );
};

OptionGroup.defaultProps = {
    level: 0
};

export default OptionGroup;
