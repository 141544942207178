import React from 'react';

import IProps from './interfaces/ISubmitPanelProps';
import * as styles from './SubmitPanel.scss';

const SubmitPanel: React.FC<IProps> = (props) => {
    const {
        onSaveClick,
        onCancelClick,
        saveLabel,
        cancelLabel,
        saveButtonDisabled,
        cancelButtonDisabled
    } = props;

    return (
        <div className={styles.submitPanelFormControls}>
            <button
                onClick={onSaveClick}
                type={'submit'}
                disabled={saveButtonDisabled ?? false}
                className={`btn-primary ${styles.submitPanelFormControlsSave}`}
            >
                {saveLabel ?? 'Сохранить'}
            </button>
            <button
                disabled={cancelButtonDisabled ?? false}
                onClick={onCancelClick}
                className={'btn-cancel'}
            >
                {cancelLabel ?? 'Отменить'}
            </button>
        </div>
    );
};

export default SubmitPanel;
