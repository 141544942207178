import React from 'react';
import {useState, useCallback, ChangeEvent, FormEvent} from 'react';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import * as styles from './LoginPanel.scss';
import SocialAuthPanel from '../SocialAuth';
import ILoginProps from './interfaces/ILoginProps';
import {EMAIL_REGEXP} from 'app/utils/constants';

const validateEmail = (email) => EMAIL_REGEXP.test(String(email).toLowerCase());

const LoginPanel: React.FC<ILoginProps> = (props: ILoginProps) => {
    const {authorizationFailed, onLogIn, onRegisterClick, onResetPasswordClick} = props;

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [required, updateRequired] = useState({login: true, password: true});
    const [email, updateEmail] = useState({login: true});
    const [wasShown, updateShow] = useState(false);

    React.useEffect(
        () => {
            if (wasShown) {
                updateRequired({login: !!login, password: !!password});
                updateEmail({login: validateEmail(login)});
            }

            updateShow(true);
        },
        [login, password]
    );

    const changeLogin = useCallback((e: ChangeEvent<HTMLInputElement>) => setLogin(e.currentTarget.value), []);
    const changePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value), []);
    const onLogInClick = useCallback(() => onLogIn(login, password), [login, password]);

    return (
        <React.Fragment>
            <h2>Вход в библиотеку РФФИ</h2>
            <div className={styles.loginSocialForm}>
                <div className={styles.loginSocialFormHeader}>Вoйдите с помощью</div>
                <div className={styles.loginSocialFormContent}>
                    <SocialAuthPanel/>
                </div>
            </div>
            <form onSubmit={(e: FormEvent<HTMLFormElement>) => { e.preventDefault(); }} className={styles.loginForm}>
                <div className={styles.loginFormHeader}>или</div>
                <label htmlFor="login">Email</label>
                <div className={classNames(styles.loginFormName, {[styles.loginFormFieldError]: !required.login || !email.login})}>
                    <input
                        id="login"
                        autoFocus
                        value={login}
                        onChange={changeLogin}
                    />
                    { !required.login && (
                        <div className={styles.loginFailMessage}>
                            Поле должно быть заполненно.
                        </div>
                    )}
                    { required.login && !email.login && (
                        <div className={styles.loginFailMessage}>
                            Введен неверный email.
                        </div>
                    )}
                </div>
                <label htmlFor="password">Пароль</label>
                <div className={classNames(styles.loginFormPassword, {[styles.loginFormFieldError]: !required.password})}>
                    <input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={changePassword}
                    />
                    <Tooltip placement="topRight" title="Показать/скрыть пароль" mouseEnterDelay={0.3} >
                        <button
                            type={'button'}
                            className={
                                classNames(
                                    styles.loginFormTogglePasswordVisibility,
                                    'btn',
                                    {
                                        'icon-eye-mini': !showPassword,
                                        'icon-eye': showPassword
                                    }
                                )
                            }
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </Tooltip>
                    { !required.password && (
                        <div className={styles.loginFailMessage}>
                            Поле должно быть заполненно.
                        </div>
                    )}
                    <button type={'button'} className={classNames(styles.loginPassRecovery, 'btn')} onClick={onResetPasswordClick}>
                        Забыли пароль?
                    </button>
                </div>
                <div className={classNames(styles.loginFormSubmit, {[styles.loginFormFieldError]: authorizationFailed === true})}>
                    <button onClick={onLogInClick} className={classNames('btn-primary')}>
                        Войти
                    </button>
                    {authorizationFailed === true &&
                        <div className={styles.loginFailMessage}>
                            Логин или пароль указаны неверно.
                        </div>
                    }
                </div>
            </form>
            <div className={styles.loginRegisterLink}>
                <span>Еще нет аккаунта?</span>
                <button
                    onClick={onRegisterClick}
                    className={'btn'}
                >
                    Создать аккаунт
                </button>
            </div>
        </React.Fragment>
    );
};

export default LoginPanel;
