import {updateProfileEpic} from './updateProfileEpic';
import {changePasswordEpic} from './changePasswordEpic';
import {deleteAccountEpic} from './deleteAccountEpic';
import { confirmAccountEpic } from './confirmAccountEpic';
import { updateEmailEpic} from './updateEmailEpic';
import { resetPasswordEpic } from './resetPasswordEpic';
import {registrationEpic} from './registrationEpic';
import {requestResetPasswordEpic} from './requestResetPassword';
import { confirmEmailChangeEpic } from './confirmEmailChangeEpic';
import {deleteBookEpic} from './deleteBookEpic';
import { addUpdateDocumentEpic, redirectOnDocumentEpic } from './addEditDocumentEpic';

export const formEpics = [
    updateProfileEpic,
    changePasswordEpic,
    deleteAccountEpic,
    confirmAccountEpic,
    confirmEmailChangeEpic,
    updateEmailEpic,
    resetPasswordEpic,
    registrationEpic,
    requestResetPasswordEpic,
    deleteBookEpic,
    addUpdateDocumentEpic,
    redirectOnDocumentEpic
];
