import React from 'react';

import Authenticate from './Authenticate';
import { connect } from 'react-redux';
import { Action } from 'redux-actions';
import { confirmAuthenticationSuccess } from 'app/redux/actions/authorization';
import { Dispatch } from 'redux';
import {FACEBOOK_PROVIDER_ID, VK_PROVIDER_ID} from 'common/constants';

import * as styles from './SocialAuthPanel.scss';

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): {onLogin(): void} => ({
    onLogin: () => dispatch(confirmAuthenticationSuccess())
});

const AuthenticateConnected = connect(null, mapDispatchToProps)(Authenticate);

const SocialAuthPanel: React.FunctionComponent<{}> = (): JSX.Element => {

    return (
        <div className={styles.socialAuthPanel}>
            <AuthenticateConnected provider={VK_PROVIDER_ID}>
                <i className="icon-vk"/>
                <span>ВКонтакте</span>
            </AuthenticateConnected>
            <AuthenticateConnected provider={FACEBOOK_PROVIDER_ID}>
                <i className="icon-fb"/>
                <span>Facebook</span>
            </AuthenticateConnected>
        </div>
    );

};

export default SocialAuthPanel;
