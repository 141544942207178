/**
 * Created by lkarmelo on 20.08.2019.
 */

import {connect} from 'react-redux';

import {ExtendedStore} from 'common/store';
import {FormName} from 'app/types/FormName';
import {INameInfoValues} from '../Form';

import {submitProfile} from 'app/redux/actions/forms/formsSendActions';
import {removeForm} from 'app/redux/actions/forms/forms';

import {INameInfoStateProps, INameInfoActionProps} from './interfaces/INameInfoProps';

import NameInfo from './NameInfo';

const mapStateToProps = (state: ExtendedStore.IState): INameInfoStateProps => {
    return {
        formStatus: state.forms[FormName.UpdateProfile] && state.forms[FormName.UpdateProfile].status,
        profile: state.profile
    };
};

const mapDispatchToProps = (dispatch): INameInfoActionProps => ({
    save(values: INameInfoValues): void {
        dispatch(submitProfile(values));
    },
    resetForm(): void {
        dispatch(removeForm(FormName.UpdateProfile));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NameInfo);
