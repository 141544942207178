/**
 * Created by lkarmelo on 27.02.2019.
 */

import {createSelector} from 'reselect';
import {normalize, schema} from 'normalizr';

import {ExtendedStore} from 'common/store';
import {Store} from 'nkc-frontend-tools/types';

import {FilterName} from 'common/types/FilterName';

const valuesSchema = new schema.Entity(
    'values',
    undefined,
    {
        idAttribute: (item: Store.IFilterValueDescription) => item.value
    }
);

type OrderedMeta = Array<ExtendedStore.IExtendedFilterMeta | ExtendedStore.IFilterMetaWithNormalizedValues>;

export const createOrderedFiltersSelector = (
    filterOrder: Array<FilterName | string>,
    filterTitles: Map<FilterName | string, string>,
    normalizeSelectFilters: boolean = true,
) =>
    createSelector(
        (state: ExtendedStore.IState): Store.IFiltersMeta => state.filtersMeta,
        (filtersMeta: Store.IFiltersMeta): OrderedMeta => {
            const orderedFiltersMeta: OrderedMeta = [];

            filterOrder.forEach(filterName => {
                const meta = filtersMeta[filterName];
                if (!meta) {
                    return;
                }

                const baseMeta: ExtendedStore.IExtendedFilterMeta = {
                    ...meta,
                    name: filterName,
                    title: filterTitles.get(filterName) || meta.title,
                };

                switch (meta.type) {
                    case Store.FilterType.MultiSelect:
                    case Store.FilterType.Select: {
                        if (!Array.isArray(meta.values)) {
                            return;
                        }

                        if (!normalizeSelectFilters) {
                            orderedFiltersMeta.push(baseMeta);
                            return;
                        }

                        const normalized = normalize(meta.values, [valuesSchema]);
                        const normalizedResult = normalized.result;
                        const normalizedValues: ExtendedStore.IFilterValuesDescriptionMap = normalized.entities.values;

                        const topLevelValues = new Set<string>();

                        normalizedResult.forEach((valId: string) => {
                            const value = normalizedValues[valId];
                            if (!value.parent) {
                                topLevelValues.add(valId);
                            } else {
                                !normalizedValues[value.parent].children && (normalizedValues[value.parent].children = []);

                                normalizedValues[value.parent].children.push(valId);
                            }
                        });

                        orderedFiltersMeta.push({
                            ...baseMeta,
                            values: normalizedValues,
                            topValues: Array.from(topLevelValues),
                        });
                        return;
                    }
                    case Store.FilterType.YearRange: {
                        orderedFiltersMeta.push(baseMeta);
                        return;
                    }
                    default: return;
                }

            });
            return orderedFiltersMeta;
        }
    );
