import React, { useMemo } from 'react';
import { clampNumber } from 'app/utils/clampNumber';
import classNames from 'classnames';
import { positiveNumberValidator, combineValidators, integerValidator } from 'app/utils/propTypesValidators';

import IProps from './interfaces/IBadgeProps';
import * as styles from './Badge.scss';

const Badge: React.FC<IProps> = ({ className, count, overflowCount }) => {
    const finalOverflowCount = useMemo(
        () => clampNumber(overflowCount, 0, overflowCount > 0 ? overflowCount : 0),
        [overflowCount]
    );

    const finalCount = useMemo(
        () => clampNumber(count, 0, count > 0 ? count : 0),
        [count, finalOverflowCount]
    );

    return finalCount > 0 ? (
        <sup className={classNames(className, styles.badge)}>
            {finalCount > finalOverflowCount ? `${finalOverflowCount}+` : count}
        </sup>
    ) : (
        null
    );
};

Badge.defaultProps = {
    overflowCount: Infinity
};

Badge.propTypes = {
    count: combineValidators(integerValidator, positiveNumberValidator),
    overflowCount: combineValidators(integerValidator, positiveNumberValidator)
};

export default React.memo(Badge);
