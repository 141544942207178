/**
 * Created by Lkarmelo on 06.09.2017.
 */

import {handleActions} from 'redux-actions';

import {Store} from '../../../types';

import {SET_DEFAULT_FILTERS} from '../../actions/search/defaultFilters';

export default handleActions(
    {
        [SET_DEFAULT_FILTERS](state: Store.IFilters, {payload}: {payload: Store.IFiltersMeta; type: string}) {
            const nextState = {};
            Object.keys(payload).forEach(key => {
                if (payload[key].default !== undefined) {
                    nextState[key] = {
                        value: payload[key].default
                    };
                }
            });
            return nextState;
        }
    },
    {} //- default value for defaultFilters
);
