/**
 * Created by lkarmelo on 18.10.2019.
 */

import React from 'react';

import {
    createContextBookList, createContextBookListFilters, FiltersComponent, IFiltersProps, IBookListProps
} from 'app/components/common/ContextBookList';

import {ExtendedStore} from 'common/store';
import clientRoutes from 'common/clientRoutes';

import {FilterName, StaticFilterName} from 'common/types/FilterName';
import {FilterToTitleFilterPanel} from 'app/types/FilterToTitle';
import {createOrderedFiltersSelector} from 'app/redux/selectors/createNormalizedFiltersSelector';

import {ROOT_BREADCRUMB_NAME} from 'app/utils/constants';

import * as styles from './DocumentsUploadedList.scss';
import { Link } from 'react-router-dom';

export const filtersSelector = createOrderedFiltersSelector(
    [
        StaticFilterName.Sort,
        FilterName.Year,
        FilterName.PublicationType,
        FilterName.MaterialType,
        FilterName.KnowledgeArea,
        FilterName.IsPublished
    ],
    FilterToTitleFilterPanel
);

const hiddenFilters = [StaticFilterName.Sort];

const Filters: React.FC<IFiltersProps> = (props) => <FiltersComponent {...props} hiddenFilters={hiddenFilters} />;

const ContextFilters = createContextBookListFilters(ExtendedStore.BookSearchContext.UploadedList, Filters, filtersSelector);
const DocumentsUploadedListContextBookList = createContextBookList(ExtendedStore.BookSearchContext.UploadedList, ContextFilters);

const pageTitle = 'Добавленные материалы';

const breadcrumbs = [
    {
        link: clientRoutes.main.getUrl(),
        title: ROOT_BREADCRUMB_NAME
    },
    {
        link: '#',
        title: pageTitle
    }
];

const renderHeader = () => (
    <div className={styles.documentsUploadedListHeader}>
        <div>{pageTitle}</div>
        <Link
            className={'btn btn-primary'}
            to={clientRoutes.newUpload.getUrl()}
        >
            Добавить материал
        </Link>
    </div>
);

const DocumentsUploadedList: React.FC = () => (
    <DocumentsUploadedListContextBookList
        breadcrumbsPath={breadcrumbs}
        className={styles.documentsUploadedList}
        renderHeaderContent={renderHeader}
    />
);

export default DocumentsUploadedList;
