import React from 'react';
import {useCallback} from 'react';
import {useMemo} from 'react';
import {DropDownOption, DropDownOptionGroup, PureDropDownSelect} from '../select/DropDownSelect';
import {ISelectFilterProps} from './interfaces/ISelectFilterProps';
import {ExtendedStore} from 'common/store';

export const SelectFilter: React.FunctionComponent<ISelectFilterProps> = (props) => {
    const {
        filter, active, valuesDocCount, onAddToSelection, onRemoveFromSelection, onSelect: onSelectProp,
        isMultiSelect
    } = props;
    const activeValues: string[] = active ? active.value as string[] : undefined;

    const onSelect = useCallback(
        ({value}) => {
            isMultiSelect ? onAddToSelection(filter.name, value) : onSelectProp(filter.name, value);
        },
        [filter.name, isMultiSelect]
    );

    const onDeselect = useCallback(
        ({value}) => {
            isMultiSelect ? onRemoveFromSelection(filter.name, value) : onSelectProp(filter.name, filter.default);
        },
        [filter.name, isMultiSelect, filter.default]
    );

    const onClear = useCallback(
        () => {
            onSelectProp(filter.name, filter.default);
        },
        [filter.name, filter.default]
    );

    const children = useMemo(
        () => filter.topValues.map(valId => renderOption(filter, valId, activeValues, valuesDocCount)),
        [filter, activeValues, valuesDocCount]
    );

    return (
        <PureDropDownSelect
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            active={activeValues}
            title={filter.title}
            showActiveCount={isMultiSelect}
        >
            {children}
        </PureDropDownSelect>
    );
};

const renderOption = (
    filter: ExtendedStore.IFilterMetaWithNormalizedValues,
    valId: string,
    active: string[],
    valuesDocCount: {[value: string]: number}
) => {
    const hasChildren = Array.isArray(filter.values[valId].children);

    const Component = hasChildren ? DropDownOptionGroup : DropDownOption;
    const isDisabled = valuesDocCount && !valuesDocCount[valId];
    return (
        <Component
            key={valId}
            value={valId}
            title={hasChildren ? filter.values[valId].label : undefined}
            activeValues={hasChildren ? active : undefined}
            childrenValues={hasChildren ? filter.values[valId].children : undefined}
            isDisabled={isDisabled}
        >
            {hasChildren ?
                filter.values[valId].children.map((childValId) => renderOption(filter, childValId, active, valuesDocCount)) :
                filter.values[valId].label
            }
        </Component>
    );
};
