import React, { useState, useEffect, useCallback } from 'react';

export interface IWizzardWrapperOwnProps {
    startStep?: number;
}

export interface IWizzardWrapperProps {
    currentStep: number;
    nextStep(step: number): void;
}

export interface IWizzardFormProps {
    onComplete(): void;
    onClose(): void;
}

const WizzardWrapper = (WrappedComponent: any & IWizzardWrapperProps): React.SFC<any & IWizzardWrapperOwnProps> => {
    return ({ startStep, ...props }) => {
        const [currentStep, setStep] = useState(1);

        // update step after loading
        useEffect(
            () => {
                !!startStep && setStep(startStep);
            },
            [startStep]
        );

        const nextStep = () => setStep(step => step + 1);

        return (
            <WrappedComponent
                {...props}
                currentStep={currentStep}
                nextStep={nextStep}
            />
        );
    };
};

export default WizzardWrapper;
