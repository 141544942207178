/**
 * Created by Lkarmelo on 16.10.2017.
 */

import urlTemplate from 'url-template';

import Route, {IRouteOptions} from './Route';

export default class ApiRoute<TParams extends string = any, TQuery extends string = any> extends Route<TParams, TQuery> {
    constructor(path: string, options?: IRouteOptions) {
        super();

        !!options && ( this.options = options );
        this.template = urlTemplate.parse(path);
    }
}
