import React, { useState, useEffect, useCallback } from 'react';
import { useStateWithCallback } from './useStateWithCallback';
import uniqueId from 'lodash.uniqueid';

import { IFileUploadProgressMap, FileUploadAjaxError } from 'common/store/ExtendedStore';
import { usePrevious } from './usePrevious';

export const useFileUpload = (
    fileUploadProgress: IFileUploadProgressMap,
    upload: (file: File, uploadId: string) => void,
    cleanFileUploadProgress: (uploadId: string) => void,
    onSuccess?: () => void,
    onError?: () => void
) => {
    const [file, setFileWithCallback] = useStateWithCallback<File>(null);
    const [uploadId, setUploadId] = useState<string>(null);
    const previousUploadId = usePrevious(uploadId);

    const [progressRatio, setProgressRatio] = useState<number>(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState<FileUploadAjaxError>(null);

    useEffect(
        () => {
            const newProgressRatio = fileUploadProgress?.[uploadId]?.progressRatio;
            newProgressRatio && setProgressRatio(newProgressRatio);
        },
        [uploadId, fileUploadProgress]
    );

    useEffect(
        () => {
            const error = fileUploadProgress?.[uploadId]?.error;
            if (error) {
                setUploadError(error);
                onError();
            }
        },
        [uploadId, fileUploadProgress]
    );

    useEffect(
        () => {
            if (fileUploadProgress?.[uploadId]?.success) {
                setUploadSuccess(true);
                onSuccess();
            }
        },
        [uploadId, fileUploadProgress, onSuccess]
    );

    useEffect(
        () => {
            if (file && uploadId) {
                setUploadError(null);
                setUploadSuccess(false);
                setProgressRatio(0);

                fileUploadProgress?.[previousUploadId] && cleanFileUploadProgress(previousUploadId);

                upload(file, uploadId);
            }
        },
        [uploadId]
    );

    useEffect(
        () => {
            (uploadSuccess || uploadError) &&
            cleanFileUploadProgress(uploadId);
        },
        [uploadSuccess, uploadError]
    );

    const setFile = useCallback(
        (newFile: React.SetStateAction<File>) => setFileWithCallback(newFile, () => {
            setUploadId(uniqueId('file-'));
        }),
        [setUploadId]
    );

    return {
        file,
        setFile,
        progressRatio,
        uploadSuccess,
        uploadError,
    };
};
