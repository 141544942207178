import React, { useMemo, useCallback, useEffect } from 'react';
import Table from 'app/components/common/Table';
import { Column, usePagination } from 'react-table';
import { INotification } from 'common/api/ExtendedApi';
import moment from 'moment';
import classNames from 'classnames';
import { useTable } from 'react-table';
import Pagination from 'app/components/common/Pagination';
import { NOTIFICATIONS_DEFAULT_LIMIT } from 'common/constants';

import IProps from './interfaces/INotificationsTableProps';
import * as styles from './NotificationsTable.scss';

const NotificationsTable: React.FC<IProps> = (props) => {
    const {
        notificationTypes,
        notifications,
        totalCount,
        fetchNotifications,
        markNotificationsAsRead,
        setNotificationsRequestParams
    } = props;

    const columns: Column<INotification>[] = useMemo(
        () => [
            {
                id: 'type',
                Header: 'Тип уведомления',
                accessor: notification => notification.notificationType,
                Cell: ({ cell: notificationType, row: notification }) => (
                    <span
                        className={!notification.original.isRead ? styles.notificationsTableCellBold : undefined}
                    >
                        {notificationTypes[notificationType.value] || notificationType.value}
                    </span>
                )
            },
            {
                id: 'content',
                Header: 'Уведомление',
                accessor: notification => notification.content,
                Cell: ({ cell: content, row: notification }) => (
                    <span
                        className={classNames(
                            !notification.original.isRead ? styles.notificationsTableCellBold : undefined,
                            styles.notificationsTableNotificationContent
                        )}
                        dangerouslySetInnerHTML={{ __html: content.value }}
                    />
                )
            },
            {
                id: 'date',
                Header: 'Дата',
                accessor: notification => notification.date,
                Cell: ({ cell: date, row: notification }) => (
                    <span
                        className={!notification.original.isRead ? styles.notificationsTableCellBold : undefined}
                    >
                        {moment(date.value).format('LL').slice(0, -3)}
                    </span>
                )
            }
        ],
        [notifications, notificationTypes]
    );

    const pageCount = useMemo(
        () => Math.ceil(totalCount / NOTIFICATIONS_DEFAULT_LIMIT),
        [totalCount]
    );

    const tableInstance = useTable<INotification>(
        {
            columns,
            data: notifications,
            getRowID: notification => notification.id,
            manualPagination: true,
            pageCount,
            initialState: { pageIndex: 0, pageSize: NOTIFICATIONS_DEFAULT_LIMIT }
        },
        usePagination
    );

    useEffect(
        () => {
            setNotificationsRequestParams({
                skip: tableInstance.pageIndex * NOTIFICATIONS_DEFAULT_LIMIT,
                limit: NOTIFICATIONS_DEFAULT_LIMIT
            });
            fetchNotifications();
        },
        [tableInstance.pageIndex]
    );

    useEffect(
        () => {
            markNotificationsAsRead(tableInstance.page
                .filter(notification => !notification.original.isRead)
                .map(notification => notification.original.id)
            );
        },
        [tableInstance]
    );

    const onPaginationChange = useCallback(
        ({ selected }) => {
            tableInstance.gotoPage(selected);
        },
        [tableInstance.gotoPage]
    );

    return Array.isArray(notifications) && notifications.length > 0 ? (
        <>
            <Table
                className={styles.notificationsTable}
                headClassName={styles.notificationsTableHead}
                columnClassNames={{
                    type: styles.notificationsTableTypeColumn,
                    content: styles.notificationsTableContentColumn,
                    date: styles.notificationsTableDateColumn
                }}
                columnCellsClassNames={{
                    type: styles.notificationsTableTypeColumnCell,
                    date: styles.notificationsTableDateColumnCell
                }}
                tableInstance={tableInstance}
            />
            <Pagination
                containerClassName={styles.notificationsTablePagination}
                pageCount={pageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                onPageChange={onPaginationChange}
                forcePage={tableInstance.pageIndex}
            />
        </>
    ) : (
        <div className={styles.notificationsTableNoNotificationsMessage}>
            Здесь будут ваши уведомления о новых поступлениях в библиотеку РФФИ и об обновлениях системы.
        </div>
    );
};

export default NotificationsTable;
