import React from 'react';

import * as styles from './ProgressBar.scss';
import classNames from 'classnames';

import IProgressBarProps from './interfaces/IProgressBarProps';

const ProgressBar: React.FC<IProgressBarProps> = ({ currentStep, steps }) => {
    let executed: boolean = true;

    const stepPoints = steps.map((item, idx) => {
        const isCurrentStep = (currentStep === item.id);
        executed = (isCurrentStep)
            ? false
            : executed;

        return (
            <div
                key={item.id}
                className={classNames(
                    styles.progressBarStep,
                    {
                        [styles.progressBarStepComplete]: executed,
                        [styles.progressBarStepInProgress]: isCurrentStep
                    }
                )}
            >
                <div className={styles.progressBarStepTitle}>{item.title}</div>
                <div className={styles.progressBarStepPoint}/>
            </div>
        );
    });

    return (
        <div className={styles.progressBar}>
            <div
                className={classNames(
                    styles.progressBarContainer,
                    `${styles.progressBarContainer}--offset-${steps.length}`,
                    `${styles.progressBarContainer}--width-${steps.length}`
                )}
            >
                {stepPoints}
            </div>
        </div>
    );
};

export default ProgressBar;
