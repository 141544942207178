/**
 * Created by lkarmelo on 14.10.2019.
 */

import {useEventListener} from './useEventListener';
import {KeyCode} from 'app/types/KeyCode';

export const useDisableNativeZoom = () => {
    if (typeof window === 'undefined') {
        return;
    }

    useEventListener(
        window,
        'wheel',
        (e: WheelEvent) => {
            e.ctrlKey && e.preventDefault();
        },
        {passive: false}
    );

    useEventListener(
        window,
        'keydown',
        (e: KeyboardEvent) => {
            if (!e.ctrlKey) {
                return;
            }
            if (e.key === KeyCode.Minus || e.key === KeyCode.Plus) {
                e.preventDefault();
            }
        }
    );
};
