/**
 * Created by lkarmelo on 22.08.2019.
 */
import {createAction} from 'redux-actions';

export interface IFavoriteActionPayload {
    bookId: string;
    favoriteState: boolean;
}

const likeActionPayloadCreator = (bookId: string, favoriteState: boolean) => ({bookId, favoriteState});

export const setFavoriteState = createAction<IFavoriteActionPayload, string, boolean>(
    'SET_FAVORITE_STATE',
    likeActionPayloadCreator
);

export const requestFavoriteStateChange = createAction<IFavoriteActionPayload, string, boolean>(
    'REQUEST_FAVORITE_STATE_CHANGE',
    likeActionPayloadCreator
);
