/**
 * Created by lkarmelo on 27.03.2019.
 */

import { ajax } from 'rxjs/observable/dom/ajax';
import { Subscriber } from 'rxjs';

import apiRoutes from './apiRoutes';
import { ExtendedApi } from './index';

import { FormName } from '../../client/src/app/types/FormName';
import { IResetPasswordPayload } from 'nkc-frontend-tools/redux/actions/authorization';
import { INotificationSetting, INotificationsRequestParams } from './ExtendedApi';

const headersAcceptJson = {
    'Accept': 'application/json'
};

const headersJson = {
    ...headersAcceptJson,
    'Content-Type': 'application/json'
};

const headersXml = {
    'Accept': 'image/svg+xml'
};

export default {
    search: (payload) => ajax.post(apiRoutes.search.getUrl(), payload || {}, headersJson),
    issueInfoCatalog: () => ajax.get(apiRoutes.issueInfoCatalog.getUrl(), headersJson),
    checkDocumentContent: (id: string) => ajax.get(apiRoutes.checkDocumentContent.getUrl({ id }), headersJson),
    libraryStats: () => ajax.get(apiRoutes.libraryStats.getUrl(), headersJson),
    changeLikeStatus: (id: string, likeStatus: boolean) => likeStatus ?
        ajax.post(apiRoutes.like.getUrl({ id }), {}, headersJson) :
        ajax.delete(apiRoutes.like.getUrl({ id }), headersJson),
    share: (id: string, provider: string) => ajax.post(apiRoutes.share.getUrl({ id, provider })),

    logIn: (email, password, rememberMe) => ajax.post(
        apiRoutes.logIn.getUrl(),
        JSON.stringify({ email, password, rememberMe }),
        headersJson
    ),
    logOut: () => ajax.get(apiRoutes.logOut.getUrl(), headersJson),

    confirmAuthentication: (accessToken: string) => ajax.get(apiRoutes.confirmAuthentication.getUrl({ accessToken }), headersJson),
    userPermissions: () => ajax.get(apiRoutes.userPermissions.getUrl(), headersJson),

    documentsShowcase: () => ajax.get(apiRoutes.documentsShowcase.getUrl(), headersJson),

    refreshUser: () => ajax.get(apiRoutes.refreshUser.getUrl(), headersJson),
    userSocial: () => ajax.get(apiRoutes.userSocial.getUrl(), headersJson),

    loadFavorite: (payload) => ajax.post(apiRoutes.favorite.getUrl(), payload || {}, headersJson),
    addFavorite: (id: string) => ajax.post(apiRoutes.addRemoveFavorite.getUrl({ id }), {}, headersJson),
    removeFavorite: (id: string) => ajax.delete(apiRoutes.addRemoveFavorite.getUrl({ id }), headersJson),

    subscriptions: () => ajax.get(apiRoutes.subscriptions.getUrl(), headersJson),
    subscribeToMailingList: (body: ExtendedApi.ISubscriptionIdentifier) =>
        ajax.post(apiRoutes.subscribeToMailingList.getUrl(), body, headersJson),
    unsubscribeFromMailingList: (body: ExtendedApi.ISubscriptionIdentifier) =>
        ajax({
            method: 'DELETE',
            url: apiRoutes.unsubscribeFromMailingList.getUrl(),
            body,
            headers: headersJson
        }),
    unsubscribeFromAllMailingLists: () => ajax.delete(apiRoutes.unsubscribeFromAllMailingLists.getUrl(), headersJson),

    bookPage: (bookId: string, page: number) => ajax({
        url: apiRoutes.bookPage.getUrl({ id: bookId, page }),
        headers: headersXml,
        responseType: 'document',
    }),

    bookReaderMeta: (bookId: string) => ajax.get(apiRoutes.bookReaderMeta.getUrl({ id: bookId }), headersJson),
    bookReaderSaveScale: (bookId: string, scale: number) =>
        ajax.put(apiRoutes.bookReaderScale.getUrl({ id: bookId }), { currentScale: scale }, headersJson),
    bookReaderSavePage: (bookId: string, page: number) =>
        ajax.put(apiRoutes.bookReaderCurrentPage.getUrl({ id: bookId }), { currentPage: page }, headersJson),

    notificationTypes: () => ajax.get(apiRoutes.notificationTypes.getUrl(), headersJson),
    notificationSettings: () => ajax.get(apiRoutes.notificationSettings.getUrl(), headersJson),
    saveNotificationSetting: (body: INotificationSetting) => ajax.post(apiRoutes.notificationSettings.getUrl(), body, headersJson),
    notifications: ({ skip, limit }: INotificationsRequestParams) =>
        ajax.get(apiRoutes.notifications.getUrl(undefined, { skip, limit }), headersJson),
    markNotificationsAsRead: (id: string[]) => ajax.put(
        apiRoutes.markNotificationsAsRead.getUrl(undefined, { id }),
        headersJson
    ),

    addBookmark: (bookId: string, page: number) => ajax.put(apiRoutes.addRemoveBookmark.getUrl({ id: bookId, page }), {}, headersJson),
    removeBookmark: (bookId: string, page: number) => ajax.delete(apiRoutes.addRemoveBookmark.getUrl({ id: bookId, page }), headersJson),

    bookmarks: (bookId: string) => ajax.get(apiRoutes.bookmarks.getUrl({ id: bookId }), headersJson),

    searchUploads: (payload) => ajax.post(apiRoutes.searchUploads.getUrl(), payload || {}, headersJson),

    uploadDocumentContent: (id: string, fileFormData: FormData, progressSubscriber: Subscriber<any>) =>
        ajax({
            method: 'PUT',
            url: apiRoutes.uploadDocumentContent.getUrl(undefined, { id }),
            body: fileFormData,
            headers: headersAcceptJson,
            progressSubscriber
        }),

    uploadDocumentCover: (id: string, fileFormData: FormData, progressSubscriber: Subscriber<any>) =>
        ajax({
            method: 'PUT',
            url: apiRoutes.uploadDocumentCover.getUrl(undefined, { id }),
            body: fileFormData,
            headers: headersAcceptJson,
            progressSubscriber
        }),

    loadDocument: (id: string) => ajax.get(apiRoutes.loadDocument.getUrl({ id }), headersJson),
    publishDocument: (id: string) => ajax.get(apiRoutes.publishDocument.getUrl({ id }), headersJson),
    unpublishDocument: (id: string) => ajax.get(apiRoutes.unpublishDocument.getUrl({ id }), headersJson),

    forms: {
        [FormName.ChangePassword]: (body: ExtendedApi.IPasswordChangeBody) =>
            ajax.put(apiRoutes.changePassword.getUrl(), body, headersJson),

        [FormName.DeleteAccount]: () => ajax.delete(apiRoutes.deleteUser.getUrl(), headersJson),

        [FormName.UpdateProfile]: (body: ExtendedApi.IProfileUpdateBody) => ajax.put(apiRoutes.updateUser.getUrl(), body, headersJson),

        [FormName.ConfirmAccount]: (token: string) => ajax.get(apiRoutes.activateUser.getUrl({ token }), headersJson),

        [FormName.ConfirmEmail]: ({ emailType, token }: ExtendedApi.IConfirmEmailPayload) =>
            ajax.get(apiRoutes.confirmEmail.getUrl({ emailType, token }), headersJson),

        [FormName.UpdateEmail]: (body: ExtendedApi.IProfileUpdateBody) => ajax.put(apiRoutes.updateEmail.getUrl(), body, headersJson),

        [FormName.ResetPassword]: ({ token, password }: IResetPasswordPayload) =>
            ajax.post(apiRoutes.resetPassword.getUrl({ token }), { password }, headersJson),

        [FormName.Registration]: (body: ExtendedApi.IRegistrationRequestBody) =>
            ajax.post(apiRoutes.registerUser.getUrl(), body, headersJson),

        [FormName.ResetPasswordEmail]: (body: ExtendedApi.IRequestResetPasswordRequestBody) =>
            ajax.post(apiRoutes.requestResetPassword.getUrl(), body, headersJson),

        [FormName.ConfirmDeleteBook]: (bookId: string) => ajax.delete(apiRoutes.deleteDocument.getUrl({ id: bookId }), headersJson),

        [FormName.AddEditDocumentForm]:
            (doc: { [key: string]: any }) =>
                !doc.id
                    ? ajax.put(apiRoutes.createDocument.getUrl(), doc, headersJson)
                    : ajax.put(apiRoutes.updateDocument.getUrl({ did: doc.id }), doc, headersJson)
    }
};
