/**
 * Created by lkarmelo on 23.09.2019.
 */

import React from 'react';

import {MessageModalContent} from 'app/components/common/modals/MessageModal';

import IProps from './interfaces/IRegistrationSuccessProps';

const RegistrationSuccess: React.FC<IProps> = ({email, onBtnClick}) => (
    <MessageModalContent
        header={'ВЫ СОЗДАЛИ АККАУНТ!'}
        btnText={'Продолжить'}
        onBtnClick={onBtnClick}
    >
        Письмо для подтверджения аккаунта отправлено на почту {email}.
    </MessageModalContent>
);

export default RegistrationSuccess;
