import { ExtendedStore } from 'common/store';
import { IPasswordResetStateProps, IPasswordResetActionProps } from './interfaces/IPasswordResetProps';
import { FormName } from 'app/types/FormName';
import { resetPassword } from 'app/redux/actions/forms/formsSendActions';
import { removeForm } from 'app/redux/actions/forms/forms';
import { connect } from 'react-redux';
import PasswordReset from './PasswordReset';
import {showPopup} from 'app/redux/actions/popups';
import {PopupName} from 'app/types/PopupName';

const mapStateToProps = (state: ExtendedStore.IState): IPasswordResetStateProps => ({
    formResponse: state.forms[FormName.ResetPassword] && state.forms[FormName.ResetPassword].response,
    formStatus: state.forms[FormName.ResetPassword] && state.forms[FormName.ResetPassword].status
});

const mapDispatchToProps = (dispatch): IPasswordResetActionProps => ({
    resetPasswordRequest(token: string, password: string): void {
        dispatch(
            resetPassword({token, password})
        );
    },
    resetForm() {
        dispatch(removeForm(FormName.ResetPassword));
    },
    showLoginPopup() {
        dispatch(showPopup(PopupName.Login));
    },
    showResetPasswordPopup() {
        dispatch(showPopup(PopupName.ResetPassword));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
