/**
 * Created by lkarmelo on 28.02.2019.
 */

import React, {useMemo} from 'react';
import {useCallback, useEffect} from 'react';
import queryString from 'qs';

import {parseOptions} from 'app/utils/queryStringOptions';
import {compareFilterValues} from 'app/utils/filters/compareFilterValues';
import {queryValueToFilterValue} from 'app/utils/filters/queryValueToFilterValue';
import {getDefaultFilterValue} from 'app/utils/filters/getDefaultFilterValue';

import {Store} from 'nkc-frontend-tools/types';
import {ExtendedStore} from 'common/store';
import IProps from './interfaces/IFiltersProps';

import * as styles from './Filters.scss';
import {SelectFilter} from 'app/components/common/filters/SelectFilter';

const Filters: React.FunctionComponent<IProps> = (props: IProps) => {
    const {
        location, filters, activeFilters, onDeselectMultiSelectFilter, onSelectMultiSelectFilter,
        setFilters, onSelectFilter, filterValuesDocCount, contextState, hiddenFilters
    } = props;

    //в deps не добавляем activeFilters, чтобы эффект не срабатывал сразу после выбора фильтра. он должен срабатывать только на смену
    //url и после обновления url в эпике updateBrowserLocation в замыкание попадёт свежий activeFilters
    useEffect(
        () => {
            if (!filters) {
                return;
            }
            const parsedLocationSearch = queryString.parse(location.search, parseOptions);

            const nextFilterValues = [];

            filters.forEach((filter) => {
                const {name, type} = filter;
                //тут нужно передавать parsedLocationSearch, потому что при смене url в браузере, searchQuery в store обновляется позже
                //(это делается в Search.tsx), чем срабатывает этот хук
                const defaultValue = getDefaultFilterValue(contextState, filter, parsedLocationSearch);
                const queryOrDefaultVal = parsedLocationSearch[name] === undefined ? defaultValue : parsedLocationSearch[name];

                const filterValueFromQuery = queryValueToFilterValue(queryOrDefaultVal, type);
                const filterValue = activeFilters[name] ? activeFilters[name].value : undefined;

                if (!compareFilterValues(filterValue, filterValueFromQuery, type)) {
                    nextFilterValues.push({
                        filterName: name,
                        value: filterValueFromQuery
                    });
                }
            });

            nextFilterValues.length > 0 && setFilters(nextFilterValues, false);
        },
        [location.search, filters]
    );

    //нельзя просто использовать filtersToDefaults(), потому что дефолтное значение теперь не всегда равно тому, что написано
    //в поле .default и вычисляется в getDefaultFilterValue
    const onClearFiltersClick = useCallback(
        () => {
            if (!filters) {
                return;
            }
            const defaultFilters = [];
            filters.forEach(filter => {
                const {name, type} = filter;
                //тут можно не передавать parsedLocationSearch, потому что здесь в contextState будет правильный searchQuery
                const defaultValue = getDefaultFilterValue(contextState, filter);
                const filterValue = activeFilters[name] ? activeFilters[name].value : undefined;
                if (!compareFilterValues(filterValue, defaultValue, type)) {
                    defaultFilters.push({
                        filterName: name,
                        value: defaultValue
                    });
                }
            });

            if (defaultFilters.length > 0) {
                setFilters(defaultFilters);
            }
        },
        [activeFilters, contextState, filters, location.search]
    );

    const filtersToRender = useMemo(
        () => hiddenFilters && Array.isArray(filters) ?
            filters.filter(f => !hiddenFilters.includes(f.name)) :
            filters,
        [filters, hiddenFilters]
    );

    return (
        <div className={styles.filters}>
            {Array.isArray(filtersToRender) && filtersToRender.map(filter => {
                if (filter.type === Store.FilterType.MultiSelect || filter.type === Store.FilterType.Select) {
                    return (
                        <div
                            key={filter.name}
                            className={styles.filtersFilter}
                        >
                            <SelectFilter
                                filter={filter as ExtendedStore.IFilterMetaWithNormalizedValues}
                                valuesDocCount={filterValuesDocCount && filterValuesDocCount[filter.name]}
                                isMultiSelect={filter.type === Store.FilterType.MultiSelect}
                                active={activeFilters && activeFilters[filter.name]}
                                onAddToSelection={onSelectMultiSelectFilter}
                                onRemoveFromSelection={onDeselectMultiSelectFilter}
                                onSelect={onSelectFilter}
                            />
                        </div>
                    );
                }
            })}
            {Array.isArray(filtersToRender) && filtersToRender.length > 0 &&
                <div className={styles.filtersFilter}>
                    <button className={`btn ${styles.filtersClearBtn}`} onClick={onClearFiltersClick}>
                        сбросить все
                    </button>
                </div>
            }
        </div>
    );
};

export default Filters;
