/**
 * Created by Lkarmelo on 21.09.2017.
 */

import { ExtendedStore } from 'common/store';
import { connect } from 'react-redux';

import { logOut } from 'nkc-frontend-tools/redux/actions/authorization';

import UserPanel from './UserPanel';

import { IUserPanelActionProps, IUserPanelStateProps } from './interfaces/IUserPanelProps';
import { CloseEvent } from './interfaces/IPopoverPanelProps';

import {showPopup} from 'app/redux/actions/popups';
import {PopupName} from 'app/types/PopupName';

const mapStateToProps = (state: ExtendedStore.IState): IUserPanelStateProps => {
    const user = state.user;

    return Object.assign(
        {
            isAuthorized: (state.authorization && state.authorization.isAuthorized)
        },
        user && {
            firstName: user.firstName,
            lastName: user.lastName
        },
        {
            notificationCount: state.notifications.unreadCount
        }
    );
};

export const mapDispatchToProps = (dispatch): IUserPanelActionProps => ({
    onLogOut(event: CloseEvent<HTMLElement>): void {
        dispatch(logOut());
    },

    onShowLogin(): void {
        dispatch(showPopup(PopupName.Login));
    },

    onShowRegisterPopup(): void {
        dispatch(showPopup(PopupName.Registration));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
