import { Observable } from 'rxjs/Observable';

import { ExtendedApi } from 'common/api';

import { FormName } from 'app/types/FormName';
import { ExtendedStore } from 'common/store';
import { Action } from 'redux-actions';

import { setForm } from '../../actions/forms/forms';

type Options = {
    triggers: string[];
    formName: FormName;
    actions: {
        requestAction: Function;
        resolveAction: Function;
        rejectAction: Function;
        spawnOnSuccess?: ((response: any, triggerAction: Action<any>) => Action<any> | null)[];
    };
    getPayload?: (payload: any, store: any) => any
};

export const createFormSendEpic = ({ triggers, formName, actions, getPayload }: Options) =>
    (action$, store, { apiCall }: { apiCall: ExtendedApi.ApiCalls }) =>
        action$.ofType(...triggers)
            .switchMap((action: Action<any>) => {
                const { payload } = action;

                return apiCall.forms[formName](getPayload ? getPayload(payload, store) : payload)
                    .mergeMap(({ response }) => {
                        if (!!response && !!response.error) {
                            return Observable.throw(new Error(response.error));
                        }

                        return actions.spawnOnSuccess ?
                            Observable.of(
                                actions.resolveAction(),
                                ...actions.spawnOnSuccess.map(spawnItem => spawnItem(response, action)),
                                setForm(formName, response, ExtendedStore.FormStatus.Accepted)
                            ) :
                            Observable.of(
                                actions.resolveAction(),
                                setForm(formName, response, ExtendedStore.FormStatus.Accepted)
                            );
                    })
                    .catch(e => {
                        console.error(e);
                        return Observable.of(
                            actions.rejectAction(e),
                            setForm(formName, e, ExtendedStore.FormStatus.Rejected)
                        );
                    })
                    .startWith(actions.requestAction());
            });
