/**
 * Created by lkarmelo on 01.03.2019.
 */

import {LoadingActionsProvider} from 'nkc-frontend-tools/redux/actions/loading';
import {LoadingActionCreator} from 'nkc-frontend-tools/redux/actions/utils/createLoadingActions';

LoadingActionsProvider.setActionCreatorDefaultOption({logInOnAuth: false});
LoadingActionsProvider.recreateActions();

export const defaultLoadingActions = LoadingActionsProvider.loadingActions;
export const defaultLoadingActionLists = LoadingActionsProvider.actionLists;

export const {
    searchRequest,
    searchReject,
    searchResolve,

    issueInfoCatalogRequest,
    issueInfoCatalogReject,
    issueInfoCatalogResolve,

    checkDocumentContentRequest,
    checkDocumentContentReject,
    checkDocumentContentResolve,

    libraryStatsRequest,
    libraryStatsReject,
    libraryStatsResolve,

    likeRequest,
    likeReject,
    likeResolve,

    shareRequest,
    shareReject,
    shareResolve,

    documentsShowcaseRequest,
    documentsShowcaseReject,
    documentsShowcaseResolve,

    logoutRequest,
    logoutReject,
    logoutResolve,

    profileUpdateRequest,
    profileUpdateResolve,
    profileUpdateReject,

    changePasswordRequest,
    changePasswordResolve,
    changePasswordReject,

    deleteAccountRequest,
    deleteAccountResolve,
    deleteAccountReject,

    confirmAccountRequest,
    confirmAccountResolve,
    confirmAccountReject,

    resetPasswordRequest,
    resetPasswordResolve,
    resetPasswordReject,

    confirmEmailRequest,
    confirmEmailResolve,
    confirmEmailReject,

    favoriteRequest,
    favoriteResolve,
    favoriteReject,

    emailSubmitRequest,
    emailSubmitResolve,
    emailSubmitReject,

    registrationRequest,
    registrationResolve,
    registrationReject,

    requestResetPasswordRequest,
    requestResetPasswordResolve,
    requestResetPasswordReject,

    subscribeToMailingListRequest,
    subscribeToMailingListResolve,
    subscribeToMailingListReject,

    unsubscribeFromMailingListRequest,
    unsubscribeFromMailingListResolve,
    unsubscribeFromMailingListReject,

    unsubscribeFromAllMailingListsRequest,
    unsubscribeFromAllMailingListsResolve,
    unsubscribeFromAllMailingListsReject,

    subscriptionsRequest,
    subscriptionsResolve,
    subscriptionsReject,

    notificationTypesRequest,
    notificationTypesResolve,
    notificationTypesReject,

    notificationSettingsRequest,
    notificationSettingsResolve,
    notificationSettingsReject,

    saveNotificationSettingRequest,
    saveNotificationSettingResolve,
    saveNotificationSettingReject,

    notificationsRequest,
    notificationsResolve,
    notificationsReject,

    markNotificationsAsReadRequest,
    markNotificationsAsReadResolve,
    markNotificationsAsReadReject,

    bookPageRequest,
    bookPageResolve,
    bookPageReject,

    refreshUserRequest,
    refreshUserResolve,
    refreshUserReject,

    bookReaderMetaRequest,
    bookReaderMetaResolve,
    bookReaderMetaReject,

    saveBookReaderScaleResolve,
    saveBookReaderScaleReject,

    saveBookReaderLastPageResolve,
    saveBookReaderLastPageReject,

    addBookmarkResolve,
    addBookmarkReject,

    removeBookmarkResolve,
    removeBookmarkReject,

    bookmarksResolve,
    bookmarksReject,

    deleteBookRequest,
    deleteBookResolve,
    deleteBookReject,

    documentRequest,
    documentResolve,
    documentReject,

    documentSubmitRequest,
    documentSubmitResolve,
    documentSubmitReject,

    uploadDocumentContentRequest,
    uploadDocumentContentResolve,
    uploadDocumentContentReject,

    uploadDocumentCoverRequest,
    uploadDocumentCoverResolve,
    uploadDocumentCoverReject,

    publishDocumentRequest,
    publishDocumentResolve,
    publishDocumentReject
} = LoadingActionCreator.createLoadingActions(
    [
        'search',
        'issueInfoCatalog',
        'checkDocumentContent',
        'libraryStats',
        'like',
        'share',
        'documentsShowcase',
        'logout',
        'profileUpdate',
        'changePassword',
        'deleteAccount',
        'confirmAccount',
        'resetPassword',
        'confirmEmail',
        'favorite',
        'emailSubmit',
        'registration',
        'requestResetPassword',
        'subscribeToMailingList',
        'unsubscribeFromMailingList',
        'unsubscribeFromAllMailingLists',
        'subscriptions',
        'bookPage',
        'refreshUser',
        'bookReaderMeta',
        'saveBookReaderScale',
        'saveBookReaderLastPage',
        'deleteBook',
        'notificationTypes',
        'notificationSettings',
        'saveNotificationSetting',
        'notifications',
        'markNotificationsAsRead',
        'addBookmark',
        'removeBookmark',
        'bookmarks',
        'document',
        'documentSubmit',
        'uploadDocumentContent',
        'uploadDocumentCover',
        'publishDocument'
    ],
    defaultLoadingActionLists.loadingShowActionCreators,
    defaultLoadingActionLists.loadingHideActionCreators,
    defaultLoadingActionLists.setErrorActionCreators,
    defaultLoadingActionLists.logOutOnUnAuthActionCreators,
    defaultLoadingActionLists.logInOnAuthActionCreators,
);
