import PermissionKey from './PermissionKey';
import PermissionRole from './PermissionRole';

export interface IAccessDescription {
    pk: PermissionKey;
    params: {[key: string]: string; };
}

/**
 *  Матрица прав (Role Base Access Matrix)
 *      Описание матрицы прав для элементов интерфейса
 *
 *  Роль -> Набор разрешений (ключей)
 */
const rbam: {[key in PermissionRole]?: (PermissionKey | IAccessDescription)[]}  = {
    Guest: [
        PermissionKey.viewBook,
        // {
        //     pk: PermissionKey.viewDocumentCategory,
        //     params: {
        //         category: 'Book'
        //     }
        // },
    ],

    Active: [
        PermissionKey.viewBook,
        PermissionKey.viewLkLink,
        PermissionKey.viewShareBookButton,
        PermissionKey.viewFavoriteBookButton,

        PermissionKey.viewLikeBookButton,
        PermissionKey.viewReadBookButton,
        PermissionKey.viewDownloadBookButton,
        PermissionKey.editBook,
        PermissionKey.removeBook,
        PermissionKey.subscribeToMailingList
    ],

    Editor: [
        PermissionKey.viewDocumentRegistratorLink,
    ],

    Admin: [
        PermissionKey.viewAdministrationLink,
    ],
};

export default rbam;
