/**
 * Created by lkarmelo on 19.08.2019.
 */

import {connect} from 'react-redux';

import Profile from './Profile';
import {IProfileStateProps} from './interfaces/IProfileProps';
import {ExtendedStore} from 'common/store';

const mapStateToProps = (state: ExtendedStore.IState): IProfileStateProps => ({
    rfbrAuth: state.user && !!state.user.login,
});

export default connect(mapStateToProps)(Profile);
