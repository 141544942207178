/**
 * Created by lkarmelo on 05.03.2019.
 */

import {Action, ActionMeta} from 'redux-actions';
import {IgnoreSearchActionMeta} from '../../epics/operators';

type InputAction<P, M> = Action<P> | ActionMeta<P, M>;

export const withIgnoreSearchActionMeta = <P, M>(
    action: InputAction<P, M>,
    ignoreSearchTrigger: boolean = true
): ActionMeta<P, M & IgnoreSearchActionMeta> | InputAction<P, M> => {

    const meta = (<ActionMeta<P, M>>action).meta;

    const isMetaExtendable = meta === undefined || (!Array.isArray(meta) && typeof meta === 'object');

    if (!isMetaExtendable) {
        console.warn('Can not safely extend action meta');
        return action;
    }

    return <ActionMeta<P, M & IgnoreSearchActionMeta>>{
        ...action,
        meta: {
            ...meta,
            ignoreSearchTrigger
        }
    };
};
