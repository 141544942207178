/**
 * Created by lkarmelo on 04.03.2019.
 */

export enum KeyCode {
    Enter = 'Enter',
    Escape =  'Escape',
    Esc = 'Esc',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowDown = 'ArrowDown',
    ArrowUp = 'ArrowUp',
    Plus = '+',
    Minus = '-',
}
