import React, {useMemo} from 'react';
import classNames from 'classnames';

import uniqueId from 'lodash.uniqueid';
import {renderError} from '../utils/renderError';

import IProps from './interfaces/ICheckboxProps';

import * as styles from './Checkbox.scss';

const Checkbox: React.FC<IProps> = ({children, className, error, ...rest}) => {
    const id = useMemo(() => uniqueId('checkbox-'), []);

    return (
        <div
            className={classNames(
                styles.checkbox,
                className,
                'form-field',
                {[styles.checkboxError]: error}
            )}
        >
            <label htmlFor={id}>
                <input {...rest} type={'checkbox'} id={id}  />
                <div className={styles.checkboxBox}/>
                <span className={styles.checkboxLabelText}>
                    {children}
                </span>
            </label>
            <div className={'form-field-error'}>
                {renderError(error)}
            </div>
        </div>
    );
};

export default Checkbox;
