import { createFormSendEpic } from './createFormSendEpic';
import { requestResetPassword } from 'app/redux/actions/forms/formsSendActions';
import { FormName } from 'app/types/FormName';
import { requestResetPasswordReject, requestResetPasswordRequest, requestResetPasswordResolve } from 'app/redux/actions/loading';

export const requestResetPasswordEpic = createFormSendEpic({
    triggers: [requestResetPassword.toString()],
    formName: FormName.ResetPasswordEmail,
    actions: {
        requestAction: requestResetPasswordRequest,
        resolveAction: requestResetPasswordResolve,
        rejectAction: requestResetPasswordReject
    }
});
