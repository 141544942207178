/**
 * Created by lkarmelo on 07.05.2019.
 */
import {createAction} from 'redux-actions';

export interface ISetLikeStatusPayload {
    id: string;
    nextLikeState: boolean;
}
export const setLikeState = createAction<ISetLikeStatusPayload, string, boolean>(
    'SET_LIKE_STATE',
    (id: string, nextLikeState: boolean): ISetLikeStatusPayload => ({id, nextLikeState})
);

export const requestLikeStateChange = createAction<ISetLikeStatusPayload, string, boolean>(
    'REQUEST_LIKE_STATE_CHANGE',
    (id: string, nextLikeState: boolean): ISetLikeStatusPayload => ({id, nextLikeState})
);
