/**
 * Created by lkarmelo on 28.02.2019.
 */

import React from 'react';
import classNames from 'classnames';

import {useOnSelectToggle} from 'app/hooks/useOnSelectToggle';

import IProps from './interfaces/IOptionProps';

import * as styles from './Option.scss';

const Option: React.FunctionComponent<IProps> = (props) => {
    const {children, isActive, onSelect, onDeselect, isDisabled} = props;

    const onClick = useOnSelectToggle(
        () => onSelect && onSelect(props),
        () => onDeselect && onDeselect(props),
        isActive
    );

    return (
        <li
            className={classNames(
                styles.option,
                {
                    [styles.optionActive]: isActive,
                    [styles.optionDisabled]: isDisabled,
                }
            )}
        >
            <button
                type="button"
                className={`btn ${styles.optionToggle}`}
                onClick={onClick}
                tabIndex={-1}
            >
                {children}
            </button>
        </li>
    );
};

export default React.memo(Option) as React.FunctionComponent<IProps>;
