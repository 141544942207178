/**
 * Created by lkarmelo on 26.08.2019.
 */

import React, {useCallback, useEffect} from 'react';

import {
    FavoriteAddedNotification,
    FavoriteRemovedNotification,
    PasswordChangeNotification,
    EmailChangeNotification,
    EmailConfirmSentNotification,
    SubscribeToAllNewsNotification,
    BookmarkAddedNotification,
    DocumentAddNotification,
    DocumentUpdateNotification,
    DocumentContentNotUploadedNotification,
} from '../NotificationComponents/NotificationComponents';

import {ExtendedStore} from 'common/store';
import IProps from './interfaces/ISystemNotificationProps';

import * as styles from '../Notifications/SystemNotifications.scss';

const getNotificationComponent = ({type}: ExtendedStore.ISystemNotification): React.ComponentType => {
    switch (type) {
        case ExtendedStore.SystemNotificationType.FavoriteAdd: return FavoriteAddedNotification;
        case ExtendedStore.SystemNotificationType.FavoriteRemove: return FavoriteRemovedNotification;
        case ExtendedStore.SystemNotificationType.PasswordChange: return PasswordChangeNotification;
        case ExtendedStore.SystemNotificationType.EmailChange: return EmailChangeNotification;
        case ExtendedStore.SystemNotificationType.EmailConfirmSent: return EmailConfirmSentNotification;
        case ExtendedStore.SystemNotificationType.SubscribeToAllNews: return SubscribeToAllNewsNotification;
        case ExtendedStore.SystemNotificationType.BookmarkAdd: return BookmarkAddedNotification;
        case ExtendedStore.SystemNotificationType.DocumentAdd: return DocumentAddNotification;
        case ExtendedStore.SystemNotificationType.DocumentUpdate: return DocumentUpdateNotification;
        case ExtendedStore.SystemNotificationType.DocumentContentNotUploaded: return DocumentContentNotUploadedNotification;
        default: return undefined;
    }
};

const getTimeout = ({type}: ExtendedStore.ISystemNotification): number => {
    switch (type) {
        //уведомления, внутри которых содержится ссылка, нужно показывать по 7 секунд, все остальные 3
        case ExtendedStore.SystemNotificationType.SubscribeToAllNews:
        case ExtendedStore.SystemNotificationType.FavoriteAdd:
        case ExtendedStore.SystemNotificationType.FavoriteRemove: return 7000;
        default: return 3000;
    }
};

const SystemNotification: React.FC<IProps> = ({notification, onRemove}) => {
    const remove = useCallback(
        () => onRemove(notification.id),
        [onRemove, notification]
    );

    useEffect(
        () => {
            const timeout = window.setTimeout(remove, getTimeout(notification));
            return () => window.clearTimeout(timeout);
        },
        [remove, notification]
    );

    const NotificationComponent = getNotificationComponent(notification);

    return (
        <div className={styles.systemNotificationsNotification}>
            {NotificationComponent &&
                <div className={styles.systemNotificationsText}>
                    <NotificationComponent />
                </div>
            }
            <button className={`btn icon-cross ${styles.systemNotificationsClose}`} onClick={remove}/>
        </div>
    );
};

export default SystemNotification;
