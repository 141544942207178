/**
 * Created by lkarmelo on 04.10.2019.
 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {IEVersion} from 'nkc-frontend-tools/utils/IEVersion';

import {useEventListener} from 'app/hooks/useEventListener';
import {useUpdateableRef} from 'app/hooks/useUpdetableRef';
import {useDisableNativeZoom} from 'app/hooks/useDisableNativeZoom';

import {toFixedNumber} from 'app/utils/toFixedNumber';
import {KeyCode} from 'app/types/KeyCode';
import {READER_SCALE_STEP} from 'app/utils/constants';

import IProps from './interfaces/IReaderPageProps';

import * as styles from './ReaderPage.scss';

const MIN_PAGE_HEIGHT = 800;

const usePageScale = (scalePageByStep) => {
    if (typeof window === 'undefined') {
        return;
    }

    useEventListener(
        window,
        'wheel',
        (e: WheelEvent) => {
            if (!e.ctrlKey) {
                return;
            }

            const delta = e.deltaY < 0 ? 1 : -1;

            scalePageByStep(delta);
        }
    );

    useEventListener(
        window,
        'keydown',
        (e: KeyboardEvent) => {
            if (!e.ctrlKey) {
                return;
            }

            e.key === KeyCode.Plus && scalePageByStep(1);
            e.key === KeyCode.Minus && scalePageByStep(-1);
        }
    );
};

const ReaderPage: React.FC<IProps> = ({pageContent, className, onNext, onPrev, scale, setScale}) => {
    //нужно чтобы не пересоздавать scroll эвент каждый раз когда меняется scale
    const scaleRef = useUpdateableRef(scale);

    const pageContainer = useRef<HTMLDivElement>(null);
    const pageNode = useRef<SVGSVGElement>(null);

    const pictureAspectRatio = useMemo(
        () =>
            pageContent ? pageContent.viewBox.baseVal.width / pageContent.viewBox.baseVal.height : 1,
        [pageContent]
    );
    const defaultPictureHeight = useMemo(
        () => {
            if (window.innerHeight >= MIN_PAGE_HEIGHT) {
                return pageContainer.current ? pageContainer.current.getBoundingClientRect().height : 0;
            }
            return MIN_PAGE_HEIGHT;
        },
        [pageContent]
    );

    useEffect(
        () => {
            pageContainer.current.innerHTML = '';

            if (!pageContent) {
                pageNode.current = null;
                return;
            }

            if (IEVersion) {
                //в ie приходится сериализовывать svg и обратно парсить и только потом вставлять в dom, иначе текст не отображается
                const str = new XMLSerializer().serializeToString(document.importNode(pageContent, true));
                pageNode.current = new DOMParser().parseFromString(str, 'image/svg+xml').documentElement as any;
                pageContainer.current.appendChild(pageNode.current);
            } else {
                pageNode.current = pageContent.cloneNode(true) as SVGSVGElement;
                pageContainer.current.appendChild(pageNode.current);
            }
            pageNode.current.setAttribute('preserveAspectRatio', 'xMidYMin meet');
        },
        [pageContent]
    );

    useEffect(
        () => {
            if (!pageNode.current) {
                return;
            }

            let height: number;
            let width: number;
            if (!scale) {
                //скрываем страницу, если картинка пришла быстрее, чем сохранённый масштаб, чтобы картинка
                //не скакала при применении масштаба
                height = 0;
                width = 0;
            } else {
                height = defaultPictureHeight * scale;
                width = defaultPictureHeight * pictureAspectRatio * scale;
            }
            pageNode.current.setAttribute('height', `${height}px`);
            pageNode.current.setAttribute('width', `${width}px`);
        },
        [scale, pageContent, defaultPictureHeight, pictureAspectRatio]
    );

    useDisableNativeZoom();

    const scalePageByStep = useCallback(
        (delta: -1 | 1) => setScale(toFixedNumber(scaleRef.current + READER_SCALE_STEP * delta, 2)),
        []
    );

    usePageScale(scalePageByStep);

    return (
        <div
            className={classNames(
                className,
                styles.readerPage
            )}
        >
            <div className={styles.readerPagePageContainer} ref={pageContainer} />
            <div className={classNames(styles.readerPagePrevPage, styles.readerPageControlContainer)} >
                <button className={'btn'} onClick={onPrev}>
                    <span className={'icon-arrow-left'} />
                </button>
            </div>
            <div className={classNames(styles.readerPageNextPage, styles.readerPageControlContainer)} >
                <button className={'btn'} onClick={onNext}>
                    <span className={'icon-arrow-right'} />
                </button>
            </div>
        </div>
    );
};

export default React.memo(ReaderPage);
