/**
 * Created by Lkarmelo on 24.10.2017.
 */

import React from 'react';

import {Link} from 'react-router-dom';
import RFBRLogo from 'app/components/common/RFBRLogo';

import clientRoutes from 'common/clientRoutes';

import * as styles from './Footer.scss';

export default () => (
    <footer className={styles.footer}>
        <div className={styles.footerContent}>
            <div className={styles.footerTopBorder} />
            <div className={styles.footerLinksLogos}>
                <div className={styles.footerLinks}>
                    <div className={styles.footerSection}>
                        <div className={styles.footerSectionTitle}>Библиотека РФФИ</div>
                        <ul>
                            <li>
                                <Link
                                    to={clientRoutes.about.getUrl()}
                                >
                                    О библиотеке
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.footerSection}>
                        <div className={styles.footerSectionTitle}>Политика и Условия</div>
                        <ul>
                            <li>
                                <Link
                                    to={clientRoutes.terms.getUrl()}
                                >
                                    Пользовательское соглашение
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={clientRoutes.privacy.getUrl()}
                                >
                                    Политика конфиденциальности
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.footerSection}>
                        <div className={styles.footerSectionTitle}>Следите за нами</div>
                        <ul>
                            <li>
                                <a
                                    href="https://www.facebook.com/RussianFoundationForBasicResearch"
                                    target="_blank"
                                    rel="nofollow noopener"
                                >
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/RFBR_research"
                                    target="_blank"
                                    rel="nofollow noopener"
                                >
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://vk.com/public186376982"
                                    target="_blank"
                                    rel="nofollow noopener"
                                >
                                    Вконтакте
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.footerVisionLogoWrapper}>

                    <div className={styles.footerVisionLogo}>
                        <Link to={''} className={`btn ${styles.footerVision}`}>
                            <div>
                                <div className={styles.footerVisionIcon}>
                                    <div className={`icon-eye`} />
                                </div>
                            </div>
                            <div>
                                <span className={styles.footerVisionText}>
                                    ВЕРСИЯ ДЛЯ ЛЮДЕЙ С&nbsp;ОГРАНИЧЕННЫМИ ВОЗМОЖНОСТЯМИ
                                </span>
                            </div>
                        </Link>
                        <Link to={clientRoutes.main.getUrl()} className={styles.footerLogo}>
                            <RFBRLogo />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);
