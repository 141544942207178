import React from 'react';

import { requiredStringValidation, requiredArrayStringValidation } from 'app/utils/forms/validation';

import {
    TextField,
    MultiselectField,
    SelectField,
    TextAreaField
} from 'app/components/common/form-fields/material-fields';

import IProps from '../../interfaces/IAttrDocumentFormProps';
import { ICategory } from 'nkc-frontend-tools/types/Store';
import PersonField from '../../DocumentPersons/PersonField';
import { Field } from 'react-final-form';

const PublicationReferenceBookForm: React.FC<IProps> = (
    {
        document: doc,
        knowledgeCategories,
        editor,
        documentSources: {
            knowledgeCategories: knowledgeCategoriesSource,
            materialTypes: materialTypeSource
        },
        submitting,
        onEditEditor, onClearEditor
    }) => {

    return (
        <>
            <TextField
                name={'title'}
                initialValue={doc.title}
                label="Наименование"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <MultiselectField
                name={'knowledgeCategories'}
                label="Область знаний"
                disabled={submitting}
                validate={requiredArrayStringValidation}
                initialValue={knowledgeCategories}
                options={knowledgeCategoriesSource || []}
                required
            />

            <SelectField
                name={'materialType'}
                label="Тип материала"
                disabled={submitting}
                validate={requiredStringValidation}
                initialValue={doc.materialType}
                options={materialTypeSource || []}
                required
            />

            <TextField
                className={'half'}
                name={'year'}
                initialValue={doc.year?.toString()}
                label="Год"
                validate={requiredStringValidation}
                disabled={submitting}
                required
            />

            <TextField
                className={'half'}
                name={'meta.issueInfo.udk'}
                initialValue={doc.meta?.issueInfo?.udk as string}
                label="УДК"
                disabled={submitting}
            />

            <TextField
                className={'half'}
                name={'meta.issueInfo.bbk'}
                initialValue={doc.meta?.issueInfo?.bbk as string}
                label="ББК"
                disabled={submitting}
            />

            <TextField
                className={'half'}
                name={'meta.issueInfo.isbn'}
                initialValue={doc.meta?.issueInfo?.isbn as string}
                label="ISBN"
                disabled={submitting}
            />

            <Field
                name="meta.issueInfo.editor"
                initialValue={editor}
            >
                {({ meta }) => (
                    <PersonField
                        label="Редактор"
                        error={meta.touched ? meta.error : undefined}
                        person={editor}
                        onEdit={onEditEditor}
                        onDelete={onClearEditor}
                    />
                )}
            </Field>

            <TextField
                className={'half'}
                name={'meta.issueInfo.edition'}
                initialValue={doc.meta?.issueInfo?.edition?.toString()}
                label="Тираж"
                disabled={submitting}
            />

            <TextAreaField
                name={'abstract'}
                initialValue={doc.abstract}
                disabled={submitting}
                placeholder={'Аннотация к библиографическому справочнику'}
            />
        </>
    );
};

export default PublicationReferenceBookForm;
