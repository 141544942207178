/**
 * Created by lkarmelo on 19.08.2019.
 */

import React, {useCallback} from 'react';
import classNames from 'classnames';

import {useEditablePanelControls} from 'app/hooks/useEditablePanel';
import EmailInfoForm from '../Form';

import {useFormSenderHelper} from 'app/hooks/forms';

import IProps from './interfaces/IEmailInfoProps';

import * as styles from './EmailInfo.scss';

const EmailInfo: React.FC<IProps> = ({email, save, resetForm, formStatus}) => {

    const [editablePanelControlsRenderer, editingState] = useEditablePanelControls();
    const [isEditing, setIsEditing] = editingState;

    const onSuccess = useCallback(
        () => {
            setIsEditing(false);
        },
        []
    );

    const formCbRef = useFormSenderHelper(formStatus, resetForm, onSuccess);

    const onSubmit = useCallback(
        (values, _, cb) => {
            save(values.email);
            formCbRef.current = cb;
        },
        [save]
    );

    return (
        <div
            className={classNames(
                styles.emailInfo,
                {[styles.emailInfoEditing]: isEditing}
            )}
        >
            {isEditing ?
                <EmailInfoForm
                    onSubmit={onSubmit}
                    initEmail={email}
                    renderControls={editablePanelControlsRenderer}
                />
                :
                <div className={styles.emailInfoEmailHintContainer}>
                    <div className={styles.emailInfoPanel}>
                        <div className={styles.emailInfoLabelEditContainer}>
                            <div className={'field-label'}>
                                Email для уведомлений
                            </div>
                            <button
                                className={'btn-edit'}
                                onClick={() => setIsEditing(true)}
                            >
                                изменить
                            </button>
                        </div>
                        <div className={styles.emailInfoEmail}>{email}</div>
                    </div>
                    <div className={styles.emailInfoHint}>
                        Этот email будет использоваться только для почтовых рассылок. Вы можете его изменить.
                    </div>
                </div>
            }
        </div>
    );
};

export default EmailInfo;
