/**
 * Created by lkarmelo on 06.02.2019.
 */

import * as ExtendedStore from './ExtendedStore';
import {BookSearchContext} from './ExtendedStore';

import {DEFAULT_PAGING_ITEMS_LIMIT, NOTIFICATIONS_DEFAULT_LIMIT} from '../constants';

const initialState: ExtendedStore.IState = {
    filtersMeta: {},
    defaultFilters: {},
    loading: {
        pendingRequests: {},
        loadingErrors: {},
        pendingRequestsCount: 0
    },
    loadingBar: {
        default: 0
    },
    authorization: {
        /*
            null означает, что неизвестно, залогинен ли пользователь или нет,
            потому что единственный способ это узнать - подождать ответ на 1-й запрос,
            которому нужна авторизация
         */
        isAuthorized: null,
        authorizationFailed: null
    },
    passwordRecovery: {},
    [BookSearchContext.Search]: {
        searchQuery: null,
        //null используется вместо undefined, потому что redux не позволяет иметь undefined как начальное значение
        lastExecutedSearchQuery: null,
        isQueryExpansion: false,
        filters: {},
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
            defaultLimit: DEFAULT_PAGING_ITEMS_LIMIT
        },
        contextName: BookSearchContext.Search,
    },
    [BookSearchContext.Catalog]: {
        searchQuery: null,
        lastExecutedSearchQuery: null,
        isQueryExpansion: false,
        filters: {},
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
            defaultLimit: DEFAULT_PAGING_ITEMS_LIMIT
        },
        //нужно, чтобы определять дефолтные значения для фильтров, у которых дефолтное значение зависит от контекста
        contextName: BookSearchContext.Catalog,
    },
    [BookSearchContext.Favorite]: {
        searchQuery: null,
        lastExecutedSearchQuery: null,
        isQueryExpansion: false,
        filters: {},
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
            defaultLimit: DEFAULT_PAGING_ITEMS_LIMIT
        },
        //нужно, чтобы определять дефолтные значения для фильтров, у которых дефолтное значение зависит от контекста
        contextName: BookSearchContext.Favorite,
    },
    [BookSearchContext.UploadedList]: {
        searchQuery: null,
        lastExecutedSearchQuery: null,
        isQueryExpansion: false,
        filters: {},
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
            defaultLimit: DEFAULT_PAGING_ITEMS_LIMIT
        },
        //нужно, чтобы определять дефолтные значения для фильтров, у которых дефолтное значение зависит от контекста
        contextName: BookSearchContext.UploadedList,
    },
    objectCard: {
        preloadFromServer: false
    },
    profile: null,
    subscriptions: {},
    notifications: {
        requestParams: {
            skip: 0,
            limit: NOTIFICATIONS_DEFAULT_LIMIT,
        },
        totalCount: 0,
        unreadCount: 0,
        notifications: [],
        notificationSettings: {},
        notificationTypes: {}
    },
    forms: {},
    systemNotifications: [],
    popups: {},
    bookReader: {
        pages: {},
    },
    fileUploadProgress: null
};

export default initialState;
