/**
 * Created by Lkarmelo on 28.09.2017.
 */

import {createActions} from 'redux-actions';

import {Store} from '../../../types';

export const SET_DEFAULT_FILTERS = 'SET_DEFAULT_FILTERS';
export const FILTERS_TO_DEFAULTS = 'FILTERS_TO_DEFAULTS';

export const {setDefaultFilters, filtersToDefaults} = createActions<Store.IFiltersMeta>({}, SET_DEFAULT_FILTERS, FILTERS_TO_DEFAULTS);
