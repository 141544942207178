import React, { useCallback } from 'react';
import classNames from 'classnames';

import { useEditablePanelControls } from 'app/hooks/useEditablePanel';

import PersonField from '../PersonField';
import PersonForm from '../PersonForm';

import IProps from './interfaces/IPersonBlockProps';
import * as styles from './PersonBlock.scss';

import { IPerson } from 'common/api/ExtendedApi';

const PersonBlock: React.FC<IProps> = (props) => {
    const {
        className,
        label,
        error,
        authors,
        onCreate: onCreateProps,
        onEdit,
        onDelete,
        required
    } = props;

    const [renderControls, editingState] = useEditablePanelControls();
    const [isEditing, setIsEditing] = editingState;

    const onCreate = useCallback(
        (newAuthor: Omit<IPerson, 'id' | 'title'>) => {
            onCreateProps(newAuthor);
            setIsEditing(false);
        },
        [onCreateProps]
    );

    return (
        <section className={classNames(className, styles.personBlock)}>
            { authors.length > 0
                ? authors.map((author, index) => (
                    <PersonField
                        key={author.id}
                        className={styles.personBlockPerson}
                        person={author}
                        label={`${label} ${index ? index + 1 : ''}`}
                        required={required}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                ))
                : (
                    <PersonField
                        className={styles.personBlockPerson}
                        label={label}
                        error={error}
                        required={required}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                )
            }
            {isEditing
                ? (
                    <PersonForm
                        className={styles.personBlockAddPersonForm}
                        title="Добавление нового автора"
                        onSubmit={onCreate}
                        renderControls={renderControls}
                    />
                )
                : (
                    <button
                        className={classNames(
                            'btn-cancel',
                            styles.personBlockAddButton
                        )}
                        onClick={() => setIsEditing(true)}
                    >
                        <i className={styles.personBlockPlusIcon} />
                        Добавить автора
                    </button>
                )
            }
        </section>
    );
};

PersonBlock.defaultProps = {
    authors: [],
    label: 'Автор'
};

export default PersonBlock;
