/**
 * Created by lkarmelo on 14.10.2019.
 */

import React from 'react';
import classNames from 'classnames';

import Popover from 'antd/es/popover';

import {toFixedNumber} from 'app/utils/toFixedNumber';
import {READER_SCALE_STEP} from 'app/utils/constants';

import * as styles from './ReaderSettings.scss';

interface IReaderSettingsProps {
    pageScale: number;
    setPageScale(scale: number): void;
}

const ReaderSettings: React.FC<IReaderSettingsProps> = (props) => {
    const {pageScale, setPageScale} = props;
    return (
        <Popover
            trigger={'click'}
            overlayClassName={styles.readerSettingsOverlay}
            placement={'bottomLeft'}
            content={(
                <div>
                    <div className={styles.readerSettingsRow}>
                        <span className={styles.readerSettingsScaleTitle}>Масштаб</span>
                        <span className={styles.readerSettingsScaleNumber}>{toFixedNumber(pageScale * 100, 2)}%</span>
                        <button
                            className={classNames('btn', styles.readerSettingsScaleBtn, styles.readerSettingsScaleBtnLeft)}
                            onClick={() => setPageScale(toFixedNumber(pageScale - READER_SCALE_STEP, 2))}
                        >
                            -
                        </button>
                        <button
                            className={classNames('btn', styles.readerSettingsScaleBtn)}
                            onClick={() => setPageScale(toFixedNumber(pageScale + READER_SCALE_STEP, 2))}
                        >
                            +
                        </button>
                    </div>
                </div>
            )}
        >
            <button
                className={classNames('icon-reader-settings', 'btn', styles.readerSettingsButton)}
                title={'Настройки'}
            />
        </Popover>
    );
};

export default ReaderSettings;
