/**
 * Created by lkarmelo on 07.05.2019.
 */

import {MiddlewareAPI} from 'redux';
import {Action} from 'redux-actions';
import {concat} from 'rxjs/observable/concat';
import {Observable} from 'rxjs/Observable';

import {shareRequest, shareReject, shareResolve} from 'app/redux/actions/loading';

import {getActionWithAllContexts} from 'app/redux/context/getActionWithAllContexts';

import {ExtendedStore} from 'common/store';
import {ExtendedApi} from 'common/api';
import {shareDocument, ISharePayload, setShareStatus} from '../actions/share';
import { IShareStat } from 'common/store/ExtendedStore';

export const shareDocumentEpic = (
    action$,
    store: MiddlewareAPI<ExtendedStore.IState>,
    {apiCall}: {apiCall: ExtendedApi.ApiCalls}
) =>
    action$.ofType(shareDocument.toString())
        .mergeMap(({payload: {id, provider}}: Action<ISharePayload>) =>
            concat(
                apiCall.share(id, provider)
                    .mergeMap(({response}: {response: IShareStat[]}) =>
                        Observable.of(...getActionWithAllContexts(setShareStatus(id, response)))
                    )
                    .catch(e => {
                        console.error(e);

                        //TODO: сделать нормальный вывод ошибки
                        if (e.status === 403) {
                            window.alert('Недостаточно прав');
                        }
                        return Observable.of(shareReject(e));
                    })
                ,
                Observable.of(shareResolve())
            )
                .takeUntil(action$.ofType(shareReject.toString()))
                .startWith(shareRequest())
        );
