/**
 * Created by lkarmelo on 20.08.2019.
 */

import {connect} from 'react-redux';

import {ExtendedStore} from 'common/store';
import {FormName} from 'app/types/FormName';

import {IEmailInfoStateProps, IEmailInfoActionProps} from './interfaces/IEmailInfoProps';

import EmailInfo from './EmailInfo';

import {removeForm} from 'app/redux/actions/forms/forms';
import {submitEmail} from 'app/redux/actions/forms/formsSendActions';
import { IProfile } from 'common/store/ExtendedStore';

const mapStateToProps = (state: ExtendedStore.IState): IEmailInfoStateProps => ({
    email: state.profile && state.profile.email,
    emailConfirmed: state.profile && state.profile.confirmedEmail === false ? false : true,
    formStatus: state.forms[FormName.UpdateEmail] && state.forms[FormName.UpdateEmail].status,
});

const mapDispatchToProps = (dispatch): IEmailInfoActionProps => ({
    save(email: string): void {
        dispatch(submitEmail({ email, raiseNotification: true }));
    },
    resetForm(): void {
        dispatch(removeForm(FormName.UpdateEmail));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailInfo);
