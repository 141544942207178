/**
 * Created by lkarmelo on 19.02.2019.
 */

import React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import BookCover from 'app/components/common/BookCover';
import { Link } from 'react-router-dom';
import BookStats from 'app/components/common/BookStats';

import { getAuthorsOrEditor } from 'app/utils/getAuthorsOrEditor';
import { apiRoutes } from 'common/api';

import IProps, { ClsNames, Events } from '../interfaces/IBookDetailsProps';

import * as styles from './BookDetailsBase.scss';
import Popover from 'antd/es/popover';
import { FilterName } from 'common/types/FilterName';
import clientRoutes from 'common/clientRoutes';

import {
    FacebookShareButton,
    TwitterShareButton,
    VKShareButton
} from 'react-share';

import {Helmet} from 'react-helmet';

import { APP_ROOT_URL, FACEBOOK_APP_ID, FACEBOOK_PROVIDER_ID, TWITTER_PROVIDER_ID, VK_PROVIDER_ID } from 'common/constants';
import { ISocialDocument } from 'common/store/ExtendedStore';
import { RestrictedPermission } from 'app/components/high-order/RestrictedPermission';
import PermissionKey from 'common/permissions/PermissionKey';
import {MAX_KEYWORDS_BOOK_DETAILS} from 'app/utils/constants';
import {openLinkNewTab} from 'app/utils/openLinkNewTab';

const defaultRenderLeftColumn = (props: IProps, clsNames: ClsNames, { onDownloadButtonClick }: Events): JSX.Element => {
    const { book, isMocked: isSkeleton, issueInfoCatalog, renderBookEditActions } = props;
    const isContentAvailable = book && book.isContentAvailable;
    const documentSnippet = book && book.documentSnippet;
    const document = documentSnippet && documentSnippet.document;
    const covers = documentSnippet && documentSnippet.covers;
    const id = document && document.id;
    const title = document && document.title || '';
    const meta = document && document.meta;
    const objectType = meta && meta.type;
    const issueInfo = meta && meta.issueInfo;
    const authorsOrEditor = book && getAuthorsOrEditor(book.documentSnippet);
    const year = document && document.year;
    const YearInfo = year && (
        <div key={`issueDate-${year}`}>
            <span>ГОД: {year}</span>
        </div>
    );

    return (
        <div className={clsNames.bookDetailsLeftColumn}>
            <div className={clsNames.bookDetailsCoverWrapper}>
                {isSkeleton ?
                    <div className={clsNames.bookDetailsCover} />
                    :
                    <BookCover
                        id={id}
                        author={Array.isArray(authorsOrEditor) && authorsOrEditor.length > 0 ? authorsOrEditor[0] : undefined}
                        title={title}
                        objectType={objectType}
                        imgClassName={clsNames.bookDetailsCover}
                        loadingPlaceholderClassName={classNames(clsNames.bookDetailsCover, clsNames.bookDetailsCoverPlaceholder)}
                        size={'BIG'}
                        covers={covers}
                        showBookType={false}
                    />
                }
            </div>
            <div className={styles.bookDetailsControlBlock}>
                <div className={clsNames.bookDetailsReadActions}>
                    <RestrictedPermission pk={PermissionKey.viewReadBookButton}>
                        <Link to={clientRoutes.read.getUrl({id})} className={`btn-misc ${clsNames.bookDetailsRead}`}>
                            Читать
                        </Link>
                    </RestrictedPermission>
                    <RestrictedPermission pk={PermissionKey.viewDownloadBookButton}>
                        <button
                            type="button"
                            onClick={isContentAvailable ? () => onDownloadButtonClick(id) : undefined}
                            className={classNames(
                                'btn-misc',
                                clsNames.bookDetailsDownload,
                                { [clsNames.bookDetailsDownloadDisabled]: !isContentAvailable }
                            )}
                            title={isContentAvailable ? 'Загрузить' : undefined}
                        >
                            <span className={'icon-download'} />
                        </button>
                    </RestrictedPermission>
                </div>
                {renderBookEditActions(props, clsNames)}
            </div>
            <div className={clsNames.bookDetailsAdditionalInfo}>
                {issueInfo && issueInfoCatalog &&
                    Object.entries(issueInfo)
                        .filter(([_, value]) => typeof value === 'string' || Array.isArray(value) && value.length > 0)
                        .map(([code, value]) => {
                            const issueInfoCatalogEntry = issueInfoCatalog[`${objectType}.${code}`];
                            return [code, issueInfoCatalogEntry && issueInfoCatalogEntry.displayValue, value];
                        })
                        .filter(([_, attrTitle]) => !!attrTitle)
                        .map(([code, attrTitle, value]) => {
                            return (
                                <div key={`${code}-${value}`}>
                                    <span>{attrTitle}: {Array.isArray(value) ? value.join(', ') : value}</span>
                                </div>
                            );
                        })
                        .concat(YearInfo)
                    || YearInfo
                }
            </div>
        </div>
    );
};

const defaultRenderBookEditActions = (props: IProps, clsNames: ClsNames): JSX.Element => {
    const { deleteBook, editBook, book } = props;
    const id = book.documentSnippet.document?.id;

    return (
        <div className={clsNames.bookDetailsEditActions}>
            <RestrictedPermission pk={PermissionKey.editBook}>
                <button
                    className={classNames(clsNames.bookDetailsEdit, 'btn-primary')}
                    onClick={() => editBook(id)}
                >
                    <span className={'icon-pencil'}/>Редактировать
                </button>
            </RestrictedPermission>
            <RestrictedPermission pk={PermissionKey.removeBook}>
                <button
                    className={classNames('btn-cancel', clsNames.bookDetailsDelete)}
                    onClick={() => deleteBook(id)}
                >
                    <span className={'icon-cross'}/>Удалить материал
                </button>
            </RestrictedPermission>
        </div>
    );
};

const defaultRenderAnnotation = ({ book }: IProps, clsNames: ClsNames): JSX.Element => {
    const writtenAnnotation = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.abstract;
    const generatedAnnotation = book &&
        book.documentSnippet &&
        book.documentSnippet.annotation;
    const isAnnotationWritten = typeof writtenAnnotation === 'string' && writtenAnnotation.length > 0;
    const annotation: string = isAnnotationWritten ?
        writtenAnnotation :
        Array.isArray(generatedAnnotation) && generatedAnnotation
            .map(a => a.content)
            .join('. ');

    return (
        <div className={clsNames.bookDetailsAnnotation}>
            <div className={clsNames.bookDetailsAnnotationTitle}>
                Аннотация ({isAnnotationWritten ? 'редакция автора' : 'выделенная автоматически'})
            </div>
            <div>{annotation}</div>
        </div>
    );
};

const shareInSocial = (
        clsNames: ClsNames,
        document: ISocialDocument,
        onShareClick: (id: string, provider: string) => void,
        withCounters: boolean
    ): JSX.Element => (
        <ul className={clsNames.bookDetailsSharePopup}>
            <li>
                <FacebookShareButton
                    className={clsNames.bookDetailsSharePopupItem}
                    url={document.url}
                    onShareWindowClose={() => {
                        withCounters &&
                        typeof document.id === 'string' &&
                        onShareClick(document.id, FACEBOOK_PROVIDER_ID);
                    }}
                >
                    <span className={'icon-fb'} />
                    <span>Facebook</span>
                </FacebookShareButton>
            </li>
            <li>
                <VKShareButton
                    className={clsNames.bookDetailsSharePopupItem}
                    url={document.url}
                    onShareWindowClose={() => {
                        withCounters &&
                        typeof document.id === 'string' &&
                        onShareClick(document.id, VK_PROVIDER_ID);
                    }}
                >
                    <span className={'icon-vk'} />
                    <span>Vkontakte</span>
                </VKShareButton>
            </li>
            <li>
                <TwitterShareButton
                    className={clsNames.bookDetailsSharePopupItem}
                    url={document.url}
                    image={document.image}
                    title={document.title}
                    onShareWindowClose={() => {
                        withCounters &&
                        typeof document.id === 'string' &&
                        onShareClick(document.id, TWITTER_PROVIDER_ID);
                    }}
                >
                    <span className={'icon-twitter'} />
                    <span>Twitter</span>
                </TwitterShareButton>
            </li>
        </ul>
    );

const defaultRenderSocialActions = (props: IProps, clsNames: ClsNames): JSX.Element => {
    const { book, changeLikeStatus, onShareClick, changeFavoriteStatus } = props;
    const documentSnippet = book && book.documentSnippet;
    const isLiked = documentSnippet && documentSnippet.liked;
    const isFavorite = documentSnippet &&
        documentSnippet.bookmarked &&
        documentSnippet.bookmarked.FAVORITE;

    const { document, annotation } = Object.assign(
        {
            document: {
                id: null,
                title: null,
                storageUrl: null,
                abstract: null
            },
            annotation: {}
        },
        documentSnippet
    );

    const documentSocialDescription: ISocialDocument = {
        id: document.id,
        url: `${APP_ROOT_URL}${clientRoutes.book.getUrl({ id: document.id})}`,
        title: document.title,
        description: document.abstract ? document.abstract.toString() : annotation.toString(),
        image: `${APP_ROOT_URL}${apiRoutes.socialNetworkBookCover.getUrl({ id: document.id, provider: FACEBOOK_PROVIDER_ID})}`,
        imageVk: `${APP_ROOT_URL}${apiRoutes.socialNetworkBookCover.getUrl({ id: document.id, provider: VK_PROVIDER_ID})}`,
        updateTime: document.modifyDate || document.creationDate || Date.now()
    };

    return (
        <div className={clsNames.bookDetailsSocialActions}>
            <Helmet>
                <meta property="fb:app_id" content={FACEBOOK_APP_ID}/>
                <meta property="og:url" content={documentSocialDescription.url} />
                <meta property="og:type" content="books.book" />
                <meta property="og:title" content={documentSocialDescription.title} />
                <meta property="og:description" content={documentSocialDescription.description} />
                <meta property="og:updated" content={documentSocialDescription.updateTime.toString(10)} />
                <meta property="og:image" content={documentSocialDescription.image} />
                <meta property="vk:image" content={documentSocialDescription.imageVk} />

                <meta name="twitter:card"  content="summary_large_image" />
                <meta name="twitter:site"  content="@irfbr_knowledge_cat" />
            </Helmet>

            <RestrictedPermission pk={ PermissionKey.viewLikeBookButton }>
                <button
                    type="button"
                    className={`btn ${clsNames.bookDetailsSocialAction}`}
                    onClick={() => {
                        typeof document.id === 'string' && changeLikeStatus(document.id, !isLiked);
                    }}
                >
                    <span
                        className={isLiked ? 'icon-thumb-up-filled' : 'icon-thumb-up'}
                    />
                    <span>мне нравится</span>
                </button>
            </RestrictedPermission>

            <Popover
                overlayClassName={styles.bookDetailsShareOverlay}
                trigger="click"
                placement="bottomLeft"
                content={
                    <React.Fragment>
                        <RestrictedPermission pk={PermissionKey.viewShareBookButton}>
                            { shareInSocial(clsNames, documentSocialDescription, onShareClick, true) }
                        </RestrictedPermission>
                        <RestrictedPermission pk={PermissionKey.viewShareBookButton} viewOnDisallowed={true}>
                            { shareInSocial(clsNames, documentSocialDescription, onShareClick, false) }
                        </RestrictedPermission>
                    </React.Fragment>
                }
            >
                <button type="button" className={`btn ${clsNames.bookDetailsShare} ${clsNames.bookDetailsSocialAction}`}>
                    <span className={'icon-share'} />
                    <span>Поделиться</span>
                </button>
            </Popover>
            <RestrictedPermission pk={PermissionKey.viewFavoriteBookButton}>
                <button
                    type="button"
                    className={`btn ${clsNames.bookDetailsSocialAction} ${clsNames.bookDetailsFavorite}`}
                    onClick={() => {
                        typeof document.id === 'string' && changeFavoriteStatus(document.id, !isFavorite);
                    }}
                >
                    <span
                        className={isFavorite ? 'icon-heart-filled' : 'icon-heart'}
                    />
                    <span>{isFavorite ? 'в избранном' : 'в избранное'}</span>
                </button>
            </RestrictedPermission>
        </div>
    );
};

const defaultRenderBody = (props: IProps, clsNames: ClsNames): JSX.Element => {
    const { book, renderCategories, renderSocialActions, renderAnnotation, renderDocumentType, renderMaterialType } = props;
    const tags = book &&
        book.documentSnippet &&
        book.documentSnippet.tags;
    const keywords = book &&
        book.documentSnippet &&
        book.documentSnippet.keywords;
    return (
        <div className={clsNames.bookDetailsBody}>
            <div className={clsNames.bookDetailsCategoryKeyWords}>
                {renderDocumentType(props, clsNames)}
                {renderCategories(props, clsNames)}
                {renderMaterialType(props, clsNames)}
                <div className={clsNames.bookDetailsKeywords}>
                    {Array.isArray(keywords) && keywords.length > 0 &&
                        <React.Fragment>
                            <div className={clsNames.bookDetailsKeywordsTitle}>
                                Ключевые слова
                            </div>
                            <div className={clsNames.bookDetailsKeywordsContent}>
                                <span>
                                    {keywords.slice(0, MAX_KEYWORDS_BOOK_DETAILS).join(', ')}
                                </span>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>

            <div className={clsNames.bookDetailsAnnotationTags}>
                {renderAnnotation(props, clsNames)}
                <div className={clsNames.bookDetailsTags}>
                    {Array.isArray(tags) && tags.map((tag, i) => (
                        <Link to={'#'} key={`${tag.name}-${i}`} className={clsNames.bookDetailsTag}>
                            {`#${tag.name}`}
                        </Link>
                    ))}
                </div>
                {renderSocialActions(props, clsNames)}
            </div>
        </div>
    );
};

const defaultRenderDocumentType = ({ book, issueInfoCatalog }: IProps, clsNames: ClsNames): JSX.Element => {
    const docType = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.meta &&
        book.documentSnippet.document.meta.type;
    const displayType = docType && issueInfoCatalog[docType] && issueInfoCatalog[docType].displayValue;
    return (
        <div className={clsNames.bookDetailsCategory}>
            {displayType &&
                <React.Fragment>
                    <div className={clsNames.bookDetailsCategoryTitle}>
                        Издание
                    </div>
                    <div className={clsNames.bookDetailsCategoryContent}>
                        <Link to={clientRoutes.search.getUrl(null, { [FilterName.PublicationType]: docType, query: '' })}>
                            {displayType}
                        </Link>
                    </div>
                </React.Fragment>
            }
        </div>
    );
};

const defaultRenderMaterialType = ({ book, categoryTitles }: IProps, clsNames: ClsNames): JSX.Element => {
    const materialType = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.materialType;
    const displayType = materialType && categoryTitles[materialType];
    return (
        <div className={clsNames.bookDetailsCategory}>
            {displayType &&
                <React.Fragment>
                    <div className={clsNames.bookDetailsCategoryTitle}>
                        Тип материала
                    </div>
                    <div className={clsNames.bookDetailsCategoryContent}>
                        <Link to={clientRoutes.search.getUrl(null, { [FilterName.MaterialType]: materialType, query: '' })}>
                            {displayType}
                        </Link>
                    </div>
                </React.Fragment>
            }
        </div>
    );
};

const defaultRenderCategories = ({ book, categoryTitles, onFilterLinkClick }: IProps, clsNames: ClsNames): JSX.Element => {
    const categories = book &&
        book.documentSnippet &&
        book.documentSnippet.categories;
    const categoriesLength = categories && categories.length || 0;
    const hasCategories = Array.isArray(categories) && categoriesLength > 0;

    return (
        <div className={clsNames.bookDetailsCategory}>
            {hasCategories &&
                <React.Fragment>
                    <div className={clsNames.bookDetailsCategoryTitle}>
                        Область знаний
                    </div>
                    <div className={clsNames.bookDetailsCategoryContent}>
                        {categories.map(cat => (
                            <Link key={cat.id} to={clientRoutes.search.getUrl(null, { [FilterName.KnowledgeArea]: cat.id, query: '' })}>
                                {categoryTitles && categoryTitles[cat.id] ?
                                    categoryTitles[cat.id] :
                                    cat.name
                                }
                            </Link>
                        ))}
                    </div>
                </React.Fragment>
            }
        </div>
    );
};

const defaultRenderAuthorsRow = (props: IProps, clsNames: ClsNames): JSX.Element => (
    <div className={clsNames.bookDetailsAuthors}>
        {props.renderAuthorNames(props, clsNames)}
    </div>
);

const defaultRenderAuthorNames = ({ book }: IProps, clsNames: ClsNames): JSX.Element => {
    const authorsOrEditor = book && getAuthorsOrEditor(book.documentSnippet);
    return (
        <div className={clsNames.bookDetailsAuthorNames}>
            {Array.isArray(authorsOrEditor) && authorsOrEditor.map((author, i) => (
                <Link to={'#'} key={i} className={clsNames.bookDetailsAuthor}>
                    {author}
                </Link>
            ))}
        </div>
    );
};

const defaultRenderHeader = ({ book }: IProps, clsNames: ClsNames): JSX.Element => (
    <header>
        <h1 className={clsNames.bookDetailsTitle}>
            {book && book.documentSnippet && book.documentSnippet.document && book.documentSnippet.document.title || ''}
        </h1>
    </header>
);

const defaultRenderStats = ({ book }: IProps): JSX.Element => {
    const documentSnippet = book && book.documentSnippet;
    const stats = documentSnippet && documentSnippet.stats;
    const isPublished = documentSnippet && documentSnippet.document && documentSnippet.document.isPublished;

    return (
        <BookStats stats={stats} isPublished={isPublished} />
    );
};

const defaultRenderRightColumn = (props: IProps, clsNames: ClsNames): JSX.Element => {
    const { renderAuthorsRow, renderHeader, renderBody, renderStats } = props;

    return (
        <div className={clsNames.bookDetailsRightColumn}>
            {renderAuthorsRow(props, clsNames)}
            {renderHeader(props, clsNames)}
            {renderStats(props, clsNames)}
            {renderBody(props, clsNames)}
        </div>
    );
};

const BookDetailsBase: React.FunctionComponent<IProps> = (props) => {
    const {
        isMocked: isSkeleton, renderRightColumn, renderLeftColumn, className, onLoadingBookStart, onLoadingBookEnd, children,
        checkDownloadableContent, contentChecker, book
    } = props;

    const title = book &&
        book.documentSnippet &&
        book.documentSnippet.document &&
        book.documentSnippet.document.title;

    //ref для того, чтобы не добавлять title в deps useEffect, чтобы не подписываться/отписываться на Subject несколько раз при смене книги
    const titleRef = useRef(title);
    titleRef.current = title;

    useEffect(() => { isSkeleton && onLoadingBookStart && onLoadingBookStart(); }, [isSkeleton]);
    useEffect(() => { !isSkeleton && onLoadingBookEnd && onLoadingBookEnd(); }, [isSkeleton]);

    useEffect(
        () => {
            const subscription = contentChecker.subscribe((id) => {
                const href = apiRoutes.downloadBook.getUrl({ id, title: titleRef.current });
                openLinkNewTab(href, '');
            });

            return () => subscription.unsubscribe();
        },
        []
    );

    const onDownloadButtonClick = useCallback(
        (id: string) => {
            checkDownloadableContent(id);
        },
        []
    );

    return typeof children === 'function' ?
        children(props, styles, { onDownloadButtonClick }) :
        (
            <article
                className={classNames(
                    className,
                    styles.bookDetails,
                    { [styles.bookDetailsSkeleton]: isSkeleton }
                )}
            >
                {renderLeftColumn(props, styles, { onDownloadButtonClick })}
                {renderRightColumn(props, styles)}
            </article>
        );
};

BookDetailsBase.defaultProps = {
    book: {
        documentSnippet: {}
    },
    renderLeftColumn: defaultRenderLeftColumn,
    renderBookEditActions: defaultRenderBookEditActions,
    renderRightColumn: defaultRenderRightColumn,
    renderBody: defaultRenderBody,
    renderAuthorsRow: defaultRenderAuthorsRow,
    renderAuthorNames: defaultRenderAuthorNames,
    renderHeader: defaultRenderHeader,
    renderStats: defaultRenderStats,
    renderCategories: defaultRenderCategories,
    renderDocumentType: defaultRenderDocumentType,
    renderMaterialType: defaultRenderMaterialType,
    renderAnnotation: defaultRenderAnnotation,
    renderSocialActions: defaultRenderSocialActions,
};

export default BookDetailsBase;
