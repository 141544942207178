/**
 * Created by lkarmelo on 29.03.2019.
 */
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import BookDetailsWithDataMock from './BookDetailsWithDataMock';

import {
    IBookDetailsStateProps as IStateProps,
    IBookDetailsOwnProps as IOwnProps,
    IBookDetailsActionProps as IActionProps
} from './interfaces/IBookDetailsProps';
import {ExtendedStore} from 'common/store';

import {filtersValueToLabelSelector} from 'app/redux/selectors/filtersValueToLabelSelector';
import {documentContentChecker} from 'app/redux/epics/loadObjectCard';
import {checkDocumentContent} from 'app/redux/actions/objectCard';
import {requestLikeStateChange} from 'app/redux/actions/like';
import {Store} from 'nkc-frontend-tools/types';
import {addMultiSelectFilterValue} from 'nkc-frontend-tools/redux/actions/search/filters';
import { shareDocument } from 'app/redux/actions/share';
import {requestFavoriteStateChange} from 'app/redux/actions/favorite';
import {showPopup} from 'app/redux/actions/popups';
import {setFormData, setFormBackURL} from 'app/redux/actions/forms/forms';

import {PopupName} from 'app/types/PopupName';
import {FormName} from 'app/types/FormName';

import clientRoutes from 'common/clientRoutes';
import { RouteComponentProps, withRouter } from 'react-router';

const issueInfoCatalogSelector = createSelector(
    (state: ExtendedStore.IState): ExtendedStore.IIssueInfoCatalogItem[] => state.issueInfoCatalog,
    (catalog: ExtendedStore.IIssueInfoCatalogItem[]): IStateProps['issueInfoCatalog'] => Array.isArray(catalog) ?
        catalog.reduce(
            (map, c) => {
                map[c.code] = c;
                return map;
            },
            {}
        )
        : {}
);

export const mapStateToProps = (state: ExtendedStore.IState, ownProps: IOwnProps & RouteComponentProps): IStateProps & IOwnProps => ({
    ...ownProps,
    issueInfoCatalog: issueInfoCatalogSelector(state),
    categoryTitles: filtersValueToLabelSelector(state),
    contentChecker: documentContentChecker,
});

export const mapDispatchToProps = (dispatch, ownProps: IOwnProps & RouteComponentProps): IActionProps => {
    const {history, location} = ownProps;

    return {
        checkDownloadableContent(id: string): void {
            dispatch(checkDocumentContent(id));
        },
        changeLikeStatus(id: string, nextLikeStatus: boolean): void {
            dispatch(requestLikeStateChange(id, nextLikeStatus));
        },
        onShareClick(id: string, provider: string): void {
            dispatch(shareDocument({id, provider}));
        },
        changeFavoriteStatus(id: string, nextFavoriteStatus: boolean): void {
        dispatch(requestFavoriteStateChange(id, nextFavoriteStatus));
        },
        onFilterLinkClick(filterName: string, value: Store.IFilterValue): void {
            dispatch(addMultiSelectFilterValue(filterName, value));
        },
        deleteBook(id: string): void {
            dispatch(showPopup(PopupName.ConfirmDeleteBook));
            dispatch(setFormData(FormName.ConfirmDeleteBook, {bookId: id}));
        },
        editBook(id: string): void {
            dispatch(setFormBackURL(FormName.AddEditDocumentForm, location.pathname + location.search));

            history.push(clientRoutes.upload.getUrl({ id }));
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookDetailsWithDataMock));
