/**
 * Created by Lkarmelo on 05.09.2017.
 */

import React from 'react';
import {Switch} from 'react-router-dom';

import RouteWithLayout from './components/RouteWithLayout';
import RouteWithSubRoutes from './components/RouteWithSubRoutes';

export interface IConfigItem {
    layout?: React.ComponentType;
    path: string;
    component: React.ComponentType;
    key?: string;
    options?: {
        exact?: boolean
    };
    authOnly?: boolean;
    unauthOnly?: boolean;
    subRoutes?: IConfigItem[];
}

export interface IRouteComponents {
    AuthRoute?: React.ComponentType;
    AuthRouteWithLayout?: React.ComponentType;
    UnauthRoute?: React.ComponentType;
    UnauthRouteWithLayout?: React.ComponentType;
}

const defaultRouteComponents: IRouteComponents = {
    AuthRoute: RouteWithSubRoutes,
    AuthRouteWithLayout: RouteWithLayout,
    UnauthRoute: RouteWithSubRoutes,
    UnauthRouteWithLayout: RouteWithLayout,
};

export const renderRoutesComponents = (cfg: IConfigItem[], components?: IRouteComponents): JSX.Element => {
    const {
        UnauthRouteWithLayout,
        UnauthRoute,
        AuthRouteWithLayout,
        AuthRoute,
    } = {...defaultRouteComponents, ...components};
    return (
        <Switch>
            {cfg.map(conf => {
                const props = {
                    key: conf.key || `route-${conf.path}`,
                    layout: conf.layout,
                    path: conf.path,
                    component: conf.component,
                    subRoutes: conf.subRoutes && renderRoutesComponents(conf.subRoutes, components),
                    ...conf.options
                };

                if (conf.unauthOnly) {
                    return conf.layout ?
                        <UnauthRouteWithLayout {...props} /> :
                        <UnauthRoute {...props} />;
                }

                if (conf.authOnly) {
                    return conf.layout ?
                        <AuthRouteWithLayout {...props} /> :
                        <AuthRoute {...props} />;
                }
                return conf.layout ?
                    <RouteWithLayout {...props} /> :
                    <RouteWithSubRoutes {...props} />;
            })}
        </Switch>
    );
};
