import React from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { ExtendedStore } from 'common/store';

export const useHandleFormStatus = (
    formStatus: ExtendedStore.FormStatus,
    resetFormStatus: () => void,
    onSuccess: () => void,
    onFail?: () => void,
    onEdited?: () => void,
    onPending?: () => void,
    onCompleted?: () => void
) => {
    useEffect(
        () => {
            switch (formStatus) {
                case ExtendedStore.FormStatus.Accepted:
                    onSuccess();
                    break;
                case ExtendedStore.FormStatus.Rejected:
                    onFail && onFail();
                    break;
                case ExtendedStore.FormStatus.Edited:
                    onEdited && onEdited();
                    break;
                case ExtendedStore.FormStatus.Pending:
                    onPending && onPending();
                    break;
                case ExtendedStore.FormStatus.Completed:
                    onCompleted && onCompleted();
                    break;
                default:
            }

            if (formStatus !== null && formStatus !== undefined) {
                resetFormStatus();
            }
        },
        [formStatus, onSuccess, onFail, onEdited, onPending, onCompleted, resetFormStatus]
    );
};

export const useFormSenderHelper = <T = any>(
    formStatus: ExtendedStore.FormStatus,
    resetFormStatus: () => void,
    onSuccess?: () => void,
    onFail?: () => T,
    onEdited?: () => void,
    onPending?: () => void,
    onCompleted?: () => void
): React.MutableRefObject<Function | null> => {
    //из-за того, что react-final-form ожидает либо асинхронный вызоы калбека, либо промис в методе отправки,
    //приходится калбек сохранять в ref и вызывать из этого ref
    const submitCb = useRef(null);

    const eventCallback = (onEvent?: () => T | void, defaultValue?: T | string) =>
        useCallback(
            () => {
                const result = onEvent && onEvent() || defaultValue;
                if (submitCb.current) {
                    result !== undefined
                        ? submitCb.current(result)
                        : submitCb.current();
                }

                submitCb.current = null;
            },
            [onEvent]
        );

    useHandleFormStatus(
        formStatus,
        resetFormStatus,
        eventCallback(onSuccess),
        eventCallback(onFail, 'Что-то пошло не так'),
        eventCallback(onEdited),
        eventCallback(onPending),
        eventCallback(onCompleted)
    );

    return submitCb;
};
