/**
 * Created by lkarmelo on 02.10.2019.
 */

import {Action, handleActions} from 'redux-actions';
import initialState from 'common/store/storeInitialState';

import {
    setReaderPageContent, setReaderCurrentPageNumber, setReaderCurrentBookId,
    setReaderCurrentPageNumberAndBookId, ISetReaderPageNumberAndBookIdPayload,
    readerNextPage, readerPrevPage, setReadingBookMeta, resetReader,
    setReaderBookScale, setBookmarks, addBookmark, removeBookmark, IAddBookmarkPayload
} from '../actions/bookReader';
import {ExtendedStore} from 'common/store';

export default handleActions<ExtendedStore.IBookReader, any>(
    {
        [setReaderPageContent.toString()](
            state: ExtendedStore.IBookReader,
            {payload}: Action<SVGSVGElement | null>
        ): ExtendedStore.IBookReader {
            return {
                ...state,
                pages: {
                    currentPage: payload,
                },
            };
        },
        [setReadingBookMeta.toString()](
            state: ExtendedStore.IBookReader,
            {payload}: Action<ExtendedStore.IReadingBookMeta>
        ): ExtendedStore.IBookReader {
            return {
                ...state,
                meta: payload,
            };
        },
        [setReaderCurrentPageNumber.toString()](state: ExtendedStore.IBookReader, {payload}: Action<number>): ExtendedStore.IBookReader {
            if (state.currentPageNumber === payload) {
                return state;
            }
            return {
                ...state,
                currentPageNumber: payload
            };
        },
        [setReaderCurrentBookId.toString()](state: ExtendedStore.IBookReader, {payload}: Action<string>): ExtendedStore.IBookReader {
            if (state.currentBookId === payload) {
                return state;
            }
            return {
                ...state,
                currentBookId: payload
            };
        },
        [setReaderCurrentPageNumberAndBookId.toString()](
            state: ExtendedStore.IBookReader,
            {payload}: Action<ISetReaderPageNumberAndBookIdPayload>
        ): ExtendedStore.IBookReader {
            if (state.currentBookId === payload.bookId && state.currentPageNumber === payload.page) {
                return state;
            }
            return {
                ...state,
                currentBookId: payload.bookId,
                currentPageNumber: payload.page
            };
        },
        [readerNextPage.toString()](state: ExtendedStore.IBookReader): ExtendedStore.IBookReader {
            return {
                ...state,
                currentPageNumber: state.currentPageNumber + 1,
            };
        },
        [readerPrevPage.toString()](state: ExtendedStore.IBookReader): ExtendedStore.IBookReader {
            if (state.currentPageNumber === 1) {
                return state;
            }
            return {
                ...state,
                currentPageNumber: state.currentPageNumber - 1,
            };
        },
        [setReaderBookScale.toString()](state: ExtendedStore.IBookReader, {payload}: Action<number>): ExtendedStore.IBookReader {
            return {
                ...state,
                meta: {
                    ...state.meta,
                    currentScale: payload,
                }
            };
        },
        [setBookmarks.toString()](
            state: ExtendedStore.IBookReader,
            {payload}: Action<ExtendedStore.IBookmark[]>
        ): ExtendedStore.IBookReader {
            return {
                ...state,
                bookmarks: payload
            };
        },
        [addBookmark.toString()](
            state: ExtendedStore.IBookReader,
            {payload}: Action<IAddBookmarkPayload>
        ): ExtendedStore.IBookReader {
            const nextBookmarks: ExtendedStore.IBookmark[] = Array.isArray(state.bookmarks) ? state.bookmarks.slice() : [];

            const firstBookmarkWithGreaterPageIndex = nextBookmarks.findIndex(({bookId, page}) =>
                bookId === payload.bookId && page >= payload.page
            );

            if (nextBookmarks[firstBookmarkWithGreaterPageIndex]?.page === payload.page) {
                return state;
            }

            if (firstBookmarkWithGreaterPageIndex >= 0) {
                const bookmarksWithLesserPage = nextBookmarks.slice(0, firstBookmarkWithGreaterPageIndex);
                const bookmarksWithGreaterPage = nextBookmarks.slice(firstBookmarkWithGreaterPageIndex);

                return {
                    ...state,
                    bookmarks: [...bookmarksWithLesserPage, {...payload}, ...bookmarksWithGreaterPage]
                };
            }

            nextBookmarks.push({...payload});

            return {
                ...state,
                bookmarks: nextBookmarks
            };
        },
        [removeBookmark.toString()](
            state: ExtendedStore.IBookReader,
            {payload}: Action<ISetReaderPageNumberAndBookIdPayload>
        ): ExtendedStore.IBookReader {
            if (!Array.isArray(state.bookmarks)) {
                return state;
            }

            const nextBookmarks = state.bookmarks.filter(({bookId, page}) => bookId !== payload.bookId || page !== payload.page);

            return {
                ...state,
                bookmarks: nextBookmarks
            };
        },
        [resetReader.toString()](): ExtendedStore.IBookReader {
            return initialState.bookReader;
        }
    },
    initialState.bookReader
);
