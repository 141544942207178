/**
 * Created by lkarmelo on 20.02.2019.
 */

import React from 'react';

import IProps from './interfaces/IBookStatsProps';

import * as styles from './BookStats.scss';

const BookStats: React.FunctionComponent<IProps> = ({stats, isPublished}: IProps) => {
    const viewsCount = stats && stats.viewsCount;
    const likesCount = stats && stats.likesCount;
    const sharesCount = stats && stats.sharesCount;

    return (
        <div className={styles.bookStats}>
            {isPublished ?
                <span>
                    <span className={styles.bookStatsViews}>
                        <span className={`${styles.bookStatsIcon} icon-eye-mini`} />
                        <span>{viewsCount || 0}</span>
                    </span>
                    <span className={styles.bookStatsLikes}>
                        <span className={`${styles.bookStatsIcon} icon-thumb-up`} />
                        <span>{likesCount || 0}</span>
                    </span>
                    <span className={styles.bookStatsShares}>
                        <span className={`${styles.bookStatsIcon} icon-share`} />
                        <span>{sharesCount || 0}</span>
                    </span>
                </span>
                :
                <div className={styles.bookStatsNotPublishedLabel}>Не опубликован</div>
            }
        </div>
    );
};

export default BookStats;
