import React from 'react';
import {Component} from 'react';

/**
 * Created by lkarmelo on 11.02.2019.
 */

interface IDataMockProviderCreator<TProps = any> {
    propsMock: Partial<TProps> | ( (props: TProps) => Partial<TProps> );
    passIsMockedProp?: boolean;
    shouldMock(props: TProps): boolean;
}

export interface IWithDataMockProps {
    isMocked?: boolean;
}

export const createDataMockProvider = <TProps extends {}>(options: IDataMockProviderCreator<TProps>) =>
    (WrappedComponent: React.ComponentType<TProps>): React.ComponentType<TProps & IWithDataMockProps> =>
        class SkeletonDataProvider extends Component<TProps & IWithDataMockProps> {
            render(): JSX.Element {
                const {propsMock, shouldMock} = options;

                const isMocked = shouldMock(this.props);
                const mock = isMocked ? ( typeof propsMock === 'function' ? propsMock(this.props) : propsMock ) : {};
                const passIsMockedProp = options.passIsMockedProp === undefined ? true : options.passIsMockedProp;

                return <WrappedComponent {...this.props} {...mock} isMocked={passIsMockedProp ? isMocked : undefined} />;
            }
        };
